import { Button, ConfigProvider, Select, Space, Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import {
  useExportExpiringDocumentsReportMutation,
  useLazyGetAllExpiringDocumentsQuery,
} from "../redux/api/reporting/reporting";
import IncredableDateFormat from "../common/incredable-date-format";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import useFileExport from "../hooks/useFileExport";

export default function ExpiringDocumentsReport() {
  const [days, setDays] = useState<string>("180");
  const handleChange = (days: string) => {
    setDays(days);
  };
  const { taskId, setTaskId } = useFileExport("csv");
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [sortingDetails, setSortingDetails] = useState<{
    sortingField?: string;
    isDescending?: boolean;
  }>({ sortingField: "id", isDescending: false });
  const [exportExpiringDocumentsReport] =
    useExportExpiringDocumentsReportMutation();

  const [
    trigger,
    { data: expiringDocumentsReportsData, isLoading, isFetching },
  ] = useLazyGetAllExpiringDocumentsQuery({});

  // Debounced trigger function
  const debouncedTrigger = useCallback(
    debounce((filters) => {
      trigger(filters);
    }, 1200),
    [trigger]
  );

  // Clean up debounce on unmount
  useEffect(() => {
    return () => {
      debouncedTrigger.cancel();
    };
  }, [debouncedTrigger]);

  useEffect(() => {
    debouncedTrigger({
      days,
      pageNumber,
      pageSize,
      sortBy: sortingDetails?.sortingField,
      direction: !!sortingDetails?.isDescending ? "DSC" : "ASC",
    });
  }, [days, pageNumber, pageSize, sortingDetails, debouncedTrigger]);

  return (
    <div style={{ padding: "32px 32px 0 32px" }}>
      <Table
        loading={isFetching || isLoading}
        sortingColumn={{ sortingField: sortingDetails?.sortingField }}
        sortingDescending={sortingDetails?.isDescending}
        onSortingChange={({ detail }) => {
          setSortingDetails({
            sortingField: detail?.sortingColumn?.sortingField,
            isDescending: detail?.isDescending,
          });
        }}
        columnDefinitions={[
          {
            id: "documentName",
            header: "Document Name",
            cell: (item) => <span>{item?.document?.name}</span>,
            sortingField: "name",
            isRowHeader: true,
          },
          {
            id: "category",
            header: "Category",
            cell: (item) => <span>{item?.document?.category?.name}</span>,
            sortingField: "category.name",
            isRowHeader: true,
          },
          {
            id: "createdDate",
            header: "Document Created Date",
            cell: (item) => IncredableDateFormat(item?.document?.createdDate),
            sortingField: "email",
          },
          {
            id: "expirationDate",
            header: "Expiration Date",
            cell: (item) =>
              IncredableDateFormat(item?.document?.expirationDate),
            sortingField: "expirationDate",
          },
          {
            id: "providerLastName",
            header: "Provider Last Name",
            cell: (item) => item?.provider?.lastName,
            sortingField: "provider?.lastName",
          },
          {
            id: "providerFirstName",
            header: "Provider First Name",
            cell: (item) => item?.provider?.firstName,
            sortingField: "provider?.firstName",
          },
          {
            id: "npiNumber",
            header: "NPI Number",
            cell: (item) => item?.provider?.npiNumber,
            sortingField: "provider.npiNumber",
          },
          {
            id: "email",
            header: "Email",
            cell: (item) => item?.provider?.email,
            sortingField: "provider.email",
          },
          {
            id: "LicenseType",
            header: "License Type",
            cell: (item) => (
              <span>{item?.provider?.licenseType?.name || "-"}</span>
            ),
            sortingField: "provider.licenseType.name",
          },
          {
            id: "identificationNumber",
            header: "Identification Number",
            cell: (item) => (
              <span>{item?.provider?.identificationNumber || "-"}</span>
            ),
            sortingField: "provider.identificationNumber",
          },
          {
            id: "providerFacility",
            header: "Provider Facility",
            cell: (item) => (
              <span>{item?.provider?.facility?.name || "-"}</span>
            ),
            sortingField: "provider.facility.name",
          },
          {
            id: "facility",
            header: "Document Facility",
            cell: (item) => item?.provider?.facility?.name,
            sortingField: "facility.name",
          },
          {
            id: "notes",
            header: "Notes",
            cell: (item) => item?.document?.notes,
          },
        ]}
        items={
          !!expiringDocumentsReportsData
            ? expiringDocumentsReportsData?.documents
            : []
        }
        pagination={
          <Pagination
            currentPageIndex={pageNumber + 1}
            onChange={({ detail }) => {
              setPageNumber(detail.currentPageIndex - 1);
            }}
            pagesCount={
              !!pageSize && pageSize !== 0
                ? Math.ceil(
                    !!expiringDocumentsReportsData
                      ? expiringDocumentsReportsData?.totalRecords / pageSize
                      : 0
                  )
                : 1
            }
          />
        }
        loadingText="Loading resources"
        trackBy="id"
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#2B6BE6",
                    },
                  }}
                >
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    loading={!!taskId}
                    onClick={() => {
                      const taskId = "Expiring_Documents_Report_" + Date.now();
                      exportExpiringDocumentsReport({
                        taskId: taskId,
                        days: days,
                      });
                      setTaskId(taskId);
                    }}
                  >
                    Export CSV
                  </Button>
                </ConfigProvider>
              }
            >
              <Space direction="vertical">
                <Typography.Title level={3} style={{ marginTop: "0" }}>
                  Expiring Documents Reports{" "}
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "16px" }}
                  >{`(${
                    expiringDocumentsReportsData?.totalRecords || 0
                  })`}</Typography.Text>
                </Typography.Title>
                <Select
                  value={days}
                  placeholder="Select Days"
                  showSearch
                  onChange={handleChange}
                  style={{ width: "240px", borderColor: "#7C4095" }}
                  options={[
                    { label: "Last 180 days", value: "180" },
                    { label: "Last 120 days", value: "120" },
                    { label: "Last 90 days", value: "90" },
                    { label: "Last 60 days", value: "60" },
                    { label: "Last 30 days", value: "30" },
                  ]}
                />
              </Space>
            </Header>
          </div>
        }
      />
    </div>
  );
}
