import {
  useFormContext,
  Controller,
  UseControllerProps,
  RegisterOptions,
  FieldValues,
  FieldError,
} from "react-hook-form";
import {
  Button,
  Divider,
  Form,
  FormItemProps,
  Select,
  SelectProps,
  Typography,
} from "antd";

// ----------------------------------------------------------------------

type Props = {
  formItemProps: FormItemProps;
  selectProps: SelectProps & { onModifyClick?: VoidFunction };
  controllerProps: UseControllerProps;
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
      >
    | undefined;
};

export default function RHFSelect({
  formItemProps,
  selectProps,
  controllerProps,
  rules,
}: Props) {
  const { control } = useFormContext();
  const { Text } = Typography;
  return (
    <Controller
      name={controllerProps.name}
      control={control}
      defaultValue={controllerProps.defaultValue}
      render={({ field, fieldState }) => {
        return (
          <Form.Item
            {...formItemProps}
            label={
              <span style={{ fontWeight: "600" }}>
                {formItemProps?.label}{" "}
                {!!rules?.required ? <Text type="danger">*</Text> : <></>}
              </span>
            }
            // required={!!rules?.required}
            validateStatus={!!fieldState.error ? "error" : ""}
            help={!!fieldState.error && getErrorMessage(fieldState.error)}
          >
            <Select
              {...selectProps}
              optionFilterProp="label"
              style={{
                width: "100%",
                display: "block",
                borderRadius: "6px",
                boxShadow: "0px 1px 2px 0px #1018280A",
              }}
              value={selectProps.options?.find(
                (option) => option?.value + "" === field.value + ""
              )}
              onChange={(value, option) => {
                field.onChange(value);
              }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  {!!selectProps.onModifyClick && (
                    <Divider style={{ margin: "8px 0" }} />
                  )}
                  {!!selectProps.onModifyClick && (
                    <Button
                      type="primary"
                      style={{ width: "100%" }}
                      onClick={selectProps.onModifyClick}
                    >
                      Modify
                    </Button>
                  )}
                </>
              )}
            />
          </Form.Item>
        );
      }}
      rules={rules}
    />
  );
}

const getErrorMessage = (error?: FieldError | undefined) => {
  if (!error) return;
  if (error.message && error.message.length > 0) return error.message;
  if (error.type === "maxLength") return "Value is too long";
  return "Invalid value";
};
