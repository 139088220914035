import {
  Box,
  Link,
  SpaceBetween,
  StatusIndicator,
  Table,
} from "@cloudscape-design/components";
import {
  Button,
  Card,
  Flex,
  Form,
  InputNumber,
  Select,
  Space,
  Typography,
} from "antd";
import DEALogoSVG from "../../../images/dea-logo";
import Icon from "@ant-design/icons";
import { capitalize } from "lodash";
import {
  useGetAllDEAVerificationQuery,
  useStartDEAVerificationMutation,
} from "../../../redux/api/verification";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { DEAVerificationType } from "../../../redux/api/verification/types";
import { useFormContext } from "react-hook-form";
import RHFSelect from "../../../components/RHF/RHFSelect";
import RHFTextField from "../../../components/RHF/RHFTextField";
const { Option } = Select;
const { Text } = Typography;

export default function DEAVerification({ deaId, deaNumber }: { deaId?: string, deaNumber?: string }) {
  const [startDEAVerification] = useStartDEAVerificationMutation();
  useFormContext();
  const { data } = useGetAllDEAVerificationQuery(
    !!deaId ? { recordId: deaId } : skipToken
  );
  return (
    <SpaceBetween size="m">
      <Card
        title={
          <SpaceBetween size={"s"} direction="horizontal">
            <Icon component={DEALogoSVG} />
            <div style={{ marginTop: "12px", fontSize: "18px" }}>
              DEA Verification
            </div>
          </SpaceBetween>
        }
        type="inner"
      >
        <div
          style={{
            paddingTop: "12px",
            paddingBottom: "24px",
          }}
        >
          <Text
            code
            style={{
              marginTop: 0,
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            DEA Number: <b>{deaNumber}</b>
          </Text>
        </div>
        <Space size="small" align="baseline">
          <span
            style={{
              fontSize: "14px",
              fontWeight: "500",
              color: "rgb(15, 20, 26)",
            }}
          >
            Run verification
          </span>
          <Space.Compact>
            <RHFTextField
              formItemProps={{
                noStyle: true,
              }}
              inputProps={{ style: { width: "40%" }, placeholder: "e.g. 60" }}
              controllerProps={{ name: "deaVerificationSchedule.timeValue" }}
            />
            <RHFSelect
              formItemProps={{
                noStyle: true,
              }}
              controllerProps={{ name: "deaVerificationSchedule.timeUnit" }}
              selectProps={{
                allowClear: true,
                showSearch: true,
                style: { width: "60%" },
                placeholder: "e.g. Days, Weeks, Months",
                options: [
                  {
                    label: "Days",
                    value: "days",
                  },
                  {
                    label: "Weeks",
                    value: "weeks",
                  },
                  {
                    label: "Months",
                    value: "months",
                  },
                ],
              }}
            />
          </Space.Compact>
          <span
            style={{
              fontSize: "14px",
              fontWeight: "500",
              color: "rgb(15, 20, 26)",
            }}
          >
            before expiration date.
          </span>
        </Space>
      </Card>
      <Table
        columnDefinitions={[
          {
            id: "deaNumber",
            header: "DEA Number",
            cell: (item: DEAVerificationType) => item.deaNumber || "-",
          },
          {
            id: "status",
            header: "Status",
            cell: (item: DEAVerificationType) => (
              <StatusIndicator
                type={
                  item?.status === "success"
                    ? "success"
                    : item?.status === "failed"
                      ? "warning"
                      : "loading"
                }
              >
                {item?.status === "warning"
                  ? "Failed"
                  : capitalize(item?.status ?? "loading")}
              </StatusIndicator>
            ),
          },
          {
            id: "file",
            header: "File ",
            cell: (item) =>
              !!item?.attachment?.name ? (
                <Link href="#">{item?.attachment?.name}</Link>
              ) : (
                "-"
              ),
          },
          {
            id: "expirationdate",
            header: "Expiration date",
            cell: (item: DEAVerificationType) => item?.expirationDate ?? "-",
          },
          {
            id: "rundate",
            header: "Run date",
            cell: (item: DEAVerificationType) => item?.runDate ?? "-",
          },
          {
            id: "completedate",
            header: "Completed date",
            cell: (item: DEAVerificationType) => item?.completionDate ?? "-",
          },
        ]}
        enableKeyboardNavigation
        items={data ?? []}
        loadingText="Loading resources"
        sortingDisabled
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <SpaceBetween size="m">
              <b>No resources</b>
              <Button>Create resource</Button>
            </SpaceBetween>
          </Box>
        }
        header={
          <Flex justify="space-between">
            <span style={{ fontSize: "16px", fontWeight: "600" }}>History</span>
            <Button
              type="primary"
              onClick={() => {
                if (!!deaId) startDEAVerification({ recordId: deaId });
              }}
            >
              Run now
            </Button>
          </Flex>
        }
      />
    </SpaceBetween>
  );
}
