import { useEffect } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import { captureException } from "@sentry/react";
import { PATH_AUTH, PATH_DASHBOARD } from "./path";
import AuthGuard from "../gaurds/AuthGaurd";
import IncredableLayout from "../layout/incredable-layout";
import ProviderDetails from "../provider/providerDetails";
import Dashboard from "../dashboard";
import PersonalInfo from "../provider/providerDetails/personalInfo";
import AddProvider from "../provider/modify/add-provider";
import EditProvider from "../provider/modify/edit-provider";
import IncredableToast from "../context/toast/incredable-toast";
import IncredableSpinner from "../context/spinner/spinner";
import AddDocument from "../document/modify/add-document";
import ProfessionalInfo from "../provider/providerDetails/professionalInfo";
import PermissionGaurd from "../gaurds/PermissionGaurd";
import EditProviderAffiliation from "../provider/modify/edit-provideraffiliation";
import EditProviderDEA from "../provider/modify/edit-providerdea";
import EditProviderEducation from "../provider/modify/edit-providereducation";
import EditProviderLiabilityInsurance from "../provider/modify/edit-providerliabilityinsurance";
import EditProviderLicense from "../provider/modify/edit-providerlicense";
import EditProviderPeerReference from "../provider/modify/edit-providerpeerreference";
import EditProviderSpecialtyInfo from "../provider/modify/edit-providerspecialtyinfo";
import EditProviderThirdPartyLogin from "../provider/modify/edit-providerthirdpartylogin";
import EditProviderWorkHistory from "../provider/modify/edit-providerworkhistory";
import ReadyFormsList from "../digitalforms";
import AddDigitalform from "../digitalforms/add-digitalform";
import EditDigitalform from "../digitalforms/edit-digitalform";
import WorkFlowList from "../workflow/workflow-list";
import EditWorkflow from "../workflow/modify/edit-workflow";
import EditProviderPersonalInfo from "../provider/modify/edit-providerpersonalinfo";
import AddFacility from "../facility/modify/add-facility";
import EditFacility from "../facility/modify/edit-facility";
import AddContract from "../contracts/modify/add-contract";
import EditContract from "../contracts/modify/edit-contract";
import EditProviderProfessionalInfo from "../provider/modify/edit-providerprofessionalInfo";
import TemplateEditor from "../digitalforms/template-editor";
import CredentialPackagesList from "../credentialpackages/credentialpackages-list";
import AddCredentialPackage from "../credentialpackages/packages/add-packages";
import EditCredentialPackages from "../credentialpackages/packages/edit-packages";
import AddTask from "../tasks/modify/add-task";
import TaskList from "../tasks/task-list";
import ProvidersReport from "../reports/providersreports";
import PayerEnrollmentsReport from "../reports/payerenrollmentsreports";
import ProviderAffiliationsReport from "../reports/provideraffiliationreports";
import AllDocumentsReport from "../reports/allDocumentsreport";
import ExpiringDocumentsReport from "../reports/expiringdocumentsreport";
import ExpiredDocumentsReport from "../reports/expiriedDocumentsreport";
import AddSignatureRequest from "../digitalforms/add-signature-request";
import AddPayerEnrollment from "../payerenrollment/modify/add-payerenrollment";
import EditPayerEnrollment from "../payerenrollment/modify/edit-payerenrollment";
import AddWorkflowTasks from "../workflow/workflowtasks/add-workflowtask";
import EditWorkflowTasks from "../workflow/workflowtasks/edit-workflowtask";
import EditTask from "../tasks/modify/edit-task";
import AddPayer from "../manage/payer/modify/add-payer";
import EditPayer from "../manage/payer/modify/edit-payer";
import AddPayerGroup from "../manage/payergroup/modify/add-payergroup";
import EditPayerGroup from "../manage/payergroup/modify/edit-payergroup";
import AddFacilityGroup from "../manage/facilitygroup/modify/add-facilitygroup";
import EditFacilityGroup from "../manage/facilitygroup/modify/edit-facilitygroup";
import EditLicenseType from "../manage/licensetypes/modify/edit-licensetype";
import AddLicenseType from "../manage/licensetypes/modify/add-licensetype";
import AddCategory from "../manage/category/modify/add-category";
import EditCategory from "../manage/category/modify/edit-category";
import AddWorkFlowTemplate from "../manage/workflowTemplate/modify/add-workflowtemplate";
import EditWorkFlowTemplate from "../manage/workflowTemplate/modify/edit-workflowtemplate";
import AddCredentialTemplate from "../manage/credentialtemplate/modify/add-credentialtemplate";
import EditCredentialTemplateDetails from "../manage/credentialtemplate/modify/edit-credentialtemplatedetails";
import EditDocument from "../document/modify/edit-document";
import Profile from "../profile";
import RolesAndPermissions from "../roles/roles-list";
import AddProviderEnrolledPayer from "../provider/modify/add-providerenrolledpayer";
import EditProviderEnrolledPayer from "../provider/modify/edit-providerenrolledpayer";
import AddAffiliation from "../affiliation/modify/add-affiliation";
import RoleDetails from "../roles/manage-roles/role-details";
import OrganizationSettings from "../organization";
import OrgBasicDetailsEdit from "../organization/org-basic-details-edit";
import ManageAdmin from "../manageadmin/manageadmin";
import AddInviteMember from "../manageadmin/modify/add-invitemember";
import EditActiveMember from "../manageadmin/modify/edit-activemembers";
import ContractsTypeList from "../contracts/contracttype";
import PayerEnrollmentStatusList from "../payerenrollment/payerenrollmentstatus";
import CredentialPackageTypeList from "../credentialpackages/packagestype";
import CredentialPackageStatusList from "../credentialpackages/packageStatus";
import WorkFlowStatusList from "../workflow/workflowstatus";
import TasksTypeList from "../tasks/taskstype";
import TasksStatusList from "../tasks/tasksstatus";
import AddContractType from "../contracts/contracttype/modify/add-contracttype";
import EditContractType from "../contracts/contracttype/modify/edit-contracttype";
import AddPackageType from "../credentialpackages/packagestype/modify/add-packagetype";
import EditPackageType from "../credentialpackages/packagestype/modify/edit-packagetype";
import AddPackageStatus from "../credentialpackages/packageStatus/modify/add-packagestatus";
import EditPackageStatus from "../credentialpackages/packageStatus/modify/edit-packagestatus";
import AddWorkflowStatus from "../workflow/workflowstatus/modify/add-workflowstatus";
import EditWorkflowStatus from "../workflow/workflowstatus/modify/edit-workflowstatus";
import AddEnrollmentStatus from "../payerenrollment/payerenrollmentstatus/modify/add-enrollmentstatus";
import EditEnrollmentStatus from "../payerenrollment/payerenrollmentstatus/modify/edit-enrollmentstatus";
import AddTaskStatus from "../tasks/tasksstatus/modify/add-taskstatus";
import EditTaskStatus from "../tasks/tasksstatus/modify/edit-taskstatus";
import AddTaskType from "../tasks/taskstype/modify/add-tasktype";
import EditTaskType from "../tasks/taskstype/modify/edit-tasktype";
import AddAffiliationStatus from "../provider/modify/add-affiliationstatus";
import FacilityTypeList from "../facility/facilitytype-list";
import AddFacilityType from "../facility/modify/add-facilitytype";
import EditFacilityType from "../facility/modify/edit-facilitytype";
import ReadyFormTypeList from "../digitalforms/digitalformtypelist";
import AddReadyFormType from "../digitalforms/add-digitalformtype";
import EditReadyFormType from "../digitalforms/edit-digitalformtype";
import EditAffiliationStatus from "../provider/modify/edit-affiliationstatus";
import EditSpecialty from "../provider/modify/edit-specialty";
import AddSpecialty from "../provider/modify/add-specialty";
import AddBoardName from "../provider/modify/add-boardname";
import EditBoardName from "../provider/modify/edit-boardname";
import AddAffiliationType from "../provider/modify/add-affiliationtype";
import EditAffiliationType from "../provider/modify/edit-affiliationtype";
import DigitalformRequestSignature from "../requests/signature-requests/digitalform-signature-request-wrapper";
import SignatureDetails from "../requests/signature-requests/signature-detail";
import { SigningDocument } from "../token-based/signing-document";
import SigningFailed from "../token-based/signature/signing-failed";
import SigningSuccess from "../token-based/signature/signing-success";
import ProviderListV3 from "../provider/providerlist-v3";
import AffiliationsListV2 from "../affiliation/affiliation-list-v2";
import PayerEnrollmentListV2 from "../payerenrollment/payerenrollment-list-v2";
import AdminRequests from "../requests/admin-requests";
import AddDocumentRequest from "../requests/document-requests/modify/add-document-request";
import SafeListV3 from "../safe/safe-list-v3";
import FacilityListV2 from "../facility/facility-list-v2";
import AffiliationStatusListV2 from "../provider/providerDetails/affiliationstatus-v2";
import AffiliationTypeListV2 from "../provider/providerDetails/affiliationtype-v2";
import BoardNameListV2 from "../provider/providerDetails/boardname-v2";
import SpecialtyListV2 from "../provider/providerDetails/specialtylist-v2";
import PayersListV2 from "../manage/payer/payer-list-v2";
import PayerGroupListV2 from "../manage/payergroup/payergroup-list-v2";
import FacilityGroupListV2 from "../manage/facilitygroup/facilitygroup-list-v2";
import WorkflowTemplateListV2 from "../manage/workflowTemplate/workflowtemplate-list-v2";
import LicenseTypesListV2 from "../manage/licensetypes/licensetypes-list-v2";
import CategoryListV2 from "../manage/category/category-list-v2";
import CredentialTemplateListV2 from "../manage/credentialtemplate/credentialtemplate-list-v2";
import ContractV2List from "../contracts/contracts-list-v2";
import LocationList from "../manage/location/location-list";
import AddLocation from "../manage/location/modify/add-location";
import EditLocation from "../manage/location/modify/edit-location";
import RequestReset from "../pages/reset-password/request-reset";
import PasswordResetForm from "../pages/reset-password";
import ViewDocumentVersion from "../document/view-document-versions";
import BulkUpload from "../safe/bulk-upload";
import Notifications from "../notification";
import EditCMETracker from "../provider/modify/edit-providercmetracker";
import PrivilegesList from "../privileges/privileges-list";
import AddPrivileges from "../privileges/modify/add-privileges";
import EditPrivileges from "../privileges/modify/edit-privileges";
import GMEList from "../gme/gme-list";
import AddTrainee from "../gme/modify/add-trainee";
import EditTrainee from "../gme/modify/edit-trainee";
import SignUpForm from "../pages/signup/signup";
import AddWorkflowV2 from "../workflow/modify/add-workflowv2";
import PrivilegeTypeList from "../privileges/privilegetype-list";
import AddPrivilegeType from "../privileges/modify/add-privilegeType";
import EditPrivilegeType from "../privileges/modify/edit-privilegeType";
import AddPrivilegeDocumentRequest from "../privileges/modify/add-privilegeDocumentRequest";
import CMECredentialTypeList from "../provider/providerDetails/cmecredentialtype";
import AddCMECredntialType from "../provider/modify/add-cmecredentialtype";
import EditCMECredentialType from "../provider/modify/edit-cmecredentialtype";
import SectionsList from "../provider/profileBuilder/sections-list";
import ProviderProfileSection from "../provider/profileBuilder/provider-profile-section";
import AddSection from "../provider/profileBuilder/add-sectionV1";
import EditSection from "../provider/profileBuilder/edit-sectionV1";

export const InvalidPathRoute = () => {
  const location = useLocation();
  useEffect(() => {
    captureException(`Invalid path: ${location.pathname}`);
    console.error(`Invalid path: ${location.pathname}`);
  }, [location.pathname]);

  return <Navigate to={PATH_AUTH.login} replace />;
};

function Router() {
  return useRoutes([
    {
      path: "/",
      element: (
        <AuthGuard>
          <IncredableSpinner>
            <IncredableToast>
              <IncredableLayout />
            </IncredableToast>
          </IncredableSpinner>
        </AuthGuard>
      ),
      children: [
        { path: "/dashboard", element: <Dashboard />, index: true },
        { path: "/", element: <Dashboard />, index: true },
        {
          path: "/manageprovider",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <ProviderListV3 />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <AddProvider />
                </PermissionGaurd>
              ),
            },
            {
              path: ":providerId/edit",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <EditProvider />
                </PermissionGaurd>
              ),
            },
            {
              path: ":providerId/addnew",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <AddDocument />
                </PermissionGaurd>
              ),
            },
            {
              path: ":providerId/document/:documentId",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <EditDocument />
                </PermissionGaurd>
              ),
            },
            {
              path: "safe/documents/uploadmultipledocument?providerId=:providerId",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <BulkUpload />
                </PermissionGaurd>
              ),
            },
            {
              path: ":providerId",
              children: [
                {
                  element: (
                    <PermissionGaurd permissionKey="provider.view">
                      <ProviderDetails />
                    </PermissionGaurd>
                  ),
                  index: true,
                },
                {
                  path: "affiliation",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "add",
                      element: <EditProviderAffiliation />,
                    },
                    {
                      path: ":affiliationId/edit",
                      element: <EditProviderAffiliation />,
                    },
                  ],
                },
                {
                  path: "dea",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "add",
                      element: <EditProviderDEA />,
                    },
                    {
                      path: ":deaId/edit",
                      element: <EditProviderDEA />,
                    },
                  ],
                },
                {
                  path: "education",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },

                    {
                      path: "add",
                      element: <EditProviderEducation />,
                    },
                    {
                      path: ":educationId/edit",
                      element: <EditProviderEducation />,
                    },
                  ],
                },
                {
                  path: "liabilityinsurance",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "add",
                      element: <EditProviderLiabilityInsurance />,
                    },
                    {
                      path: ":liabilityinsuranceId/edit",
                      element: <EditProviderLiabilityInsurance />,
                    },
                  ],
                },
                {
                  path: "license",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "add",
                      element: <EditProviderLicense />,
                    },
                    {
                      path: ":licenseId/edit",
                      element: <EditProviderLicense />,
                    },
                  ],
                },
                {
                  path: "peerreference",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "add",
                      element: <EditProviderPeerReference />,
                    },
                    {
                      path: ":peerReferenceId/edit",
                      element: <EditProviderPeerReference />,
                    },
                  ],
                },
                {
                  path: "specialtyinfo",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "add",
                      element: <EditProviderSpecialtyInfo />,
                    },
                    {
                      path: ":specialtyinfoId/edit",
                      element: <EditProviderSpecialtyInfo />,
                    },
                  ],
                },
                {
                  path: "thirdpartylogin",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "add",
                      element: <EditProviderThirdPartyLogin />,
                    },
                    {
                      path: ":thirdpartyloginId/edit",
                      element: <EditProviderThirdPartyLogin />,
                    },
                  ],
                },
                {
                  path: "workhistory",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "add",
                      element: <EditProviderWorkHistory />,
                    },
                    {
                      path: ":workhistoryId/edit",
                      element: <EditProviderWorkHistory />,
                    },
                  ],
                },
                {
                  path: "enrolledpayer",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "add",
                      element: <AddProviderEnrolledPayer />,
                    },
                    {
                      path: ":enrolledPayerId/edit",
                      element: <EditProviderEnrolledPayer />,
                    },
                  ],
                },
                {
                  path: "personalinfo",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "edit",
                      element: <EditProviderPersonalInfo />,
                    },
                  ],
                },
                {
                  path: "professionalinfo",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "edit",
                      element: <EditProviderProfessionalInfo />,
                    },
                  ],
                },
                {
                  path: "profile",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                  ],
                },
                {
                  path: "editpersonalinfo",
                  element: <PersonalInfo />,
                },
                {
                  path: "editprofessionalinfo",
                  element: <ProfessionalInfo />,
                },
                {
                  path: "cme-tracker",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "add",
                      element: <EditCMETracker />,
                    },
                    {
                      path: ":cmeId/edit",
                      element: <EditCMETracker />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "/affiliationtype",
          children: [
            { element: <AffiliationTypeListV2 />, index: true },
            { path: "add", element: <AddAffiliationType /> },
            {
              path: ":affiliationtypeId/edit",
              element: <EditAffiliationType />,
            },
          ],
        },
        {
          path: "/affiliationstatus",
          children: [
            { element: <AffiliationStatusListV2 />, index: true },
            { path: "add", element: <AddAffiliationStatus /> },
            {
              path: ":affiliationstatusId/edit",
              element: <EditAffiliationStatus />,
            },
          ],
        },
        {
          path: "/specialty",
          children: [
            { element: <SpecialtyListV2 />, index: true },
            { path: "add", element: <AddSpecialty /> },
            { path: ":specialtyId/edit", element: <EditSpecialty /> },
          ],
        },
        {
          path: "/boardname",
          children: [
            { element: <BoardNameListV2 />, index: true },
            { path: "add", element: <AddBoardName /> },
            { path: ":boardnameId/edit", element: <EditBoardName /> },
          ],
        },
        {
          path: "/cmecredentialtype",
          children: [
            { element: <CMECredentialTypeList />, index: true },
            { path: "add", element: <AddCMECredntialType /> },
            {
              path: ":cmeCredentialTypeId/edit",
              element: <EditCMECredentialType />,
            },
          ],
        },
        {
          path: "/facilitytype",
          children: [
            { element: <FacilityTypeList />, index: true },
            { path: "add", element: <AddFacilityType /> },
            { path: ":facilitytypeId/edit", element: <EditFacilityType /> },
          ],
        },
        {
          path: "/readyformtype",
          children: [
            { element: <ReadyFormTypeList />, index: true },
            { path: "add", element: <AddReadyFormType /> },
            { path: ":readyformtypeId/edit", element: <EditReadyFormType /> },
          ],
        },
        {
          path: "/privilegetype",
          children: [
            { element: <PrivilegeTypeList />, index: true },
            { path: "add", element: <AddPrivilegeType /> },
            {
              path: ":privilegetypeId/edit",
              element: <EditPrivilegeType />,
            },
          ],
        },
        { path: "/dashboard", element: <Dashboard /> },
        { path: "/affiliationtype", element: <EditAffiliationType /> },
        {
          path: "/managefacilities",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="facility.view">
                  <FacilityListV2 />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="facility.edit">
                  <AddFacility />
                </PermissionGaurd>
              ),
            },
            {
              path: ":facilityId/edit",
              element: (
                <PermissionGaurd permissionKey="facility.edit">
                  <EditFacility />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "/gme",
          children: [
            {
              element: <GMEList />,
              index: true,
            },
            {
              path: "add",
              element: <AddTrainee />,
            },
            {
              path: ":traineeId/edit",
              element: <EditTrainee />,
            },
          ],
        },
        {
          path: "/contracttype",
          children: [
            { element: <ContractsTypeList />, index: true },
            {
              path: "add",
              element: <AddContractType />,
            },
            {
              path: ":typeId/edit",
              element: <EditContractType />,
            },
          ],
        },
        {
          path: "requests",
          children: [
            { element: <AdminRequests />, index: true },
            {
              path: "add",
              element: <AddDocumentRequest />,
            },
          ],
        },
        {
          path: "/contract",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="contract.view">
                  <ContractV2List />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="contract.edit">
                  <AddContract />
                </PermissionGaurd>
              ),
            },
            {
              path: ":contractId/edit",
              element: (
                <PermissionGaurd permissionKey="contract.edit">
                  <EditContract />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "/providerroatser",
          element: (
            <PermissionGaurd permissionKey="reports.view">
              <ProvidersReport />
            </PermissionGaurd>
          ),
        },
        {
          path: "/enrollmentreport",
          element: (
            <PermissionGaurd permissionKey="reports.view">
              <PayerEnrollmentsReport />
            </PermissionGaurd>
          ),
        },
        {
          path: "/provideraffiliation",
          element: (
            <PermissionGaurd permissionKey="reports.view">
              <ProviderAffiliationsReport />
            </PermissionGaurd>
          ),
        },
        {
          path: "/alldocuments",
          element: (
            <PermissionGaurd permissionKey="reports.view">
              <AllDocumentsReport />
            </PermissionGaurd>
          ),
        },
        {
          path: "/expiringdocuments",
          element: (
            <PermissionGaurd permissionKey="reports.view">
              <ExpiringDocumentsReport />
            </PermissionGaurd>
          ),
        },
        {
          path: "/expireddocuments",
          element: (
            <PermissionGaurd permissionKey="reports.view">
              <ExpiredDocumentsReport />
            </PermissionGaurd>
          ),
        },
        {
          path: "/payerenrollmentstatus",

          children: [
            { element: <PayerEnrollmentStatusList />, index: true },
            {
              path: "add",
              element: <AddEnrollmentStatus />,
            },
            {
              path: ":enrollmentStatusId/edit",
              element: <EditEnrollmentStatus />,
            },
          ],
        },
        {
          path: "/payerenrollment",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="payer.view">
                  <PayerEnrollmentListV2 />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="payer.edit">
                  <AddPayerEnrollment />
                </PermissionGaurd>
              ),
            },
            {
              path: ":enrollmentId/edit",
              element: (
                <PermissionGaurd permissionKey="payer.view">
                  <EditPayerEnrollment />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "/payers",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="payer.view">
                  <PayersListV2 />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="payer.edit">
                  <AddPayer />
                </PermissionGaurd>
              ),
            },
            {
              path: ":payerId/edit",
              element: (
                <PermissionGaurd permissionKey="payer.edit">
                  <EditPayer />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "/payergroup",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="payer.view">
                  <PayerGroupListV2 />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="payer.edit">
                  <AddPayerGroup />
                </PermissionGaurd>
              ),
            },
            {
              path: ":payergroupId/edit",
              element: (
                <PermissionGaurd permissionKey="payer.edit">
                  <EditPayerGroup />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "/facilitygroup",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="facility.view">
                  <FacilityGroupListV2 />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="facility.edit">
                  <AddFacilityGroup />
                </PermissionGaurd>
              ),
            },
            {
              path: ":facilitygroupId/edit",
              element: (
                <PermissionGaurd permissionKey="facility.edit">
                  <EditFacilityGroup />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "/templatetype",
          children: [
            { element: <TasksTypeList />, index: true },
            {
              path: "add",
              element: <AddTaskType />,
            },
            {
              path: ":typeId/edit",
              element: <EditTaskType />,
            },
          ],
        },
        {
          path: "/templatestatus",
          children: [
            { element: <TasksStatusList />, index: true },
            {
              path: "add",
              element: <AddTaskStatus />,
            },
            {
              path: ":statusId/edit",
              element: <EditTaskStatus />,
            },
          ],
        },
        {
          path: "/workflowtemplate",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="workflow.view">
                  <WorkflowTemplateListV2 />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="workflow.edit">
                  <AddWorkFlowTemplate />
                </PermissionGaurd>
              ),
            },
            {
              path: ":templateId/edit",
              element: (
                <PermissionGaurd permissionKey="workflow.edit">
                  <EditWorkFlowTemplate />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "/licensetypes",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <LicenseTypesListV2 />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <AddLicenseType />
                </PermissionGaurd>
              ),
            },
            {
              path: ":licencetypeId/edit",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <EditLicenseType />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "/categories",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <CategoryListV2 />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <AddCategory />
                </PermissionGaurd>
              ),
            },
            {
              path: ":categoryId/edit",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <EditCategory />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "/credentialtemplates",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="credentialpackage.view">
                  <CredentialTemplateListV2 />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="credentialpackage.edit">
                  <AddCredentialTemplate />
                </PermissionGaurd>
              ),
            },
            {
              path: ":templateId",
              element: (
                <PermissionGaurd permissionKey="credentialpackage.edit">
                  <EditCredentialTemplateDetails />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "/safe",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <SafeListV3 />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "addnew",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <AddDocument />
                </PermissionGaurd>
              ),
            },
            {
              path: ":documentId/edit",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <EditDocument />
                </PermissionGaurd>
              ),
            },
            {
              path: "documents/uploadmultipledocument",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <BulkUpload />
                </PermissionGaurd>
              ),
            },
            {
              path: "documents/:documentId/version/:versionId",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <ViewDocumentVersion />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "/workflowstatus",
          children: [
            { element: <WorkFlowStatusList />, index: true },
            {
              path: "add",
              element: <AddWorkflowStatus />,
            },
            {
              path: ":workflowStatusId/edit",
              element: <EditWorkflowStatus />,
            },
          ],
        },
        {
          path: "workflows",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="workflow.view">
                  <WorkFlowList />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="workflow.edit">
                  <AddWorkflowV2 />
                </PermissionGaurd>
              ),
            },
            {
              path: ":workflowId",
              element: (
                <PermissionGaurd permissionKey="workflow.edit">
                  <EditWorkflow />
                </PermissionGaurd>
              ),
            },
            {
              path: "/workflows/:workflowId/addtask",
              element: <AddWorkflowTasks />,
            },
            {
              path: "/workflows/:workflowId/task/:taskId",
              element: <EditWorkflowTasks />,
            },
          ],
        },
        {
          path: "/readyforms",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="readyforms.view">
                  <ReadyFormsList />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="readyforms.edit">
                  <AddDigitalform />
                </PermissionGaurd>
              ),
            },
            {
              path: ":digitalformId",
              element: (
                <PermissionGaurd permissionKey="readyforms.edit">
                  <EditDigitalform />
                </PermissionGaurd>
              ),
            },
            { path: ":digitalformId/editor", element: <TemplateEditor /> },
            {
              path: ":digitalformId/requestsignature",
              element: (
                <PermissionGaurd permissionKey="signature.view">
                  <DigitalformRequestSignature />
                </PermissionGaurd>
              ),
            },
            {
              path: ":digitalformId/signaturerequest",
              element: (
                <PermissionGaurd permissionKey="signature.view">
                  <AddSignatureRequest />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "/taskstype",
          children: [
            { element: <TasksTypeList />, index: true },
            {
              path: "add",
              element: <AddTaskType />,
            },
            {
              path: ":typeId/edit",
              element: <EditTaskType />,
            },
          ],
        },
        {
          path: "/taskstatus",
          children: [
            { element: <TasksStatusList />, index: true },
            {
              path: "add",
              element: <AddTaskStatus />,
            },
            {
              path: ":statusId/edit",
              element: <EditTaskStatus />,
            },
          ],
        },
        {
          path: "tasks",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="task.view">
                  <TaskList />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="task.edit">
                  <AddTask />
                </PermissionGaurd>
              ),
            },
            {
              path: ":taskId",
              element: (
                <PermissionGaurd permissionKey="task.edit">
                  <EditTask />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "packagetype",
          children: [
            { element: <CredentialPackageTypeList />, index: true },
            {
              path: "add",
              element: <AddPackageType />,
            },
            {
              path: ":packageTypeId/edit",
              element: <EditPackageType />,
            },
          ],
        },
        {
          path: "packagestatus",
          children: [
            { element: <CredentialPackageStatusList />, index: true },
            {
              path: "add",
              element: <AddPackageStatus />,
            },
            {
              path: ":packageStatusId/edit",
              element: <EditPackageStatus />,
            },
          ],
        },
        {
          path: "credentialpackages",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="credentialpackage.view">
                  <CredentialPackagesList />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="credentialpackage.edit">
                  <AddCredentialPackage />
                </PermissionGaurd>
              ),
            },
            {
              path: ":id",
              element: (
                <PermissionGaurd permissionKey="credentialpackage.edit">
                  <EditCredentialPackages />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "notifications",
          element: <Notifications />,
        },
        {
          path: "affiliations",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <AffiliationsListV2 />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "addaffiliation",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <AddAffiliation />
                </PermissionGaurd>
              ),
            },
            {
              path: "editaffiliation/:affiliationId",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <AddAffiliation />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "notifications",
          element: <Notifications />,
        },
        {
          path: "providerprofilesection",
          element: <ProviderProfileSection />,
        },
        {
          path: "profilebuilder",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="section.view">
                  <SectionsList />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "add",
              element: <PermissionGaurd permissionKey="section.edit">
                <AddSection />
              </PermissionGaurd>,
            },
            {
              path: ":sectionId/edit",
              element: <PermissionGaurd permissionKey="section.edit">
                <EditSection />
              </PermissionGaurd>,
            },
          ],
        },
        {
          path: "privileges",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <PrivilegesList />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "addprivileges",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <AddPrivileges />
                </PermissionGaurd>
              ),
            },
            {
              path: "editprivileges/:privilegeId",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <EditPrivileges />
                </PermissionGaurd>
              ),
            },
            {
              path: "editprivileges/:privilegeId/addDocumentRequest",
              element: <AddPrivilegeDocumentRequest />,
            },
          ],
        },
        {
          path: "/manageadmin",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="member.view">
                  <ManageAdmin />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="member.edit">
                  <AddInviteMember />
                </PermissionGaurd>
              ),
            },
            {
              path: ":activeMemberId",
              element: (
                <PermissionGaurd permissionKey="member.edit">
                  <EditActiveMember />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "/requests",
          children: [
            {
              element: <AdminRequests />,
              index: true,
            },
            {
              path: ":signatureId",
              element: <SignatureDetails />,
            },
          ],
        },
        {
          path: "org",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="organization_settings.view">
                  <OrganizationSettings />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "details/edit",
              element: (
                <PermissionGaurd permissionKey="organization_settings.edit">
                  <OrgBasicDetailsEdit />
                </PermissionGaurd>
              ),
            },
            // {
            //   path: "settings/edit",
            //   element: <OrgBasicDetailsEdit />,
            // },
          ],
        },
        {
          path: "settings/profile",
          children: [
            {
              element: <Profile />,
              index: true,
            },
          ],
        },
        {
          path: "location",
          children: [
            {
              element: <LocationList />,
              index: true,
            },
            {
              path: "add",
              element: <AddLocation />,
            },
            {
              path: ":locationId/edit",
              element: <EditLocation />,
            },
          ],
        },
        {
          path: "settings/roles",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="roles.view">
                  <RolesAndPermissions />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: ":id",
              element: (
                <PermissionGaurd permissionKey="roles.view">
                  <RoleDetails />
                </PermissionGaurd>
              ),
            },
          ],
        },
      ],
    },
    {
      path: PATH_AUTH.login,
      element: (
        <IncredableToast>
          <Navigate to={PATH_DASHBOARD.dashboard.list} />
        </IncredableToast>
      ),
    },

    {
      path: PATH_AUTH.register,
      element: (
        <IncredableToast>
          <SignUpForm />
        </IncredableToast>
      ),
    },
    {
      path: PATH_AUTH.requestReset,
      element: (
        <IncredableSpinner>
          <IncredableToast>
            <RequestReset />
          </IncredableToast>
        </IncredableSpinner>
      ),
    },
    {
      path: PATH_AUTH.resetPassword,
      element: (
        <IncredableSpinner>
          <IncredableToast>
            <PasswordResetForm />
          </IncredableToast>
        </IncredableSpinner>
      ),
    },
    {
      path: "signing",
      children: [
        { element: <SigningDocument />, index: true },
        { path: "error", element: <SigningFailed /> },
        { path: "success", element: <SigningSuccess /> },
      ],
    },
  ]);
}

export default Router;
