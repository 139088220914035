import { Input, Select, Space, Tag } from "antd";
import { useMemo, useState } from "react";
import { useGetBasicAllProvidersQuery } from "../redux/api/provider/provider";
import { useGetAllDocumentRequestsQuery } from "../redux/api/documentrequest/documentrequest";
import { useGetAllCategoryQuery } from "../redux/api/category/category";
import { useGetAllPrivilegeDocumentRequestQuery, useGetAllPrivilegeDocumentRequestStatsQuery } from "../redux/api/privileges/privileges";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";

const StatusOptions = [
    { value: "approved", label: "Approved" },
    { value: "to_be_reviewed", label: "To Be Reviewed" },
    { value: "pending", label: "Pending" },
    { value: "denied", label: "Denied" },
];

export default function usePrivilegeDocumentRequestFilter() {
    const { privilegeId } = useParams();
    const [selectedFilterkey, setSelectedFilterKey] = useState<string>("status");

    const [statusQueryFilterValue, setStatusQueryFilterValue] = useState<
        string | undefined
    >(undefined);
    const [providerNameFiltervalue, setProviderNameFiltervalue] = useState<
        string | undefined
    >(undefined);

    const [titleFiltervalue, setTitleFiltervalue] = useState<string | undefined>(
        undefined
    );

    const [categoryFiltervalue, setCategoryFiltervalue] = useState<
        string | undefined
    >(undefined);

    const { data: providers } = useGetBasicAllProvidersQuery();

    const {
        data: documentRequests,
        isLoading,
        isFetching,
        refetch,
    } = useGetAllPrivilegeDocumentRequestQuery(privilegeId ? { privilegeId } : skipToken);

    const { data: categories } = useGetAllCategoryQuery();

    const filteredPrivilegeDocumentRequest = useMemo(() => {
        return documentRequests?.filter((documentrequest) => {
            // Initialize matches as true to accumulate filter conditions
            let matches = true;

            // Apply provider filter if `providerNameFiltervalue` is set
            if (providerNameFiltervalue) {
                matches =
                    matches && documentrequest?.provider?.id === providerNameFiltervalue;
            }

            // Apply status filter if `statusQueryFilterValue` is set
            if (statusQueryFilterValue) {
                matches = matches && documentrequest?.status === statusQueryFilterValue;
            }

            // Apply category filter if `categoryFiltervalue` is set
            if (categoryFiltervalue) {
                matches =
                    matches &&
                    documentrequest?.category?.id + "" === categoryFiltervalue + "";
            }

            // Apply title filter if `titleFiltervalue` is set
            if (titleFiltervalue) {
                const filterWords = titleFiltervalue?.toLowerCase().split(" ");
                matches =
                    matches &&
                    filterWords.every((word) =>
                        documentrequest?.title?.toLowerCase().includes(word)
                    );
            }

            return matches;
        });
    }, [
        categoryFiltervalue,
        documentRequests,
        providerNameFiltervalue,
        statusQueryFilterValue,
        titleFiltervalue,
    ]);

    const TableFilters = (
        <Space direction="vertical">
            <Space size="small">
                <Select
                    value={selectedFilterkey}
                    onChange={(value) => setSelectedFilterKey(value)}
                    style={{ width: "140px" }}
                    options={[
                        { value: "title", label: "Title" },
                        { value: "provider", label: "Provider" },
                        { value: "status", label: "Status" },
                        { value: "category", label: "Category" },
                    ]}
                />

                {selectedFilterkey === "status" && (
                    <Select
                        key="status-select"
                        value={statusQueryFilterValue}
                        placeholder="Select status"
                        onChange={(value) => setStatusQueryFilterValue(value)}
                        style={{ width: "320px" }}
                        options={StatusOptions}
                    />
                )}
                {selectedFilterkey === "title" && (
                    <Input
                        onChange={(e) => setTitleFiltervalue(e.target.value)}
                        value={titleFiltervalue}
                        style={{
                            width: "320px",
                        }}
                        allowClear
                        placeholder="Find by Title"
                    />
                )}
                {selectedFilterkey === "provider" && (
                    <Select
                        allowClear={true}
                        showSearch={true}
                        optionFilterProp="label"
                        value={providerNameFiltervalue}
                        placeholder="Select Provider"
                        onChange={(value) => setProviderNameFiltervalue(value)}
                        style={{ width: "320px" }}
                        options={providers?.map((provider) => ({
                            label: `${provider?.firstName} ${provider?.lastName}`,
                            value: provider?.id,
                        }))}
                    />
                )}
                {selectedFilterkey === "category" && (
                    <Select
                        key="category-select"
                        value={categoryFiltervalue}
                        placeholder="Select Category"
                        onChange={(value) => setCategoryFiltervalue(value)}
                        style={{ width: "320px" }}
                        options={categories?.map((category) => {
                            return {
                                label: category?.name,
                                searchValue: category?.name,
                                value: category?.id,
                            };
                        })}
                    />
                )}
            </Space>
            <div>
                {!!statusQueryFilterValue && (
                    <Tag
                        closeIcon
                        onClose={() => {
                            setStatusQueryFilterValue(undefined);
                        }}
                    >
                        <span>
                            Status ={" "}
                            {
                                StatusOptions?.find(
                                    (stautsOption) =>
                                        stautsOption?.value === statusQueryFilterValue
                                )?.label
                            }
                        </span>
                    </Tag>
                )}
                {!!providerNameFiltervalue && (
                    <Tag
                        closeIcon
                        onClose={() => {
                            setProviderNameFiltervalue(undefined);
                        }}
                    >
                        <span>
                            Provider ={" "}
                            {
                                providers?.find(
                                    (stautsOption) =>
                                        stautsOption?.id + "" === providerNameFiltervalue + ""
                                )?.firstName
                            }
                        </span>
                    </Tag>
                )}
                {!!categoryFiltervalue && (
                    <Tag
                        closeIcon
                        onClose={() => {
                            setCategoryFiltervalue(undefined);
                        }}
                    >
                        <span>
                            Title ={" "}
                            {
                                categories?.find(
                                    (category) => category?.id + "" === categoryFiltervalue + ""
                                )?.name
                            }
                        </span>
                    </Tag>
                )}
            </div>
        </Space>
    );

    return {
        TableFilters,
        filteredPrivilegeDocumentRequest,
        UseQueryHookResult: {
            refetch,
            isLoading,
            isFetching,
        },
    };
}
