import { FormProvider, useForm } from "react-hook-form";
import {
  Col,
  Divider,
  Form,
  Row,
  Tabs as AntTabs,
  ConfigProvider,
  Space,
  Dropdown,
  Menu,
  Card,
} from "antd";
import {
  ProviderProfileRecordType,
  ProviderProfileRecordTypes,
} from "./section-data";
import { Attribute, SectionRequest } from "../../redux/api/section/types";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import RHFCheckBox from "../../components/RHF/RHFCheckbox";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFSelect from "../../components/RHF/RHFSelect";
import RHFPhoneNumber from "../../components/RHF/RHFPhonenumber";
import { skipToken } from "@reduxjs/toolkit/query";
import { useParams } from "react-router-dom";
import IncredableContent from "../../components/incredable-content";
import { useGetProviderQuery } from "../../redux/api/provider/provider";
import {
  useAddRecordMutation,
  useGetAllProviderProfileDocumentsQuery,
  useGetRecordsByProviderAndRecordIdQuery,
  useUpdateRecordMutation,
} from "../../redux/api/section/section";
import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import ProviderAuditLog from "./versions/audit_logs";
import ProviderProfileVersions from "./versions/record-versions";
import IncredableButton from "../../components/button";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import ProviderProfileSectionDocumentModal from "./provider-profile-section-document-modal";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { useGetAllCategoryQuery } from "../../redux/api/category/category";
import AttachmentViewer from "../../components/attachment-viewer";

export type AllDocumentProviderRecordResponse = {
  id: number;
  hgId?: string;
  name: string;
  expirationDate: string;
  createdDate: string;
  alertDays: number;
  notes: string;
  status: string;
  attachment: {
    id: string | undefined;
    name: string;
    size: number;
    contentType: string;
    contentMD5Hash: string;
    key: string;
  };
  category: {
    id: number;
    name: string;
    description: string;
    providerProfileSection: {
      id: number;
      name: string;
      orgId: string;
      deleted: boolean;
    };
    default?: boolean;
    stateRequired: boolean;
    expirationRequired: boolean;
  };
};

const footerStyles: React.CSSProperties = {
  position: "absolute",
  bottom: 0,
  zIndex: 99,
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "100%",
  minHeight: "64px",
  backgroundColor: "hsla(0, 0%, 100%, .90)",
  borderBlockStart: "1px solid rgba(5, 5, 5, 0.20)",
};

type DocumentFormValues = {
  [key: string]: string | undefined;
  documentId: string;
  name: string;
  categoryId: string;
  notes: string;
};

export default function AddEditProviderProfileSection({
  recordId,
  sectionData,
  toggleDrawer,
}: {
  recordId?: string;
  sectionData?: SectionRequest;
  toggleDrawer?: () => void;
}) {
  const { providerId } = useParams<{ providerId?: string }>();
  const methods = useForm<ProviderProfileRecordType>({
    defaultValues: {},
  });
  const documentMethods = useForm<
    DocumentFormValues & { documentId: string }
  >();
  const [activeKey, setActiveKey] = useState<string>("defaultKey");
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [showDocumentTab, setShowDocumentTab] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<
    AllDocumentProviderRecordResponse[]
  >([]);
  const [selectedTotalDocument, setSelectedTotalDocument] = useState<
    AllDocumentProviderRecordResponse[]
  >([]);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const { data: provider } = useGetProviderQuery(
    providerId ? { providerId } : skipToken
  );
  const [addRecord] = useAddRecordMutation();
  const [updateRecord] = useUpdateRecordMutation();

  const { data: providerRecord, isLoading } =
    useGetRecordsByProviderAndRecordIdQuery(
      providerId && recordId
        ? {
            providerId: providerId as string,
            recordId: recordId as string,
          }
        : skipToken
    );
  const { data: documentdetails } = useGetAllProviderProfileDocumentsQuery(
    providerId && recordId
      ? {
          providerId: providerId as string,
          recordId: recordId as string,
        }
      : skipToken
  );

  useEffect(() => {
    if (recordId && providerRecord) {
      const transformedData: ProviderProfileRecordType = Object.entries(
        providerRecord
      ).reduce((acc, [key, value]) => {
        acc[key] = value !== null && value !== undefined ? String(value) : "";
        return acc;
      }, {} as ProviderProfileRecordType);
      setSelectedTotalDocument(documentdetails || []);
      methods.reset(transformedData);
    } else {
      methods.reset({});
    }
  }, [recordId, providerRecord, methods, documentdetails]);

  useEffect(() => {
    if (!recordId) {
      methods.reset();
    }
  }, [recordId, methods]);
  const { data: categories } = useGetAllCategoryQuery();

  const handleDocumentFieldChange = (
    index: number,
    field: keyof AllDocumentProviderRecordResponse,
    value: string
  ) => {
    setSelectedTotalDocument((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      const selectedCategory = categories?.find(
        (cat) => cat.id + "" == value + ""
      );

      updatedDocuments[index] = {
        ...updatedDocuments[index],
        [field]: value,
        ...(field === "category" && {
          category:
            value && selectedCategory
              ? {
                  id: Number(value),
                  name: selectedCategory.name,
                  description: selectedCategory.description || "",
                  providerProfileSection:
                    updatedDocuments[index].category.providerProfileSection,
                  default: selectedCategory.default || false,
                  stateRequired: selectedCategory.stateRequired || false,
                  expirationRequired:
                    selectedCategory.expirationRequired || false,
                }
              : updatedDocuments[index].category,
        }),
      };
      return updatedDocuments;
    });
  };

  const handleVersionUpdate = async (formValues: ProviderProfileRecordType) => {
    const formattedData: ProviderProfileRecordTypes = {
      attributeValues: formValues,
      documents: selectedTotalDocument?.length
        ? selectedTotalDocument.map((doc, index) => ({
            id: String(doc?.id),
            name: String(
              documentMethods?.getValues(`name_${index}`) ?? doc.name
            ),
            expirationDate: String(doc?.expirationDate),
            alertDays: Number(doc?.alertDays) || 0,
            notes: String(
              documentMethods?.getValues(`notes_${index}`) ?? doc.notes
            ),
            categoryId: String(doc?.category?.id || ""),
            attachment: {
              id: Number(doc?.attachment?.id) || 0,
              name: String(doc?.attachment?.name || ""),
              size: Number(doc?.attachment?.size) || 0,
              contentType: String(doc?.attachment?.contentType || ""),
              contentMD5Hash: String(doc?.attachment?.contentMD5Hash || ""),
              key: String(doc?.attachment?.key || ""),
            },
            createdDate: String(doc?.createdDate),
            providerId: String(providerId || ""),
            facilityId: "",
          }))
        : [],
    };

    try {
      if (recordId && providerId && sectionData?.id) {
        await updateRecord({
          providerID: providerId,
          sectionId: sectionData.id,
          recordId: recordId,
          isVersioned: true,
          data: formattedData,
        }).unwrap();
        toastContext?.openSuccessNotification({
          message: "Record version updated successfully",
          placement: "topRight",
        });
      }
    } catch (err) {
      toastContext?.openErrorNotification({
        message: "Failed to update record version",
        placement: "topRight",
      });
    }
  };

  const handleSubmit = methods.handleSubmit(async (formValues) => {
    const formattedData: ProviderProfileRecordTypes = {
      attributeValues: formValues,
      documents: selectedTotalDocument?.length
        ? selectedTotalDocument.map((doc, index) => ({
            id: undefined,
            name: String(
              documentMethods?.getValues(`name_${index}`) ?? doc.name
            ),
            expirationDate: String(doc?.expirationDate),
            alertDays: Number(doc?.alertDays) || 0,
            notes: String(
              documentMethods?.getValues(`notes_${index}`) ?? doc.notes
            ),
            categoryId: String(doc?.category?.id || ""),
            attachment: {
              id: Number(doc?.attachment?.id) || 0,
              name: String(doc?.attachment?.name || ""),
              size: Number(doc?.attachment?.size) || 0,
              contentType: String(doc?.attachment?.contentType || ""),
              contentMD5Hash: String(doc?.attachment?.contentMD5Hash || ""),
              key: String(doc?.attachment?.key || ""),
            },
            createdDate: String(doc?.createdDate),
            providerId: String(providerId || ""),
            facilityId: "",
          }))
        : [],
    };
    const formattedDataForUpdate: any = {
      id: String(recordId || ""),
      attributeValues: formValues,
      documents:
        selectedTotalDocument?.map((doc, index) => ({
          ...doc,
          id: String(doc.id),
          name: String(documentMethods?.getValues(`name_${index}`) ?? doc.name),
          notes: String(
            documentMethods?.getValues(`notes_${index}`) ?? doc.notes
          ),
          categoryId: String(doc?.category?.id || ""),
        })) || [],
    };

    try {
      if (recordId) {
        await updateRecord({
          providerID: String(providerId),
          sectionId: String(sectionData?.id),
          recordId: recordId,
          isVersioned: false,
          data: formattedDataForUpdate,
        }).unwrap();
        toastContext?.openSuccessNotification({
          message: "Record updated successfully",
          placement: "topRight",
        });
      } else {
        await addRecord({
          providerId: String(providerId),
          sectionId: String(sectionData?.id),
          data: formattedData,
        }).unwrap();
        toastContext?.openSuccessNotification({
          message: "Record added successfully",
          placement: "topRight",
        });
      }
      toggleDrawer?.();
      setSelectedDocument([]);
      setSelectedTotalDocument([]);
      methods.reset();
      documentMethods.reset();
    } catch (error) {
      toastContext?.openErrorNotification({
        message: "Failed to save record",
        placement: "topRight",
      });
    } finally {
    }
    methods.reset();
    documentMethods.reset();
    setSelectedDocument([]);
  });

  useEffect(() => {
    if (selectedTotalDocument.length > 0) {
      selectedTotalDocument.forEach((doc, index) => {
        if (!documentMethods.getValues(`name_${index}`)) {
          documentMethods.setValue(`documentId_${index}`, String(doc.id) || "");
          documentMethods.setValue(`name_${index}`, doc.name || "");
          documentMethods.setValue(
            `categoryId_${index}`,
            String(doc.category?.id) || ""
          );
          documentMethods.setValue(`notes_${index}`, doc.notes || "");
        }
      });
    }
  }, [selectedTotalDocument, documentMethods]);

  const handleExistingDocumentSubmit = (type?: string) => {
    setIsDocumentModalOpen(false);
    setShowDocumentTab(true);
    if (type === "linkDocument") {
      setSelectedTotalDocument(selectedDocument);
    } else {
      const dummyDocument: AllDocumentProviderRecordResponse = {
        id: Date.now(),
        hgId: "",
        name: "New Document",
        expirationDate: new Date().toISOString(),
        createdDate: new Date().toISOString(),
        alertDays: 0,
        notes: "",
        status: "draft",
        attachment: {
          id: undefined,
          name: "",
          size: 0,
          contentType: "",
          contentMD5Hash: "",
          key: "",
        },
        category: {
          id: 0,
          name: "Uncategorized",
          description: "",
          providerProfileSection: {
            id: 0,
            name: "",
            orgId: "",
            deleted: false,
          },
          default: false,
          stateRequired: false,
          expirationRequired: false,
        },
      };
      setSelectedTotalDocument((prev) => [...prev, dummyDocument]);
    }
  };

  const menu = (
    <Menu style={{ margin: "20px 0px 20px 0px" }}>
      <Menu.Item
        key="1"
        onClick={(e) => {
          e.domEvent.stopPropagation();
          handleVersionUpdate(methods.getValues());
        }}
      >
        Add New Version
      </Menu.Item>
    </Menu>
  );

  const handleCancel = () => {
    toggleDrawer?.();
    methods.reset();
    documentMethods.reset();
    setSelectedDocument([]);
    setSelectedTotalDocument([]);
    setUploadedFile(undefined);
    setActiveKey("defaultKey");
  };

  const documentTabs = selectedTotalDocument.map((doc, index) => {
    return {
      label: doc.name,
      key: String(doc.id),
      children: (
        <FormProvider {...documentMethods}>
          <ConfigProvider
            theme={{
              components: {
                Form: {
                  verticalLabelMargin: -4,
                  itemMarginBottom: 16,
                },
              },
            }}
          >
            <Form
              style={{
                height: "calc(100vh - 250px)",
                overflowY: "scroll",
                scrollbarWidth: "none",
                paddingBottom: "30px",
              }}
              layout="vertical"
              size="middle"
              autoComplete="off"
            >
              <Row gutter={24}>
                <Col flex="360px">
                  <Card style={{ height: "100%" }}>
                    <RHFTextField
                      formItemProps={{ label: `Document Name` }}
                      controllerProps={{
                        name: `name_${index}`,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          handleDocumentFieldChange(
                            index,
                            "name",
                            e.target.value
                          );
                        },
                      }}
                      rules={{ required: "This field is required" }}
                    />
                    <RHFSelect
                      formItemProps={{
                        label: "Category",
                      }}
                      controllerProps={{ name: `categoryId_${index}` }}
                      selectProps={{
                        allowClear: true,
                        showSearch: true,
                        style: { width: "100%" },
                        placeholder: "Select Category",
                        options: categories?.map((category) => ({
                          label: category?.name,
                          value: String(category?.id),
                        })),
                        onChange: (value) => {
                          handleDocumentFieldChange(
                            index,
                            "category",
                            value || ""
                          );
                        },
                      }}
                    />
                    <RHFTextArea
                      formItemProps={{ label: `Notes` }}
                      controllerProps={{
                        name: `notes_${index}`,
                      }}
                      textAreaProps={{
                        onChange: (e) => {
                          handleDocumentFieldChange(
                            index,
                            "notes",
                            e.target.value
                          );
                        },
                      }}
                    />
                  </Card>
                </Col>
                <Col flex="auto">
                  <AttachmentViewer
                    attachment={
                      `documentId_${index}` ? doc?.attachment : undefined
                    }
                    onFileChange={(file) => {
                      setUploadedFile(file);
                      setSelectedTotalDocument((prev) => {
                        const updated = [...prev];
                        updated[index] = {
                          ...updated[index],
                          attachment: {
                            ...updated[index].attachment,
                            name: file?.name || "",
                            size: file?.size || 0,
                            contentType: file?.type || "",
                          },
                        };
                        return updated;
                      });
                    }}
                    file={uploadedFile}
                  />
                </Col>
              </Row>
            </Form>
          </ConfigProvider>
        </FormProvider>
      ),
    };
  });

  return (
    <IncredableContent
      style={{ position: "relative", paddingTop: "10px" }}
      breadcrumbGroupProps={{
        items: [
          { text: "Provider", href: "/manageprovider" },
          {
            text: (
              <span onClick={toggleDrawer}>{`${provider?.firstName || ""} ${
                provider?.lastName || ""
              }`}</span>
            ),
          },
          {
            text: ` ${recordId ? "Edit" : "Add"} ${sectionData?.name}`,
            href: "#",
          },
        ],
      }}
    >
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "#2B6BE6",
              cardBg: "#F5F5F5",
              colorBgContainer: "#2B6BE6",
              inkBarColor: "#2B6BE6",
              colorBorderSecondary: "#c6c6cd",
              fontSize: 16,
            },
          },
        }}
      >
        <div style={{ position: "relative" }}>
          <AntTabs
            size="large"
            activeKey={activeKey}
            defaultActiveKey="defaultKey"
            tabBarExtraContent={
              <IncredableButton
                icon={<PlusOutlined />}
                onClick={() => setIsDocumentModalOpen(true)}
              >
                Add Document
              </IncredableButton>
            }
            onChange={(key) => setActiveKey(key)}
            items={[
              {
                label: (
                  <span style={{ fontWeight: "700" }}>
                    {recordId
                      ? `Edit ${sectionData?.name}`
                      : `Add ${sectionData?.name}`}
                  </span>
                ),
                key: "defaultKey",
                closable: false,
                children: (
                  <FormProvider {...methods}>
                    <Form
                      style={{
                        height: "calc(100vh - 200px)",
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        paddingBottom: "90px",
                      }}
                      name="editproviderdea"
                      layout="vertical"
                      autoComplete="off"
                    >
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        {[...(sectionData?.attributes || [])]
                          .sort(
                            (a, b) => (a.sortOrder || 0) - (b.sortOrder || 0)
                          )
                          .map((attribute) => (
                            <Col
                              key={attribute?.id}
                              span={attribute?.type === "Divider" ? 24 : 8}
                            >
                              {renderAttribute(attribute)}
                            </Col>
                          ))}
                      </Row>
                    </Form>
                  </FormProvider>
                ),
              },
              ...documentTabs,
              ...(recordId
                ? [
                    {
                      label: (
                        <span style={{ fontWeight: "700" }}>Versions</span>
                      ),
                      key: "versions",
                      children: <ProviderProfileVersions recordId={recordId} />,
                    },
                    {
                      label: (
                        <span style={{ fontWeight: "700" }}>Audit logs</span>
                      ),
                      key: "auditLogs",
                      children: <ProviderAuditLog recordId={recordId} />,
                    },
                  ]
                : []),
            ]}
          />
          <div style={footerStyles}>
            <Space size="middle">
              <IncredableButton secondary onClick={() => handleCancel()}>
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" onClick={handleSubmit}>
                Save
                {recordId && (
                  <>
                    <Divider
                      type="vertical"
                      style={{ backgroundColor: "#877070" }}
                    />
                    <Dropdown
                      overlay={menu}
                      trigger={["hover"]}
                      placement="bottomRight"
                    >
                      <DownOutlined onClick={(e) => e.stopPropagation()} />
                    </Dropdown>
                  </>
                )}
              </IncredableButton>
            </Space>
          </div>
        </div>
      </ConfigProvider>
      <ProviderProfileSectionDocumentModal
        providerId={providerId}
        isDocumentModalOpen={isDocumentModalOpen}
        setIsDocumentModalOpen={setIsDocumentModalOpen}
        selectedDocument={selectedDocument}
        setActiveKey={setActiveKey}
        setSelectedDocument={setSelectedDocument}
        handleExistingDocumentSubmit={handleExistingDocumentSubmit}
      />
    </IncredableContent>
  );
}

export const renderAttribute = (attribute: Attribute) => {
  const attributeId = String(attribute?.id ?? "defaultName");

  switch (attribute?.type) {
    case "Date":
      return (
        <RHFDatePicker
          formItemProps={{ label: attribute?.name }}
          controllerProps={{
            name: attributeId,
          }}
          datePickerProps={{
            style: { width: "100%" },
            placeholder: attribute?.description,
          }}
          rules={
            attribute?.required ? { required: "This field is required" } : {}
          }
        />
      );
    case "Checkbox":
      return (
        <RHFCheckBox
          formItemProps={{ label: attribute?.name }}
          controllerProps={{
            name: attributeId,
          }}
          checkboxProps={{ label: "" }}
          rules={
            attribute?.required ? { required: "This field is required" } : {}
          }
        />
      );
    case "Dropdown.Single":
      return (
        <RHFSelect
          formItemProps={{ label: attribute?.name }}
          controllerProps={{
            name: attributeId,
          }}
          selectProps={{
            allowClear: true,
            showSearch: true,
            style: { width: "100%" },
            placeholder: attribute?.description,
            filterOption: (input, option) =>
              (option?.value ?? "")
                .toString()
                .toLowerCase()
                .includes(input?.toLowerCase()),
            options: attribute?.optionDefinition?.map((option) => ({
              value: option?.value,
              label: option?.label,
            })),
          }}
          rules={
            attribute?.required ? { required: "This field is required" } : {}
          }
        />
      );
    case "Phone":
      return (
        <RHFPhoneNumber
          formItemProps={{ label: attribute?.name }}
          inputProps={{}}
          controllerProps={{
            name: attributeId,
          }}
          rules={
            attribute?.required ? { required: "This field is required" } : {}
          }
        />
      );
    case "Divider":
      return <Divider orientation="left">{attribute?.name}</Divider>;
    default:
      return (
        <RHFTextField
          formItemProps={{ label: attribute?.name }}
          inputProps={{ placeholder: attribute?.description }}
          controllerProps={{
            name: attributeId,
          }}
          rules={
            attribute?.required ? { required: "This field is required" } : {}
          }
        />
      );
  }
};
