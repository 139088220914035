import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { useContext, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  CalendarOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { ProviderSpecialtyInfoType } from "../../redux/api/provider/types";
import {
  useDeleteProviderSpecialtyInfoMutation,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/query";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { useLocalStorage } from "../../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  SPECIALTYINFO_CONTENT_DISPLAY_OPTIONS,
  SPECIALTYINFO_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../table-config";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import IncredableDateFormat from "../../common/incredable-date-format";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import EditProviderSpecialtyInfo from "../modify/edit-providerspecialtyinfo";

export default function SpecialtyInfoV2() {
  const { providerId } = useParams();
  const [selectedRows, setSelectedRows] = useState<ProviderSpecialtyInfoType[]>(
    []
  );
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
    setSelectedRows([]);
  };
  const navigate = useNavigate();
  const [deleteProviderSpecialtyInfo] =
    useDeleteProviderSpecialtyInfoMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.delete") ?? false)
  );

  const {
    data: provider,
    isLoading,
    isFetching,
    refetch,
  } = useGetProviderQuery(
    !!providerId
      ? {
        providerId: providerId,
      }
      : skipToken
  );

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-SpecialtyInfo-Table-Preferences",
    SPECIALTYINFO_TABLE_DEFAULT_PREFERENCES
  );

  const { items, collectionProps, paginationProps } = useCollection(
    provider?.providerSpecialties ?? [],
    {
      pagination: { pageSize: tablePreferences?.pageSize },
    }
  );

  return (
    <>
      <Table
        loading={isFetching || isLoading}
        onSelectionChange={({ detail }) => {
          setSelectedRows([detail.selectedItems[0]]);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows.at(0)?.id === selectedItem?.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        columnDefinitions={[
          {
            id: "id",
            header: <div>ID</div>,
            cell: (item) => item.id || "-",
          },
          {
            id: "boardName",
            header: <div>Board Name</div>,
            cell: (item) => (
              <Button
                style={{ margin: "0", padding: "0", height: "0" }}
                onClick={() => {
                  setSelectedRows([item]);
                  toggleDrawer();
                }}
                type="link"
              >
                {item?.boardName ?? "-"}{" "}
              </Button>
            ),
            sortingField: "specialty.name",
            sortingComparator: (a, b) =>
              (a?.specialty?.name ?? "").localeCompare(
                b?.specialty?.name ?? ""
              ),
          },
          {
            id: "specialty",
            header: <div>Specialty Info</div>,
            cell: (item) => <div> {item?.specialty?.name ?? "-"} </div>,
            sortingField: "specialty.name",
            sortingComparator: (a, b) =>
              (a?.specialty?.name ?? "").localeCompare(
                b?.specialty?.name ?? ""
              ),
          },
          {
            id: "initialCertificationDate",
            header: <div>Initial Certification Date</div>,
            cell: (item) =>
              item?.initialCertificationDate ? (
                <span>
                  <CalendarOutlined />{" "}
                  {IncredableDateFormat(item?.initialCertificationDate)}
                </span>
              ) : (
                "-"
              ),
            sortingField: "initialCertificationDate",
          },
          {
            id: "expirationDate",
            header: <div>Expiration Date</div>,
            cell: (item) =>
              item?.expirationDate ? (
                <span>
                  <CalendarOutlined />{" "}
                  {IncredableDateFormat(item?.expirationDate)}
                </span>
              ) : (
                "-"
              ),
            sortingField: "expirationDate",
          },
          {
            id: "reCertificationDate",
            header: <div>Re-Certification Date</div>,
            cell: (item) =>
              item?.reCertificationDate ? (
                <span>
                  <CalendarOutlined />{" "}
                  {IncredableDateFormat(item?.reCertificationDate)}
                </span>
              ) : (
                "-"
              ),
            sortingField: "reCertificationDate",
          },
        ]}
        items={items}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={SPECIALTYINFO_CONTENT_DISPLAY_OPTIONS}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <Space size="middle">
                  <Button
                    onClick={() => {
                      !!refetch && refetch();
                    }}
                  >
                    <ReloadOutlined />
                  </Button>
                  <Dropdown
                    menu={{
                      disabled: selectedRows?.length !== 1,
                      items: [
                        {
                          label: hasPermission ? "Edit" : "View",
                          key: "edit",
                          icon: <EditOutlined />,
                          onClick: () => {
                            setIsOpen(true);
                          },
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          onClick: () => setDeleteModalOpen(true),
                          disabled: !hasDeletePermission,
                          icon: <DeleteOutlined />,
                          danger: true,
                        },
                      ],
                    }}
                  >
                    <Button>
                      Actions
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#2B6BE6",
                      },
                    }}
                  >
                    <Button
                      disabled={!hasPermission}
                      onClick={() => {
                        setSelectedRows([]);
                        toggleDrawer();
                      }}
                      type="primary"
                      icon={<PlusOutlined />}
                    >
                      Add
                    </Button>
                  </ConfigProvider>
                </Space>
              }
            >
              <Typography.Title
                level={3}
                style={{ marginTop: "0", fontSize: "22px" }}
              >
                Specialty Info{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${provider?.providerSpecialties?.length || 0
                  })`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
       <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        size="90vw"
      >
        <EditProviderSpecialtyInfo
          key={selectedRows?.at(0)?.id ?? "add"}
          specialtyinfoId={"" + selectedRows?.at(0)?.id}
          toggleDrawer={toggleDrawer}
        />
      </Drawer>
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (!!providerId && !!selectedRows?.at(0)?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            try {
              await deleteProviderSpecialtyInfo({
                providerId: providerId,
                specialtyInfoId: selectedRows?.at(0)?.id + "",
              }).unwrap();
              toastContext?.openSuccessNotification({
                message: `Specialty deleted successfully`,
                placement: "topRight",
              });
            } catch (error: any) {
              toastContext?.openErrorNotification({
                message: error?.data?.errorMessage || `Failed to delete specialty info`,
                placement: "topRight"
              })
            }
            spinnerContext?.hidespinner();
            setSelectedRows([]);
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete Specialty Info"
        content={
          <Typography.Text>
            Board Name:{" "}
            <Typography.Text strong>
              {selectedRows?.at(0)?.boardName}
            </Typography.Text>
          </Typography.Text>
        }
        description="Are you sure you want to delete this specialty info?"
      />
    </>
  );
}
