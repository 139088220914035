import { Form, FormItemProps, Input, Typography } from "antd";
import { TextAreaProps } from "antd/es/input";
import {
  useFormContext,
  Controller,
  UseControllerProps,
  RegisterOptions,
  FieldValues,
  FieldError,
} from "react-hook-form";

// ----------------------------------------------------------------------

type Props = {
  formItemProps: FormItemProps;
  textAreaProps?: TextAreaProps;
  controllerProps: UseControllerProps;
  rules?:
  | Omit<
    RegisterOptions<FieldValues, string>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >
  | undefined;
};

export default function RHFTextArea({
  formItemProps,
  textAreaProps,
  controllerProps,
  rules,
}: Props) {
  const { control } = useFormContext();
  const { Text } = Typography;
  return (
    <Controller
      name={controllerProps.name}
      control={control}
      defaultValue={controllerProps.defaultValue}
      render={({ field, fieldState }) => {
        return (
          <Form.Item
            {...formItemProps}
            label={
              <span style={{ fontWeight: "600" }}>{formItemProps?.label}{" "}{!!rules?.required ? <Text type="danger">*</Text> : <></>}</span>
            }
            // required={!!rules?.required}
            validateStatus={!!fieldState.error ? "error" : ""}
            help={!!fieldState.error && getErrorMessage(fieldState.error)}
          >
            <Input.TextArea
              {...textAreaProps}
              value={field.value}
              onChange={(changeDetail) =>
                field.onChange(changeDetail.target.value)
              }
            />
          </Form.Item>
        );
      }}
      rules={rules}
    />
  );
}

const getErrorMessage = (error?: FieldError | undefined) => {
  console.log({ error });
  if (!error) return;
  if (error.message && error.message.length > 0) return error.message;
  if (error.type === "maxLength") return "Value is too long";
  return "Invalid value";
};
