import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { useMemo, useState } from "react";
import { useGetAllSectionQuery } from "../redux/api/section/section";

export default function useSectionFilter() {
  const [selectedFilterKey, setSelectedFilterKey] = useState<string>("name");
  const [nameFilterValue, setNameFilterValue] = useState<string | undefined>(
    undefined
  );
  const [descriptionFilterValue, setDescriptionFilterValue] = useState<
    string | undefined
  >(undefined);

  const {
    data: sectionData,
    refetch,
    isLoading,
    isFetching,
  } = useGetAllSectionQuery();
  const filteredSection = useMemo(() => {
    if (isLoading) return [];
    return sectionData?.filter((section) => {
      let matches = true;
      if (nameFilterValue) {
        const nameWords = nameFilterValue?.toLowerCase().split(" ");
        matches =
          matches &&
          nameWords.every((word) =>
            section?.name?.toLowerCase().includes(word)
          );
      }
      if (descriptionFilterValue) {
        const descriptionWords = descriptionFilterValue
          ?.toLowerCase()
          .split(" ");
        matches =
          matches &&
          descriptionWords.every((word) =>
            section?.description?.toLowerCase().includes(word)
          );
      }
      return matches;
    });
  }, [sectionData, nameFilterValue, descriptionFilterValue]);
  const TableFilters = (
    <Space direction="vertical">
      <Space size="small">
        <Select
          value={selectedFilterKey}
          onChange={(value) => setSelectedFilterKey(value)}
          style={{ width: "140px" }}
          options={[
            { value: "name", label: "Name" },
            { value: "description", label: "Description" },
          ]}
        />
        {selectedFilterKey === "name" && (
          <Input
            onChange={(event) => setNameFilterValue(event.target.value)}
            value={nameFilterValue}
            style={{ width: "320px" }}
            placeholder="Find By Name"
            prefix={<SearchOutlined />}
          />
        )}
        {selectedFilterKey === "description" && (
          <Input
            onChange={(event) => setDescriptionFilterValue(event.target.value)}
            value={nameFilterValue}
            style={{ width: "320px" }}
            placeholder="Find By Description"
            prefix={<SearchOutlined />}
          />
        )}
      </Space>
    </Space>
  );
  return {
    TableFilters,
    filteredSection,
    UseQueryHookResult: {
      refetch,
      isLoading,
      isFetching,
    },
  };
}
