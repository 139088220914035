import { Card, Col, Row, Typography } from "antd";

/**
 * Color options "#FFBF00", "#cf1322","#3f8600","#2B6BE6","#b4b4bb"
 */
export default function TableStats({
  stats,
}: {
  stats: { label: string; count: string; color?: string }[];
}) {
  return (
    <Row gutter={16} style={{ paddingTop: "12px" }}>
      {stats?.map((stat) => (
        <Col span={24 / (stats?.length ?? 3)}>
          <Card bordered={true} type="inner">
            <Typography.Title
              level={5}
              style={{
                paddingTop: "0px",
                marginTop: "0px",
                margin: "0",
              }}
            >
              {stat?.label}:{" "}
              <span
                style={{
                  color: stat?.color ?? "#2B6BE6",
                  fontWeight: "400",
                  fontSize: "24px",
                  textAlign: "center",
                }}
              >
                {stat?.count || 0}
              </span>{" "}
            </Typography.Title>
          </Card>
        </Col>
      ))}
    </Row>
  );
}
