import {
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Row,
  Space,
  theme,
  Typography,
} from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SpinnerContext } from "../../../context/spinner/spinner";
import { ToastContext } from "../../../context/toast/incredable-toast";
import IncredableButton from "../../../components/button";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { WorkFlowTemplateRequest } from "../../../redux/api/workflow/types";
import { useAddWorkflowTemplateMutation } from "../../../redux/api/workflow/workflow";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import RHFSelect from "../../../components/RHF/RHFSelect";
import {
  useGetAllTaskStatusQuery,
  useGetAllTaskTypeQuery,
} from "../../../redux/api/task";
import IncredableContent from "../../../components/incredable-content";

const { useToken } = theme;
type FormInputType = WorkFlowTemplateRequest;

export default function AddWorkFlowTemplate() {
  const navigate = useNavigate();
  const methods = useForm<FormInputType>();
  const [addWorkFlowTemplate] = useAddWorkflowTemplateMutation();
  const { token } = useToken();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const { data: taskStatuses } = useGetAllTaskStatusQuery();

  const { data: taskTypes } = useGetAllTaskTypeQuery();

  return (
    <IncredableContent
      breadcrumbGroupProps={{
        items: [
          {
            text: "WorkFlow Template",
            href: "/workflowtemplate",
          },
          {
            text: "Add",
            href: "#",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          initialValues={{}}
          name="addworkflowtemplate"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            spinnerContext?.showSpinner();
            try {
              await addWorkFlowTemplate({
                name: data?.name,
                notes: data?.notes,
                tasks: data?.tasks,
              }).unwrap();
              toastContext?.openSuccessNotification({
                message: `Work Flow Template saved successfully`,
                placement: "topRight",
              });
            } catch (error: any) {
              toastContext?.openErrorNotification({
                message: error?.data?.errorMessage || `Failed to add Work Flow Template`,
                placement: "topRight"
              })
            }
            spinnerContext?.hidespinner();
            navigate(-1);
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Workflow Template
            </Typography.Text>
            <Space>
              <IncredableButton secondary>
                <NavLink to="/workflowtemplate">Cancel</NavLink>
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="workflowtemplate" initialValue={[{}]}>
            {(fields, { }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Space
                        direction="vertical"
                        size="middle"
                        style={{ display: "flex" }}
                      >
                        <Card>
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col span={8}>
                              <RHFTextField
                                formItemProps={{
                                  label: "Name",
                                }}
                                inputProps={{}}
                                controllerProps={{
                                  name: `name`,
                                }}
                                rules={{ required: "This field is required" }}
                              />
                            </Col>
                            <Col span={8}>
                              <RHFTextField
                                formItemProps={{
                                  label: "Notes",
                                }}
                                inputProps={{}}
                                controllerProps={{
                                  name: `notes`,
                                }}
                              />
                            </Col>
                          </Row>
                        </Card>
                        <Card>
                          <Form.List
                            name="workflowtemplatetask"
                            initialValue={[{}]}
                          >
                            {(subFields, subOpt) => (
                              <>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                  {subFields.map((subfield, index) => (
                                    <Col span={24} key={subfield.key}>
                                      <div style={{ marginTop: "20px" }}>
                                        {index > 0 && <Divider />}
                                        <Row
                                          gutter={{
                                            xs: 8,
                                            sm: 16,
                                            md: 24,
                                            lg: 32,
                                          }}
                                        >
                                          <Col span={4}>
                                            <RHFSelect
                                              formItemProps={{
                                                label: "Task Type",
                                              }}
                                              controllerProps={{
                                                name: `tasks.${index}.taskTypeId`,
                                              }}
                                              selectProps={{
                                                allowClear: true,
                                                showSearch: true,
                                                style: { width: "100%" },
                                                placeholder: "Select Task Type",
                                                options: taskTypes?.map(
                                                  (type) => ({
                                                    label: type.name,
                                                    value: type.id,
                                                  })
                                                ),
                                                onModifyClick: () => {
                                                  navigate("/templatetype");
                                                },
                                              }}
                                              rules={{
                                                required:
                                                  "Task Type is required",
                                              }}
                                            />
                                          </Col>

                                          <Col span={4}>
                                            <RHFTextField
                                              formItemProps={{
                                                label: "Task Name",
                                              }}
                                              inputProps={{
                                                placeholder: "Enter Task Name",
                                              }}
                                              controllerProps={{
                                                name: `tasks.${index}.name`,
                                              }}
                                            />
                                          </Col>
                                          <Col span={4}>
                                            <RHFTextField
                                              formItemProps={{
                                                label: "Task Notes",
                                              }}
                                              inputProps={{
                                                placeholder: "Enter Task Notes",
                                              }}
                                              controllerProps={{
                                                name: `tasks.${index}.description`,
                                              }}
                                            />
                                          </Col>

                                          <Col span={4}>
                                            <RHFSelect
                                              formItemProps={{
                                                label: "Task Status",
                                              }}
                                              controllerProps={{
                                                name: `tasks.${index}.taskStatusId`,
                                              }}
                                              selectProps={{
                                                allowClear: true,
                                                showSearch: true,
                                                style: { width: "100%" },
                                                placeholder:
                                                  "Select Task Status",
                                                options: taskStatuses?.map(
                                                  (status) => ({
                                                    label: status.name,
                                                    value: status.id,
                                                  })
                                                ),
                                                onModifyClick: () => {
                                                  navigate("/templatestatus");
                                                },
                                              }}
                                            />
                                          </Col>
                                          <Col
                                            span={4}
                                            style={{
                                              textAlign: "right",
                                              marginTop: "25px",
                                            }}
                                          >
                                            <IncredableButton
                                              onClick={() =>
                                                subOpt.remove(subfield?.name)
                                              }
                                              style={{
                                                width: "140px",
                                                height: "40px",
                                                border: "1.5px solid #598BEB",
                                                color: "#598BEB",
                                                borderRadius: "8px",
                                                padding: "10px 18px",
                                                fontWeight: 600,
                                                fontSize: "16px",
                                              }}
                                              icon={<MinusCircleOutlined />}
                                            >
                                              Remove
                                            </IncredableButton>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  ))}
                                  <Col span={24}>
                                    <Divider
                                      style={{
                                        borderBottom: "1px solid #A884C9",
                                      }}
                                    />
                                    <Form.Item>
                                      <IncredableButton
                                        onClick={() => subOpt.add()}
                                        style={{
                                          color: token.colorPrimary,
                                          borderColor: token.colorPrimary,
                                        }}
                                        type="dashed"
                                        block
                                        icon={<PlusOutlined />}
                                      >
                                        Add New Task
                                      </IncredableButton>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Form.List>
                        </Card>
                      </Space>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
