import { useContext, useState } from "react";
import {
  Button,
  ConfigProvider,
  Dropdown,
  Layout,
  Space,
  theme,
  Typography,
} from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { PayerGroup } from "../../redux/api/payergroup/types";
import { useDeletePayerGroupMutation } from "../../redux/api/payergroup/payergroup";
import usePayerGroupFilter from "../../table-filters/payergroup-filters";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { useLocalStorage } from "../../common/localStorage";
import {
  PAYER_GROUP_CONTENT_DISPLAY_OPTIONS,
  PAYER_GROUP_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "./table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { Header, Pagination, Table } from "@cloudscape-design/components";
const { useToken } = theme;

export default function PayerGroupListV2() {
  const [selectedRows, setSelectedRows] = useState<PayerGroup[]>([]);
  const {
    TableFilters,
    filteredPayerGroup = [],
    UseQueryHookResult: {
      refetch,
      setPageSize,
      isFetching,
      sortingDetails,
      setSortingDetails,
    },
  } = usePayerGroupFilter();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.delete") ?? false)
  );
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-PayerGroup-Table-Preferences",
    PAYER_GROUP_TABLE_DEFAULT_PREFERENCES
  );

  const { items, collectionProps, paginationProps } = useCollection(
    filteredPayerGroup,
    {
      pagination: { pageSize: tablePreferences.pageSize },
    }
  );
  const navigate = useNavigate();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const [deletePayerGroup] = useDeletePayerGroupMutation();

  return (
    <Layout style={{ margin: "20px 30px 0 30px" }}>
      <Space size="large" direction="vertical">
        <Table
          loading={isFetching}
          onSelectionChange={({ detail }) => {
            setSelectedRows([detail?.selectedItems[0]]);
          }}
          onRowClick={(onRowClickDetails) => {
            const selectedItem = onRowClickDetails?.detail?.item;
            if (selectedItem) {
              if (selectedRows?.at(0)?.id === selectedItem?.id) {
                setSelectedRows([]);
              } else {
                setSelectedRows([selectedItem]);
              }
            }
          }}
          selectedItems={selectedRows || []}
          sortingColumn={{ sortingField: sortingDetails?.sortingField }}
          sortingDescending={sortingDetails?.isDescending}
          onSortingChange={({ detail }) => {
            setSortingDetails({
              sortingField: detail?.sortingColumn?.sortingField,
              isDescending: detail?.isDescending,
            });
          }}
          columnDefinitions={[
            {
              id: "id",
              header: <div>ID</div>,
              cell: (item) => <span>{item.id}</span>,
            },
            {
              id: "name",
              header: <div>Name</div>,
              cell: (item) => (
                <NavLink
                  to={hasPermission ? `/payergroup/${item.id}/edit` : `#`}
                >
                  {item?.name}
                </NavLink>
              ),
            },
            {
              id: "groupCode",
              header: <div>Group Code</div>,
              cell: (item) => <div>{item?.groupCode ?? "-"}</div>,
            },
            {
              id: "description",
              header: "Description",
              cell: (item) => <span>{item?.description || "-"}</span>,
              isRowHeader: true,
            },
          ]}
          items={items}
          pagination={<Pagination {...paginationProps} />}
          preferences={
            <TablePreferences
              preferences={tablePreferences}
              setPreferences={(preferences) => {
                if (preferences.pageSize !== tablePreferences.pageSize) {
                  preferences.pageSize && setPageSize(preferences.pageSize);
                }
                setTablePreferences(preferences);
              }}
              contentDisplayOptions={PAYER_GROUP_CONTENT_DISPLAY_OPTIONS}
            />
          }
          loadingText="Loading resources"
          selectionType="single"
          trackBy="id"
          filter={TableFilters}
          header={
            <div style={{ marginTop: "8px", marginBottom: "12px" }}>
              <Header
                actions={
                  <Space size="middle">
                    <Button
                      onClick={() => {
                        !!refetch && refetch();
                      }}
                    >
                      <ReloadOutlined />
                    </Button>
                    <Dropdown
                      menu={{
                        disabled: selectedRows?.length !== 1,
                        items: [
                          {
                            label: hasPermission ? "Edit" : "View",
                            key: "edit",
                            icon: <EditOutlined />,
                            onClick: () => {
                              navigate(`${selectedRows?.at(0)?.id}/edit`);
                            },
                          },
                          {
                            label: "Delete",
                            key: "delete",
                            onClick: () => setDeleteModalOpen(true),
                            disabled: !hasDeletePermission,
                            icon: <DeleteOutlined />,
                            danger: true,
                          },
                        ],
                      }}
                    >
                      <Button>
                        Actions
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "#2B6BE6",
                        },
                      }}
                    >
                      <Button
                        disabled={!hasPermission}
                        onClick={() => navigate(`add`)}
                        type="primary"
                        icon={<PlusOutlined />}
                      >
                        Add Network
                      </Button>
                    </ConfigProvider>
                  </Space>
                }
              >
                <Typography.Title level={3} style={{ marginTop: "0" }}>
                  Networks{" "}
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "16px" }}
                  >{`(${filteredPayerGroup?.length})`}</Typography.Text>
                </Typography.Title>
              </Header>
            </div>
          }
          {...collectionProps}
          columnDisplay={tablePreferences?.contentDisplay}
          wrapLines={tablePreferences?.wrapLines}
          stripedRows={tablePreferences?.stripedRows}
          contentDensity={tablePreferences?.contentDensity}
          stickyColumns={tablePreferences?.stickyColumns}
        />
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (selectedRows?.at(0)?.id) {
              setDeleteModalOpen(false);
              spinnerContext?.showSpinner();
              try {
                await deletePayerGroup({
                  id: String(selectedRows?.at(0)?.id),
                }).unwrap();
                toastContext?.openSuccessNotification({
                  message: `Payer Group deleted successfully`,
                  placement: "topRight",
                });
              } catch (error: any) {
                toastContext?.openErrorNotification({
                  message: error?.data?.errorMessage || `Failed to delete Payer Group`,
                  placement: "topRight"
                })
              }
              spinnerContext?.hidespinner();
              setSelectedRows([]);
            }
          }}
          closeModal={() => setDeleteModalOpen(false)}
          header="Delete Payer Group"
          content={
            <Typography.Text>
              Payer Group Name:{" "}
              <Typography.Text>{selectedRows?.at(0)?.name}</Typography.Text>
            </Typography.Text>
          }
          description="Are you sure you want to delete this payer group?"
        />
      </Space>
    </Layout>
  );
}
