import { api } from "..";
import { DEAVerificationType } from "./types";

const deaVerificationAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllDEAVerification: build.query<
      DEAVerificationType[],
      { recordId: string }
    >({
      query: ({ recordId }) => `verify/dea/${recordId}/all`,
      providesTags: ["DEAVerification"],
    }),
    startDEAVerification: build.mutation<void, { recordId: string }>({
      query: ({ recordId }) => {
        return {
          url: `verify/dea/start/${recordId}`,
          method: "POST",
        };
      },
      invalidatesTags: ["DEAVerification"],
    }),
    scheduleDEAVerification: build.mutation<void, { recordId: string }>({
      query: ({ recordId }) => {
        return {
          url: `verify/dea/schedule/${recordId}`,
          method: "POST",
        };
      },
      invalidatesTags: ["DEAVerification"],
    }),
  }),
});

export const {
  useGetAllDEAVerificationQuery,
  useStartDEAVerificationMutation,
  useScheduleDEAVerificationMutation
} = deaVerificationAPI;
