import { useContext, useState } from "react";
import { Button, ConfigProvider, Flex, Space } from "antd";
import { Pagination, Table, Modal } from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { AllDocumentResponse } from "../redux/api/document/types";
import { useLocalStorage } from "../common/localStorage";
import {
  useAddPrivilegeDocumentsMutation,
  useGetPrivilegeQuery,
} from "../redux/api/privileges/privileges";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  PRIVILEGEDOCUMENT_CONTENT_DISPLAY_OPTIONS,
  PRIVILEGEDOCUMENT_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "./table-config";
import { useGetAllProviderSafeDocumentsQuery } from "../redux/api/provider/provider";
import {
  READYFORM_CONTENT_DISPLAY_OPTIONS,
  READYFORM_TABLE_DEFAULT_PREFERENCES,
} from "../digitalforms/table-config";
import { DigitalForm } from "../redux/api/digitalform/types";
import { useGetAllDigitalFormsQuery } from "../redux/api/digitalform/digitalforms";
import { read } from "fs";

type ReadyformModalProps = {
  closeModal: VoidFunction;
  visible: boolean;
};

export default function ReadyformModal({
  visible,
  closeModal,
}: ReadyformModalProps) {
  const [selectedRows, setSelectedRows] = useState<DigitalForm[]>([]);

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-ReadyForm-Table-Preferences",
    READYFORM_TABLE_DEFAULT_PREFERENCES
  );

  const { privilegeId } = useParams();
  const { data: privilege, isLoading: privilegeData } = useGetPrivilegeQuery(
    !!privilegeId ? { privilegeId: privilegeId } : skipToken
  );

  const { data: readyforms, isLoading } = useGetAllDigitalFormsQuery();
  const navigate = useNavigate();

  const handleSave = async () => {
    if (!selectedRows) {
      closeModal();
      return;
    }
    navigate(
      `/readyforms/${
        selectedRows?.at(0)?.id
      }/requestsignature?privilegeId=${privilegeId}&providerId=${
        privilege?.provider?.id
      }`
    );
  };

  const { items, collectionProps, paginationProps } = useCollection(
    readyforms || [],
    {
      pagination: { pageSize: tablePreferences.pageSize },
    }
  );

  return (
    <Modal
      header={"Select Readyform"}
      visible={visible}
      onDismiss={closeModal}
      size="large"
      footer={[
        <Flex justify="end">
          <Space>
            <Button
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </Button>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#2B6BE6",
                },
              }}
            >
              <Button
                type="primary"
                disabled={selectedRows?.length !== 1}
                onClick={handleSave}
              >
                Request Signature
              </Button>
            </ConfigProvider>
          </Space>
        </Flex>,
      ]}
    >
      <Table
        loading={isLoading || privilegeData}
        onSelectionChange={({ detail }) => {
          const selectedItem = detail?.selectedItems[0];
          setSelectedRows(selectedItem ? [selectedItem] : []);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows?.at(0)?.id === selectedItem.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        columnDefinitions={[
          {
            id: "id",
            header: "Id",
            cell: (item) => <span>{item?.id}</span>,
            sortingField: "id",
          },
          {
            id: "name",
            header: "Readyform name",
            cell: (item) => <span>{item?.name}</span>,
            sortingField: "name",
            sortingComparator: (a, b) =>
              (a?.name ?? "").localeCompare(b?.name ?? ""),
          },
        ]}
        items={items}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            contentDisplayOptions={READYFORM_CONTENT_DISPLAY_OPTIONS}
            setPreferences={(preferences) => {
              setTablePreferences(preferences);
            }}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        {...collectionProps}
        columnDisplay={tablePreferences.contentDisplay}
        wrapLines={tablePreferences.wrapLines}
        stripedRows={tablePreferences.stripedRows}
        contentDensity={tablePreferences.contentDensity}
        stickyColumns={tablePreferences.stickyColumns}
      />
    </Modal>
  );
}
