import { useContext, useState } from "react";
import {
  Button,
  ConfigProvider,
  Dropdown,
  Layout,
  Space,
  Typography,
} from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { CredentialTemplate } from "../../redux/api/credentialpackages/types";
import { useDeleteCredentialTemplateMutation } from "../../redux/api/credentialpackages/credentialpackages";
import useCredentialTemplateFilter from "../../table-filters/credentialtemplates-filters";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { useLocalStorage } from "../../common/localStorage";
import {
  CREDENTIALTEMPLATE_CONTENT_DISPLAY_OPTIONS,
  CREDENTIALTEMPLATE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "./table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { Header, Pagination, Table } from "@cloudscape-design/components";

export default function CredentialTemplateListV2() {
  const [selectedRows, setSelectedRows] = useState<CredentialTemplate[]>([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("credentialpackage.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("credentialpackage.delete") ?? false)
  );

  const {
    TableFilters,
    filteredCredentialTemplate,
    UseQueryHookResult: { refetch, isLoading, isFetching },
  } = useCredentialTemplateFilter();
  const navigate = useNavigate();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const [deleteCredentialTemplate] = useDeleteCredentialTemplateMutation();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-CredentialTemplate-Table-Preferences",
    CREDENTIALTEMPLATE_TABLE_DEFAULT_PREFERENCES
  );

  const { items, collectionProps, paginationProps } = useCollection(
    filteredCredentialTemplate ?? [],
    {
      pagination: { pageSize: tablePreferences?.pageSize },
    }
  );

  return (
    <Layout style={{ margin: "20px 30px 0 30px" }}>
      <Space size="large" direction="vertical">
        <Table
          loading={isFetching || isLoading}
          onSelectionChange={({ detail }) => {
            const selectedItem = detail?.selectedItems[0];
            setSelectedRows(selectedItem ? [selectedItem] : []);
          }}
          onRowClick={(onRowClickDetails) => {
            const selectedItem = onRowClickDetails?.detail?.item;
            if (selectedItem) {
              if (selectedRows?.at(0)?.id === selectedItem?.id) {
                setSelectedRows([]);
              } else {
                setSelectedRows([selectedItem]);
              }
            }
          }}
          selectedItems={selectedRows || []}
          columnDefinitions={[
            {
              id: "id",
              header: <div>ID</div>,
              cell: (item) => <div>{item?.id}</div>,
            },
            {
              id: "name",
              header: <div>Name</div>,
              cell: (item) => (
                <NavLink
                  to={hasPermission ? `/credentialtemplates/${item?.id}` : `#`}
                >
                  {item?.name}
                </NavLink>
              ),
            },
            {
              id: "categoriesCount",
              header: <div>Categories Count</div>,
              cell: (item) => <div>{item?.categories?.length}</div>,
            },
            {
              id: "licenseType",
              header: <div>License Type</div>,
              cell: (item) => <div>{item?.licenseType?.name}</div>,
            },
          ]}
          items={items}
          pagination={<Pagination {...paginationProps} />}
          preferences={
            <TablePreferences
              preferences={tablePreferences}
              setPreferences={(preferences) => {
                setTablePreferences(preferences);
              }}
              contentDisplayOptions={CREDENTIALTEMPLATE_CONTENT_DISPLAY_OPTIONS}
            />
          }
          loadingText="Loading resources"
          selectionType="single"
          trackBy="id"
          filter={TableFilters}
          header={
            <div style={{ marginTop: "8px", marginBottom: "12px" }}>
              <Header
                actions={
                  <Space size="middle">
                    <Button
                      onClick={() => {
                        !!refetch && refetch();
                      }}
                    >
                      <ReloadOutlined />
                    </Button>
                    <Dropdown
                      menu={{
                        disabled: selectedRows?.length !== 1,
                        items: [
                          {
                            label: hasPermission ? "Edit" : "View",
                            key: "edit",
                            icon: <EditOutlined />,
                            onClick: () => {
                              navigate(`${selectedRows?.at(0)?.id}`);
                            },
                          },
                          {
                            label: "Delete",
                            key: "delete",
                            onClick: () => setDeleteModalOpen(true),
                            disabled: !hasDeletePermission,
                            icon: <DeleteOutlined />,
                            danger: true,
                          },
                        ],
                      }}
                    >
                      <Button>
                        Actions
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "#2B6BE6",
                        },
                      }}
                    >
                      <Button
                        disabled={!hasPermission}
                        onClick={() => navigate(`add`)}
                        type="primary"
                        icon={<PlusOutlined />}
                      >
                        Add Credential Template
                      </Button>
                    </ConfigProvider>
                  </Space>
                }
              >
                <Typography.Title level={3} style={{ marginTop: "0" }}>
                  Credential Templates{" "}
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "16px" }}
                  >{`(${filteredCredentialTemplate?.length})`}</Typography.Text>
                </Typography.Title>
              </Header>
            </div>
          }
          {...collectionProps}
          columnDisplay={tablePreferences?.contentDisplay}
          wrapLines={tablePreferences?.wrapLines}
          stripedRows={tablePreferences?.stripedRows}
          contentDensity={tablePreferences?.contentDensity}
          stickyColumns={tablePreferences?.stickyColumns}
        />
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (selectedRows?.at(0)?.id) {
              setDeleteModalOpen(false);
              spinnerContext?.showSpinner();
              try {
                await deleteCredentialTemplate({
                  credentialTemplateId: String(selectedRows?.at(0)?.id),
                }).unwrap();
                toastContext?.openSuccessNotification({
                  message: `Credential Template deleted successfully`,
                  placement: "topRight",
                });
              } catch (error: any) {
                toastContext?.openSuccessNotification({
                  message: error?.data?.errorMessage || `Failed to delete Credential Template`,
                  placement: "topRight",
                });
              }
              spinnerContext?.hidespinner();
              setSelectedRows([]);
            }
          }
          }
          closeModal={() => setDeleteModalOpen(false)}
          header="Delete Credential Template"
          content={
            <Typography.Text>
              Credential Template Name:{" "}
              <Typography.Text strong>
                {selectedRows?.at(0)?.name}
              </Typography.Text>
            </Typography.Text>
          }
          description="Are you sure you want to delete this credential template?"
        />
      </Space>
    </Layout>
  );
}
