import { Anchor, Card, Col, Flex, Row } from "antd";
import {
  CopyToClipboard,
  KeyValuePairs,
  Link,
  ProgressBar,
  SpaceBetween,
  StatusIndicator,
} from "@cloudscape-design/components";
import IncredableButton from "../components/button";
import ReadyformModal from "./readyform-modal";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function AnchorLabel({ children }: { children: React.ReactNode }) {
  return (
    <span
      style={{ fontSize: "14px", fontWeight: "500", letterSpacing: ".25px" }}
    >
      {children}
    </span>
  );
}
export default function RequestedSignatures() {
  const [isReadyformModalVisible, setIsReadyformModalVisible] =
    useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <SpaceBetween size={"l"}>
      <Flex justify={"flex-end"} align={"center"}>
        <IncredableButton
          type="primary"
          onClick={() => {
            setIsReadyformModalVisible(true);
          }}
        >
          Request Signature
        </IncredableButton>
      </Flex>
      <Row gutter={12}>
        <Col span={19}>
          <SpaceBetween size="m">
            <div id="overview">
              <Card title="Onboarding forms">
                <SpaceBetween size={"l"}>
                  <Card
                    type="inner"
                    title="Subject one"
                    extra={<a href="#">More</a>}
                  >
                    <KeyValuePairs
                      columns={3}
                      items={[
                        {
                          label: "Signature ID",
                          value: "E1WG1ZNPRXT0D4",
                        },
                        {
                          label: "Category",
                          value: "Appointment",
                        },
                        {
                          label: "Status",
                          value: <StatusIndicator>Completed</StatusIndicator>,
                        },
                        {
                          label: "Progress",
                          id: "ssl-certificate-id",
                          value: (
                            <ProgressBar
                              value={30}
                              description="1/3 recipeints signed"
                            />
                          ),
                        },
                        {
                          label: "Ready form",
                          value: "Provider acknowledgements form",
                        },
                        {
                          label: "Document",
                          value: (
                            <Link external={true} href="#">
                              abc.service23G24.xyz
                            </Link>
                          ),
                        },
                      ]}
                    />
                  </Card>
                  <Card
                    type="inner"
                    title="Subject two"
                    extra={<a href="#">More</a>}
                  >
                    <KeyValuePairs
                      columns={3}
                      items={[
                        {
                          label: "Signature ID",
                          value: "E1WG1ZNPRXT0D4",
                        },
                        {
                          label: "ARN",
                          value: (
                            <CopyToClipboard
                              copyButtonAriaLabel="Copy ARN"
                              copyErrorText="ARN failed to copy"
                              copySuccessText="ARN copied"
                              textToCopy="arn:service23G24::111122223333:distribution/23E1WG1ZNPRXT0D4"
                              variant="inline"
                            />
                          ),
                        },
                        {
                          label: "Status",
                          value: <StatusIndicator>Completed</StatusIndicator>,
                        },
                        {
                          label: "Progress",
                          id: "ssl-certificate-id",
                          value: (
                            <ProgressBar
                              value={30}
                              description="1/3 recipeints signed"
                            />
                          ),
                        },
                        {
                          label: "Ready form",
                          value: "Provider acknowledgements form",
                        },
                        {
                          label: "Document",
                          value: (
                            <Link external={true} href="#">
                              abc.service23G24.xyz
                            </Link>
                          ),
                        },
                      ]}
                    />
                  </Card>
                </SpaceBetween>
              </Card>
            </div>
            <div id="documentsummary">
              <Card title="Intial committee review forms">
                <SpaceBetween size={"l"}>
                  <Card
                    type="inner"
                    title="Subject one"
                    extra={<a href="#">More</a>}
                  >
                    <KeyValuePairs
                      columns={3}
                      items={[
                        {
                          label: "Signature ID",
                          value: "E1WG1ZNPRXT0D4",
                        },
                        {
                          label: "Category",
                          value: "Appointment",
                        },
                        {
                          label: "Status",
                          value: <StatusIndicator>Completed</StatusIndicator>,
                        },
                        {
                          label: "Progress",
                          id: "ssl-certificate-id",
                          value: (
                            <ProgressBar
                              value={30}
                              description="1/3 recipeints signed"
                            />
                          ),
                        },
                        {
                          label: "Ready form",
                          value: "Provider acknowledgements form",
                        },
                        {
                          label: "Document",
                          value: (
                            <Link external={true} href="#">
                              abc.service23G24.xyz
                            </Link>
                          ),
                        },
                      ]}
                    />
                  </Card>
                  <Card
                    type="inner"
                    title="Subject two"
                    extra={<a href="#">More</a>}
                  >
                    <KeyValuePairs
                      columns={3}
                      items={[
                        {
                          label: "Signature ID",
                          value: "E1WG1ZNPRXT0D4",
                        },
                        {
                          label: "ARN",
                          value: (
                            <CopyToClipboard
                              copyButtonAriaLabel="Copy ARN"
                              copyErrorText="ARN failed to copy"
                              copySuccessText="ARN copied"
                              textToCopy="arn:service23G24::111122223333:distribution/23E1WG1ZNPRXT0D4"
                              variant="inline"
                            />
                          ),
                        },
                        {
                          label: "Status",
                          value: <StatusIndicator>Completed</StatusIndicator>,
                        },
                        {
                          label: "Progress",
                          id: "ssl-certificate-id",
                          value: (
                            <ProgressBar
                              value={30}
                              description="1/3 recipeints signed"
                            />
                          ),
                        },
                        {
                          label: "Ready form",
                          value: "Provider acknowledgements form",
                        },
                        {
                          label: "Document",
                          value: (
                            <Link external={true} href="#">
                              abc.service23G24.xyz
                            </Link>
                          ),
                        },
                      ]}
                    />
                  </Card>
                </SpaceBetween>
              </Card>
            </div>
            <div id="exclusions">
              <Card title="Privilege forms">
                <SpaceBetween size={"l"}>
                  <Card
                    type="inner"
                    title="Subject one"
                    extra={<a href="#">More</a>}
                  >
                    <KeyValuePairs
                      columns={3}
                      items={[
                        {
                          label: "Signature ID",
                          value: "E1WG1ZNPRXT0D4",
                        },
                        {
                          label: "Category",
                          value: "Appointment",
                        },
                        {
                          label: "Status",
                          value: <StatusIndicator>Completed</StatusIndicator>,
                        },
                        {
                          label: "Progress",
                          id: "ssl-certificate-id",
                          value: (
                            <ProgressBar
                              value={30}
                              description="1/3 recipeints signed"
                            />
                          ),
                        },
                        {
                          label: "Ready form",
                          value: "Provider acknowledgements form",
                        },
                        {
                          label: "Document",
                          value: (
                            <Link external={true} href="#">
                              abc.service23G24.xyz
                            </Link>
                          ),
                        },
                      ]}
                    />
                  </Card>
                  <Card
                    type="inner"
                    title="Subject two"
                    extra={<a href="#">More</a>}
                  >
                    <KeyValuePairs
                      columns={3}
                      items={[
                        {
                          label: "Signature ID",
                          value: "E1WG1ZNPRXT0D4",
                        },
                        {
                          label: "ARN",
                          value: (
                            <CopyToClipboard
                              copyButtonAriaLabel="Copy ARN"
                              copyErrorText="ARN failed to copy"
                              copySuccessText="ARN copied"
                              textToCopy="arn:service23G24::111122223333:distribution/23E1WG1ZNPRXT0D4"
                              variant="inline"
                            />
                          ),
                        },
                        {
                          label: "Status",
                          value: <StatusIndicator>Completed</StatusIndicator>,
                        },
                        {
                          label: "Progress",
                          id: "ssl-certificate-id",
                          value: (
                            <ProgressBar
                              value={30}
                              description="1/3 recipeints signed"
                            />
                          ),
                        },
                        {
                          label: "Ready form",
                          value: "Provider acknowledgements form",
                        },
                        {
                          label: "Document",
                          value: (
                            <Link external={true} href="#">
                              abc.service23G24.xyz
                            </Link>
                          ),
                        },
                      ]}
                    />
                  </Card>
                </SpaceBetween>
              </Card>
            </div>
            <div id="personalinfo">
              <Card title="Final Appointment forms">
                <SpaceBetween size={"l"}>
                  <Card
                    type="inner"
                    title="Subject one"
                    extra={<a href="#">More</a>}
                  >
                    <KeyValuePairs
                      columns={3}
                      items={[
                        {
                          label: "Signature ID",
                          value: "E1WG1ZNPRXT0D4",
                        },
                        {
                          label: "Category",
                          value: "Appointment",
                        },
                        {
                          label: "Status",
                          value: <StatusIndicator>Completed</StatusIndicator>,
                        },
                        {
                          label: "Progress",
                          id: "ssl-certificate-id",
                          value: (
                            <ProgressBar
                              value={30}
                              description="1/3 recipeints signed"
                            />
                          ),
                        },
                        {
                          label: "Ready form",
                          value: "Provider acknowledgements form",
                        },
                        {
                          label: "Document",
                          value: (
                            <Link external={true} href="#">
                              abc.service23G24.xyz
                            </Link>
                          ),
                        },
                      ]}
                    />
                  </Card>
                  <Card
                    type="inner"
                    title="Subject two"
                    extra={<a href="#">More</a>}
                  >
                    <KeyValuePairs
                      columns={3}
                      items={[
                        {
                          label: "Signature ID",
                          value: "E1WG1ZNPRXT0D4",
                        },
                        {
                          label: "ARN",
                          value: (
                            <CopyToClipboard
                              copyButtonAriaLabel="Copy ARN"
                              copyErrorText="ARN failed to copy"
                              copySuccessText="ARN copied"
                              textToCopy="arn:service23G24::111122223333:distribution/23E1WG1ZNPRXT0D4"
                              variant="inline"
                            />
                          ),
                        },
                        {
                          label: "Status",
                          value: <StatusIndicator>Completed</StatusIndicator>,
                        },
                        {
                          label: "Progress",
                          id: "ssl-certificate-id",
                          value: (
                            <ProgressBar
                              value={30}
                              description="1/3 recipeints signed"
                            />
                          ),
                        },
                        {
                          label: "Ready form",
                          value: "Provider acknowledgements form",
                        },
                        {
                          label: "Document",
                          value: (
                            <Link external={true} href="#">
                              abc.service23G24.xyz
                            </Link>
                          ),
                        },
                      ]}
                    />
                  </Card>
                </SpaceBetween>
              </Card>
            </div>
            <div id="professionalinfo">
              <Card title="Onboarding forms">
                <SpaceBetween size={"l"}>
                  <Card
                    type="inner"
                    title="Subject one"
                    extra={<a href="#">More</a>}
                  >
                    <KeyValuePairs
                      columns={3}
                      items={[
                        {
                          label: "Signature ID",
                          value: "E1WG1ZNPRXT0D4",
                        },
                        {
                          label: "Category",
                          value: "Appointment",
                        },
                        {
                          label: "Status",
                          value: <StatusIndicator>Completed</StatusIndicator>,
                        },
                        {
                          label: "Progress",
                          id: "ssl-certificate-id",
                          value: (
                            <ProgressBar
                              value={30}
                              description="1/3 recipeints signed"
                            />
                          ),
                        },
                        {
                          label: "Ready form",
                          value: "Provider acknowledgements form",
                        },
                        {
                          label: "Document",
                          value: (
                            <Link external={true} href="#">
                              abc.service23G24.xyz
                            </Link>
                          ),
                        },
                      ]}
                    />
                  </Card>
                  <Card
                    type="inner"
                    title="Subject two"
                    extra={<a href="#">More</a>}
                  >
                    <KeyValuePairs
                      columns={3}
                      items={[
                        {
                          label: "Signature ID",
                          value: "E1WG1ZNPRXT0D4",
                        },
                        {
                          label: "ARN",
                          value: (
                            <CopyToClipboard
                              copyButtonAriaLabel="Copy ARN"
                              copyErrorText="ARN failed to copy"
                              copySuccessText="ARN copied"
                              textToCopy="arn:service23G24::111122223333:distribution/23E1WG1ZNPRXT0D4"
                              variant="inline"
                            />
                          ),
                        },
                        {
                          label: "Status",
                          value: <StatusIndicator>Completed</StatusIndicator>,
                        },
                        {
                          label: "Progress",
                          id: "ssl-certificate-id",
                          value: (
                            <ProgressBar
                              value={30}
                              description="1/3 recipeints signed"
                            />
                          ),
                        },
                        {
                          label: "Ready form",
                          value: "Provider acknowledgements form",
                        },
                        {
                          label: "Document",
                          value: (
                            <Link external={true} href="#">
                              abc.service23G24.xyz
                            </Link>
                          ),
                        },
                      ]}
                    />
                  </Card>
                </SpaceBetween>
              </Card>
            </div>
          </SpaceBetween>
        </Col>
        <Col span={5}>
          <Anchor
            affix={true}
            targetOffset={100}
            offsetTop={100}
            items={[
              {
                key: "overview",
                href: "#overview",
                title: <AnchorLabel>Onboarding forms</AnchorLabel>,
              },
              {
                key: "documentsummary",
                href: "#documentsummary",
                title: <AnchorLabel>Intial committee review forms</AnchorLabel>,
              },
              {
                key: "exclusions",
                href: "#exclusions",
                title: <AnchorLabel>Privilege forms</AnchorLabel>,
              },
              {
                key: "personalinfo",
                href: "#personalinfo",
                title: <AnchorLabel>Final Appointment form</AnchorLabel>,
              },
            ]}
          />
        </Col>
      </Row>
      <ReadyformModal
        closeModal={() => {
          setIsReadyformModalVisible(false);
          navigate("/privileges");
        }}
        visible={isReadyformModalVisible}
      />
    </SpaceBetween>
  );
}
