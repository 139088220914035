import React, { useContext, useState } from "react";
import { Tabs, Table, Checkbox, Tag, Typography, ConfigProvider } from "antd";
import {
  findLowerPermissionKeys,
  isPermissionCheckBoxDisabled,
  Permission,
  PermissionsGroupType,
  RolePermissions,
} from "../../redux/api/role/types";
import { capitalize, remove, uniq } from "lodash";
import IncredableButton from "../../components/button";
import { useUpdateRolePermissionsMutation } from "../../redux/api/role/role";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
export const permissionsDataInitial: Permission[] = [
  { id: "1", key: "provider.view" },
  { id: "2", key: "provider.edit" },
  { id: "3", key: "provider.delete" },
  { id: "4", key: "provider.all" },
  { id: "5", key: "facility.view" },
  { id: "6", key: "facility.edit" },
  { id: "7", key: "facility.delete" },
  { id: "8", key: "facility.all" },
  { id: "9", key: "readyforms.view" },
  { id: "10", key: "readyforms.edit" },
  { id: "11", key: "readyforms.delete" },
  { id: "12", key: "readyforms.all" },
  { id: "13", key: "privileges.view" },
  { id: "14", key: "privileges.edit" },
  { id: "15", key: "privileges.delete" },
  { id: "16", key: "privileges.all" },
  { id: "17", key: "signature.view" },
  { id: "18", key: "signature.request" },
  { id: "19", key: "signature.void" },
  { id: "20", key: "signature.all" },
  { id: "21", key: "contract.view" },
  { id: "22", key: "contract.edit" },
  { id: "23", key: "contract.delete" },
  { id: "24", key: "contract.all" },
  { id: "25", key: "payer.view" },
  { id: "26", key: "payer.edit" },
  { id: "27", key: "payer.delete" },
  { id: "28", key: "payer.all" },
  { id: "29", key: "credentialpackage.view" },
  { id: "30", key: "credentialpackage.edit" },
  { id: "31", key: "credentialpackage.delete" },
  { id: "32", key: "credentialpackage.all" },
  { id: "33", key: "roles.view" },
  { id: "34", key: "roles.edit" },
  { id: "35", key: "roles.delete" },
  { id: "36", key: "roles.all" },
  { id: "37", key: "member.view" },
  { id: "38", key: "member.edit" },
  { id: "39", key: "member.delete" },
  { id: "40", key: "member.all" },
  { id: "41", key: "reports.view" },
  { id: "42", key: "support.all" },
  { id: "43", key: "organization_settings.view" },
  { id: "44", key: "organization_settings.edit" },
  { id: "45", key: "task.view" },
  { id: "46", key: "task.edit" },
  { id: "47", key: "task.delete" },
  { id: "48", key: "task.all" },
  { id: "49", key: "workflow.view" },
  { id: "50", key: "workflow.edit" },
  { id: "51", key: "workflow.delete" },
  { id: "52", key: "workflow.all" },
  { id: "53", key: "ssn.view" },
  { id: "54", key: "ssn.edit" },
  { id: "55", key: "section.view" },
  { id: "56", key: "section.edit" }
];
const PermissionsGroup: PermissionsGroupType = {
  Provider: [
    {
      id: "1",
      key: "provider.view",
    },
    {
      id: "2",
      key: "provider.edit",
    },
    {
      id: "3",
      key: "provider.delete",
    },
    {
      id: "4",
      key: "provider.all",
    },
  ],
  Facility: [
    {
      id: "5",
      key: "facility.view",
    },
    {
      id: "6",
      key: "facility.edit",
    },
    {
      id: "7",
      key: "facility.delete",
    },
    {
      id: "8",
      key: "facility.all",
    },
  ],
  "Ready Forms": [
    {
      id: "9",
      key: "readyforms.view",
    },
    {
      id: "10",
      key: "readyforms.edit",
    },
    {
      id: "11",
      key: "readyforms.delete",
    },
    {
      id: "12",
      key: "readyforms.all",
    },
  ],
  Privileges: [
    {
      id: "13",
      key: "privileges.view",
    },
    {
      id: "14",
      key: "privileges.edit",
    },
    {
      id: "15",
      key: "privileges.delete",
    },
    {
      id: "16",
      key: "privileges.all",
    },
  ],
  Signature: [
    {
      id: "17",
      key: "signature.view",
    },
    {
      id: "18",
      key: "signature.request",
    },
    {
      id: "19",
      key: "signature.void",
    },
    {
      id: "20",
      key: "signature.all",
    },
  ],
  Contract: [
    {
      id: "21",
      key: "contract.view",
    },
    {
      id: "22",
      key: "contract.edit",
    },
    {
      id: "23",
      key: "contract.delete",
    },
    {
      id: "24",
      key: "contract.all",
    },
  ],
  Payer: [
    {
      id: "25",
      key: "payer.view",
    },
    {
      id: "26",
      key: "payer.edit",
    },
    {
      id: "27",
      key: "payer.delete",
    },
    {
      id: "28",
      key: "payer.all",
    },
  ],
  "Credential Packages": [
    {
      id: "29",
      key: "credentialpackage.view",
    },
    {
      id: "30",
      key: "credentialpackage.edit",
    },
    {
      id: "31",
      key: "credentialpackage.delete",
    },
    {
      id: "32",
      key: "credentialpackage.all",
    },
  ],
  Task: [
    {
      id: "45",
      key: "task.view",
    },
    {
      id: "46",
      key: "task.edit",
    },
    {
      id: "47",
      key: "task.delete",
    },
    {
      id: "48",
      key: "task.all",
    },
  ],
  Workflow: [
    {
      id: "49",
      key: "workflow.view",
    },
    {
      id: "50",
      key: "workflow.edit",
    },
    {
      id: "51",
      key: "workflow.delete",
    },
    {
      id: "52",
      key: "workflow.all",
    },
  ],
  Roles: [
    {
      id: "33",
      key: "roles.view",
    },
    {
      id: "34",
      key: "roles.edit",
    },
    {
      id: "35",
      key: "roles.delete",
    },
    {
      id: "36",
      key: "roles.all",
    },
  ],
  Member: [
    {
      id: "37",
      key: "member.view",
    },
    {
      id: "38",
      key: "member.edit",
    },
    {
      id: "39",
      key: "member.delete",
    },
    {
      id: "40",
      key: "member.all",
    },
  ],
  Reports: [
    {
      id: "41",
      key: "reports.view",
    },
  ],
  "Flex Fields": [
    {
      id: "55",
      key: "section.view",
    },
    {
      id: "56",
      key: "section.edit"
    }
  ],
  "Organization Settings": [
    {
      id: "43",
      key: "organization_settings.view",
    },
    {
      id: "44",
      key: "organization_settings.edit",
    },
  ],
  "Confidential Info": [
    {
      id: "53",
      key: "ssn.view",
    },
    {
      id: "54",
      key: "ssn.edit",
    },
  ],
};
export type PermissionsTableProps = {
  roles: RolePermissions[];
  setRoles: React.Dispatch<React.SetStateAction<RolePermissions[]>>;
  tabName?: string;
};
export type PermissionsTabContentProps = {
  group: string;
  rolePermissions: RolePermissions[];
  permissions: Permission[];
  onRolePermissionChange: (change: RolePermissionsChange) => void;
};
export type RolePermissionsChange = {
  checked: boolean;
  role: RolePermissions;
  key: string;
};
const PermissionsList = ({
  roles: rolePermissions,
  setRoles,
  tabName,
}: PermissionsTableProps) => {
  const [activeTabKey, setActiveTabKey] = useState<string>("Provider");
  const permissions: Permission[] = PermissionsGroup[activeTabKey];
  const [updateRolePermission] = useUpdateRolePermissionsMutation();

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const onRolePermissionChange = ({
    role,
    key,
    checked,
  }: RolePermissionsChange) => {
    if (!!rolePermissions && !!permissions) {
      let temp_role_permissions = [...rolePermissions];
      let role_index = temp_role_permissions.findIndex(
        (temp_role_permission) => role.id === temp_role_permission.id
      );
      if (role_index === -1) return;
      if (!!checked) {
        temp_role_permissions[role_index].permissions = uniq([
          ...findLowerPermissionKeys(permissions, key),
          ...temp_role_permissions[role_index].permissions,
        ]);
      } else {
        remove(temp_role_permissions[role_index].permissions, (permission) =>
          findLowerPermissionKeys(permissions, key).includes(permission)
        );
      }
      setRoles(temp_role_permissions);
    }
  };

  const columns = [
    {
      title: () => <Typography.Text>Role Name</Typography.Text>,
      dataIndex: "role",
      key: "role",
      render: (role: string, record: RolePermissions) => {
        return (
          <Typography.Text>
            {record.name || "-"}
            {record.default && <Tag style={{ marginLeft: "8px" }}>Default</Tag>}
          </Typography.Text>
        );
      },
    },
    ...permissions.map((permission: Permission) => {
      return {
        title: () => (
          <Typography.Text>
            {capitalize(permission.key.split(".")[1] || "")}
          </Typography.Text>
        ),
        dataIndex: ["permissions", "key"],
        key: permission.key,
        render: (value: boolean, record: RolePermissions) => {
          return (
            <Checkbox
              id={permission.key}
              disabled={
                isPermissionCheckBoxDisabled({
                  role_permissions: record.permissions,
                  permissions: permissions,
                  permission_key: permission.key,
                }) || record.default
              }
              checked={
                !!record.permissions.find(
                  (role_permission: string) =>
                    role_permission === permission.key
                )
              }
              onChange={(changeDetail) => {
                onRolePermissionChange({
                  role: record,
                  key: permission.key,
                  checked: changeDetail.target.checked,
                });
              }}
            />
          );
        },
      };
    }),
  ];

  const handleRoleDetailsSave = async () => {
    spinnerContext?.showSpinner();
    try {
      await updateRolePermission(rolePermissions).unwrap();
      toastContext?.openSuccessNotification({
        message: "Role Saved successfully",
        placement: "topRight",
      });
    } catch (error: any) {
      toastContext?.openErrorNotification({
        message: error?.data?.errorMessage || "Failed to save role permissions",
        placement: "topRight",
      });
    }
    spinnerContext?.hidespinner();
  };
  const handlePermissionSave = async () => {
    const nonDefaultRoles = rolePermissions.filter((role) => !role.default);
    spinnerContext?.showSpinner();
    const response = await updateRolePermission(nonDefaultRoles);
    if (response?.error) {
      toastContext?.openErrorNotification({
        message: "Failed to save role permissions",
        placement: "topRight",
      });
    } else {
      toastContext?.openSuccessNotification({
        message: "Role Saved successfully",
        placement: "topRight",
      });
    }
    spinnerContext?.hidespinner();
  };
  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Typography.Text
          strong
          style={{
            fontSize: "22px",
            color: "#1D1A1F",
            lineHeight: "25px",
            fontWeight: "700",
          }}
        >
          Permissions
        </Typography.Text>
        <IncredableButton
          type="primary"
          onClick={
            tabName === "roleDetails"
              ? handleRoleDetailsSave
              : handlePermissionSave
          }
        >
          Save
        </IncredableButton>
      </div>

      <Tabs
        activeKey={activeTabKey}
        onChange={setActiveTabKey}
        tabPosition="left"
        type="card"
        tabBarStyle={{ width: "220px" }}
      >
        {Object.keys(PermissionsGroup).map((key) => (
          <Tabs.TabPane
            tab={
              <span style={{ letterSpacing: "0.25px", fontWeight: "600" }}>
                {key}
              </span>
            }
            key={key}
          >
            <Table
              columns={columns}
              dataSource={rolePermissions}
              rowKey="id"
              pagination={false}
              bordered
              title={() => (
                <Typography.Title level={4} style={{ marginTop: "0" }}>
                  {key}
                </Typography.Title>
              )}
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};
export default PermissionsList;
