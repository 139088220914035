import { api } from "..";
import { AllDocumentResponse, Document, DocumentRequest } from "../document/types";
import { ApproveDocumentRequest, DocumentRequestResponse, ShareDocumentMutation } from "../documentrequest/types";
import { eSignatureResponse } from "../signaturerequest/types";
import { Workflow, WorkflowRequest } from "../workflow/types";
import {
  PrivilegeCreateRequest,
  PrivilegeDocumentRequestStats,
  PrivilegeDocumentStats,
  PrivilegeType,
  PrivilegeTypeRequest,
  Privileges,
} from "./types";

const privilegeAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllPrivileges: build.query<Privileges[], void>({
      query: () => `privilege/all`,
      providesTags: ["Privilege"],
    }),
    getPrivilege: build.query<Privileges, { privilegeId: string }>({
      query: ({ privilegeId }) => `privilege/${privilegeId}`,
      providesTags: (result, error, { privilegeId }) => [
        { type: "Privilege", id: privilegeId },
      ],
    }),
    getAllPrivilegeTypes: build.query<PrivilegeType[], void>({
      query: () => `privilege/type/all`,
      providesTags: ["Privilege"],
    }),

    getPrivilegeType: build.query<PrivilegeType, { privilegeTypeId: string }>({
      query: ({ privilegeTypeId }) => `privilege/type/${privilegeTypeId}`,
      providesTags: ["Privilege"],
    }),
    createPrivilege: build.mutation<Privileges, PrivilegeCreateRequest>({
      query: ({ ...body }) => {
        return {
          url: `privilege`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Privilege"],
    }),
    updatePrivilege: build.mutation<
      Privileges,
      Pick<Privileges, "id"> & Partial<PrivilegeCreateRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `privilege/${id}`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Privilege", id: arg.id },
        { type: "Privilege" },
      ],
    }),
    deletePrivilege: build.mutation<
      void,
      {
        privilegeId: string;
      }
    >({
      query: ({ privilegeId }) => ({
        url: `privilege/${privilegeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Privilege"],
    }),
    createPrivilegeType: build.mutation<void, PrivilegeTypeRequest>({
      query: ({ ...body }) => {
        return {
          url: "privilege/type",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Privilege"],
    }),
    saveAllPrivilegeTypes: build.mutation<void, PrivilegeType[]>({
      query: (privilegeTypes) => {
        return {
          url: "privilege/type/all",
          method: "POST",
          body: privilegeTypes,
        };
      },
      invalidatesTags: ["Privilege"],
    }),
    deletePrivilegeType: build.mutation<
      void,
      {
        privilegeTypeId: string;
      }
    >({
      query: ({ privilegeTypeId }) => ({
        url: `privilege/type/${privilegeTypeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Privilege"],
    }),
    updatePrivilegeType: build.mutation<
      void,
      Pick<PrivilegeType, "id"> & Partial<PrivilegeTypeRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `privilege/type/${id}`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Privilege", id: arg.id },
        { type: "Privilege" },
      ],
    }),
    uploadPrivilegeAttachment: build.mutation<
      void,
      { privilegeId: string; file: File }
    >({
      query({ privilegeId, file }) {
        // upload with multipart/form-data
        const formData = new FormData();
        if (file) formData.append("file", file);

        return {
          url: `privilege/${privilegeId}/attachment`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Privilege" }],
    }),
    getAllPrivilegeDocumentRequestStats: build.query<PrivilegeDocumentRequestStats, { privilegeId: string }>({
      query: ({ privilegeId }) => `/privilege/${privilegeId}/documentrequest/stats`,
      providesTags: ["Privilege", "DocumentRequest"],
    }),
    getAllPrivilegeDocumentRequest: build.query<DocumentRequestResponse[], { privilegeId: string }>({
      query: ({ privilegeId }) => `/privilege/${privilegeId}/documentrequest/all  `,
      providesTags: ["Privilege", "DocumentRequest"],
    }),
    addPrivilegeDocumentShareRequest: build.mutation<ShareDocumentMutation, { privilegeId: string }>({
      query: ({ privilegeId, ...body }) => {
        return {
          url: `/privilege/${privilegeId}/documentrequest`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Privilege", "DocumentRequest"],
    }),

    updatePrivilegeDocumentShareAndMarkAsDenied: build.mutation<void, { privilegeId: string, documentRequestId: string }>({
      query: ({ privilegeId, documentRequestId }) => {
        return {
          url: `/privilege/${privilegeId}/documentrequest/status/deny/${documentRequestId}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["Privilege", "DocumentRequest"],
    }),
    updatePrivilegeDocumentShareAndMarkAsApprove: build.mutation<void, { privilegeId: string, documentRequestId: string } & Partial<ApproveDocumentRequest>>({
      query: ({ privilegeId, documentRequestId, ...patch }) => {
        return {
          url: `privilege/${privilegeId}/documentrequest/status/approve/${documentRequestId}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: ["Privilege", "DocumentRequest"],
    }),
    resendPrivilegeDocumentShareRequest: build.mutation<void, { privilegeId: string, documentRequestId: string } & Partial<DocumentRequest>>({
      query: ({ privilegeId, documentRequestId, ...patch }) => {
        return {
          url: `privilege/${privilegeId}/documentrequest/resend/${documentRequestId}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: ["Privilege", "DocumentRequest"],
    }),
    getAllPrivilegeSignatureRequest: build.query<eSignatureResponse, { privilegeId: string }>({
      query: ({ privilegeId }) => `/privilege/${privilegeId}/signaturerequest/all`,
      providesTags: ["Privilege"],
    }),
    getAllPrivilegeDocumentStats: build.query<PrivilegeDocumentStats, { privilegeId: string }>({
      query: ({ privilegeId }) => `/privilege/${privilegeId}/completeddocument/stats`,
      providesTags: ["Privilege"],
    }),
    getAllPrivilegeDocument: build.query<AllDocumentResponse[], { privilegeId: string }>({
      query: ({ privilegeId }) => `/privilege/${privilegeId}/completeddocument/all`,
      providesTags: ["Privilege"],
    }),
    addPrivilegeDocuments: build.mutation<
      AllDocumentResponse,
      { privilegeId: string; documents: string[] }
    >({
      query: ({ privilegeId, documents }) => {
        return {
          url: `privilege/${privilegeId}/documents`,
          method: "POST",
          body: documents,
        };
      },
      invalidatesTags: ["Privilege"]
    }),
    deletePrivilegeDocument: build.mutation<
      AllDocumentResponse,
      {
        privilegeId: string, documents: string[]
      }
    >({
      query: ({ privilegeId, documents }) => ({
        url: `privilege/${privilegeId}/documents`,
        method: "DELETE",
        body: documents
      }),
      invalidatesTags: ["Privilege", "Document"],
    }),
    addWorkflowToPrivilege: build.mutation<Workflow, { privilegeId: string }>({
      query: ({ privilegeId, ...patch }) => {
        return {
          url: `/privilege/${privilegeId}/workflow`,
          method: "POST",
          body: { ...patch },
        };
      },
      invalidatesTags: ["Privilege", "Workflow"],
    }),
    getPrivilegeWorkflow: build.query<Workflow, { privilegeId: string }>({
      query: ({ privilegeId }) => `privilege/${privilegeId}/workflow`,
      providesTags: ["Privilege", "Workflow"],
    }),
    updatePrivilegeWorkflow: build.mutation<Workflow, { privilegeId: string, workflowId: string } &
      Partial<WorkflowRequest>>({
        query: ({ privilegeId, workflowId, ...body }) => {
          return {
            url: `/privilege/${privilegeId}/workflow/${workflowId}`,
            method: "PUT",
            body: body
          };
        },
        invalidatesTags: ["Privilege", "Workflow"],
      }),
  }),
});
export const {
  useSaveAllPrivilegeTypesMutation,
  useGetAllPrivilegesQuery,
  useGetPrivilegeQuery,
  useGetAllPrivilegeTypesQuery,
  useGetPrivilegeTypeQuery,
  useCreatePrivilegeMutation,
  useUpdatePrivilegeMutation,
  useDeletePrivilegeMutation,
  useCreatePrivilegeTypeMutation,
  useDeletePrivilegeTypeMutation,
  useUpdatePrivilegeTypeMutation,
  useUploadPrivilegeAttachmentMutation,
  useGetAllPrivilegeDocumentRequestStatsQuery,
  useGetAllPrivilegeDocumentRequestQuery,
  useAddPrivilegeDocumentShareRequestMutation,
  useUpdatePrivilegeDocumentShareAndMarkAsApproveMutation,
  useUpdatePrivilegeDocumentShareAndMarkAsDeniedMutation,
  useResendPrivilegeDocumentShareRequestMutation,
  useGetAllPrivilegeSignatureRequestQuery,
  useGetAllPrivilegeDocumentStatsQuery,
  useAddPrivilegeDocumentsMutation,
  useGetAllPrivilegeDocumentQuery,
  useAddWorkflowToPrivilegeMutation,
  useGetPrivilegeWorkflowQuery,
  useUpdatePrivilegeWorkflowMutation,
  useDeletePrivilegeDocumentMutation
} = privilegeAPI;
