import { useContext, useState } from "react";
import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Link, NavLink, useNavigate } from "react-router-dom";
import useTaskFilter from "../table-filters/task-filters";
import { useLocalStorage } from "../common/localStorage";
import {
  TASKS_CONTENT_DISPLAY_OPTIONS,
  TASKS_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "./table-config";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import { Task } from "../redux/api/task/types";
import IncredableDateFormat from "../common/incredable-date-format";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
import { useDeleteTaskMutation } from "../redux/api/task";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";

export default function TaskList() {
  const [selectedRows, setSelectedRows] = useState<Task[]>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const {
    TableFilters,
    filteredTasks = [],
    UseQueryHookResult: { refetch, isFetching, isLoading },
  } = useTaskFilter();

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("task.edit") ?? false)
  );

  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("task.delete") ?? false)
  );

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Task-Table-Preferences",
    TASKS_TABLE_DEFAULT_PREFERENCES
  );
  const navigate = useNavigate();

  const [deleteTask] = useDeleteTaskMutation();

  const { items, collectionProps, paginationProps } = useCollection(
    filteredTasks,
    {
      pagination: { pageSize: tablePreferences.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    }
  );

  return (
    <div style={{ padding: "32px 32px 0 32px" }}>
      <Table
        loading={isFetching || isLoading}
        onSelectionChange={({ detail }) => {
          const selectedItem = detail?.selectedItems[0];
          setSelectedRows(selectedItem ? [selectedItem] : []);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows?.at(0)?.id === selectedItem.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        columnDefinitions={[
          {
            id: "id",
            header: <div>Task ID</div>,
            cell: (item) => <span>{item.id}</span>,
          },
          {
            id: "taskType",
            header: <div>Task Type</div>,
            cell: (item) => <span>{item?.taskType?.name}</span>,
            sortingField: "taskType.name",
            sortingComparator: (a, b) =>
              (a?.taskType?.name ?? "").localeCompare(b?.taskType?.name ?? ""),
          },
          {
            id: "name",
            header: <div>Name</div>,
            cell: (item) => (
              <NavLink to={hasPermission ? `/tasks/${item?.id}` : "#"}>
                {!!item?.name ? item?.name : "-"}
              </NavLink>
            ),
            sortingField: "name",
            sortingComparator: (a, b) =>
              (a?.name ?? "").localeCompare(b?.name ?? ""),
          },
          {
            id: "admin",
            header: <div>Assigned To</div>,
            cell: (item) => (
              <span>
                {!!item?.admin
                  ? `${item?.admin?.firstName} ${item?.admin?.lastName}`
                  : "-"}
              </span>
            ),
            sortingField: "admin",
            sortingComparator: (a, b) =>
              (a?.admin?.firstName ?? "").localeCompare(
                b?.admin?.firstName ?? ""
              ),
          },
          {
            id: "provider",
            header: <div>Provider</div>,
            cell: (item) => (
              <span>
                {!!item?.provider
                  ? `${item?.provider?.firstName} ${item?.provider?.lastName}`
                  : "-"}
              </span>
            ),
            sortingField: "provider",
            sortingComparator: (a, b) =>
              (a?.provider?.firstName ?? "").localeCompare(
                b?.provider?.firstName ?? ""
              ),
          },
          {
            id: "facility",
            header: <div>Facility</div>,
            cell: (item) => (
              <span>{!!item?.facility ? `${item?.facility?.name}` : "-"}</span>
            ),
            sortingField: "facility.name",
            sortingComparator: (a, b) =>
              (a?.facility?.name ?? "").localeCompare(b?.facility?.name ?? ""),
          },
          {
            id: "taskStatusId",
            header: <div>Task Status</div>,
            cell: (item) => <span>{item?.taskStatus?.name}</span>,
            sortingField: "taskStatus.name",
            sortingComparator: (a, b) =>
              (a?.taskStatus?.name ?? "").localeCompare(
                b?.taskStatus?.name ?? ""
              ),
          },
          {
            id: "workflow",
            header: <div>Workflow</div>,
            cell: (item) => (
              <span>
                {!!item?.workflow?.name ? (
                  <Link
                    // external
                    to={`/workflows/${item?.workflow?.id}?activeTabId=tasks`}
                  >
                    {item?.workflow?.name}
                  </Link>
                ) : (
                  "-"
                )}
              </span>
            ),
            sortingField: "workflow.name",
            sortingComparator: (a, b) =>
              (a?.workflow?.name ?? "").localeCompare(b?.workflow?.name ?? ""),
          },
          {
            id: "startDate",
            header: <div>Start Date</div>,
            cell: (item) => (
              <span>{IncredableDateFormat(item?.startDate) ?? "-"}</span>
            ),
            sortingField: "startDate",
          },
          {
            id: "completedDate",
            header: <div>Expiration Date</div>,
            cell: (item) => (
              <span>{IncredableDateFormat(item?.completedDate) ?? "-"}</span>
            ),
            sortingField: "completedDate",
          },
          {
            id: "dueDate",
            header: <div>Due Date</div>,
            cell: (item) => (
              <span>{IncredableDateFormat(item?.dueDate) ?? "-"}</span>
            ),
            sortingField: "dueDate",
          },
          {
            id: "alertDays",
            header: <div>Alert Days</div>,
            cell: (item) => <span>{item?.alertDays ?? "-"}</span>,
          },
          {
            id: "description",
            header: <div>Description</div>,
            cell: (item) => <span>{item?.description ?? "-"}</span>,
          },
          {
            id: "createdDate",
            header: <div>Created Date</div>,
            cell: (item) => (
              <span>{IncredableDateFormat(item?.createdDate)}</span>
            ),
          },
        ]}
        items={items}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={TASKS_CONTENT_DISPLAY_OPTIONS}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        filter={TableFilters}
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <Space size="middle">
                  <Button
                    onClick={() => {
                      !!refetch && refetch();
                    }}
                  >
                    <ReloadOutlined />
                  </Button>
                  <Dropdown
                    menu={{
                      disabled: selectedRows?.length !== 1,
                      items: [
                        {
                          label: hasPermission ? "Edit" : "View",
                          key: "edit",
                          icon: <EditOutlined />,
                          onClick: () => {
                            navigate(`${selectedRows?.at(0)?.id}`);
                          },
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          onClick: () => setIsDeleteModalOpen(true),
                          disabled: !hasDeletePermission,
                          icon: <DeleteOutlined />,
                          danger: true,
                        },
                      ],
                    }}
                  >
                    <Button>
                      Actions
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#2B6BE6",
                      },
                    }}
                  >
                    <Button
                      disabled={!hasPermission}
                      onClick={() => navigate(`add`)}
                      type="primary"
                      icon={<PlusOutlined />}
                    >
                      Add Task
                    </Button>
                  </ConfigProvider>
                </Space>
              }
            >
              <Typography.Title level={3} style={{ marginTop: "0" }}>
                Tasks{" "}
                <Typography.Text type="secondary" style={{ fontSize: "16px" }}>
                  {`(${filteredTasks.length})`}
                </Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedRows?.at(0)?.id) {
            setIsDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            try {
              await deleteTask({
                id: String(selectedRows?.at(0)?.id),
              }).unwrap();
              toastContext?.openSuccessNotification({
                message: `Task deleted successfully`,
                placement: "topRight",
              });
            }
            catch (error: any) {
              toastContext?.openErrorNotification({
                message: `Failed to delete Task`,
                placement: "topRight"
              })
            }
            spinnerContext?.hidespinner();
            setSelectedRows([]);
          }
        }
        }
        closeModal={() => setIsDeleteModalOpen(false)}
        header="Delete Task"
        content={
          <Typography.Text>
            Task Name:{" "}
            <Typography.Text>{selectedRows?.at(0)?.name}</Typography.Text>
          </Typography.Text>
        }
        description="Are you sure you want to delete this task?"
      />
    </div>
  );
}
