import { useEffect, useState } from "react";
import { DIGITALFORM_FIELDS_Type } from "./digitalform-tags-list";
import {
  PROVIDER_DIGITALFORM_FIELDS,
  STANDARD_DIGITALFORM_FIELDS,
} from "./constants";
import { Images } from "../../common/images";
import { useGetAllSectionQuery } from "../../redux/api/section/section";
import { Attribute } from "../../redux/api/section/types";

export type ReadyFormTagsFilterType = {
  filterText: string;
  section_type: string;
};

export default function useReadyFormTagsFilter() {
  const [filter, setFilter] = useState<ReadyFormTagsFilterType>({
    filterText: "",
    section_type: "all",
  });

  const [filteredFields, setFilteredFields] = useState<
    DIGITALFORM_FIELDS_Type[]
  >([]);

  const { data: sectionData, isLoading, error } = useGetAllSectionQuery();

  useEffect(() => {
    const sectionFields = Array.isArray(sectionData)
      ? sectionData.map((section) => ({
          label: section?.name,
          value: section?.id,
          type: "list",
          icon: (
            <img
              className="provider-menu"
              src={Images.workHistory}
              alt="Work History"
            />
          ),
          properties: section?.attributes?.map((attribute: Attribute) => ({
            label: attribute?.name,
            value: attribute?.id + "",
            key: attribute?.id + "",
            type: (["string", "date", "bool", "signature"].includes(
              attribute?.type
            )
              ? attribute.type
              : "string") as "string" | "date" | "bool" | "signature",
          })),
        }))
      : [];

    let temp_filtered_fields: DIGITALFORM_FIELDS_Type[] = [
      ...STANDARD_DIGITALFORM_FIELDS,
      ...PROVIDER_DIGITALFORM_FIELDS,
      ...sectionFields,
    ];

    if (filter.section_type === "provider") {
      temp_filtered_fields = [...PROVIDER_DIGITALFORM_FIELDS];
    }
    if (filter.section_type === "standard") {
      temp_filtered_fields = [...STANDARD_DIGITALFORM_FIELDS];
    }
    if (filter.section_type === "section" && sectionFields.length > 0) {
      temp_filtered_fields = [...sectionFields];
    }
    if (filter.filterText) {
      temp_filtered_fields = temp_filtered_fields
        .map((field) => ({
          ...field,
          properties: field?.properties?.filter(
            (property) =>
              property?.label
                ?.toLowerCase()
                ?.includes(filter.filterText?.toLowerCase()) ||
              property?.key
                ?.toLowerCase()
                ?.includes(filter.filterText?.toLowerCase())
          ),
        }))
        .filter((field) => field?.properties?.length > 0);
    }

    setFilteredFields(temp_filtered_fields);
  }, [filter, sectionData]);

  return { filteredFields, filter, setFilter, isLoading, error };
}
