import ReactGA from "react-ga4";

// Initialize Google Analytics
export const initGA = (): void => {
  ReactGA.initialize("G-L8C8ED0D64"); // Replace with your Measurement ID
};

// Log page view
export const logPageView = (pageName: string): void => {
  ReactGA.send({ hitType: "pageview", page: pageName });
};

// Log custom events
export const logEvent = (
  category: string,
  action: string,
  label?: string
): void => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
