import { PlusOutlined } from "@ant-design/icons";
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Modal,
  Table,
  Pagination,
} from "@cloudscape-design/components";
import { Space, ConfigProvider, Button } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import { useCollection } from "@cloudscape-design/collection-hooks";
import useSafeFilter from "../../table-filters/safe-filters";
import { AllDocumentProviderRecordResponse } from "./add-edit-provider-profile-section";
import { AllDocumentResponse } from "../../redux/api/document/types";

export default function ProviderProfileSectionDocumentModal({
  providerId,
  isDocumentModalOpen,
  setIsDocumentModalOpen,
  setActiveKey,
  selectedDocument,
  setSelectedDocument,
  handleExistingDocumentSubmit,
}: {
  providerId: string | undefined;
  isDocumentModalOpen: boolean;
  setIsDocumentModalOpen: Dispatch<SetStateAction<boolean>>;
  setActiveKey: Dispatch<SetStateAction<string>>;
  selectedDocument: AllDocumentProviderRecordResponse[];
  setSelectedDocument: Dispatch<
    SetStateAction<AllDocumentProviderRecordResponse[]>
  >;
  handleExistingDocumentSubmit: (type?: string) => void;
}) {
  const {
    TableFilters,
    filteredDocuments = [],
    documentsTotalRecords,
    UseQueryHookResult: {
      setPageNumber,
      pageNumber,
      pageSize,
      setPageSize,
      isFetching: documentFetching,
      isLoading: documentLoding,
    },
  } = useSafeFilter({
    selectedProviderId: providerId ? providerId : "",
    isModal: true,
  });
  const [preferences, setPreferences] = useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: pageSize,
    wrapLines: true,
  });

  const transformedDocuments: AllDocumentProviderRecordResponse[] =
    filteredDocuments.map((doc: AllDocumentResponse) => ({
      id: Number(doc.id) || Date.now(),
      hgId: "",
      name: doc.name || "Unnamed Document",
      expirationDate: doc.expirationDate || new Date().toISOString(),
      createdDate: doc.createdDate || new Date().toISOString(),
      alertDays: doc.alertDays || 0,
      notes: doc.notes || "",
      status: doc.status || "draft",
      attachment: {
        id: String(doc.attachment?.id) || "",
        name: doc.attachment?.name || "",
        size: doc.attachment?.size || 0,
        contentType: doc.attachment?.contentType || "",
        contentMD5Hash: doc.attachment?.contentMD5Hash || "",
        key: doc.attachment?.key || "",
      },
      category: {
        id: Number(doc.category?.id) || 0,
        name: doc.category?.name || "Uncategorized",
        description: doc.category?.description || "",
        providerProfileSection: {
          id: Number(doc.category?.categoryGroup?.id) || 0,
          name: doc.category?.categoryGroup?.name || "",
          orgId: "",
          deleted: false,
        },
        default: false,
        stateRequired: doc.category?.isStateRequired || false,
        expirationRequired: doc.category?.isExpirationRequired || false,
      },
    }));

  const { items, collectionProps, paginationProps } = useCollection(
    transformedDocuments,
    {
      pagination: { pageSize },
    }
  );

  return (
    <Modal
      visible={isDocumentModalOpen}
      onDismiss={() => setIsDocumentModalOpen(false)}
      closeAriaLabel="false"
      header={
        <Header counter={`(${transformedDocuments?.length})`}>Documents</Header>
      }
      footer={
        <Space style={{ width: "100%", justifyContent: "space-between" }}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#2B6BE6",
              },
            }}
          >
            <Button
              type="primary"
              onClick={() => handleExistingDocumentSubmit()}
              icon={<PlusOutlined />}
            >
              Create New Document
            </Button>
          </ConfigProvider>
          <Space>
            <Button
              onClick={() => {
                setIsDocumentModalOpen(false);
                setActiveKey("defaultKey");
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => handleExistingDocumentSubmit("linkDocument")}
              disabled={selectedDocument?.length < 1}
            >
              Link
            </Button>
          </Space>
        </Space>
      }
      size="large"
    >
      <div>
        <Table
          items={items}
          {...collectionProps}
          pagination={
            <Pagination
              {...paginationProps}
              currentPageIndex={pageNumber + 1}
              onChange={({ detail }) => {
                setPageNumber(detail.currentPageIndex - 1);
              }}
              pagesCount={
                pageSize && pageSize !== 0
                  ? Math.ceil(documentsTotalRecords / pageSize)
                  : 1
              }
            />
          }
          preferences={
            <CollectionPreferences
              onConfirm={({ detail }) => {
                if (detail) {
                  setPreferences(detail);
                  if (detail.pageSize) {
                    setPageSize(detail.pageSize);
                  }
                }
              }}
              preferences={preferences}
              pageSizePreference={{
                options: [
                  { value: 10, label: "10 items" },
                  { value: 30, label: "30 items" },
                  { value: 50, label: "50 items" },
                ],
              }}
            />
          }
          filter={TableFilters}
          variant="borderless"
          loading={documentLoding || documentFetching}
          loadingText="Loading..."
          selectionType="multi"
          resizableColumns={false}
          stickyHeader={true}
          onSelectionChange={({ detail }) => {
            setSelectedDocument([...detail.selectedItems]);
          }}
          onRowClick={(onRowClickDetails) => {
            const selectedItem = onRowClickDetails?.detail?.item;
            if (selectedItem) {
              const temp = selectedDocument ? [...selectedDocument] : [];
              const selectedItemIndex = temp.findIndex(
                (record) => record?.id + "" === "" + selectedItem?.id
              );
              if (selectedItemIndex !== -1) {
                temp?.splice(selectedItemIndex, 1);
                setSelectedDocument(temp);
              } else {
                setSelectedDocument([...temp, selectedItem]);
              }
            }
          }}
          selectedItems={selectedDocument}
          trackBy="id"
          columnDisplay={[
            { id: "id", visible: false },
            { id: "name", visible: true },
            { id: "category", visible: true },
            { id: "facility", visible: true },
          ]}
          columnDefinitions={[
            {
              id: "id",
              header: <div>ID</div>,
              cell: (item) => <span>{item?.id}</span>,
            },
            {
              id: "name",
              header: <div>Document Name</div>,
              cell: (item) => <span>{item?.name ?? "-"}</span>,
            },
            {
              id: "category",
              header: <div>Category</div>,
              cell: (item) => <span>{item?.category?.name ?? "-"}</span>,
            },
          ]}
        />
      </div>
    </Modal>
  );
}
