import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Pagination,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { Button, Modal, Space } from "antd";
import { Facility } from "../../../redux/api/facility/types";
import { useAddFacilitesToFacilityGroupMutation } from "../../../redux/api/facilitygroup/facilityGroup";
import useFacilityFilter from "../../../table-filters/facility-filters";

export default function AssociatedFacilityModals({
  isAssociatedFacilitiesModalOpen,
  setAssociatedFacilitiesModalOpen,
  associatedFacilities,
  setAssociatedFacilities,
}: {
  isAssociatedFacilitiesModalOpen: boolean;
  setAssociatedFacilitiesModalOpen: Dispatch<SetStateAction<boolean>>;
  associatedFacilities: Facility[];
  setAssociatedFacilities: Dispatch<SetStateAction<Facility[]>>;
}) {
  const { facilitygroupId } = useParams();
  const [addFacilitesToFacilityGroup] =
    useAddFacilitesToFacilityGroupMutation();
  const { TableFilters, filteredFacilities, UseQueryHookResult } =
    useFacilityFilter();
  const [preferences, setPreferences] = useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: 10,
    wrapLines: true,
  });

  const { items, collectionProps, paginationProps } = useCollection(
    filteredFacilities,
    {
      pagination: {},
    }
  );

  const [selectedFacility, setSelectedFacility] = useState<Facility[]>([]);

  const tableProps: TableProps<Facility> = useMemo(() => {
    return {
      items,
      ...collectionProps,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <CollectionPreferences
          onConfirm={({ detail }) => {
            if (!!detail) setPreferences(detail);
          }}
          preferences={preferences}
          pageSizePreference={{
            options: [
              { value: 10, label: "10 items" },
              { value: 30, label: "30 items" },
              { value: 50, label: "50 items" },
            ],
          }}
        />
      ),
      filter: TableFilters,
      variant: "borderless",
      loading: UseQueryHookResult?.isLoading || UseQueryHookResult?.isFetching,
      loadingText: "Loading...",
      selectionType: "multi",
      resizableColumns: false,
      stickyHeader: true,
      onSelectionChange: (selectionChangeDetail) => {
        setSelectedFacility(selectionChangeDetail.detail.selectedItems);
      },
      onRowClick: ({ detail }) => {
        const temp = selectedFacility;
        if (
          !detail.item ||
          !!associatedFacilities
            ?.map((associatedFacilities) => associatedFacilities?.id + "")
            ?.includes(detail.item.id + "")
        )
          return;
        if (temp?.includes(detail.item))
          setSelectedFacility(temp.filter((item) => item !== detail.item));
        else setSelectedFacility([detail.item, ...temp]);
      },
      selectedItems: selectedFacility,
      trackBy: (item) => "" + item.id,
      columnDisplay: [
        { id: "id", visible: true },
        { id: "name", visible: true },
        { id: "description", visible: true },
        { id: "npiNumber", visible: true },
        { id: "facilityType", visible: true },
        { id: "taxId", visible: true },
        { id: "cliaId", visible: true },
        { id: "immunizationId", visible: true },
        { id: "taxonomyCode", visible: true },
        { id: "addressLane1", visible: true },
        { id: "addressLane2", visible: true },
        { id: "city", visible: true },
        { id: "state", visible: true },
        { id: "zipcode", visible: true },
        { id: "country", visible: true },
      ],
      isItemDisabled: (item) => {
        return associatedFacilities
          ?.map((associatedFacility) => associatedFacility?.id + "")
          ?.includes(item?.id + "");
      },
      columnDefinitions: [
        {
          id: "id",
          header: <div>ID</div>,
          cell: (item) => <span>{item.id}</span>,
          visibility: false,
        },
        {
          id: "name",
          header: <div>Name</div>,
          cell: (item) => <span>{item?.name}</span>,
          sortingField: "name",
        },
        {
          id: "description",
          header: <div>Description</div>,
          cell: (item) => <div>{item?.description ?? "-"}</div>,
        },
        {
          id: "npiNumber",
          header: <div>NPI Number</div>,
          cell: (item) => <div>{item?.npiNumber ?? "-"}</div>,
        },
        {
          id: "facilityType",
          header: <div>Facility Type</div>,
          cell: (item) => <div>{item?.facilityType?.name ?? "-"}</div>,
        },
        {
          id: "taxId",
          header: <div>Tax ID</div>,
          cell: (item) => <div>{item?.taxId ?? "-"}</div>,
        },
        {
          id: "cliaId",
          header: <div>CLIA ID</div>,
          cell: (item) => <div>{item?.cliaId ?? "-"}</div>,
        },
        {
          id: "immunizationId",
          header: <div>Immunization ID</div>,
          cell: (item) => <div>{item?.immunizationId ?? "-"}</div>,
        },
        {
          id: "taxonomyCode",
          header: <div>Taxonomy Code</div>,
          cell: (item) => <div>{item?.taxonomyCode ?? "-"}</div>,
        },
        {
          id: "addressLane1",
          header: <div>Address Line 1</div>,
          cell: (item) => (
            <div>{item?.facilityAddress?.addressLane1 ?? "-"}</div>
          ),
        },
        {
          id: "addressLane2",
          header: <div>Address Line 2</div>,
          cell: (item) => (
            <div>{item?.facilityAddress?.addressLane2 ?? "-"}</div>
          ),
        },
        {
          id: "city",
          header: <div>City</div>,
          cell: (item) => <div>{item?.facilityAddress?.city ?? "-"}</div>,
        },
        {
          id: "state",
          header: <div>State</div>,
          cell: (item) => <div>{item?.facilityAddress?.state ?? "-"}</div>,
        },
        {
          id: "zipcode",
          header: <div>Zipcode</div>,
          cell: (item) => <div>{item?.facilityAddress?.zipcode ?? "-"}</div>,
        },
        {
          id: "country",
          header: <div>Country</div>,
          cell: (item) => <div>{item?.facilityAddress?.country ?? "-"}</div>,
        },
      ],
    };
  }, [items, selectedFacility]);
  return (
    <Modal
      open={isAssociatedFacilitiesModalOpen}
      title={
        <Header counter={`(${filteredFacilities?.length})`}>Facilities</Header>
      }
      onCancel={() => {
        setAssociatedFacilitiesModalOpen(false);
      }}
      footer={
        <Space direction="horizontal">
          <Button
            onClick={() => {
              setAssociatedFacilities([]);
              setAssociatedFacilitiesModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={async () => {
              try {
                if (!!selectedFacility && !!facilitygroupId) {
                  const facilityIdGroups =
                    selectedFacility?.map(
                      (selectedFacilityItem) => selectedFacilityItem?.id + ""
                    ) || [];
                  await addFacilitesToFacilityGroup({
                    facilityGroupId: facilitygroupId,
                    facilityIds: facilityIdGroups,
                  });

                  setAssociatedFacilities([
                    ...associatedFacilities,
                    ...selectedFacility,
                  ]);
                  setSelectedFacility([]);
                  setAssociatedFacilitiesModalOpen(false);
                }
              } catch (error) {
                console.error("Error updating admin facilityGroups:", error);
              }
            }}
          >
            Submit
          </Button>
        </Space>
      }
      width={800}
      style={{ maxHeight: "90vh" }}
      styles={{
        body: { overflowY: "auto", maxHeight: "80vh" },
      }}
    >
      <div>
        <Table {...tableProps} />
      </div>
    </Modal>
  );
}
