import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { logEvent, logPageView } from "../utils/ga-4";

const usePageTracking = () => {
  const location = useLocation();
  const startTimeRef = useRef<number>(Date.now());

  useEffect(() => {
    // Only track on production domain (readydoc.app)
    if (window.location.hostname === "readydoc.app") {
      // Log page view
      logPageView(location.pathname);

      // Start time for current screen
      startTimeRef.current = Date.now();

      // Cleanup function to log time spent when navigating away
      return () => {
        const endTime = Date.now();
        const timeSpent = (endTime - startTimeRef.current) / 1000; // Convert to seconds
        logEvent(
          "Screen Time",
          "Time Spent",
          `${location.pathname}: ${timeSpent} seconds`
        );
      };
    }
  }, [location.pathname]);
};

export default usePageTracking;
