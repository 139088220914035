import { RecipientTypeOptions } from "../../../requests/signature-requests/add-eSignature/recipient";
import { Category } from "../category/types";
import { CredentialPackage } from "../credentialpackages/types";
import { DigitalForm } from "../digitalform/types";
import { Attachment, Document } from "../document/types";
import { MemberType } from "../manageadmin/types";
import { ProviderMetaData } from "../provider/types";

export type PaginationSignatureRequest = {
  totalRecords: number;
  signatureRequests: eSignatureResponse[];
};

export interface SignatureRequestType {
  id: string;
  primaryRecipient: RecipientType;
  addlRecipients: RecipientType[];
  ccRecipientEmailList: string[];
  subject?: string;
  message?: string;
  categoryId?: string;
  privilegeId?: string;
  alertDays?: string;
  expirationDate?: Date | string;
}

export interface eSignatureResponse {
  id: string;
  ccRecipientEmailList: string[];
  subject: string;
  message: string;
  admin: {
    email: string;
    firstName: string;
    lastName: string;
    id: string;
    userId: string;
  };
  recipients: RecipientResponse[];
  recipientGroupByStatus: {
    pending: RecipientResponse[];
    current: RecipientResponse;
    completed: RecipientResponse[];
  };
  category: Category;
  expirationDate?: Date | string;
  alertDays: string;
  document: Document;
  attachment: Attachment;
  digitalForm: DigitalForm;
  credentialPackage: CredentialPackage;
  createdDate: Date;
  completedDate: Date;
  status: string;
}
export interface RecipientResponse {
  id: string;
  provider?: ProviderMetaData;
  admin?: MemberType;
  emailAddress?: string;
  xfdfString?: string;
  action: string;
  primary: boolean;
  status: string;
  completedDate: Date;
  recipientOrder: number;
}

export interface RecipientType {
  id: string;
  type?: string;
  provider?: ProviderMetaData;
  member?: MemberType;
  emailAddress?: string;
  xfdfString?: string;
  action: string;
  backgroundColor?: BackgroundColorType;
}

export function getRecipientTypeOption(recipient?: RecipientType) {
  return (
    RecipientTypeOptions.find((option) => option.value === recipient?.type) ??
    RecipientTypeOptions[0]
  );
}

export interface BackgroundColorType {
  r: number;
  g: number;
  b: number;
  a: number;
}
