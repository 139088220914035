import { useContext, useState } from "react";
import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../common/localStorage";
import {
  READYFORM_CONTENT_DISPLAY_OPTIONS,
  READYFORM_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "./table-config";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import IncredableDateFormat from "../common/incredable-date-format";
import useDigitalFormFilter from "../table-filters/digitalform-filterts";
import { DigitalForm } from "../redux/api/digitalform/types";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
import { useDeleteDigitalFormMutation } from "../redux/api/digitalform/digitalforms";
import IncredableButton from "../components/button";

export default function ReadyFormsList() {
  const [selectedRows, setSelectedRows] = useState<DigitalForm[]>();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const {
    TableFilters,
    filteredReadyForms = [],
    UseQueryHookResult: { refetch, isFetching, isLoading },
  } = useDigitalFormFilter();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("readyforms.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("readyforms.delete") ?? false)
  );

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Template-Table-Preferences",
    READYFORM_TABLE_DEFAULT_PREFERENCES
  );
  const navigate = useNavigate();

  const { items, collectionProps, paginationProps } = useCollection(
    filteredReadyForms,
    {
      pagination: { pageSize: tablePreferences.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    }
  );

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const [deleteDigitalForm] = useDeleteDigitalFormMutation();

  return (
    <div style={{ padding: "32px 32px 0 32px" }}>
      <Table
        loading={isFetching || isLoading}
        onSelectionChange={({ detail }) => {
          const selectedItem = detail?.selectedItems[0];
          setSelectedRows(selectedItem ? [selectedItem] : []);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows?.at(0)?.id === selectedItem.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        columnDefinitions={[
          {
            id: "id",
            header: <div>ID</div>,
            cell: (item) => <span>{item.id}</span>,
          },
          {
            id: "name",
            header: <div>Name</div>,
            cell: (item) => (
              <NavLink to={`${item.id}`}>
                {!!item?.name ? item?.name : "-"}
              </NavLink>
            ),
            sortingField: "name",
            sortingComparator: (a, b) =>
              (a?.name ?? "").localeCompare(b?.name ?? ""),
          },
          {
            id: "digitalFormType",
            header: <div>Type</div>,
            cell: (item) => <span>{item?.digitalFormType?.type ?? "-"}</span>,
            sortingField: "digitalFormType.type",
            sortingComparator: (a, b) =>
              (a?.digitalFormType?.type ?? "").localeCompare(
                b?.digitalFormType?.type ?? ""
              ),
          },
          {
            id: "template",
            header: <div>Attachment File Name</div>,
            cell: (item) => <span>{item?.template?.name ?? "-"}</span>,
          },
          {
            id: "description",
            header: <div>Description</div>,
            cell: (item) => <span>{item?.description ?? "-"}</span>,
          },
          {
            id: "dateCreated",
            header: <div>Created Date</div>,
            cell: (item) => (
              <span>{IncredableDateFormat(item?.createdDate)}</span>
            ),
            sortingField: "createdDate",
          },
        ]}
        items={items}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={READYFORM_CONTENT_DISPLAY_OPTIONS}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        filter={TableFilters}
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <Space size="middle">
                  <Button
                    onClick={() => {
                      !!refetch && refetch();
                    }}
                  >
                    <ReloadOutlined />
                  </Button>
                  <Dropdown
                    menu={{
                      disabled: selectedRows?.length !== 1,
                      items: [
                        {
                          label: hasPermission ? "Edit" : "View",
                          key: "edit",
                          disabled: selectedRows?.length !== 1,
                          icon: <EditOutlined />,
                          onClick: () => {
                            navigate(`${selectedRows?.at(0)?.id}`);
                          },
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          disabled: !hasDeletePermission,
                          icon: <DeleteOutlined />,
                          onClick: () => {
                            handleOpenDeleteModal();
                          },
                          danger: true,
                        },
                      ],
                    }}
                  >
                    <Button>
                      Actions
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#2B6BE6",
                      },
                    }}
                  >
                    <Space size="middle">
                      <IncredableButton
                        disabled={selectedRows?.length !== 1}
                        onClick={() => {
                          !!selectedRows?.at(0)?.id &&
                            navigate(`${selectedRows?.at(0)?.id}/editor`);
                        }}
                        size="middle"
                        iconPosition={"end"}
                      >
                        Template Editor
                      </IncredableButton>
                      <Button
                        disabled={selectedRows?.length !== 1}
                        onClick={() => {
                          !!selectedRows?.at(0)?.id &&
                            navigate(
                              `${selectedRows?.at(0)?.id}/requestsignature`
                            );
                        }}
                        size="middle"
                        iconPosition={"end"}
                      >
                        Request Signature
                      </Button>
                      <Button
                        disabled={!hasPermission}
                        onClick={() => navigate("add")}
                        type="primary"
                        icon={<PlusOutlined />}
                        size="middle"
                      >
                        Create Ready Form
                      </Button>
                    </Space>
                  </ConfigProvider>
                </Space>
              }
            >
              <Typography.Title level={3} style={{ marginTop: "0" }}>
                Ready Forms{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${filteredReadyForms.length})`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedRows?.at(0)?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            try {
              await deleteDigitalForm({
                id: String(selectedRows?.at(0)?.id),
              }).unwrap();
              toastContext?.openSuccessNotification({
                message: `Ready Form deleted successfully`,
                placement: "topRight",
              });
            } catch (error: any) {
              toastContext?.openErrorNotification({
                message: error?.data?.errorMessage || "Failed to delete ready form",
                placement: "topRight",
              });
            }
            spinnerContext?.hidespinner();
            setSelectedRows([]);
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete Digital Form"
        content={
          <Typography.Text>
            Digital Form:{" "}
            <Typography.Text>{selectedRows?.at(0)?.name}</Typography.Text>
          </Typography.Text>
        }
        description="Are you sure you want to delete this digital from?"
      />
    </div>
  );
}
