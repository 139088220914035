import {
  Box,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Divider, Spin } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import IncredableDateFormat from "../../../common/incredable-date-format";
import { useGetProviderProfileActivitiesQuery } from "../../../redux/api/section/section";
import { skipToken } from "@reduxjs/toolkit/query";

export default function ProviderAuditLog({
  recordId,
}: {
  recordId: string | undefined;
}) {
  const { data: providerProfileHistory, isLoading } =
    useGetProviderProfileActivitiesQuery(!!recordId ? { recordId } : skipToken);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: `calc(100vh - 275px)`,
          marginBottom: "74px",
        }}
      >
        <Spin tip="Loading..." />
      </div>
    );
  }

  return (
    <div
      style={{
        height: `calc(100vh - 275px)`,
        marginBottom: "74px",
        overflowY: "auto",
      }}
    >
      <Container header={<Header>Audit Logs</Header>}>
        <SpaceBetween size="m">
          {!providerProfileHistory?.user &&
            !!providerProfileHistory?.activities &&
            providerProfileHistory?.activities?.length === 0 &&
            "No Audit logs Found"}
          {!!providerProfileHistory?.user && (
            <div>
              <Divider
                dashed
                style={{
                  borderWidth: "1px 0 0",
                  margin: "4px 0",
                }}
              />
              <Box variant="p">
                Created by{" "}
                <b>
                  {providerProfileHistory?.user?.firstName}{" "}
                  {providerProfileHistory?.user?.lastName}
                </b>{" "}
                on{" "}
                <b>
                  {!!providerProfileHistory?.createdDate
                    ? new Date(
                        providerProfileHistory?.createdDate
                      ).toLocaleString()
                    : "-"}
                </b>
              </Box>
              <Divider
                dashed
                style={{
                  borderWidth: "1px 0 0",
                  margin: "4px 0",
                }}
              />
              {!!providerProfileHistory?.user?.preferredName && (
                <Box variant="p">
                  Preferred Name:{" "}
                  <i>{providerProfileHistory?.user?.preferredName} </i>
                </Box>
              )}

              {!!providerProfileHistory?.user?.role && (
                <Box variant="p">
                  Role: <i>{providerProfileHistory?.user?.role ?? "-"} </i>
                </Box>
              )}
              <Divider
                dashed
                style={{
                  borderWidth: "1px 0 0",
                  margin: "12px 0",
                }}
              />
            </div>
          )}
          {!!providerProfileHistory &&
            !!providerProfileHistory?.activities &&
            !!providerProfileHistory?.activities?.length &&
            providerProfileHistory?.activities
              .slice()
              .sort(
                (a, b) =>
                  new Date(a.createdDate).getTime() -
                  new Date(b.createdDate).getTime()
              )
              .map((documentActivity) => {
                return (
                  <div>
                    <Divider
                      dashed
                      style={{
                        borderWidth: "1px 0 0",
                        margin: "4px 0",
                      }}
                    />
                    <Box variant="p">
                      Modified by{" "}
                      <b>
                        {documentActivity?.user?.firstName}{" "}
                        {documentActivity?.user?.lastName}
                      </b>{" "}
                      on{" "}
                      <b>
                        {!!documentActivity?.createdDate
                          ? new Date(
                              documentActivity?.createdDate
                            ).toLocaleString()
                          : "-"}
                      </b>
                    </Box>
                    <Divider
                      dashed
                      style={{
                        borderWidth: "1px 0 0",
                        margin: "4px 0",
                      }}
                    />
                    {!!documentActivity?.activity && (
                      <Box variant="p">
                        {Object.entries(documentActivity.activity).map(
                          ([key, { oldValue, newValue }]) => {
                            return (
                              <div key={key}>
                                {key}:{" "}
                                <i>
                                  {key.toLowerCase().includes("date")
                                    ? IncredableDateFormat(oldValue)
                                    : oldValue == "false" || oldValue == "true"
                                    ? oldValue == "true"
                                      ? "Yes"
                                      : "No"
                                    : oldValue || "None"}
                                  <ArrowRightOutlined
                                    style={{
                                      fontSize: "12px",
                                      margin: "0 4px",
                                    }}
                                  />
                                  {key.toLowerCase().includes("date")
                                    ? IncredableDateFormat(newValue)
                                    : newValue == "false" || newValue == "true"
                                    ? newValue == "true"
                                      ? "Yes"
                                      : "No"
                                    : newValue || "None"}
                                </i>
                              </div>
                            );
                          }
                        )}
                      </Box>
                    )}
                    <Divider
                      dashed
                      style={{
                        borderWidth: "1px 0 0",
                        margin: "12px 0",
                      }}
                    />
                  </div>
                );
              })}
        </SpaceBetween>
      </Container>
    </div>
  );
}
