import React from "react";
import {
  Card,
  Form,
  Select,
  InputNumber,
  Button,
  Space,
  Typography,
  Tooltip,
  message,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { SpaceBetween } from "@cloudscape-design/components";

const { Option } = Select;
const { Text } = Typography;

const DocumentAlertScreen = () => {
  const [form] = Form.useForm();
  const deaExpirationDate = "2025-03-15"; // Example DEA expiration date

  const handleFinish = (values: any) => {
    message.success("Your alert rules have been saved!");
    console.log("Saved Alert Rules:", values);
  };

  return (
    <Card
      bordered={false}
      title={
        <Text code style={{ marginTop: 0, marginBottom: 0, fontWeight: "500" }}>
          DEA Expiration Date: {deaExpirationDate}. Reminders are based on this
          date.
        </Text>
      }
    >
      <Form form={form} onFinish={handleFinish} layout="horizontal">
        <Form.List name="alerts">
          {(fields, { add, remove }) => (
            <>
              <SpaceBetween size={"m"}>
                {fields.map(({ key, name, ...restField }) => (
                  <Space size="small">
                    <span>Remind</span>
                    <Space.Compact>
                      <Form.Item
                        {...restField}
                        noStyle
                        name={[name, "time"]}
                        rules={[
                          {
                            required: true,
                            message: "Specify how many units before expiration",
                          },
                        ]}
                        label="Remind"
                      >
                        <InputNumber
                          placeholder="e.g. 60"
                          defaultValue={60}
                          min={1}
                          style={{ width: "40%" }}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "unit"]}
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: "Choose a time unit",
                          },
                        ]}
                      >
                        <Select
                          placeholder="e.g. Days, Weeks, Months"
                          style={{ width: "60%" }}
                          defaultValue={"days"}
                        >
                          <Option value="days">Days</Option>
                          <Option value="weeks">Weeks</Option>
                          <Option value="months">Months</Option>
                        </Select>
                      </Form.Item>
                    </Space.Compact>
                    <span>before expiration date.</span>
                    <Tooltip title="Remove reminder">
                      <MinusCircleOutlined
                        onClick={() => remove(name)}
                        style={{ fontSize: 16, color: "gray" }}
                      />
                    </Tooltip>
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    style={{ borderColor: "#521262", color: "#521262" }}
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Reminder
                  </Button>
                </Form.Item>
              </SpaceBetween>
            </>
          )}
        </Form.List>
      </Form>
    </Card>
  );
};

export default DocumentAlertScreen;
