import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space, Tag } from "antd";
import { useEffect, useMemo, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetAllCMECredentialTypeQuery, useGetProviderQuery } from "../redux/api/provider/provider";

export default function useCMEFilter({ providerId }: { providerId?: string }) {
    const [selectedFilterkey, setSelectedFilterKey] = useState<string>("credentialType");
    const [selectedFiltervalue, setSelectedFiltervalue] = useState<
        string | undefined
    >(undefined);
    const { data: cmeCredentialType = [] } = useGetAllCMECredentialTypeQuery();
    const {
        data: provider,
        isLoading,
        isFetching,
        refetch,
    } = useGetProviderQuery(
        !!providerId
            ? {
                providerId: providerId,
            }
            : skipToken
    );


    const filteredCME = useMemo(() => {
        if (!!selectedFiltervalue) {
            if (selectedFilterkey === "credentialType")
                return provider?.providerCme?.filter(
                    (cme) => cme?.cmeCredentialType?.id + "" === selectedFiltervalue + ""
                );
        }
        return provider?.providerCme;
    }, [selectedFiltervalue, provider, provider?.providerCme]);

    useEffect(() => {
        setSelectedFiltervalue(undefined);
    }, [selectedFilterkey]);

    const TableFilters = (
        <Space direction="vertical">
            <Space size="small">
                <Select
                    value={selectedFilterkey}
                    onChange={(value) => {
                        setSelectedFilterKey(value);
                    }}
                    style={{ width: "140px" }}
                    options={[{ value: "credentialType", label: "Credential Type" }]}
                />
                {selectedFilterkey === "credentialType" && (
                    <Select
                        allowClear
                        showSearch
                        optionFilterProp="label"
                        value={selectedFiltervalue}
                        placeholder="Select credential type"
                        onChange={(value) => setSelectedFiltervalue(value)}
                        style={{ width: "320px" }}
                        options={cmeCredentialType.map((type) => ({
                            label: type.name,
                            value: type.id,
                        }))}
                    />
                )}
            </Space>
            <div>
                {selectedFiltervalue && (
                    <Tag closable onClose={() => setSelectedFiltervalue(undefined)}>
                        Credential Type:{" "}
                        {
                            cmeCredentialType.find((type) => type.id === selectedFiltervalue)
                                ?.name
                        }
                    </Tag>
                )}
            </div>
        </Space>
    );

    return {
        TableFilters,
        filteredCME,
        UseQueryHookResult: {
            refetch,
            isLoading,
            isFetching,
        },
    };
}
