import "./App.css";
import * as Sentry from "@sentry/react";
import Router, { InvalidPathRoute } from "./routes";
import "@cloudscape-design/global-styles/index.css";
import { useEffect } from "react";
import { initGA } from "./utils/ga-4";
import usePageTracking from "./hooks/usePageTracking";

function App() {
  useEffect(() => {
    initGA();
  }, []);

  // Use the custom hook globally
  usePageTracking();

  return (
    <Sentry.ErrorBoundary fallback={<InvalidPathRoute />}>
      <Router />
    </Sentry.ErrorBoundary>
  );
}

export default App;
