import {
  Box,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Card, Spin } from "antd";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetProviderProfileVersionsQuery } from "../../../redux/api/section/section";
import { SectionRequest } from "../../../redux/api/section/types";
import IncredableDateFormat from "../../../common/incredable-date-format";

export default function ProviderProfileVersions({
  recordId,
  sectionData,
}: {
  recordId: string | undefined;
  sectionData?: SectionRequest;
}) {
  const { data: providerProfileVersions, isLoading } =
    useGetProviderProfileVersionsQuery(!!recordId ? { recordId } : skipToken);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: `calc(100vh - 275px)`,
          marginBottom: "74px",
        }}
      >
        <Spin tip="Loading..." />
      </div>
    );
  }

  console.log("section data", sectionData);

  return (
    <div
      style={{
        height: `calc(100vh - 275px)`,
        marginBottom: "74px",
        overflowY: "auto",
      }}
    >
      <Container header={<Header>Version History</Header>}>
        <SpaceBetween size="m">
          {(!providerProfileVersions ||
            providerProfileVersions?.length === 0) &&
            "No Versions Founds"}

          {!!providerProfileVersions &&
            !!providerProfileVersions?.length &&
            providerProfileVersions?.map((version) => {
              return (
                <Card
                  size="small"
                  title={
                    <Box variant="p">
                      Added by{" "}
                      <b>
                        {version?.user?.firstName} {version?.user?.lastName}
                      </b>{" "}
                      on{" "}
                      <b>
                        {!!version?.createdDate
                          ? new Date(version?.createdDate).toLocaleString()
                          : "-"}
                      </b>
                    </Box>
                  }
                  hoverable
                >
                  <Box variant="p">
                    Preferred Name:{" "}
                    <i>{version?.user?.preferredName ?? "-"} </i>
                  </Box>

                  <Box variant="p">
                    Role: <i>{version?.user?.role ?? "-"}</i>
                  </Box>
                  {sectionData?.attributes
                    .filter((attr) =>
                      version?.attributeValues?.hasOwnProperty(String(attr.id))
                    )
                    .map((attr) => {
                      const value = String(
                        version?.attributeValues[String(attr.id)]
                      );

                      let displayValue: string | undefined =
                        value === "true"
                          ? "Yes"
                          : value === "false"
                          ? "No"
                          : value;
                      if (attr?.name?.toLowerCase()?.includes("date")) {
                        displayValue = IncredableDateFormat(value);
                      }

                      return (
                        <Box variant="p" key={attr.id}>
                          {attr.name}: <i>{displayValue || "-"}</i>
                        </Box>
                      );
                    })}
                </Card>
              );
            })}
        </SpaceBetween>
      </Container>
    </div>
  );
}
