import { useParams } from "react-router-dom";
import { useGetAllDigitalFormsQuery } from "../../../redux/api/digitalform/digitalforms";
import { useGetAllCredentialTemplateFormsQuery, useUpdateCredentialTemplateFormsMutation } from "../../../redux/api/credentialpackages/credentialpackages";
import { useState } from "react";
import { DigitalForm } from "../../../redux/api/digitalform/types";
import { Button, Col, ConfigProvider, Row } from "antd";
import { Table, Modal } from "@cloudscape-design/components";
import { skipToken } from "@reduxjs/toolkit/query";

export default function AddFormModal({
  visible,
  closeModal,
}: {
  visible: boolean;
  closeModal: VoidFunction;
}) {
  const { templateId } = useParams();

  const {
    data: digitalForms = [],
    isLoading,
    isFetching,
  } = useGetAllDigitalFormsQuery();
  const {
    data: templatedigitalforms = [],
    refetch,
    isLoading: templateformsLoading,
    isFetching: templateformsFetching

  } = useGetAllCredentialTemplateFormsQuery(
    templateId ? { credentialTemplateId: templateId } : skipToken
  );

  const [updateCredentialTemplateForms] =
    useUpdateCredentialTemplateFormsMutation();

  const [selectedRows, setSelectedRows] = useState<DigitalForm[]>([]);

  return (
    <Modal
      header={"Add Forms"}
      onDismiss={closeModal}
      visible={visible}
      size="medium"
      footer={
        <Row justify="end" gutter={16}>
          <Col>
            <Button onClick={closeModal}>Cancel</Button>
          </Col>
          <Col>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#2B6BE6",
                },
              }}
            >
              <Button
                type="primary"
                onClick={async () => {
                  if (templateId) {
                    const formIds = selectedRows.map((form) => form.id);
                    await updateCredentialTemplateForms({
                      id: templateId,
                      forms: formIds,
                    });
                  }
                  setSelectedRows([]);
                  closeModal();
                }}
              >
                Save
              </Button>
            </ConfigProvider>
          </Col>
        </Row>
      }
    >
      <Table
        loading={(isLoading || isFetching) && (templateformsLoading || templateformsFetching)}
        onSelectionChange={(selectionChangeDetail) => {
          setSelectedRows(selectionChangeDetail.detail.selectedItems);
        }}
        onRowClick={({ detail }) => {
          const temp = selectedRows;
          if (
            !detail.item ||
            !!templatedigitalforms
              ?.map((forms) => forms?.id + "")
              ?.includes(detail.item.id + "")
          )
            return;
          if (temp?.includes(detail.item))
            setSelectedRows(temp.filter((item) => item !== detail.item));
          else setSelectedRows([detail.item, ...temp]);
        }}
        selectedItems={selectedRows || []}
        columnDefinitions={[
          {
            id: "name",
            header: <div>Name</div>,
            cell: (item) => <span>{item?.name}</span>,
          },
          {
            id: "type",
            header: <div>Type</div>,
            cell: (item) => <span>{item?.digitalFormType?.type}</span>,
          },
        ]}
        isItemDisabled={(item) => {
          return templatedigitalforms
            ?.map((forms) => forms?.id + "")
            ?.includes(item?.id + "");
        }}
        items={!!digitalForms ? [...digitalForms] : []}
        loadingText="Loading resources"
        selectionType="multi"
        trackBy="id"
      />
    </Modal>
  );
}
