import React, { useEffect, useState } from "react";
import { Table, Card, Button, Modal, Form, Input } from "antd";
import { SMSMessageType } from "../redux/api/twilio/types";
import {
  useGetAllSMSMessageByProvideridQuery,
  useSendSMSMessageMutation,
} from "../redux/api/twilio";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  SpaceBetween,
  StatusIndicator,
  Textarea,
} from "@cloudscape-design/components";
import IncredableDateFormat from "../common/incredable-date-format";

const SmsMessages: React.FC = () => {
  const { providerId } = useParams();
  const { data, isLoading } = useGetAllSMSMessageByProvideridQuery(
    !!providerId ? { providerId } : skipToken
  );
  const [sendSmsMessage] = useSendSMSMessageMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageBody, setMessageBody] = useState<string>("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    if (!!providerId) sendSmsMessage({ providerId, messageBody });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setMessageBody("");
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "Send To",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Message Body",
      dataIndex: "messageBody",
      key: "messageBody",
    },
    {
      title: "Sent Date",
      dataIndex: "createdDate",
      key: "sentDate",
      render: (text?: string) => IncredableDateFormat(text) || "-",
    },
    {
      title: "Status",
      key: "status",
      render: (item?: any) => <StatusIndicator>Delivered</StatusIndicator>,
    },
  ];

  return (
    <Card
      title="Sent SMS Messages"
      bordered={false}
      extra={
        <Button type="primary" onClick={showModal}>
          Send Message
        </Button>
      }
    >
      <Table
        dataSource={data}
        columns={columns}
        rowKey="id"
        loading={isLoading}
      />
      <Modal
        title="Send text message"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <SpaceBetween size={"m"}>
          <span style={{ fontWeight: "500", fontSize: "16px" }}>Message:</span>
          <Textarea
            placeholder="Enter the message content here"
            value={messageBody}
            onChange={({ detail }) => {
              setMessageBody(detail?.value);
            }}
          />
        </SpaceBetween>
      </Modal>
    </Card>
  );
};

export default SmsMessages;
