import { api } from "..";
import { AllDocumentProviderRecordResponse } from "../../../provider/profileBuilder/add-edit-provider-profile-section";
import { ProviderProfileRecordType, ProviderProfileRecordTypes } from "../../../provider/profileBuilder/section-data";
import {
    ProviderProfileActivities,
    ProviderProfileVersion,
    SectionRequest,
    SectionSortOrder,
    SectionType
} from "./types";

const SectionAPI = api.injectEndpoints({
    endpoints: (build) => ({
        getAllSection: build.query<SectionType[], void>({
            query: () => "/section/all",
            providesTags: ["section"],
        }),
        addSection: build.mutation<void, SectionRequest>({
            query: ({ ...body }) => {
                return {
                    url: "section",
                    method: "POST",
                    body: body,
                };
            },
            invalidatesTags: ["section"],
        }),
        getSection: build.query<SectionRequest, { id: string }>({
            query: ({ id }) => `/section/${id}`,
            providesTags: ["section"],
        }),
        updateSection: build.mutation<void, { id: string } & Partial<SectionRequest>>({
            query: ({ id, ...patch }) => {
                return {
                    url: `section/${id}`,
                    method: "PUT",
                    body: patch,
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: "section", id: arg.id },
                { type: "section" },
            ],
        }),
        deleteSection: build.mutation<void, { sectionId: string; }>({
            query: ({ sectionId }) => ({
                url: `section/${sectionId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["section"],
        }),
        deleteAttribute: build.mutation<void, { sectionId: string; attributeId: string }>({
            query: ({ sectionId, attributeId }) => ({
                url: `section/${sectionId}/attribute/${attributeId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["section"],
        }),
        deleteAttributeOption: build.mutation<void, { sectionId: string; attributeId: string; optionId: string }>({
            query: ({ sectionId, attributeId, optionId }) => ({
                url: `section/${sectionId}/attribute/${attributeId}/attributeoption/${optionId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["section"],
        }),
        SectionChangeSortOrder: build.mutation<void, SectionSortOrder[]>({
            query: (patch) => ({
                url: `section/changesortorder`,
                method: "POST",
                body: patch,
            }),
            invalidatesTags: ["section"],
        }),
        getAllRecordsByProviderAndSectionId: build.query<ProviderProfileRecordType[], { providerId: string; sectionId: String }>({
            query: ({ providerId, sectionId }) => `/providers/${providerId}/sections/${sectionId}`,
            providesTags: ["section"],
        }),
        getAllProviderProfileRecordsByProvider: build.query<void, { providerID: string; sectionId: String }>({
            query: ({ providerID, sectionId }) => `/providers/${providerID}/section/${sectionId}/records`,
            providesTags: ["section"],
        }),
        getAllProviderProfileDocuments: build.query<AllDocumentProviderRecordResponse[], { providerId: string; recordId: String }>({
            query: ({ providerId, recordId }) => `/providers/${providerId}/records/${recordId}/documents`,
            providesTags: ["section"],
        }),
        getRecordsByProviderAndRecordId: build.query<ProviderProfileRecordTypes[], { providerId: string; recordId: String }>({
            query: ({ providerId, recordId }) => `/providers/${providerId}/records/${recordId}`,
            providesTags: ["section"],
        }),
        addRecord: build.mutation<void, { providerId: string; sectionId: string; data: ProviderProfileRecordTypes }>({
            query: ({ providerId, sectionId, data }) => ({
                url: `/providers/${providerId}/sections/${sectionId}/records/add`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["section"],
        }),
        updateProviderProfileRecord: build.mutation<void, { providerID: string; recordId: string; data: ProviderProfileRecordType }>({
            query: ({ providerID, recordId, data }) => ({
                url: `/providers/${providerID}/records/${recordId}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["section"],
        }),
        updateRecord: build.mutation<void, { providerID: string; sectionId: string; recordId: string, isVersioned: boolean, data: ProviderProfileRecordTypes }>({
            query: ({ providerID, sectionId, recordId, isVersioned = false, data }) => ({
                url: `/providers/${providerID}/sections/${sectionId}/records/${recordId}?isVersioned=${isVersioned}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["section"],
        }),
        deleteCustomProfileRecord: build.mutation<void, { providerId: string; recordId: string }>({
            query: ({ providerId, recordId }) => {
                return {
                    url: `/providers/${providerId}/records/${recordId}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: ["section"],
        }),
        getProviderProfileActivities: build.query<ProviderProfileActivities, { recordId: string }>(
            {
                query: ({ recordId }) => `/providerprofile/${recordId}/activities`,
                providesTags: ["section"],
            }
        ),
        getProviderProfileVersions: build.query<ProviderProfileVersion[], { recordId: string }>(
            {
                query: ({ recordId }) => `providerprofile/${recordId}/versions`,
                providesTags: ["section"],
            }
        ),
    }),
});

export const {
    useGetAllSectionQuery,
    useAddSectionMutation,
    useGetSectionQuery,
    useUpdateSectionMutation,
    useDeleteSectionMutation,
    useDeleteAttributeMutation,
    useDeleteAttributeOptionMutation,
    useSectionChangeSortOrderMutation,
    useGetAllRecordsByProviderAndSectionIdQuery,
    useGetAllProviderProfileRecordsByProviderQuery,
    useGetRecordsByProviderAndRecordIdQuery,
    useAddRecordMutation,
    useUpdateProviderProfileRecordMutation,
    useUpdateRecordMutation,
    useDeleteCustomProfileRecordMutation,
    useGetProviderProfileActivitiesQuery,
    useGetProviderProfileVersionsQuery,
    useGetAllProviderProfileDocumentsQuery
} = SectionAPI;
