import { useEffect, useState } from "react";
import { useFileExistsQuery } from "../redux/api/reporting/reporting";
import { skipToken } from "@reduxjs/toolkit/query";
import { getFileUrl } from "../config";
import { useGetOrganizationDetailsQuery } from "../redux/api/organization/organization";

function openWithUpdatedQuery(url: string) {
  const newTab = window.open(url, "_blank");
}

function useFileExport(type: string) {
  const [taskId, setTaskId] = useState<string>();
  const { data: org } = useGetOrganizationDetailsQuery();
  const { data, status } = useFileExistsQuery(
    !!taskId ? { taskId: taskId + "." + type } : skipToken,
    { pollingInterval: 20000 }
  );

  useEffect(() => {
    if (status === "fulfilled") {
      setTimeout(() => {
        openWithUpdatedQuery(
          getFileUrl(`exports/${org?.id}/` + taskId + "." + type)
        );
        setTaskId(undefined);
      }, 18000);
    }
  }, [status]);
  return { taskId, setTaskId };
}

export default useFileExport;
