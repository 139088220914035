import React, {
    useContext,
    useEffect,
    useMemo,
    useState,
    useCallback,
} from "react";
import { Flex, Space, Modal, Card, Form, Input, Col, Layout, Typography, Divider } from "antd";
import {
    ExclamationCircleOutlined,
    HolderOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import type { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Table } from "antd";
import type { TableColumnsType } from "antd";
import {
    Container,
    Header,
    KeyValuePairs,
    SpaceBetween,
} from "@cloudscape-design/components";
import IncredableButton from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import StickyFooter from "../../components/StickyFooter";
import IncredableContent from "../../components/incredable-content";
import { Attribute, SectionRequest } from "../../redux/api/section/types";
import {
    useDeleteAttributeMutation,
    useDeleteSectionMutation,
    useGetSectionQuery,
    useUpdateSectionMutation,
} from "../../redux/api/section/section";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { skipToken } from "@reduxjs/toolkit/query";
import TypeModal from "./type-modal";
import AddAttributeModal from "./modify/add-attribute-modal";
import EditAttributeModal from "./modify/edit-attribute-modal";
import { FormProvider, useForm } from "react-hook-form";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import RHFTextField from "../../components/RHF/RHFTextField";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";

const EditSection: React.FC = () => {
    const methods = useForm<SectionRequest>();
    const hasPermission = useSelector(
        (state: ApplicationState) =>
            state.ui.is_owner ||
            (state.ui.permissions?.includes("section.edit") ?? false),
    );

    const [section, setSection] = useState<SectionRequest | undefined>(undefined);
    const [isFieldsModalOpen, setIsFieldsModalOpen] = useState(false);
    const [isEditFieldModalOpen, setIsEditFieldModalOpen] = useState(false);
    const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
        useState(false);
    const [attributeToDelete, setAttributeToDelete] = useState<number | null>(
        null
    );
    const [editAttribute, setEditAttribute] = useState<Attribute | null>(null);
    const [sectionAttribute, setSectionAttribute] = useState<Partial<Attribute>>({
        name: "",
        description: "",
        type: "",
        defaultValue: "",
        required: false,
        sensitive: false,
    });
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isTypeModalOpen, setIsTypeModalOpen] = useState(false);
    const [attributeType, setAttributeType] = useState<string>("");

    const spinnerContext = useContext(SpinnerContext);
    const toastContext = useContext(ToastContext);
    const navigate = useNavigate();
    const { sectionId } = useParams();

    const { data: sectionData, isLoading } = useGetSectionQuery(
        sectionId ? { id: sectionId } : skipToken
    );

    useEffect(() => {
        if (sectionData) {
            methods.setValue("name", sectionData?.name ?? "");
            methods.setValue("description", sectionData?.description ?? "");
        }
    }, [methods, sectionData]);

    const [updateSection] = useUpdateSectionMutation();
    const [deleteSection] = useDeleteSectionMutation();
    const [deleteAttribute] = useDeleteAttributeMutation();

    const handleSectionDelete = async () => {
        try {
            spinnerContext?.showSpinner();
            await deleteSection({ sectionId: section?.id + "" });
            toastContext?.openSuccessNotification({
                message: "Section deleted successfully",
                placement: "top",
            });
            setIsDeleteModalOpen(false);
        } catch (error) {
            toastContext?.openErrorNotification({
                message: "Failed to delete section",
                placement: "top",
            });
        } finally {
            spinnerContext?.hidespinner();
        }
        navigate(-1);
    };
    useEffect(() => {
        if (sectionData) {
            const sortedAttributes = [...sectionData.attributes].sort(
                (a, b) => a.sortOrder - b.sortOrder
            );
            setSection({ ...sectionData, attributes: sortedAttributes });
        }
    }, [sectionData]);

    const handleAddAttribute = useCallback(() => {
        if (sectionAttribute.name?.trim() && section) {
            const newAttribute: Attribute = {
                id: undefined,
                name: sectionAttribute.name || "",
                description: sectionAttribute.description || "",
                type: attributeType || "",
                sortOrder: section.attributes.length,
                defaultValue: sectionAttribute.defaultValue || "",
                required: sectionAttribute.required || false,
                sensitive: sectionAttribute.sensitive || false,
            };

            setSection({
                ...section,
                attributes: [...section.attributes, newAttribute],
            });
            setSectionAttribute({
                name: "",
                description: "",
                type: "",
                defaultValue: "",
                required: false,
                sensitive: false,
            });
            setIsFieldsModalOpen(false);
            setAttributeType("");
        }
    }, [section, sectionAttribute]);

    const handleDeleteAttributeConfirm = useCallback((attributeIndex: number) => {
        setAttributeToDelete(attributeIndex);
        setIsDeleteConfirmModalOpen(true);
    }, []);

    const handleDeleteAttribute = useCallback(async () => {
        if (attributeToDelete !== null && section && sectionId) {
            const attribute = section.attributes[attributeToDelete];

            try {
                spinnerContext?.showSpinner();
                if (attribute.id) {
                    await deleteAttribute({
                        sectionId,
                        attributeId: attribute.id,
                    }).unwrap();
                }
                const updatedAttributes = section.attributes
                    .filter((_, index) => index !== attributeToDelete)
                    .map((attr, index) => ({ ...attr, sortOrder: index }));

                setSection({ ...section, attributes: updatedAttributes });
                toastContext?.openSuccessNotification({
                    message: `Attribute deleted successfully`,
                    placement: "topRight",
                });
            } catch (error) {
                toastContext?.openWarningNotification({
                    message: `Attribute deletion failed`,
                    placement: "topRight",
                });
            } finally {
                spinnerContext?.hidespinner();
                setAttributeToDelete(null);
                setIsDeleteConfirmModalOpen(false);
            }
        }
    }, [
        attributeToDelete,
        section,
        sectionId,
        deleteAttribute,
        spinnerContext,
        toastContext,
    ]);

    const handleEditAttribute = useCallback(() => {
        if (editAttribute && section) {
            const updatedAttributes = section.attributes.map((attr) =>
                attr.sortOrder === editAttribute.sortOrder ? { ...editAttribute } : attr
            );
            setSection({ ...section, attributes: updatedAttributes });
            setEditAttribute(null);
            setIsEditFieldModalOpen(false);
        }
    }, [editAttribute, section]);

    const openEditModal = useCallback((attribute: Attribute) => {
        setEditAttribute({ ...attribute });
        setIsEditFieldModalOpen(true);
    }, []);

    const handleAttributeOrderUpdate = useCallback(
        (newAttributes: Attribute[]) => {
            if (!section) return;
            requestAnimationFrame(() => {
                setSection({
                    ...section,
                    attributes: newAttributes.map((attr, index) => ({
                        ...attr,
                        sortOrder: index,
                    })),
                });
            });
        },
        [section]
    );

    const handleSubmit = useCallback(async () => {
        try {
            if (section) {
                spinnerContext?.showSpinner();
                if (section.id) {
                    await updateSection({
                        id: section.id,
                        ...section,
                    }).unwrap();
                }
                spinnerContext?.hidespinner();
                toastContext?.openSuccessNotification({
                    message: `Section updated successfully`,
                    placement: "topRight",
                });
                navigate(-1);
            }
        } catch (error) {
            toastContext?.openWarningNotification({
                message: `Section failed to update`,
                placement: "topRight",
            });
            spinnerContext?.hidespinner();
        }
    }, [section, updateSection, spinnerContext, toastContext, navigate]);

    const templateData = [
        {
            title: "Section Name",
            description: section?.name,
        },
        {
            title: "Description",
            description: section?.description,
        },
        {
            title: "Type",
            description: section?.isFacility === false ? "Provider" : "Facility"
        },
        {
            title: "Creation Time",
            description: section?.creationTime,
        },
    ];

    return (
        <>
            <IncredableContent
                breadcrumbGroupProps={{
                    items: [
                        {
                            text: "Dynamic Profiles",
                            href: "/profilebuilder",
                        },
                        {
                            text: hasPermission ? "Edit" : "View",
                            href: "#",
                        },
                    ],
                }}
                style={{ padding: "0" }}
                spinning={isLoading}
            >
                {section && (
                    <Container
                        key={section.id || "section"}
                        header={
                            <Header
                                actions={
                                    <SpaceBetween size="l" direction="horizontal">
                                        <IncredableButton
                                            onClick={() => {
                                                setAttributeType("");
                                                setIsEditModalOpen(true);
                                            }}
                                            disabled={!hasPermission}
                                            type="primary"
                                            style={{ backgroundColor: "#598BEB" }}
                                        >
                                            Edit
                                        </IncredableButton>
                                        <IncredableButton
                                            onClick={() => {
                                                setAttributeType("");
                                                setIsDeleteModalOpen(true);
                                            }}
                                            secondary
                                        >
                                            Delete
                                        </IncredableButton>
                                    </SpaceBetween>
                                }
                            >
                                {hasPermission ? "Edit" : "View"} {section.name}
                            </Header>
                        }
                    >
                        <Space
                            direction="vertical"
                            size="large"
                            style={{
                                height: "calc(100vh - 220px)",
                                overflowY: "scroll",
                                scrollbarWidth: "none",
                                paddingBottom: "50px",
                                display: "flex",
                            }}
                        >
                            <Card>
                                <KeyValuePairs
                                    columns={4}
                                    items={templateData?.map((item) => ({
                                        label: item?.title,
                                        value: item?.description,
                                    }))}
                                />
                            </Card>

                            <Card
                                title={
                                    <Header
                                        actions={
                                            <SpaceBetween size={"s"} direction="horizontal">
                                                <IncredableButton
                                                    onClick={() => {
                                                        setAttributeType("");
                                                        setIsTypeModalOpen(true);
                                                    }}
                                                    type="primary"
                                                    style={{ backgroundColor: "#598BEB" }}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Add New Field
                                                </IncredableButton>
                                            </SpaceBetween>
                                        }
                                    >
                                        Flex Fields
                                    </Header>
                                }
                            >
                                <DragableTable
                                    dataSource={section.attributes}
                                    onDelete={handleDeleteAttributeConfirm}
                                    onEdit={openEditModal}
                                    onUpdateOrder={handleAttributeOrderUpdate}
                                />
                            </Card>

                            <StickyFooter
                                style={{ zIndex: "200", width: "96%" }}
                                hasPermission={true}
                                saveText="Submit"
                                onSubmit={handleSubmit}
                                onCancel={() => navigate(-1)}
                            />
                        </Space>
                    </Container>
                )}

                <TypeModal
                    isTypeModalOpen={isTypeModalOpen}
                    setIsTypeModalOpen={setIsTypeModalOpen}
                    setIsFieldsModalOpen={setIsFieldsModalOpen}
                    setAttributeType={setAttributeType}
                />

                <AddAttributeModal
                    isFieldsModalOpen={isFieldsModalOpen}
                    setIsFieldsModalOpen={setIsFieldsModalOpen}
                    handleAddAttribute={handleAddAttribute}
                    sectionAttribute={sectionAttribute as Attribute}
                    setSectionAttribute={setSectionAttribute}
                    attributeType={attributeType}
                />

                <EditAttributeModal
                    isEditFieldModalOpen={isEditFieldModalOpen}
                    setIsEditFieldModalOpen={setIsEditFieldModalOpen}
                    handleEditAttribute={handleEditAttribute}
                    editAttribute={editAttribute as Attribute}
                    setEditAttribute={setEditAttribute}
                    attributeType={attributeType}
                    sectionId={sectionId || undefined}
                />

                <Modal
                    title="Confirm Delete"
                    open={isDeleteConfirmModalOpen}
                    onCancel={() => setIsDeleteConfirmModalOpen(false)}
                    footer={
                        <Space>
                            <Button onClick={() => setIsDeleteConfirmModalOpen(false)}>
                                Cancel
                            </Button>
                            <Button type="primary" danger onClick={handleDeleteAttribute}>
                                Delete
                            </Button>
                        </Space>
                    }
                    zIndex={1000}
                >
                    <p>Are you sure you want to delete this field?</p>
                </Modal>

                <Modal
                    title={<Header>{hasPermission ? "Edit" : "View"} Section</Header>}
                    open={isEditModalOpen}
                    onCancel={() => setIsEditModalOpen(false)}
                    footer={false}
                    zIndex={1000}
                >
                    <Layout.Content>
                        <FormProvider {...methods}>
                            <Form
                                name="editsection"
                                layout="vertical"
                                onFinish={methods.handleSubmit(async (data: SectionRequest) => {
                                    setIsEditModalOpen(false);
                                    if (!!sectionId)
                                        await updateSection({
                                            id: sectionId,
                                            name: data?.name,
                                            description: data?.description,
                                        });
                                })}
                                autoComplete="off"
                            >
                                <Divider />
                                <Form.List name="section" initialValue={[{}]}>
                                    {(fields, { add }) => {
                                        return (
                                            <>
                                                {fields.map(({ key, name, ...restField }, index) => (
                                                    <div key={key}>
                                                        {index > 0 && <Divider />}
                                                        <>
                                                            <Col span={24}>
                                                                <RHFTextField
                                                                    formItemProps={{
                                                                        label: "Name",
                                                                    }}
                                                                    inputProps={{}}
                                                                    controllerProps={{
                                                                        name: `name`,
                                                                    }}
                                                                    rules={{ required: "This field is required" }}
                                                                />
                                                            </Col>
                                                            <Col span={24}>
                                                                <RHFTextArea
                                                                    formItemProps={{
                                                                        label: "Description",
                                                                    }}
                                                                    textAreaProps={{}}
                                                                    controllerProps={{
                                                                        name: `description`,
                                                                    }}
                                                                    rules={{ required: "This field is required" }}
                                                                />
                                                            </Col>
                                                        </>
                                                    </div>
                                                ))}
                                            </>
                                        );
                                    }}
                                </Form.List>
                                <Flex justify="flex-end">
                                    <Space>
                                        <IncredableButton disabled={!hasPermission} type="primary" htmlType="submit">
                                            Edit
                                        </IncredableButton>
                                        <IncredableButton
                                            secondary
                                            onClick={() => {
                                                setIsEditModalOpen(false);
                                            }}
                                        >
                                            Cancel
                                        </IncredableButton>
                                    </Space>
                                </Flex>
                            </Form>
                        </FormProvider>
                    </Layout.Content>
                </Modal>

                <Modal
                    title={
                        <Space>
                            <ExclamationCircleOutlined style={{ color: "#faad14" }} />
                            Delete Section
                        </Space>
                    }
                    open={isDeleteModalOpen}
                    onOk={handleSectionDelete}
                    onCancel={() => {
                        setIsDeleteModalOpen(false);
                        // setSectionToDelete(null);
                    }}
                    okText="Delete"
                    okButtonProps={{ danger: true }}
                    zIndex={1000}
                >
                    <p>Are you sure you want to delete the section "{section?.name}"?</p>
                    <p>This action cannot be undone.</p>
                </Modal>
            </IncredableContent>
        </>
    );
};

interface DragableTableProps {
    dataSource: Attribute[];
    onDelete: (attributeIndex: number) => void;
    onEdit: (attribute: Attribute) => void;
    onUpdateOrder: (newAttributes: Attribute[]) => void;
}

interface RowContextProps {
    setActivatorNodeRef?: (element: HTMLElement | null) => void;
    listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});

const DragHandle: React.FC = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
        <Button
            type="text"
            size="small"
            icon={<HolderOutlined />}
            style={{ cursor: "move" }}
            ref={setActivatorNodeRef}
            {...listeners}
        />
    );
};

const columns = (
    onDelete: (attributeIndex: number) => void,
    onEdit: (attribute: Attribute) => void
): TableColumnsType<Attribute> => [
        { key: "sort", align: "center", width: 80, render: () => <DragHandle /> },
        { title: "Name", dataIndex: "name" },
        { title: "Type", dataIndex: "type" },
        {
            key: "action",
            title: "Actions",
            render: (_, record, index) => (
                <Flex wrap gap="small">
                    <Button onClick={() => onDelete(index)}>Delete</Button>
                    <Button onClick={() => onEdit(record)}>Edit</Button>
                </Flex>
            ),
        },
    ];

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    "data-row-key": string;
}

const Row: React.FC<RowProps> = React.memo((props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        isDragging,
    } = useSortable({ id: props["data-row-key"] });

    const style: React.CSSProperties = useMemo(
        () => ({
            ...props.style,
            transform: CSS.Translate.toString(transform),
            transition: isDragging ? "none" : "transform 0.2s ease-out",
            ...(isDragging ? { position: "relative", zIndex: 100 } : {}),
        }),
        [props.style, transform, isDragging]
    );

    const contextValue = useMemo<RowContextProps>(
        () => ({ setActivatorNodeRef, listeners }),
        [setActivatorNodeRef, listeners]
    );

    return (
        <RowContext.Provider value={contextValue}>
            <tr
                {...props}
                ref={setNodeRef}
                style={style}
                {...attributes}
                className="sortable-row"
            />
        </RowContext.Provider>
    );
});

const DragableTable: React.FC<DragableTableProps> = React.memo(
    ({ dataSource, onDelete, onEdit, onUpdateOrder }) => {
        const [tableData, setTableData] = useState<Attribute[]>([]);

        useEffect(() => {
            setTableData(dataSource.map((item) => ({ ...item })));
        }, [dataSource]);

        const onDragEnd = useCallback(
            ({ active, over }: DragEndEvent) => {
                if (active.id !== over?.id && over) {
                    requestAnimationFrame(() => {
                        setTableData((prevState) => {
                            const activeIndex = Number(active.id);
                            const overIndex = Number(over.id);
                            const newData = arrayMove(prevState, activeIndex, overIndex).map(
                                (item, index) => ({ ...item, sortOrder: index })
                            );
                            onUpdateOrder(newData);
                            return newData;
                        });
                    });
                }
            },
            [onUpdateOrder]
        );

        const getRowClassName = useCallback(
            (record: Attribute) => (record.type === "Divider" ? "divider-row" : ""),
            []
        );

        return (
            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                <SortableContext
                    items={tableData.map((_, index) => String(index))}
                    strategy={verticalListSortingStrategy}
                >
                    <Table<Attribute>
                        rowKey={(record, index) => String(index)}
                        components={{ body: { row: Row } }}
                        columns={columns(onDelete, onEdit)}
                        dataSource={tableData}
                        bordered={true}
                        pagination={{ position: ["none", "none"] }}
                        size="small"
                        rowClassName={getRowClassName}
                    />
                </SortableContext>
            </DndContext>
        );
    }
);

export default EditSection;
