import {
  Col,
  Divider,
  Form,
  Row,
  theme,
  Tabs as AntTabs,
  ConfigProvider,
  Spin,
} from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import {
  useAddProviderLiabilityInsuranceMutation,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import IncredableContent from "../../components/incredable-content";
import { ProviderLiabilityInsuranceRequestType } from "../../redux/api/provider/types";
import RHFCheckBox from "../../components/RHF/RHFCheckbox";
import RHFPhoneNumber from "../../components/RHF/RHFPhonenumber";
import { Countries, USStatesList } from "../../common/constants";
import RHFSelect from "../../components/RHF/RHFSelect";
import StickyFooter from "../../components/StickyFooter";
import {
  AllDocumentResponse,
  DocumentRequest,
} from "../../redux/api/document/types";
import ProviderDocumentDetails from "../provider-document-details";
import {
  useSaveDocumentMutation,
  useUpdateDocumentMutation,
} from "../../redux/api/document/document";
import { fileAxios } from "../../context/axios";
import { getFileUrl } from "../../config";
import { captureException } from "@sentry/browser";
import { PlusOutlined } from "@ant-design/icons";
import ProviderDocumentModal from "../provider-document-modal";
import IncredableButton from "../../components/button";

const { useToken } = theme;

export default function EditProviderLiabilityInsurance({
  liabilityinsuranceId,
  toggleDrawer,
}: {
  liabilityinsuranceId?: string;
  toggleDrawer?: () => void;
}) {
  const { providerId } = useParams();
  const methods = useForm<ProviderLiabilityInsuranceRequestType>();
  const documentMethods = useForm<DocumentRequest & { documentId: string }>();
  const [spinning, setSpinning] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [showDocumentTab, setShowDocumentTab] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>("liabilityinsurance");
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<
    AllDocumentResponse[]
  >([]);

  const {
    data: provider,
    isLoading,
    isFetching,
  } = useGetProviderQuery(!!providerId ? { providerId } : skipToken);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (provider && provider.providerLiabilityInsurances) {
      const liabilityinsurance = provider?.providerLiabilityInsurances?.find(
        (liability) => liability?.id + "" === liabilityinsuranceId + ""
      );
      if (liabilityinsurance) {
        methods.setValue("id", liabilityinsurance.id);
        methods.setValue("isCurrent", liabilityinsurance?.isCurrent);
        methods.setValue("name", liabilityinsurance?.name);
        methods.setValue("policyNumber", liabilityinsurance?.policyNumber);
        methods.setValue("selfInsured", liabilityinsurance?.selfInsured);
        methods.setValue("effectiveDate", liabilityinsurance?.effectiveDate);
        methods.setValue("expirationDate", liabilityinsurance?.expirationDate);
        methods.setValue("email", liabilityinsurance?.email);
        methods.setValue("phone", liabilityinsurance?.phone);
        methods.setValue("fax", liabilityinsurance?.fax);
        methods.setValue("typeOfCoverage", liabilityinsurance?.typeOfCoverage);
        methods.setValue(
          "timeWithCurrentCarrierValue",
          liabilityinsurance?.timeWithCurrentCarrierValue
        );
        methods.setValue(
          "timeWithCurrentCarrierUnit",
          liabilityinsurance?.timeWithCurrentCarrierUnit
        );
        methods.setValue(
          "coveragePerOccurrence",
          liabilityinsurance?.coveragePerOccurrence
        );
        methods.setValue(
          "aggregateCoverage",
          liabilityinsurance?.aggregateCoverage
        );
        methods.setValue("addressLane1", liabilityinsurance?.addressLane1);
        methods.setValue("addressLane2", liabilityinsurance?.addressLane2);
        methods.setValue("city", liabilityinsurance?.city);
        methods.setValue("state", liabilityinsurance?.state);
        methods.setValue("zip", liabilityinsurance?.zip);
        methods.setValue("country", liabilityinsurance?.country);
        if (!!liabilityinsurance?.document) {
          documentMethods.setValue(
            "documentId",
            liabilityinsurance?.document?.id
          );
          documentMethods.setValue("name", liabilityinsurance?.document?.name);
          documentMethods.setValue(
            "categoryId",
            liabilityinsurance?.document?.category?.id
          );
          documentMethods.setValue(
            "notes",
            liabilityinsurance?.document?.notes
          );
          documentMethods.setValue(
            "expirationDate",
            liabilityinsurance?.document?.expirationDate
          );
          documentMethods.setValue(
            "alertDays",
            liabilityinsurance?.document?.alertDays
          );
        }
      }
    }
  }, [provider, liabilityinsuranceId, methods]);

  const [updateProviderLiabilityInsurance] =
    useAddProviderLiabilityInsuranceMutation();
  const [updateDocument] = useUpdateDocumentMutation();
  const [saveDocument] = useSaveDocumentMutation();

  const handleExistingDocumentSubmit = (type?: string) => {
    setIsDocumentModalOpen(false);
    setShowDocumentTab(true);
    setActiveKey("document");
    if (type == "linkDocument") {
      documentMethods.setValue("documentId", selectedDocument?.at(0)?.id || "");
      documentMethods.setValue("name", selectedDocument?.at(0)?.name + "");
      documentMethods.setValue(
        "categoryId",
        selectedDocument?.at(0)?.category?.id || ""
      );
      documentMethods.setValue("notes", selectedDocument?.at(0)?.notes);
    } else {
      setSelectedDocument([]);
      documentMethods.setValue("documentId", "");
      setIsDocumentModalOpen(false);
      setShowDocumentTab(true);
      setActiveKey("document");
    }
  };

  return (
    <IncredableContent
      style={{ position: "relative", paddingTop: "10px" }}
      spinning={isLoading || isFetching}
      breadcrumbGroupProps={{
        items: [
          {
            text: "Provider",
            href: "/manageprovider",
          },
          {
            text: (
              <span onClick={toggleDrawer}>{`${provider?.firstName || ""
                } ${provider?.lastName || ""}`}</span>
            ),
          },
          {
            text: liabilityinsuranceId != "undefined"
              ? "Edit LiabilityInsurance"
              : "Add LiabilityInsurance",
            href: "#",
          },
        ],
      }}
    >
      <Spin spinning={spinning} fullscreen />
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "#2B6BE6",
              cardBg: "#F5F5F5",
              colorBgContainer: "#2B6BE6",
              inkBarColor: "#2B6BE6",
              colorBorderSecondary: "#c6c6cd",
              fontSize: 16,
            },
          },
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <AntTabs
            defaultActiveKey="liabilityinsurance"
            size="large"
            activeKey={activeKey}
            onChange={(activeKey) => setActiveKey(activeKey)}
            tabBarExtraContent={
              (liabilityinsuranceId == "undefined" ||
                provider?.providerLiabilityInsurances?.find(
                  (liability) => liability?.id + "" == liabilityinsuranceId + ""
                )?.document === null) && (
                <IncredableButton
                  icon={<PlusOutlined />}
                  onClick={() => setIsDocumentModalOpen(true)}
                >
                  Add Document
                </IncredableButton>
              )
            }
            items={[
              {
                label: (
                  <span style={{ fontWeight: "700" }}>
                    {liabilityinsuranceId != "undefined"
                      ? "Edit LiabilityInsurance"
                      : "Add LiabilityInsurance"}
                  </span>
                ),
                key: "liabilityinsurance",
                children: (
                  <FormProvider {...methods}>
                    <Form
                      style={{
                        height: "calc(100vh - 250px)",
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        paddingBottom: "50px",
                      }}
                      name="editproviderliabilityinsurance"
                      layout="vertical"
                      onFinish={methods.handleSubmit(
                        async (data: ProviderLiabilityInsuranceRequestType) => {
                          if (!!providerId) {
                            spinnerContext?.showSpinner();
                            await updateProviderLiabilityInsurance({
                              ...data,
                              providerId: providerId,
                            });
                            spinnerContext?.hidespinner();
                            toastContext?.openSuccessNotification({
                              message: `Liability Insurance saved successfully`,
                              placement: "topRight",
                            });
                          }
                          !!toggleDrawer && toggleDrawer();
                        }
                      )}
                      autoComplete="off"
                    >
                      <Form.List name="liabilityinsurance" initialValue={[{}]}>
                        {(fields, { add }) => {
                          return (
                            <>
                              {fields.map(
                                ({ key, name, ...restField }, index) => (
                                  <div key={key}>
                                    {index > 0 && <Divider />}
                                    <Row
                                      gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                      }}
                                    >
                                      <Col span={8}>
                                        <RHFTextField
                                          formItemProps={{
                                            label: "Name",
                                          }}
                                          inputProps={{}}
                                          controllerProps={{
                                            name: "name",
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <RHFTextField
                                          formItemProps={{
                                            label: "Policy Number",
                                          }}
                                          inputProps={{}}
                                          controllerProps={{
                                            name: "policyNumber",
                                          }}
                                        />
                                      </Col>

                                      <Col span={8}>
                                        <RHFDatePicker
                                          formItemProps={{
                                            label: "Effective Date",
                                          }}
                                          controllerProps={{
                                            name: `effectiveDate`,
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <RHFDatePicker
                                          formItemProps={{
                                            label: "Expiration Date",
                                          }}
                                          controllerProps={{
                                            name: `expirationDate`,
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <RHFTextField
                                          formItemProps={{
                                            label: "Type Of Coverage",
                                          }}
                                          inputProps={{}}
                                          controllerProps={{
                                            name: "typeOfCoverage",
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <RHFTextField
                                          formItemProps={{
                                            label:
                                              "Time With Current Carrier Value",
                                          }}
                                          inputProps={{ type: "number" }}
                                          controllerProps={{
                                            name: "timeWithCurrentCarrierValue",
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <RHFTextField
                                          formItemProps={{
                                            label:
                                              "Time With Current Carrier Unit",
                                          }}
                                          inputProps={{}}
                                          controllerProps={{
                                            name: "timeWithCurrentCarrierUnit",
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <RHFTextField
                                          formItemProps={{
                                            label: "Coverage Per Occurrence",
                                          }}
                                          inputProps={{ type: "number" }}
                                          controllerProps={{
                                            name: "coveragePerOccurrence",
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <RHFTextField
                                          formItemProps={{
                                            label: "Aggregate Coverage",
                                          }}
                                          inputProps={{ type: "number" }}
                                          controllerProps={{
                                            name: "aggregateCoverage",
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <RHFCheckBox
                                          formItemProps={{ label: "" }}
                                          controllerProps={{
                                            name: "selfInsured",
                                          }}
                                          checkboxProps={{
                                            label: "Self Insured",
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <Divider orientation="left">
                                      Contact Info
                                    </Divider>
                                    <Row
                                      gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                      }}
                                    >
                                      <Col span={8}>
                                        <RHFTextField
                                          formItemProps={{
                                            label: "Email",
                                          }}
                                          inputProps={{}}
                                          controllerProps={{
                                            name: "email",
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <RHFPhoneNumber
                                          formItemProps={{
                                            label: "Phone",
                                          }}
                                          inputProps={{}}
                                          controllerProps={{
                                            name: "phone",
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <RHFTextField
                                          formItemProps={{
                                            label: "Fax",
                                          }}
                                          inputProps={{}}
                                          controllerProps={{
                                            name: "fax",
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <Row
                                      gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                      }}
                                    >
                                      <Col span={8}>
                                        <RHFTextField
                                          formItemProps={{
                                            label: "Address Line 1",
                                          }}
                                          inputProps={{}}
                                          controllerProps={{
                                            name: "addressLane1",
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <RHFTextField
                                          formItemProps={{
                                            label: "Address Line 2",
                                          }}
                                          inputProps={{}}
                                          controllerProps={{
                                            name: "addressLane2",
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <RHFTextField
                                          formItemProps={{
                                            label: "City",
                                          }}
                                          inputProps={{}}
                                          controllerProps={{
                                            name: "city",
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <RHFSelect
                                          formItemProps={{ label: "State" }}
                                          controllerProps={{
                                            name: `state`,
                                          }}
                                          selectProps={{
                                            allowClear: true,
                                            showSearch: true,
                                            style: { width: "100%" },
                                            placeholder: "Select state",
                                            filterOption: (input, option) =>
                                              (option?.value ?? "")
                                                .toString()
                                                .toLowerCase()
                                                .includes(input?.toLowerCase()),
                                            options: USStatesList?.map(
                                              (state) => {
                                                return {
                                                  value: state?.value,
                                                };
                                              }
                                            ),
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <RHFSelect
                                          formItemProps={{ label: "Country" }}
                                          controllerProps={{
                                            name: `country`,
                                          }}
                                          selectProps={{
                                            allowClear: true,
                                            showSearch: true,
                                            style: { width: "100%" },
                                            placeholder: "Select country",
                                            filterOption: (input, option) =>
                                              (option?.value ?? "")
                                                .toString()
                                                .toLowerCase()
                                                .includes(input?.toLowerCase()),
                                            options: Countries?.map(
                                              (country) => {
                                                return {
                                                  value: country?.value,
                                                };
                                              }
                                            ),
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <RHFTextField
                                          formItemProps={{
                                            label: "Zip",
                                          }}
                                          inputProps={{}}
                                          controllerProps={{
                                            name: "zip",
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                )
                              )}
                            </>
                          );
                        }}
                      </Form.List>
                    </Form>
                  </FormProvider>
                ),
              },
              {
                label: <span style={{ fontWeight: "700" }}>Document</span>,
                key: "document",
                children: (
                  <FormProvider {...documentMethods}>
                    <ConfigProvider
                      theme={{
                        components: {
                          Form: {
                            verticalLabelMargin: -4,
                            itemMarginBottom: 16,
                          },
                        },
                      }}
                    >
                      <Form
                        style={{
                          height: "calc(100vh - 250px)",
                          overflowY: "scroll",
                          scrollbarWidth: "none",
                          paddingBottom: "30px",
                        }}
                        onFinish={documentMethods.handleSubmit(
                          async (data: DocumentRequest) => {
                            console.log("document methods");
                          }
                        )}
                        layout="vertical"
                        size="middle"
                        autoComplete="off"
                      >
                        <ProviderDocumentDetails
                          documentId={documentMethods?.getValues("documentId")}
                          uploadedFile={uploadedFile}
                          setUploadedFile={setUploadedFile}
                        />
                      </Form>
                    </ConfigProvider>
                  </FormProvider>
                ),
              },
            ].filter((item) =>
              provider?.providerLiabilityInsurances?.find((liability) => liability?.id + "" == liabilityinsuranceId + "")
                ?.document != null
                ? item
                : showDocumentTab
                  ? item
                  : item.key !== "document"
            )}
          />
          <ProviderDocumentModal
            providerId={providerId}
            isDocumentModalOpen={isDocumentModalOpen}
            setIsDocumentModalOpen={setIsDocumentModalOpen}
            selectedDocument={selectedDocument}
            setSelectedDocument={setSelectedDocument}
            handleExistingDocumentSubmit={handleExistingDocumentSubmit}
          />
          <StickyFooter
            hasPermission={true}
            onSubmit={async () => {
              const hasDocumentValues =
                documentMethods.getValues("alertDays") ||
                documentMethods.getValues("expirationDate") ||
                documentMethods.getValues("name") ||
                documentMethods.getValues("file");

              const handleProviderLiabilityInsuranceSubmit = async (
                documentId?: string
              ): Promise<void> => {
                methods.handleSubmit(
                  async (data: ProviderLiabilityInsuranceRequestType) => {
                    if (!!providerId) {
                      spinnerContext?.showSpinner();
                      await updateProviderLiabilityInsurance({
                        ...data,
                        providerId: providerId,
                        ...(documentId && { documentId }),
                      });
                      spinnerContext?.hidespinner();
                      toastContext?.openSuccessNotification({
                        message: "LiabilityInsurance saved successfully",
                        placement: "topRight",
                      });
                      !!toggleDrawer && toggleDrawer();
                    }
                  }
                )();
              };

              const handleFileUpload = async (
                key: string,
                file?: File
              ): Promise<void> => {
                if (!!file && key) {
                  try {
                    await fileAxios.put(getFileUrl(key), file);
                    toastContext?.openSuccessNotification({
                      message: `Document saved successfully`,
                      placement: "topRight",
                    });
                  } catch (error) {
                    captureException(error);
                    toastContext?.openErrorNotification({
                      message: `Failed to save Document`,
                      placement: "topRight",
                    });
                  }
                }
              };

              if (!hasDocumentValues) {
                handleProviderLiabilityInsuranceSubmit();
              } else {
                const documentId = documentMethods.getValues("documentId");

                const handleDocumentUpdate = async (
                  data: DocumentRequest & { documentId: string }
                ): Promise<void> => {
                  try {
                    const result = await updateDocument({
                      documentId: data.documentId,
                      isVersioned: false,
                      document: {
                        ...data,
                        file: uploadedFile,
                        providerId,
                      },
                    }).unwrap();

                    await handleProviderLiabilityInsuranceSubmit(result?.id);
                    await handleFileUpload(
                      result?.attachment?.key,
                      uploadedFile
                    );
                    spinnerContext?.hidespinner();
                    toastContext?.openSuccessNotification({
                      message: `Document updated successfully`,
                      placement: "topRight",
                    });
                  } catch (error) {
                    spinnerContext?.hidespinner();
                    toastContext?.openErrorNotification({
                      message: `Document update failed`,
                      placement: "topRight",
                    });
                  }
                };

                const handleDocumentSave = async (
                  data: DocumentRequest
                ): Promise<void> => {
                  try {
                    const documentResponse = await saveDocument({
                      document: {
                        ...data,
                        file: uploadedFile,
                        providerId,
                      },
                    }).unwrap();

                    await handleProviderLiabilityInsuranceSubmit(
                      documentResponse?.id
                    );
                    await handleFileUpload(
                      documentResponse?.attachment?.key,
                      uploadedFile
                    );
                  } catch (error) {
                    captureException(error);
                    toastContext?.openErrorNotification({
                      message: `Failed to save Document`,
                      placement: "topRight",
                    });
                  }
                };

                documentMethods.handleSubmit(
                  documentId ? handleDocumentUpdate : handleDocumentSave
                )();
              }
            }}
            onCancel={() => {
              !!toggleDrawer && toggleDrawer();
            }}
          />
        </div>
      </ConfigProvider>
    </IncredableContent>
  );
}
