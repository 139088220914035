import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Card,
  ConfigProvider,
  Input,
  Modal,
  Pagination,
  Form,
  Space,
  Table,
} from "antd";
import { Header } from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  DeleteOutlined,
  EditOutlined,
  HolderOutlined,
  PlusOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { SECTION_TABLE_DEFAULT_PREFERENCES } from "../table-config";
import useSectionFilter from "../../table-filters/section-filters";
import { SectionRequest } from "../../redux/api/section/types";
import { useLocalStorage } from "../../common/localStorage";
import IncredableContent from "../../components/incredable-content";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import {
  useDeleteSectionMutation,
  useSectionChangeSortOrderMutation,
  useUpdateSectionMutation,
} from "../../redux/api/section/section";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragEndEvent, DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import IncredableButton from "../../components/button";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
export default function SectionsList() {
  const [selectedRow, setSelectedRow] = useState<SectionRequest | null>(null);
  const [section, setSection] = useState<SectionRequest[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editSection, setEditSection] = useState<SectionRequest | null>(null);
  const [sectionToDelete, setSectionToDelete] = useState<SectionRequest | null>(
    null
  );
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("section.edit") ?? false),
  );

  const {
    TableFilters,
    filteredSection,
    UseQueryHookResult: { refetch, isFetching, isLoading },
  } = useSectionFilter();

  useEffect(() => {
    setSection(filteredSection || []);
  }, [filteredSection]);

  const [deleteSection] = useDeleteSectionMutation();
  const [updateSection] = useUpdateSectionMutation();
  const [sectionChangeSortOrder] = useSectionChangeSortOrderMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Section-Table-Preferences",
    SECTION_TABLE_DEFAULT_PREFERENCES
  );
  const navigate = useNavigate();

  const { items } = useCollection(section, {
    pagination: { pageSize: tablePreferences.pageSize },
  });

  const handleSectionSortOrderUpdate = useCallback(
    async (newAttributes: SectionRequest[]) => {
      const transformedAttributes = newAttributes.map((item, index) => ({
        sectionId: Number(item?.id),
        sortOrder: index,
      }));
      try {
        await sectionChangeSortOrder(transformedAttributes);
      } catch {
        toastContext?.openErrorNotification({
          message: `Section order change failed`,
          placement: "topRight",
        });
      }
    },
    []
  );

  const handleSectionEditSave = async (values: {
    name: string;
    description: string;
  }) => {
    setIsEditModalOpen(false);
    if (editSection) {
      try {
        spinnerContext?.showSpinner();
        const updatedSection = section
          .map((item) =>
            item.id === editSection.id ? { ...item, ...values } : item
          )
          .find((item) => item?.id === editSection?.id);

        if (!updatedSection) {
          throw new Error("Updated section not found");
        }
        await updateSection({
          id: String(editSection.id),
          ...updatedSection,
        }).unwrap();
        toastContext?.openSuccessNotification({
          message: `Section updated successfully`,
          placement: "topRight",
        });
        setEditSection(null);
      } catch (error) {
        toastContext?.openErrorNotification({
          message: `Section update failed`,
          placement: "topRight",
        });
      } finally {
        spinnerContext?.hidespinner();
      }
    }
  };

  const handleSectionDelete = async () => {
    if (sectionToDelete) {
      try {
        spinnerContext?.showSpinner();
        await deleteSection({ sectionId: sectionToDelete?.id + "" });
        toastContext?.openSuccessNotification({
          message: "Section deleted successfully",
          placement: "top",
        });
        setSection(section.filter((item) => item.id !== sectionToDelete.id));
        setIsDeleteModalOpen(false);
        setSectionToDelete(null);
      } catch (error) {
        toastContext?.openErrorNotification({
          message: "Failed to delete section",
          placement: "top",
        });
      } finally {
        spinnerContext?.hidespinner();
      }
    }
  };

  return (
    <IncredableContent style={{ padding: "0" }}>
      <Card
        styles={{
          header: { border: "none" },
          body: { paddingTop: "0" },
        }}
        title={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              description="FlexField Section is a collection of related FlexFields, that helps organize and manage custom data fileds efficiently within a provider profile."
              actions={
                <Space size="middle">
                  <Button onClick={() => refetch && refetch()}>
                    <ReloadOutlined />
                  </Button>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#2B6BE6",
                      },
                    }}
                  >
                    <Button
                      type="primary"
                      disabled={!hasPermission}
                      onClick={() => navigate("add")}
                      icon={<PlusOutlined />}
                    >
                      Add New Section
                    </Button>
                  </ConfigProvider>
                </Space>
              }
            >
              FlexField Sections{" "}
            </Header>
          </div>
        }
      >
        <Space
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "10px",
          }}
        >
          {TableFilters}
          <Pagination />
        </Space>
        <DragableTable
          dataSource={[...items]}
          onUpdateOrder={handleSectionSortOrderUpdate}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          isFetching={isFetching}
          isLoading={isLoading}
          onEdit={(record) => {
            setEditSection(record);
            setIsEditModalOpen(true);
          }}
          onDelete={(record) => {
            setSectionToDelete(record);
            setIsDeleteModalOpen(true);
          }}
        />
      </Card>

      <Modal
        title="Edit Section"
        open={isEditModalOpen}
        onCancel={() => setIsEditModalOpen(false)}
        footer={null}
        zIndex={1000}
      >
        {editSection && (
          <Form
            layout="vertical"
            initialValues={{
              name: editSection.name,
              description: editSection.description,
            }}
            onFinish={handleSectionEditSave}
          >
            <Form.Item
              label="Section Name"
              name="name"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: "Description is required" }]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item>
              <Space style={{ marginTop: 16 }}>
                <Button onClick={() => setIsEditModalOpen(false)}>
                  Cancel
                </Button>
                <IncredableButton
                  style={{ backgroundColor: "#598BEB" }}
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </IncredableButton>
              </Space>
            </Form.Item>
          </Form>
        )}
      </Modal>

      <Modal
        title={
          <Space>
            <ExclamationCircleOutlined style={{ color: "#faad14" }} />
            Delete Section
          </Space>
        }
        open={isDeleteModalOpen}
        onOk={handleSectionDelete}
        onCancel={() => {
          setIsDeleteModalOpen(false);
          setSectionToDelete(null);
        }}
        okText="Delete"
        okButtonProps={{ danger: true }}
        zIndex={1000}
      >
        <p>
          Are you sure you want to delete the section "{sectionToDelete?.name}"?
        </p>
        <p>This action cannot be undone.</p>
      </Modal>
    </IncredableContent>
  );
}

interface DragableTableProps {
  dataSource: SectionRequest[];
  onUpdateOrder: (newAttributes: SectionRequest[]) => void;
  selectedRow: SectionRequest | null;
  setSelectedRow: React.Dispatch<React.SetStateAction<SectionRequest | null>>;
  isLoading: boolean;
  isFetching: boolean;
  onEdit: (record: SectionRequest) => void;
  onDelete: (record: SectionRequest) => void;
}

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: any;
}

const RowContext = React.createContext<RowContextProps>({});

const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: "move" }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const DragableTable: React.FC<DragableTableProps> = ({
  dataSource,
  onUpdateOrder,
  selectedRow,
  setSelectedRow,
  isLoading,
  isFetching,
  onEdit,
  onDelete,
}) => {
  const [tableData, setTableData] = useState<SectionRequest[]>([]);

  useEffect(() => {
    setTableData(
      dataSource.map((item, index) => ({ ...item, sortOrder: index }))
    );
  }, [dataSource]);
  const navigate = useNavigate();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("section.edit") ?? false),
  );

  const onDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (active.id !== over?.id && over) {
        setTableData((prevState) => {
          const activeIndex = prevState.findIndex(
            (item) => item.id === active.id
          );
          const overIndex = prevState.findIndex((item) => item.id === over.id);
          const newData = arrayMove(prevState, activeIndex, overIndex).map(
            (item, index) => ({ ...item, sortOrder: index })
          );
          onUpdateOrder(newData);
          return newData;
        });
      }
    },
    [onUpdateOrder]
  );

  const columns = [
    {
      key: "sort",
      align: "center" as const,
      width: 80,
      render: () => <DragHandle />,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text: string, record: SectionRequest) => (
        <NavLink to={`${record.id}/edit`}>{text}</NavLink>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Type",
      dataIndex: "isFacility",
      render: (text: string, record: SectionRequest) => <span>{record?.isFacility === false ? "Provider" : "Facility"}</span>,
    },
    {
      title: "Actions",
      key: "actions",
      width: 300,
      render: (_: any, record: SectionRequest) => (
        <Space>
          <Button
            disabled={!hasPermission}
            icon={<EditOutlined />}
            onClick={() => navigate(`${record.id}/edit`)}
          >
            Edit
          </Button>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => onDelete(record)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        items={tableData.map((item) => item?.id || "")}
        strategy={verticalListSortingStrategy}
      >
        <Table
          loading={isLoading || isFetching}
          rowKey="id"
          components={{ body: { row: Row } }}
          columns={columns}
          dataSource={tableData}
          bordered
          size="small"
          pagination={false}
          onRow={(record) => ({
            onClick: () => setSelectedRow(record),
            style: {
              backgroundColor:
                selectedRow?.id === record.id ? "#d9d9d9" : "inherit",
            },
          })}
        />
      </SortableContext>
    </DndContext>
  );
};

const Row: React.FC<
  { "data-row-key": string } & React.HTMLAttributes<HTMLTableRowElement>
> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    isDragging,
  } = useSortable({ id: props["data-row-key"] });

  const style: React.CSSProperties = useMemo(
    () => ({
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition: isDragging ? "none" : "transform 0s ease-out",
      ...(isDragging ? { position: "relative", zIndex: 100 } : {}),
    }),
    [props.style, transform, isDragging]
  );

  const contextValue = useMemo(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners]
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};
