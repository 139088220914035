import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    PlusOutlined,
    ReloadOutlined,
} from "@ant-design/icons";
import IncredableButton from "../../components/button";
import {
    useDeleteCMECredentialTypeMutation,
    useGetAllCMECredentialTypeQuery,
} from "../../redux/api/provider/provider";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { CMECredentialType } from "../../redux/api/provider/types";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import { useLocalStorage } from "../../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
    CMECREDENTIALTYPE_CONTENT_DISPLAY_OPTIONS,
    CMECREDENTIALTYPE_TABLE_DEFAULT_PREFERENCES,
    TablePreferences,
} from "../table-config";

export default function CMECredentialTypeList() {
    const navigate = useNavigate();
    const [selectedRows, setSelectedRows] = useState<CMECredentialType[]>([]);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const spinnerContext = useContext(SpinnerContext);
    const toastContext = useContext(ToastContext);
    const [deleteCMECredentialType] = useDeleteCMECredentialTypeMutation();

    const {
        data: cmeCredentialType,
        isLoading,
        isFetching,
        error,
        refetch,
    } = useGetAllCMECredentialTypeQuery();

    const [tablePreferences, setTablePreferences] = useLocalStorage(
        "RD-CME Credential Type-Table-Preferences",
        CMECREDENTIALTYPE_TABLE_DEFAULT_PREFERENCES
    );

    const { items, collectionProps, paginationProps } = useCollection(
        cmeCredentialType ?? [],
        {
            pagination: { pageSize: tablePreferences?.pageSize },
        }
    );

    if (error) {
        return <div>Error loading cme credential type</div>;
    }

    return (
        <>
            <IncredableButton
                style={{
                    margin: "12px 0 0 32px",
                    padding: "12px",
                }}
                secondary
                onClick={() => navigate(-1)}
            >
                Go back
            </IncredableButton>
            <div style={{ padding: "32px 32px 0 32px" }}    >
                <Table
                    loading={isFetching || isLoading}
                    onSelectionChange={({ detail }) => {
                        setSelectedRows([...detail.selectedItems]);
                    }}
                    onRowClick={(onRowClickDetails) => {
                        const selectedItem = onRowClickDetails?.detail?.item;
                        if (selectedItem) {
                            if (selectedRows?.at(0)?.id === selectedItem?.id) {
                                setSelectedRows([]);
                            } else {
                                setSelectedRows([selectedItem]);
                            }
                        }
                    }}
                    selectedItems={selectedRows || []}
                    columnDefinitions={[
                        {
                            id: "name",
                            header: <div>Name</div>,
                            cell: (item) => (
                                <NavLink to={`${item.id}/edit`}>{item?.name}</NavLink>
                            ),
                        },
                        {
                            id: "creditsRequired",
                            header: <div>Credits Required</div>,
                            cell: (item) => item?.creditsRequired
                        },
                        {
                            id: "renewalValue",
                            header: <div>Renewal Value</div>,
                            cell: (item) => item?.renewalValue
                        },
                        {
                            id: "renewalUnit",
                            header: <div>Renewal Unit</div>,
                            cell: (item) => item?.renewalUnit
                        },
                        {
                            id: "description",
                            header: <div>Description</div>,
                            cell: (item) => item?.description
                        },

                    ]}
                    items={items}
                    pagination={<Pagination {...paginationProps} />}
                    preferences={
                        <TablePreferences
                            preferences={tablePreferences}
                            setPreferences={(preferences) => {
                                setTablePreferences(preferences);
                            }}
                            contentDisplayOptions={CMECREDENTIALTYPE_CONTENT_DISPLAY_OPTIONS}
                        />
                    }
                    loadingText="Loading resources"
                    selectionType="single"
                    trackBy="id"
                    header={
                        <div style={{ marginTop: "8px", marginBottom: "12px" }}>
                            <Header
                                actions={
                                    <Space size="middle">
                                        <Button
                                            onClick={() => {
                                                !!refetch && refetch();
                                            }}
                                        >
                                            <ReloadOutlined />
                                        </Button>
                                        <Dropdown
                                            menu={{
                                                disabled: selectedRows?.length !== 1,
                                                items: [
                                                    {
                                                        label: "Edit",
                                                        key: "edit",
                                                        icon: <EditOutlined />,
                                                        onClick: () => {
                                                            navigate(`${selectedRows?.at(0)?.id}/edit`);
                                                        },
                                                    },
                                                    {
                                                        label: "Delete",
                                                        key: "delete",
                                                        onClick: () => setDeleteModalOpen(true),
                                                        icon: <DeleteOutlined />,
                                                        danger: true,
                                                    },
                                                ],
                                            }}
                                        >
                                            <Button>
                                                Actions
                                                <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                        <ConfigProvider
                                            theme={{
                                                token: {
                                                    colorPrimary: "#2B6BE6",
                                                },
                                            }}
                                        >
                                            <Button
                                                onClick={() => navigate(`add`)}
                                                type="primary"
                                                icon={<PlusOutlined />}
                                            >
                                                Add CME Credential Type
                                            </Button>
                                        </ConfigProvider>
                                    </Space>
                                }
                            >
                                <Typography.Title level={3} style={{ marginTop: "0" }}>
                                    CME Credential Type{" "}
                                    <Typography.Text
                                        type="secondary"
                                        style={{ fontSize: "16px" }}
                                    >{`(${cmeCredentialType?.length || 0})`}</Typography.Text>
                                </Typography.Title>
                            </Header>
                        </div>
                    }
                    {...collectionProps}
                    columnDisplay={tablePreferences?.contentDisplay}
                    wrapLines={tablePreferences?.wrapLines}
                    stripedRows={tablePreferences?.stripedRows}
                    contentDensity={tablePreferences?.contentDensity}
                    stickyColumns={tablePreferences?.stickyColumns}
                />
                <DeleteAlertModal
                    visible={isDeleteModalOpen}
                    action={async () => {
                        if (selectedRows?.at(0)?.id) {
                            setDeleteModalOpen(false);
                            spinnerContext?.showSpinner();
                            try {
                                await deleteCMECredentialType({
                                    id: String(selectedRows?.at(0)?.id),
                                }).unwrap();
                                toastContext?.openSuccessNotification({
                                    message: `CME Credential Type deleted successfully`,
                                    placement: "topRight",
                                });
                            } catch (error: any) {
                                toastContext?.openErrorNotification({
                                    message: error?.data?.errorMessage || `Failed to delete CME credential type`,
                                    placement: "topRight"
                                })
                            }
                            spinnerContext?.hidespinner();
                            setSelectedRows([]);
                        }
                    }}
                    closeModal={() => setDeleteModalOpen(false)}
                    header="Delete CME Credential Type"
                    content={
                        <Typography.Text>
                            CME Credential Type:{" "}
                            <Typography.Text>{selectedRows?.at(0)?.name}</Typography.Text>
                        </Typography.Text>
                    }
                    description="Are you sure you want to delete this affiliation status?"
                />
            </div>
        </>
    );
}
