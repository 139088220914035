import { Col, Space, Form, Flex, Divider, Row, Modal, Card } from "antd";
import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import IncredableButton from "../../../components/button";
import RHFTextArea from "../../../components/RHF/RHFTextArea";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { SpinnerContext } from "../../../context/spinner/spinner";
import { ToastContext } from "../../../context/toast/incredable-toast";
import RHFSelect from "../../../components/RHF/RHFSelect";
import { useGetAllCategoryQuery } from "../../../redux/api/category/category";
import { useGetBasicAllProvidersQuery } from "../../../redux/api/provider/provider";
import {
  ApproveDocumentRequest,
  DocumentRequestResponse,
} from "../../../redux/api/documentrequest/types";
import RHFDatePicker from "../../../components/RHF/RHFDatePicker";
import PDFTronViewer from "../../../components/pdftron-viewer/pdftron-viewer";
import { useUpdateDocumentShareAndMarkAsApproveMutation } from "../../../redux/api/documentrequest/documentrequest";
import { alertReminderOptions } from "../../../common/constants";

interface AddNewDocumentModalProps {
  addNewDocumentModal: boolean;
  handleCloseAddNewDocument: () => void;
  document: DocumentRequestResponse | undefined;
}
type FormInputType = ApproveDocumentRequest;
const AddNewDocument: React.FC<AddNewDocumentModalProps> = ({
  addNewDocumentModal,
  handleCloseAddNewDocument,
  document,
}) => {
  const methods = useForm<FormInputType>();

  const spinnerContext = React.useContext(SpinnerContext);
  const toastContext = React.useContext(ToastContext);

  const { data: categories } = useGetAllCategoryQuery();
  const { data: providers } = useGetBasicAllProvidersQuery();

  const [updateDocumentShareAndMarkAsApprove] =
    useUpdateDocumentShareAndMarkAsApproveMutation();
  useEffect(() => {
    if (document) {
      methods.setValue("name", document?.title);
      methods.setValue("categoryId", document?.category?.id);
      methods.setValue("providerId", document?.provider.id);
      methods.setValue("notes", document?.description);
    }
  }, [document]);

  return (
    <Modal
      style={{ top: "15px" }}
      width="85%"
      centered
      open={addNewDocumentModal}
      title="Approve Document Request"
      onCancel={handleCloseAddNewDocument}
      footer={false}
    >
      <FormProvider {...methods}>
        <Form
          layout="vertical"
          name="approveDocumentShareRequest"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            if (document) {
              handleCloseAddNewDocument();
              spinnerContext?.showSpinner();
              await updateDocumentShareAndMarkAsApprove({
                id: document?.id,
                name: data?.name,
                expirationDate: data?.expirationDate,
                alertDays: data?.alertDays,
                notes: data?.notes,
                categoryId: data?.categoryId,
                attachment: document?.attachment,
              });
              spinnerContext?.showSpinner();
              toastContext?.openSuccessNotification({
                message: "Document Share Request Updated successfully",
                placement: "topRight",
              });
            }
          })}
          autoComplete="off"
        >
          <Divider />
          <Row gutter={16}>
            <Col flex="380px">
              <Card title="Add Document">
                <RHFSelect
                  formItemProps={{ label: "Provider" }}
                  controllerProps={{ name: "providerId" }}
                  selectProps={{
                    allowClear: true,
                    showSearch: true,
                    disabled: true,
                    placeholder: "Search to Select",
                    options: providers?.map((provider) => ({
                      label: `${provider.firstName} ${provider.lastName}`,
                      value: provider.id,
                    })),
                  }}
                />

                <RHFTextField
                  formItemProps={{
                    label: "Document Name",
                  }}
                  inputProps={{}}
                  controllerProps={{
                    name: `name`,
                  }}
                  rules={{ required: "This field is required" }}
                />

                <RHFSelect
                  formItemProps={{ label: "Category" }}
                  controllerProps={{
                    name: `categoryId`,
                  }}
                  selectProps={{
                    allowClear: true,
                    showSearch: true,
                    disabled: document?.category ? true : false,
                    placeholder: "Search to Select",
                    options: categories?.map((category) => {
                      return {
                        label: category?.name,
                        value: category?.id,
                      };
                    }),
                  }}
                />

                <RHFDatePicker
                  formItemProps={{
                    label: "Expiration Date",
                  }}
                  controllerProps={{
                    name: `expirationDate`,
                  }}
                />
                <RHFSelect
                  formItemProps={{ label: "Alert Days" }}
                  controllerProps={{ name: "alertDays" }}
                  selectProps={{
                    allowClear: true,
                    showSearch: true,
                    style: { width: "100%" },
                    placeholder: "Search to Select",
                    options: alertReminderOptions?.map((alert) => {
                      return {
                        label: alert?.label,
                        value: alert?.value,
                      };
                    }),
                  }}
                />

                <RHFTextArea
                  formItemProps={{
                    label: "Notes",
                  }}
                  textAreaProps={{}}
                  controllerProps={{
                    name: `description`,
                  }}
                />
              </Card>
            </Col>

            <Col flex="auto">
              <PDFTronViewer
                attachmentKey={document?.attachment?.key}
                replaceFile={undefined}
              />
            </Col>
          </Row>
          <Flex gap="small" wrap justify="end" style={{ marginTop: "15px" }}>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  methods.reset();
                  handleCloseAddNewDocument();
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Approve
              </IncredableButton>
            </Space>
          </Flex>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default AddNewDocument;
