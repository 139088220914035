import { Col, Divider, Form, Row, ConfigProvider, Tabs } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import {
  useGetAllFacilityTypesQuery,
  useGetFacilityQuery,
  useUpdateFacilityMutation,
} from "../../redux/api/facility/facility";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import IncredableContent from "../../components/incredable-content";
import { FacilityRequest } from "../../redux/api/facility/types";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { Countries, USStatesList } from "../../common/constants";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import RHFPhoneNumber from "../../components/RHF/RHFPhonenumber";
import { useGetAllFacilityGroupQuery } from "../../redux/api/facilitygroup/facilityGroup";
import StickyFooter from "../../components/StickyFooter";

type FormInputType = FacilityRequest;

export default function EditFacility() {
  const methods = useForm<FormInputType>();
  const { data: facilityType } = useGetAllFacilityTypesQuery();
  const { data: facilityGroup } = useGetAllFacilityGroupQuery();
  const { facilityId } = useParams();
  const {
    data: facility,
    isLoading,
    isFetching,
  } = useGetFacilityQuery(
    !!facilityId
      ? {
          id: facilityId,
        }
      : skipToken
  );
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("facility.edit") ?? false)
  );
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (facility) {
      methods.setValue("name", facility?.name ?? "");
      methods.setValue("npiNumber", facility?.npiNumber ?? "");
      methods.setValue("notes", facility?.notes ?? "");
      methods.setValue("facilityTypeId", facility?.facilityType?.id ?? "");
      methods.setValue(
        "facilityGroupId",
        facility?.facilityGroup?.at(0)?.id ?? ""
      );
      methods.setValue("taxId", facility?.taxId ?? "");
      methods.setValue("cliaId", facility?.cliaId ?? "");
      methods.setValue("immunizationId", facility?.immunizationId ?? "");
      methods.setValue("taxonomyCode", facility?.taxonomyCode ?? "");
      methods.setValue("phoneNumber", facility?.phoneNumber ?? "");
      methods.setValue("email", facility?.email ?? "");
      methods.setValue("pocName", facility?.pocName ?? "");
      methods.setValue("pocEmail", facility?.pocEmail ?? "");
      methods.setValue("pocFax", facility?.pocFax ?? "");
      methods.setValue("medicaidId", facility?.medicaidId ?? "");
      methods.setValue("doingBusinessName", facility?.doingBusinessName ?? "");
      methods.setValue("medicareNumber", facility?.medicareNumber ?? "");
      methods.setValue(
        "secondaryNpiNumber",
        facility?.secondaryNpiNumber ?? ""
      );
      methods.setValue(
        "facilityAddress.addressLane1",
        facility?.facilityAddress?.addressLane1
      );
      methods.setValue(
        "facilityAddress.addressLane2",
        facility?.facilityAddress?.addressLane2
      );
      methods.setValue(
        "facilityAddress.state",
        facility?.facilityAddress?.state
      );
      methods.setValue("facilityAddress.city", facility?.facilityAddress?.city);
      methods.setValue(
        "facilityAddress.zipcode",
        facility?.facilityAddress?.zipcode
      );
      methods.setValue(
        "facilityAddress.country",
        facility?.facilityAddress?.country
      );
    }
  }, [methods, facility]);

  const [updateFacility] = useUpdateFacilityMutation();

  return (
    <IncredableContent
      spinning={isLoading || isFetching}
      style={{
        position: "relative",
        paddingTop: "5px",
        paddingBottom: "5px",
      }}
      breadcrumbGroupProps={{
        items: [
          {
            text: "Facility",
            href: "/managefacilities",
          },
          {
            text: "Edit Facility",
            href: "#",
          },
        ],
      }}
    >
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "rgb(29, 26, 31)",
              verticalItemPadding: "0",
              cardBg: "#F5F5F5",
              colorBgContainer: "#2B6BE6",
              inkBarColor: "none ",
              colorBorderSecondary: "#c6c6cd",
              fontSize: 16,
            },
          },
        }}
      >
        <Tabs
          defaultActiveKey="editFacility"
          size="large"
          items={[
            {
              label: (
                <span style={{ fontWeight: "600", padding: "0" }}>
                  Edit Facility
                </span>
              ),
              key: "editFacility",
              children: (
                <FormProvider {...methods}>
                  <Form
                    name="addfacility"
                    layout="vertical"
                    onFinish={methods.handleSubmit(
                      async (data: FormInputType) => {
                        if (!!facilityId) {
                          spinnerContext?.showSpinner();
                          try {
                            await updateFacility({
                              id: facilityId,
                              name: data?.name,
                              npiNumber: data?.npiNumber,
                              description: data?.description,
                              facilityTypeId: data?.facilityTypeId,
                              facilityGroupId: data?.facilityGroupId,
                              taxId: data?.taxId,
                              cliaId: data?.cliaId,
                              phoneNumber: data?.phoneNumber,
                              email: data?.email,
                              notes: data?.notes,
                              pocName: data?.pocName,
                              pocEmail: data?.pocEmail,
                              pocFax: data?.pocFax,
                              medicaidId: data?.medicaidId,
                              doingBusinessName: data?.doingBusinessName,
                              medicareNumber: data?.medicareNumber,
                              secondaryNpiNumber: data?.secondaryNpiNumber,
                              immunizationId: data?.immunizationId,
                              taxonomyCode: data?.taxonomyCode,
                              facilityAddress: {
                                addressLane1:
                                  data?.facilityAddress?.addressLane1,
                                addressLane2:
                                  data?.facilityAddress?.addressLane2,
                                city: data?.facilityAddress?.city,
                                state: data?.facilityAddress?.state,
                                zipcode: data?.facilityAddress?.zipcode,
                                country: data?.facilityAddress?.country,
                              },
                            }).unwrap();
                            toastContext?.openInfoNotification({
                              message: `Facility update successfully`,
                              placement: "topRight",
                            });
                          } catch (error: any) {
                            if (error && error?.status === 400) {
                              toastContext?.openErrorNotification({
                                message: error?.data?.errorMessage,
                                placement: "topRight",
                              });
                            } else {
                              toastContext?.openErrorNotification({
                                message: `Failed to update Facility`,
                                placement: "topRight",
                              });
                            }
                          }
                          spinnerContext?.hidespinner();
                          navigate(-1);
                        }
                      }
                    )}
                    style={{
                      height: "calc(100vh - 250px)",
                      overflowY: "scroll",
                      scrollbarWidth: "none",
                      paddingBottom: "50px",
                    }}
                    autoComplete="off"
                  >
                    <Form.List name="facilities" initialValue={[{}]}>
                      {(fields, { add }) => {
                        return (
                          <>
                            {fields.map(
                              ({ key, name, ...restField }, index) => (
                                <div key={key}>
                                  {index > 0 && <Divider />}
                                  <Row
                                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                                  >
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Name",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `name`,
                                        }}
                                        rules={{
                                          required: "This field is required",
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "NPI Number",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `npiNumber`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFSelect
                                        formItemProps={{
                                          label: "Facility Type",
                                        }}
                                        controllerProps={{
                                          name: `facilityTypeId`,
                                        }}
                                        selectProps={{
                                          allowClear: true,
                                          showSearch: true,
                                          style: { width: "100%" },
                                          placeholder: "Search to Select",
                                          options: facilityType?.map((type) => {
                                            return {
                                              label: type?.name,
                                              value: type?.id,
                                            };
                                          }),
                                          onModifyClick: () =>
                                            navigate("/facilitytype"),
                                        }}
                                        rules={{
                                          required: "This field is required",
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFSelect
                                        formItemProps={{
                                          label: "Facility Group",
                                        }}
                                        controllerProps={{
                                          name: `facilityGroupId`,
                                        }}
                                        selectProps={{
                                          allowClear: true,
                                          showSearch: true,
                                          style: { width: "100%" },
                                          placeholder: "Search to Select",
                                          options: facilityGroup?.map(
                                            (type) => {
                                              return {
                                                label: type?.name,
                                                value: type?.id,
                                              };
                                            }
                                          ),
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Tax ID",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `taxId`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "CLIA ID",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `cliaId`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Immunization ID",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `immunizationId`,
                                        }}
                                      />
                                    </Col>

                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Taxonomy Code",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `taxonomyCode`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFPhoneNumber
                                        formItemProps={{
                                          label: "Phone Number",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `phoneNumber`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Mailing Address",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `email`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Doing Business As Name",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `doingBusinessName`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Medicare Number",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `medicareNumber`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Secondary NPI Number",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `secondaryNpiNumber`,
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Divider orientation="left">
                                    Office Point of Contact Info
                                  </Divider>
                                  <Row
                                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                                  >
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Name",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `pocName`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Email",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `pocEmail`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Fax",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `pocFax`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Medicaid",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `medicaidId`,
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Divider orientation="left">
                                    Facility Address
                                  </Divider>
                                  <Row
                                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                                  >
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Address Line 1",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `facilityAddress.addressLane1`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Address Line 2",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `facilityAddress.addressLane2`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "City",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `facilityAddress.city`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFSelect
                                        formItemProps={{ label: "State" }}
                                        controllerProps={{
                                          name: `facilityAddress.state`,
                                        }}
                                        selectProps={{
                                          allowClear: true,
                                          showSearch: true,
                                          style: { width: "100%" },
                                          placeholder: "Select state",
                                          filterOption: (input, option) =>
                                            (option?.value ?? "")
                                              .toString()
                                              .toLowerCase()
                                              .includes(input?.toLowerCase()),
                                          options: USStatesList?.map(
                                            (state) => {
                                              return {
                                                value: state?.value,
                                              };
                                            }
                                          ),
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFSelect
                                        formItemProps={{ label: "Country" }}
                                        controllerProps={{
                                          name: `facilityAddress.country`,
                                        }}
                                        selectProps={{
                                          allowClear: true,
                                          showSearch: true,
                                          style: { width: "100%" },
                                          placeholder: "Select country",
                                          filterOption: (input, option) =>
                                            (option?.value ?? "")
                                              .toString()
                                              .toLowerCase()
                                              .includes(input?.toLowerCase()),
                                          options: Countries?.map((country) => {
                                            return {
                                              value: country?.value,
                                            };
                                          }),
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Zip",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `facilityAddress.zipcode`,
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Divider></Divider>
                                  <Col span={24}>
                                    <RHFTextArea
                                      formItemProps={{
                                        label: "Notes",
                                      }}
                                      controllerProps={{
                                        name: `notes`,
                                      }}
                                    />
                                  </Col>
                                </div>
                              )
                            )}
                          </>
                        );
                      }}
                    </Form.List>
                    <StickyFooter
                      hasPermission={true}
                      onSubmit={() => ""}
                      onCancel={() => {
                        navigate(-1);
                      }}
                    />
                  </Form>
                </FormProvider>
              ),
            },
          ]}
        />
      </ConfigProvider>
    </IncredableContent>
  );
}
