import { Col, Divider, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { WorkflowRequest } from "../redux/api/workflow/types";
import { useFormContext } from "react-hook-form";
import {
  useGetAllWorkflowStatusQuery,
  useGetAllWorkflowTemplateQuery,
} from "../redux/api/workflow/workflow";
import { useGetAllMembersQuery } from "../redux/api/manageadmin/manageadmin";
import { useGetBasicAllProvidersQuery } from "../redux/api/provider/provider";
import { useGetFacilitiesQuery } from "../redux/api/facility/facility";
import RHFTextField from "../components/RHF/RHFTextField";
import RHFSelect from "../components/RHF/RHFSelect";
import RHFDatePicker from "../components/RHF/RHFDatePicker";
import RHFTextArea from "../components/RHF/RHFTextArea";
import StepThree from "../workflow/modify/step-three";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetPrivilegeWorkflowQuery } from "../redux/api/privileges/privileges";
import TasksList from "./tasks-list";

export default function AddWorkflowV2() {
  const methods = useFormContext();
  const [formData, setFormData] = useState<WorkflowRequest>();
  const { privilegeId } = useParams();
  const { data: workflow } = useGetPrivilegeWorkflowQuery(
    !!privilegeId ? { privilegeId } : skipToken
  );
  const { data: members = [] } = useGetAllMembersQuery();
  const { data: workflowStatuses, isLoading } = useGetAllWorkflowStatusQuery();
  const navigate = useNavigate();

  const { data: providers = [] } = useGetBasicAllProvidersQuery();
  const { data: facilities } = useGetFacilitiesQuery();
  const { data: workflowTemplates } = useGetAllWorkflowTemplateQuery();

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          <RHFTextField
            formItemProps={{
              label: "Workflow Name",
            }}
            inputProps={{}}
            controllerProps={{
              name: `name`,
            }}
            rules={{ required: "This field is required" }}
          />
        </Col>
        <Col span={8}>
          <RHFSelect
            formItemProps={{
              label: "Workflow Status",
            }}
            controllerProps={{
              name: "workflowStatusId",
            }}
            selectProps={{
              allowClear: true,
              showSearch: true,
              placeholder: "Select Workflow Status",
              options: workflowStatuses?.map((status) => {
                return {
                  label: status?.name,
                  value: status?.id,
                };
              }),
              onModifyClick: () => {
                navigate("/workflowstatus");
              },
            }}
          />
        </Col>
        <Col span={8}>
          <RHFSelect
            formItemProps={{ label: "Assigned To" }}
            controllerProps={{
              name: "adminId",
            }}
            selectProps={{
              allowClear: true,
              showSearch: true,
              placeholder: "",
              options: members?.map((member) => {
                return {
                  label: `${member?.firstName} ${member?.lastName}`,
                  value: member?.id,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <RHFSelect
            formItemProps={{ label: "Provider" }}
            controllerProps={{
              name: `providerId`,
            }}
            selectProps={{
              allowClear: true,
              showSearch: true,
              placeholder: "Search to Select",
              options: providers?.map((provider) => {
                return {
                  label: `${provider?.firstName} ${provider?.lastName}`,
                  value: provider?.id,
                };
              }),
            }}
            rules={{ required: "This field is required" }}
          />
        </Col>
        <Col span={8}>
          <RHFSelect
            formItemProps={{ label: "Workflow Template" }}
            controllerProps={{
              name: "workflowTemplateId",
            }}
            selectProps={{
              allowClear: true,
              showSearch: true,
              placeholder: "",
              options: workflowTemplates?.map((workflowtemplate) => ({
                label: workflowtemplate?.name,
                value: workflowtemplate?.id,
              })),
            }}
          />
        </Col>
        <Col span={8}>
          <RHFSelect
            formItemProps={{ label: "Facility" }}
            controllerProps={{
              name: "facilityId",
            }}
            selectProps={{
              allowClear: true,
              showSearch: true,
              placeholder: "Search to Select",
              options: facilities?.map((facility) => ({
                label: facility.name,
                value: facility.id,
              })),
            }}
          />
        </Col>
        <Col span={8}>
          <RHFDatePicker
            formItemProps={{
              label: "Start Date",
            }}
            controllerProps={{
              name: "startDate",
            }}
            datePickerProps={{
              placeholder: "Select Start Date",
            }}
          />
        </Col>
        <Col span={8}>
          <RHFDatePicker
            formItemProps={{
              label: "Due Date",
            }}
            controllerProps={{
              name: "dueDate",
            }}
            datePickerProps={{
              placeholder: "Select Due Date",
            }}
          />
        </Col>
        <Col span={8}>
          <RHFDatePicker
            formItemProps={{
              label: "Completed Date",
            }}
            controllerProps={{
              name: "completedDate",
            }}
            datePickerProps={{
              placeholder: "Completed Date",
            }}
          />
        </Col>
        <Col span={24}>
          <RHFTextArea
            formItemProps={{ label: "Notes" }}
            controllerProps={{ name: "notes" }}
          />
        </Col>
      </Row>
      {!!workflow ? (
        <TasksList />
      ) : (
        <>
          <Divider orientation="left">Tasks</Divider>
          <StepThree
            workflowTemplateId={formData?.workflowTemplateId}
            methods={methods}
          />
        </>
      )}
    </>
  );
}
