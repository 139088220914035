import { Col, Divider, Flex, Form, Row, Space, theme, Typography } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ToastContext } from "../../context/toast/incredable-toast";
import { SpinnerContext } from "../../context/spinner/spinner";
import IncredableContent from "../../components/incredable-content";
import IncredableButton from "../../components/button";
import { PrivilegeTypeRequest } from "../../redux/api/privileges/types";
import { useCreatePrivilegeTypeMutation } from "../../redux/api/privileges/privileges";

const { useToken } = theme;

type FormInputType = PrivilegeTypeRequest;
export default function AddPrivilegeType() {
    const navigate = useNavigate();
    const methods = useForm<FormInputType>();
    const spinnerContext = useContext(SpinnerContext);
    const toastContext = useContext(ToastContext);
    const [addPrivilegeType] = useCreatePrivilegeTypeMutation();

    return (
        <IncredableContent
            breadcrumbGroupProps={{
                items: [
                    {
                        text: "Privilege Type",
                        href: "/privilegetype",
                    },
                    {
                        text: "Add",
                        href: "#",
                    },
                ],
            }}
        >
            <FormProvider {...methods}>
                <Form
                    name="privilegeType"
                    layout="vertical"
                    onFinish={methods.handleSubmit(async (data: FormInputType) => {
                        spinnerContext?.showSpinner();
                        try {
                            await addPrivilegeType({
                                name: data?.name,
                                description: data?.description,
                            }).unwrap();
                            toastContext?.openInfoNotification({
                                message: `Privilege Type saved successfully`,
                                placement: "topRight",
                            });
                        } catch (error: any) {
                            toastContext?.openErrorNotification({
                                message: error?.data?.errorMessage || `Failed to delete Privilege Type`,
                                placement: "topRight"
                            })
                        }
                        spinnerContext?.hidespinner();
                        navigate(-1);
                    })}
                    autoComplete="off"
                >
                    <Flex gap="small" wrap justify="space-between">
                        <Typography.Text
                            strong
                            style={{
                                fontSize: "22px",
                                color: "#1D1A1F",
                                lineHeight: "25px",
                                fontWeight: "700",
                            }}
                        >
                            Privilege Type
                        </Typography.Text>
                        <Space>
                            <IncredableButton
                                secondary
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                Cancel
                            </IncredableButton>
                            <IncredableButton type="primary" htmlType="submit">
                                Save
                            </IncredableButton>
                        </Space>
                    </Flex>
                    <Divider />
                    <Form.List name="privilegetype" initialValue={[{}]}>
                        {(fields, { add }) => {
                            return (
                                <>
                                    {fields.map(({ key, name, ...restField }, index) => (
                                        <div key={key}>
                                            {index > 0 && <Divider />}
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col span={12}>
                                                    <RHFTextField
                                                        formItemProps={{
                                                            label: "Name",
                                                        }}
                                                        inputProps={{}}
                                                        controllerProps={{
                                                            name: `name`,
                                                        }}
                                                        rules={{ required: "This field is required" }}
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <RHFTextField
                                                        formItemProps={{
                                                            label: "Description",
                                                        }}
                                                        inputProps={{}}
                                                        controllerProps={{
                                                            name: `description`,
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </>
                            );
                        }}
                    </Form.List>
                </Form>
            </FormProvider>
        </IncredableContent>
    );
}
