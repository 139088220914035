import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { useContext, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  CalendarOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { ProviderWorkHistoryType } from "../../redux/api/provider/types";
import {
  useDeleteProviderWorkHistoryMutation,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/query";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { useLocalStorage } from "../../common/localStorage";
import {
  TablePreferences,
  WORKHISTORY_CONTENT_DISPLAY_OPTIONS,
  WORKHISTORY_TABLE_DEFAULT_PREFERENCES,
} from "../table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import IncredableDateFormat from "../../common/incredable-date-format";
import { AsYouType } from "libphonenumber-js";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import EditProviderWorkHistory from "../modify/edit-providerworkhistory";

export default function WorkHistoryV2() {
  const { providerId } = useParams();
  const [selectedRows, setSelectedRows] = useState<ProviderWorkHistoryType[]>(
    []
  );
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
    setSelectedRows([]);
  };
  const navigate = useNavigate();
  const [deleteProviderWorkHistory] = useDeleteProviderWorkHistoryMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.delete") ?? false)
  );

  const {
    data: provider,
    isLoading,
    isFetching,
    refetch,
  } = useGetProviderQuery(
    !!providerId
      ? {
        providerId: providerId,
      }
      : skipToken
  );
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Work-History-Table-Preferences",
    WORKHISTORY_TABLE_DEFAULT_PREFERENCES
  );

  const { items, collectionProps, paginationProps } = useCollection(
    provider?.providerWorkHistories ?? [],
    {
      pagination: { pageSize: tablePreferences?.pageSize },
    }
  );

  return (
    <>
      <Table
        loading={isFetching || isLoading}
        onSelectionChange={({ detail }) => {
          setSelectedRows([detail.selectedItems[0]]);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows.at(0)?.id === selectedItem?.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        columnDefinitions={[
          {
            id: "id",
            header: <div>ID</div>,
            cell: (item) => item.id || "-",
            isRowHeader: true,
          },
          {
            id: "name",
            header: <div>Name</div>,
            cell: (item) => (
              <Button
                style={{ margin: "0", padding: "0", height: "0" }}
                onClick={() => {
                  setSelectedRows([item]);
                  toggleDrawer();
                }}
                type="link"
              >
                {item?.name}
              </Button>
            ),
            sortingField: "name",
            sortingComparator: (a, b) =>
              (a?.name ?? "").localeCompare(b?.name ?? ""),
          },
          {
            id: "type",
            header: <div>Type</div>,
            cell: (item) => item?.type || "-",
            sortingField: "type",
            sortingComparator: (a, b) =>
              (a?.type ?? "").localeCompare(b?.type ?? ""),
          },
          {
            id: "startDate",
            header: <div>Start Date</div>,
            cell: (item) =>
              item?.startDate ? (
                <span>
                  <CalendarOutlined /> {IncredableDateFormat(item?.startDate)}
                </span>
              ) : (
                "-"
              ),
            sortingField: "startDate",
          },
          {
            id: "endDate",
            header: <div>End Date</div>,
            cell: (item) =>
              item?.endDate ? (
                <span>
                  <CalendarOutlined /> {IncredableDateFormat(item?.endDate)}
                </span>
              ) : (
                "-"
              ),
            sortingField: "endDate",
          },
          {
            id: "positionHeld",
            header: <div>Position Held</div>,
            cell: (item) => <div> {item?.positionHeld} </div>,
          },
          {
            id: "gapExplanation",
            header: <div>Gap Explanation </div>,
            cell: (item) => <div> {item?.gapExplanation ?? "-"} </div>,
          },
          {
            id: "referenceName",
            header: <div>Reference Name</div>,
            cell: (item) => <div> {item?.referenceName ?? "-"} </div>,
          },
          {
            id: "referenceEmail",
            header: <div>Reference Email</div>,
            cell: (item) => <div> {item?.referenceEmail ?? "-"} </div>,
          },
          {
            id: "referencePhone",
            header: <div>Reference Phone</div>,
            cell: (item) => (
              <div>
                {" "}
                {new AsYouType("US").input(item?.referencePhone ?? "") ??
                  "-"}{" "}
              </div>
            ),
          },
          {
            id: "referenceFax",
            header: <div>Reference Fax</div>,
            cell: (item) => <div> {item?.referenceFax ?? "-"} </div>,
          },
          {
            id: "addressLane1",
            header: <div>Address Line 1</div>,
            cell: (item) => <div> {item?.addressLane1 ?? "-"} </div>,
          },
          {
            id: "addressLane2",
            header: <div>Address Line 2</div>,
            cell: (item) => <div> {item?.addressLane2 ?? "-"} </div>,
          },
          {
            id: "city",
            header: <div>City</div>,
            cell: (item) => <div> {item?.city ?? "-"} </div>,
          },
          {
            id: "state",
            header: <div>State</div>,
            cell: (item) => <div> {item?.state ?? "-"} </div>,
          },
          {
            id: "zip",
            header: <div>Zipcode</div>,
            cell: (item) => <div> {item?.zip ?? "-"} </div>,
          },
          {
            id: "country",
            header: <div>Country</div>,
            cell: (item) => <div> {item?.country ?? "-"} </div>,
          },
        ]}
        items={items}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={WORKHISTORY_CONTENT_DISPLAY_OPTIONS}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <Space size="middle">
                  <Button
                    onClick={() => {
                      !!refetch && refetch();
                    }}
                  >
                    <ReloadOutlined />
                  </Button>
                  <Dropdown
                    menu={{
                      disabled: selectedRows?.length !== 1,
                      items: [
                        {
                          label: hasPermission ? "Edit" : "View",
                          key: "edit",
                          icon: <EditOutlined />,
                          onClick: () => {
                            setIsOpen(true);
                          },
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          onClick: () => setDeleteModalOpen(true),
                          disabled: !hasDeletePermission,
                          icon: <DeleteOutlined />,
                          danger: true,
                        },
                      ],
                    }}
                  >
                    <Button>
                      Actions
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#2B6BE6",
                      },
                    }}
                  >
                    <Button
                      disabled={!hasPermission}
                      onClick={() => {
                        setSelectedRows([]);
                        toggleDrawer();
                      }}
                      type="primary"
                      icon={<PlusOutlined />}
                    >
                      Add
                    </Button>
                  </ConfigProvider>
                </Space>
              }
            >
              <Typography.Title
                level={3}
                style={{ marginTop: "0", fontSize: "22px" }}
              >
                Work History{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${provider?.providerWorkHistories?.length || 0
                  })`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        size="90vw"
      >
        <EditProviderWorkHistory
          key={selectedRows?.at(0)?.id ?? "add"}
          workhistoryId={"" + selectedRows?.at(0)?.id}
          toggleDrawer={toggleDrawer}
        />
      </Drawer>
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (!!providerId && !!selectedRows?.at(0)?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            try {
              await deleteProviderWorkHistory({
                providerId: providerId,
                workHistoryId: selectedRows?.at(0)?.id + "",
              }).unwrap();
              toastContext?.openSuccessNotification({
                message: `Work History deleted successfully`,
                placement: "topRight",
              });
            } catch (error: any) {
              toastContext?.openErrorNotification({
                message: error?.data?.errormessage || `Failed to delete work history`,
                placement: "topRight"
              })
            }
            spinnerContext?.hidespinner();
            setSelectedRows([]);
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete Work History"
        content={
          <Typography.Text>
            Work History Name:{" "}
            <Typography.Text strong>
              {selectedRows?.at(0)?.name}
            </Typography.Text>
          </Typography.Text>
        }
        description="Are you sure you want to delete this work history?"
      />
    </>
  );
}
