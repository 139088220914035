import {
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Row,
  Space,
  theme,
  Typography,
} from "antd";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { SpinnerContext } from "../../../context/spinner/spinner";
import { ToastContext } from "../../../context/toast/incredable-toast";
import IncredableButton from "../../../components/button";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { WorkFlowTemplateRequest } from "../../../redux/api/workflow/types";
import {
  useGetWorkflowTemplateQuery,
  useUpdateWorkflowTemplateMutation,
} from "../../../redux/api/workflow/workflow";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import RHFSelect from "../../../components/RHF/RHFSelect";
import {
  useGetAllTaskStatusQuery,
  useGetAllTaskTypeQuery,
} from "../../../redux/api/task";
import { skipToken } from "@reduxjs/toolkit/query";
import IncredableContent from "../../../components/incredable-content";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../redux/store";

const { useToken } = theme;
type FormInputType = WorkFlowTemplateRequest;

export default function EditWorkFlowTemplate() {
  const navigate = useNavigate();
  const methods = useForm<FormInputType>();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("workflow.edit") ?? false)
  );
  const { templateId: workflowTemplateId } = useParams();
  const { data: workflowtemplate, isLoading } = useGetWorkflowTemplateQuery(
    !!workflowTemplateId ? { id: workflowTemplateId } : skipToken
  );
  const [updateWorkFlowTemplate] = useUpdateWorkflowTemplateMutation();
  const { token } = useToken();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const { data: taskStatuses } = useGetAllTaskStatusQuery();

  const { data: taskTypes } = useGetAllTaskTypeQuery();

  useEffect(() => {
    if (!!workflowtemplate) {
      methods.setValue("name", workflowtemplate?.name ?? "");
      methods.setValue("notes", workflowtemplate?.notes ?? "");

      const transformedTasks = workflowtemplate.tasks.map((task) => ({
        id: task.id,
        name: task.name,
        description: task.description,
        taskTypeId: task.taskType?.id || "",
        taskStatusId: task.taskStatus?.id || "",
      }));

      methods.setValue("tasks", transformedTasks);
    }
  }, [methods, workflowtemplate]);

  const { control } = methods;

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "tasks",
  });

  return (
    <IncredableContent
      spinning={isLoading}
      breadcrumbGroupProps={{
        items: [
          {
            text: "WorkFlow Template",
            href: "/workflowtemplate",
          },
          {
            text: "Edit",
            href: "#",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="editworkflowtemplate"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            spinnerContext?.showSpinner();
            if (!!workflowTemplateId) {
              try {
                await updateWorkFlowTemplate({
                  id: workflowTemplateId,
                  name: data?.name,
                  notes: data?.notes,
                  tasks: data?.tasks,
                }).unwrap();
                toastContext?.openSuccessNotification({
                  message: `Workflow Template saved successfully`,
                  placement: "topRight",
                });
              } catch (error: any) {
                toastContext?.openErrorNotification({
                  message: error?.data?.errorMessage || `Failed to update work flow template`,
                  placement: "topRight"
                })
              }
              spinnerContext?.hidespinner();
              navigate(-1);
            }
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Workflow Template
            </Typography.Text>
            <Space>
              <IncredableButton secondary>
                <NavLink to="/workflowtemplate">Cancel</NavLink>
              </IncredableButton>
              <IncredableButton
                disabled={!hasPermission}
                type="primary"
                htmlType="submit"
              >
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Card>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={8}>
                <RHFTextField
                  formItemProps={{
                    label: "Name",
                  }}
                  inputProps={{}}
                  controllerProps={{
                    name: `name`,
                  }}
                  rules={{ required: "This field is required" }}
                />
              </Col>
              <Col span={8}>
                <RHFTextField
                  formItemProps={{
                    label: "Notes",
                  }}
                  inputProps={{}}
                  controllerProps={{
                    name: `notes`,
                  }}
                />
              </Col>
            </Row>
          </Card>

          <Card>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {fields
                .filter((item) => !item.deleted)
                .map((task, index) => (
                  <Col span={24} key={task.id || index}>
                    <div style={{ marginTop: "20px" }}>
                      {index > 0 && <Divider />}
                      <Row
                        gutter={{
                          xs: 8,
                          sm: 16,
                          md: 24,
                          lg: 32,
                        }}
                      >
                        <Col span={4}>
                          <RHFSelect
                            formItemProps={{
                              label: "Task Type",
                            }}
                            controllerProps={{
                              name: `tasks.${index}.taskTypeId`,
                              defaultValue: task.taskTypeId || "",
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select Task Type",
                              options: taskTypes?.map((type) => ({
                                label: type.name,
                                value: type.id,
                              })),
                            }}
                            rules={{
                              required: "Task Type is required",
                            }}
                          />
                        </Col>

                        <Col span={4}>
                          <RHFTextField
                            formItemProps={{
                              label: "Task Name",
                            }}
                            inputProps={{
                              placeholder: "Enter Task Name",
                            }}
                            controllerProps={{
                              name: `tasks.${index}.name`,
                              defaultValue: task.name || "",
                            }}
                          />
                        </Col>

                        <Col span={4}>
                          <RHFTextField
                            formItemProps={{
                              label: "Task Notes",
                            }}
                            inputProps={{
                              placeholder: "Enter Task Notes",
                            }}
                            controllerProps={{
                              name: `tasks.${index}.description`,
                              defaultValue: task.description || "",
                            }}
                          />
                        </Col>

                        <Col span={4}>
                          <RHFSelect
                            formItemProps={{
                              label: "Task Status",
                            }}
                            controllerProps={{
                              name: `tasks.${index}.taskStatusId`,
                              defaultValue: task.taskStatusId || "",
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select Task Status",
                              options: taskStatuses?.map((status) => ({
                                label: status.name,
                                value: status.id,
                              })),
                            }}
                          />
                        </Col>

                        <Col
                          span={4}
                          style={{ textAlign: "right", marginTop: "25px" }}
                        >
                          <IncredableButton
                            onClick={() => {
                              update(index, {
                                ...task,
                                id: workflowtemplate?.tasks[index].id + "",
                                deleted: true,
                              });
                            }}
                            style={{
                              width: "140px",
                              height: "40px",
                              border: "1.5px solid #598BEB",
                              color: "#598BEB",
                              borderRadius: "8px",
                              padding: "10px 18px",
                              fontWeight: 600,
                              fontSize: "16px",
                            }}
                            icon={<MinusCircleOutlined />}
                          >
                            Remove
                          </IncredableButton>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                ))}

              <Col span={24}>
                <Divider
                  style={{
                    borderBottom: "1px solid #A884C9",
                  }}
                />
                <Form.Item>
                  <IncredableButton
                    onClick={() =>
                      append({
                        id: "",
                        description: "",
                        name: "",
                        taskStatusId: "",
                        taskTypeId: "",
                      })
                    }
                    style={{
                      color: token.colorPrimary,
                      borderColor: token.colorPrimary,
                    }}
                    type="dashed"
                    block
                    icon={<PlusOutlined />}
                  >
                    Add New Task
                  </IncredableButton>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </FormProvider>
    </IncredableContent >
  );
}
