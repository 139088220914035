import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Card, Flex, Space, Modal, Typography, Form, Input } from "antd";
import { HolderOutlined, PlusOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Table } from "antd";
import type { TableColumnsType } from "antd";
import { Container, Header, SpaceBetween, Tiles } from "@cloudscape-design/components";
import IncredableButton from "../../components/button";
import { useLocation, useNavigate } from "react-router-dom";
import StickyFooter from "../../components/StickyFooter";
import IncredableContent from "../../components/incredable-content";
import { Attribute, SectionRequest } from "../../redux/api/section/types";
import { useAddSectionMutation } from "../../redux/api/section/section";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import TypeModal from "./type-modal";
import AddAttributeModal from "./modify/add-attribute-modal";
import EditAttributeModal from "./modify/edit-attribute-modal";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { namespace } from "axios-retry";

export const TypeOptions = [
  { label: "Text", value: "Text" },
  { label: "Date", value: "Date" },
  { label: "Number", value: "Number" },
  { label: "Email", value: "Email" },
  { label: "Phone", value: "Phone" },
  { label: "Checkbox", value: "Checkbox" },
  { label: "Divider", value: "Divider" },
  { label: "Dropdown Single", value: "Dropdown.Single" },
  { label: "Dropdown Multiple", value: "Dropdown.Multiple" },
];

const AddSection = () => {
  const methods = useForm<SectionRequest>();
  const [value, setValue] = useState("Provider");
  const [section, setSection] = useState<SectionRequest | undefined>(undefined);
  const [isFieldsModalOpen, setIsFieldsModalOpen] = useState(false);
  const [isEditFieldModalOpen, setIsEditFieldModalOpen] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [attributeToDelete, setAttributeToDelete] = useState<string | null>(
    null
  );
  const [sectionAttribute, setSectionAttribute] = useState<Partial<Attribute>>({
    name: "",
    description: "",
    type: "",
    defaultValue: "",
    required: false,
    sensitive: false,
    optionDefinition: [],
  });
  const [isTypeModalOpen, setIsTypeModalOpen] = useState(false);
  const [attributeType, setAttributeType] = useState<string>("");
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  const name = methods.watch("name");
  const description = methods.watch("description");
  useEffect(() => {
    const sectionData: SectionRequest = {
      name: name,
      description: description || "",
      type: "",
      sortOrder: 0,
      isFacility: value === "Provider" ? false : true,
      allowMultiple: false,
      attributes: [],
    };
    setSection(sectionData);
  }, [methods, name, description]);
  const [editAttribute, setEditAttribute] = useState<Attribute | null>(null);
  const [addSection] = useAddSectionMutation();

  const handleAddAttribute = () => {
    if (sectionAttribute.name?.trim() && section) {
      const newAttribute: Attribute = {
        id: `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
        name: sectionAttribute.name || "",
        description: sectionAttribute.description || "",
        type: attributeType || "",
        sortOrder: section.attributes.length,
        defaultValue: sectionAttribute.defaultValue || "",
        required: sectionAttribute.required || false,
        sensitive: sectionAttribute.sensitive || false,
        optionDefinition: sectionAttribute.optionDefinition || [],
      };
      setSection({
        ...section,
        attributes: [...section.attributes, newAttribute],
      });
      setSectionAttribute({
        name: "",
        description: "",
        type: "",
        defaultValue: "",
        required: false,
        sensitive: false,
        optionDefinition: [],
      });
      setIsFieldsModalOpen(false);
      setAttributeType("");
    }
  };

  const handleDeleteAttributeConfirm = (attributeId: string) => {
    setAttributeToDelete(attributeId);
    setIsDeleteConfirmModalOpen(true);
  };

  const handleDeleteAttribute = () => {
    if (attributeToDelete && section) {
      const updatedAttributes = section.attributes
        .filter((attr) => attr.id !== attributeToDelete)
        .map((attr, index) => ({
          ...attr,
          sortOrder: index,
        }));
      setSection({ ...section, attributes: updatedAttributes });
      setAttributeToDelete(null);
      setIsDeleteConfirmModalOpen(false);
    }
  };

  const handleEditAttribute = () => {
    if (editAttribute && section) {
      const updatedAttributes = section.attributes.map((attr) =>
        attr.id === editAttribute.id ? { ...editAttribute } : attr
      );
      setSection({ ...section, attributes: updatedAttributes });
      setEditAttribute(null);
      setIsEditFieldModalOpen(false);
    }
  };

  const openEditModal = (attribute: Attribute) => {
    setEditAttribute({ ...attribute });
    setIsEditFieldModalOpen(true);
  };

  const handleAttributesReorder = (newAttributes: Attribute[]) => {
    setSection({
      ...section!,
      attributes: newAttributes,
    });
  };

  const handleSubmit = async () => {
    try {
      if (section) {
        spinnerContext?.showSpinner();
        await addSection(section).unwrap();
        spinnerContext?.hidespinner();
        toastContext?.openSuccessNotification({
          message: `Section Saved successfully`,
          placement: "topRight",
        });
        navigate(-1);
      }
    } catch (error) {
      spinnerContext?.hidespinner();
      toastContext?.openWarningNotification({
        message: `Failed to save section`,
        placement: "topRight",
      });
    }
  };

  return (
    <IncredableContent
      breadcrumbGroupProps={{
        items: [
          {
            text: "Dynamic Profiles",
            href: "/profilebuilder",
          },
          {
            text: "Add",
            href: "#",
          },
        ],
      }}
      style={{ padding: "0" }}
    >
      <Container
        header={
          <Header description="Group of FlexField">Create Section</Header>
        }
      >
        <div
          style={{
            height: "calc(100vh - 220px)",
            overflowY: "scroll",
            scrollbarWidth: "none",
            paddingBottom: "50px",
          }}
        >
          <Card style={{ marginBottom: 16 }}>
            <div style={{ marginBottom: 24 }}>
              <Tiles
                onChange={({ detail }) => setValue(detail.value)}
                value={value}
                items={[
                  { label: "Provider", value: "Provider" },
                  { label: "Facility", value: "facility" }
                ]}
              />
            </div>
            <FormProvider {...methods}>
              <Form
                layout="vertical"
              >
                <RHFTextField
                  formItemProps={{
                    label: "Section Name",
                  }}
                  inputProps={{}}
                  controllerProps={{
                    name: `name`,
                  }}
                  rules={{ required: "This field is required" }}
                />
                <RHFTextArea
                  formItemProps={{
                    label: "Description",
                  }}
                  textAreaProps={{}}
                  controllerProps={{
                    name: `description`,
                  }}
                  rules={{ required: "This field is required" }}
                />
              </Form>
            </FormProvider>
          </Card>
          <Card
            title={
              <Header
                actions={
                  <SpaceBetween size={"s"} direction="horizontal">
                    <IncredableButton
                      onClick={() => {
                        setAttributeType("");
                        setIsTypeModalOpen(true);
                      }}
                      type="primary"
                      style={{ backgroundColor: "#598BEB" }}
                      icon={<PlusOutlined />}
                    >
                      Add New Field
                    </IncredableButton>
                  </SpaceBetween>
                }
              >
                Add FlexFields to this Section
              </Header>
            }
          >
            {section && (
              <DragableTable
                dataSource={section?.attributes}
                onDelete={handleDeleteAttributeConfirm}
                onEdit={openEditModal}
                onReorder={handleAttributesReorder}
              />
            )}
          </Card>
          <StickyFooter
            style={{ zIndex: "200", width: "97%" }}
            hasPermission={true}
            saveText="Submit"
            onSubmit={handleSubmit}
            onCancel={() => navigate(-1)}
          />
        </div>
      </Container>

      <TypeModal
        isTypeModalOpen={isTypeModalOpen}
        setIsTypeModalOpen={setIsTypeModalOpen}
        setIsFieldsModalOpen={setIsFieldsModalOpen}
        setAttributeType={setAttributeType}
      />

      <AddAttributeModal
        isFieldsModalOpen={isFieldsModalOpen}
        setIsFieldsModalOpen={setIsFieldsModalOpen}
        handleAddAttribute={handleAddAttribute}
        sectionAttribute={sectionAttribute as Attribute}
        setSectionAttribute={setSectionAttribute}
        attributeType={attributeType}
      />

      <EditAttributeModal
        isEditFieldModalOpen={isEditFieldModalOpen}
        setIsEditFieldModalOpen={setIsEditFieldModalOpen}
        handleEditAttribute={handleEditAttribute}
        editAttribute={editAttribute as Attribute}
        setEditAttribute={setEditAttribute}
        attributeType={attributeType}
      />

      <Modal
        title="Confirm Delete Attribute"
        open={isDeleteConfirmModalOpen}
        onCancel={() => setIsDeleteConfirmModalOpen(false)}
        footer={
          <Space>
            <Button onClick={() => setIsDeleteConfirmModalOpen(false)}>
              Cancel
            </Button>
            <Button type="primary" danger onClick={handleDeleteAttribute}>
              Delete
            </Button>
          </Space>
        }
        zIndex={1000}
      >
        <p>Are you sure you want to delete this field?</p>
      </Modal>
    </IncredableContent>
  );
};

interface DragableTableProps {
  dataSource: Attribute[];
  onDelete: (attributeId: string) => void;
  onEdit: (attribute: Attribute) => void;
  onReorder: (newAttributes: Attribute[]) => void;
}

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});

const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: "move" }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const columns = (
  onDelete: (attributeId: string) => void,
  onEdit: (attribute: Attribute) => void
): TableColumnsType<Attribute> => [
  { key: "sort", align: "center", width: 80, render: () => <DragHandle /> },
  { title: "Name", dataIndex: "name" },
  { title: "Type", dataIndex: "type" },
  { title: "Description", dataIndex: "description" },
  {
    key: "action",
    title: "Actions",
    render: (_, record) => (
      <Flex wrap gap="small">
        <Button onClick={() => onDelete(record.id || "")}>Delete</Button>
        <Button onClick={() => onEdit(record)}>Edit</Button>
      </Flex>
    ),
  },
];

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row: React.FC<RowProps> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props["data-row-key"] });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 100 } : {}),
  };

  const contextValue = useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners]
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const DragableTable: React.FC<DragableTableProps> = ({
  dataSource,
  onDelete,
  onEdit,
  onReorder,
}) => {
  const [tableData, setTableData] = useState<Attribute[]>([]);

  React.useEffect(() => {
    setTableData([...dataSource]);
  }, [dataSource]);

  const onDragEnd = ({ active, over }: { active: any; over: any }) => {
    if (active.id !== over?.id) {
      setTableData((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.id === active?.id
        );
        const overIndex = prevState.findIndex(
          (record) => record.id === over?.id
        );
        const newData = arrayMove(prevState, activeIndex, overIndex);
        newData.forEach((item, index) => {
          item.sortOrder = index;
        });
        onReorder(newData);
        return newData;
      });
    }
  };

  const getRowClassName = (record: Attribute) => {
    return record.type === "Divider" ? "divider-row" : "";
  };

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        items={tableData.map((i) => i.id || "")}
        strategy={verticalListSortingStrategy}
      >
        <Table<Attribute>
          rowKey="id"
          components={{ body: { row: Row } }}
          columns={columns(onDelete, onEdit)}
          dataSource={tableData}
          bordered={true}
          pagination={{ position: ["none", "none"] }}
          size="small"
          rowClassName={getRowClassName}
        />
      </SortableContext>
    </DndContext>
  );
};

export default AddSection;
