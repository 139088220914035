import { Col, Divider, Form, Row, Tabs } from "antd";
import RHFSelect from "../../components/RHF/RHFSelect";
import RHFTextField from "../../components/RHF/RHFTextField";
import IncredableContent from "../../components/incredable-content";
import { FormProvider, useForm } from "react-hook-form";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import {
  useGetBasicAllProvidersQuery,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { ToastContext } from "../../context/toast/incredable-toast";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { SpinnerContext } from "../../context/spinner/spinner";
import {
  useAddWorkflowToPrivilegeMutation,
  useGetAllPrivilegeDocumentStatsQuery,
  useGetAllPrivilegeTypesQuery,
  useGetPrivilegeQuery,
  useGetPrivilegeWorkflowQuery,
  useUpdatePrivilegeMutation,
  useUpdatePrivilegeWorkflowMutation,
} from "../../redux/api/privileges/privileges";
import StickyFooter from "../../components/StickyFooter";
import PrivilegeDocumentRequest from "../privilege-document-requests";
import AddWorkflowV2 from "../add-workflow";
import TableStats from "../../components/table-stats";
import { SpaceBetween } from "@cloudscape-design/components";
import RequestedSignatures from "../requested-signatures";
import { PrivilegeCreateRequest } from "../../redux/api/privileges/types";
import { useGetAllLicenseTypeQuery } from "../../redux/api/licensetype/licensetype";
import { useGetAllCategoryQuery } from "../../redux/api/category/category";
import { alertReminderOptions } from "../../common/constants";
import {
  WorkflowRequest,
  WorkflowUpdateRequest,
} from "../../redux/api/workflow/types";
import PrivilegeDocumentList from "../privilege-document";
import ReadyformModal from "../readyform-modal";

export default function EditPrivileges() {
  const methods = useForm<PrivilegeCreateRequest>();
  const workflowMethods = useForm<WorkflowRequest & { workflowId: string }>();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false)
  );
  const { privilegeId } = useParams();
  const { data: providers, isLoading } = useGetBasicAllProvidersQuery();
  const { data: licenseType } = useGetAllLicenseTypeQuery();
  const { data: categories } = useGetAllCategoryQuery();
  const { data: privilegeType } = useGetAllPrivilegeTypesQuery();
  const [updatePrivilege] = useUpdatePrivilegeMutation();
  const [addWorkflowToPrivilege] = useAddWorkflowToPrivilegeMutation();
  const [updateWorkflowToPrivilege] = useUpdatePrivilegeWorkflowMutation();

  const [selectedTab, setSelectedTab] = useState<string>();

  const { data: privilege } = useGetPrivilegeQuery(
    !!privilegeId ? { privilegeId: privilegeId } : skipToken
  );
  const { data: documentStats } = useGetAllPrivilegeDocumentStatsQuery(
    !!privilegeId ? { privilegeId } : skipToken
  );
  const { data: workflow } = useGetPrivilegeWorkflowQuery(
    !!privilegeId ? { privilegeId } : skipToken
  );

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const selectedProviderId = methods.watch("providerId");
  useEffect(() => {
    methods.resetField("providerAffiliationId");
  }, [selectedProviderId]);
  const { data: provider } = useGetProviderQuery(
    !!selectedProviderId ? { providerId: selectedProviderId } : skipToken
  );

  const handleSubmit = methods.handleSubmit(async (data) => {
    spinnerContext?.showSpinner();
    try {
      await updatePrivilege({ id: privilegeId + "", ...data }).unwrap();
      toastContext?.openSuccessNotification({
        message: "Privileges edited successfully",
        placement: "topRight",
      });
    } catch (error: any) {
      toastContext?.openErrorNotification({
        message: "Failed to edit Privileges",
        placement: "topRight",
      });
    }
    spinnerContext?.hidespinner();
    navigate(-1);
  });

  useEffect(() => {
    if (!!privilege && !!provider) {
      const affiliationIndex = provider?.providerAffiliations?.findIndex(
        (providerAffiliation) =>
          providerAffiliation?.id + "" ===
          privilege?.providerAffiliation?.id?.toString()
      );
      if (affiliationIndex === -1) methods.resetField("providerAffiliationId");
      else
        methods.setValue(
          "providerAffiliationId",
          privilege?.providerAffiliation?.id?.toString()
        );
    } else methods.resetField("providerAffiliationId");
  }, [provider]);

  useEffect(() => {
    if (!!privilege) {
      !!privilege?.privilegeType?.id &&
        methods.setValue("privilegeTypeId", privilege.privilegeType.id);
      methods.setValue("name", privilege?.name);
      methods.setValue("providerId", privilege?.provider?.id);
      methods.setValue("licenseTypeId", privilege?.licenseType?.id);
      methods.setValue("categoryId", privilege?.category?.id);
      methods.setValue("expirationDate", privilege?.expirationDate);
      methods.setValue("alertDays", privilege?.alertDays);
      methods.setValue("description", privilege?.description);
      if (workflow) {
        workflowMethods.setValue("name", workflow?.name);
        workflowMethods.setValue("providerId", workflow?.provider?.id);
        workflowMethods.setValue("name", workflow?.name);
        workflowMethods.setValue("providerId", workflow?.provider?.id);
        workflowMethods.setValue(
          "workflowStatusId",
          workflow.workFlowStatus?.id ?? ""
        );
        workflowMethods.setValue(
          "workflowTemplateId",
          workflow.workflowTemplate?.id ?? ""
        );
        workflowMethods.setValue("adminId", workflow.admin?.id ?? "");
        workflowMethods.setValue("facilityId", workflow.facility?.id ?? "");
        workflowMethods.setValue("startDate", workflow.startDate ?? "");
        workflowMethods.setValue("dueDate", workflow.dueDate ?? "");
        workflowMethods.setValue("completedDate", workflow.completedDate ?? "");
        workflowMethods.setValue("notes", workflow.notes ?? "");
      }
    }
  }, [privilege, workflow]);

  return (
    <>
      <IncredableContent
        spinning={isLoading}
        style={{ position: "relative", paddingTop: "10px" }}
        breadcrumbGroupProps={{
          items: [
            {
              text: "Privileges",
              href: "/privileges",
            },
            {
              text: "Edit",
              href: "#",
            },
          ],
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <Tabs
            defaultActiveKey="details"
            onChange={(activeKey) => {
              setSelectedTab(activeKey);
            }}
            type="card"
            hideAdd
            size="large"
            items={[
              {
                label: `Details`,
                key: "details",
                children: (
                  <FormProvider {...methods}>
                    <Form
                      layout="vertical"
                      style={{
                        height: "calc(100vh - 250px)",
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        paddingBottom: "50px",
                      }}
                      onFinish={handleSubmit}
                    >
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `name`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Privilege Type" }}
                            controllerProps={{ name: "privilegeTypeId" }}
                            selectProps={{
                              style: { width: "100%" },
                              placeholder: "Select Privilege Type",
                              options: privilegeType?.map((item) => ({
                                label: item?.name,
                                value: item?.id,
                              })),
                              onModifyClick: () => navigate("/privilegetype"),
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Category" }}
                            controllerProps={{ name: "categoryId" }}
                            selectProps={{
                              style: { width: "100%" },
                              placeholder: "Select Category",
                              options: categories?.map((category) => ({
                                label: category.name,
                                value: category.id,
                              })),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Provider" }}
                            controllerProps={{ name: "providerId" }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              disabled: true,
                              options: providers?.map((provider) => ({
                                label: `${provider.firstName} ${provider.lastName}`,
                                value: provider.id,
                              })),
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Provider Affiliation" }}
                            controllerProps={{ name: "providerAffiliationId" }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              options: provider?.providerAffiliations?.map(
                                (a) => ({
                                  label: a.name,
                                  value: a.id,
                                })
                              ),
                            }}
                          />
                        </Col>

                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "License Type" }}
                            controllerProps={{ name: "licenseTypeId" }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: licenseType?.map((type) => ({
                                label: type?.name,
                                value: type?.id,
                              })),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{ label: "Expiration Date" }}
                            controllerProps={{ name: "expirationDate" }}
                            datePickerProps={{
                              style: { width: "100%" },
                              placeholder: "Select Expiration Date",
                            }}
                          />
                        </Col>

                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Follow Up Date" }}
                            controllerProps={{
                              name: `alertDays`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select Days",
                              options: alertReminderOptions?.map((alert) => {
                                return {
                                  label: alert?.label,
                                  value: alert?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={24}>
                          <RHFTextArea
                            formItemProps={{ label: "Notes" }}
                            controllerProps={{ name: "description" }}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </FormProvider>
                ),
              },
              {
                label: `Requested documents`,
                key: "documents_requested",
                children: (
                  <div
                    style={{
                      height: "calc(100vh - 250px)",
                      overflowY: "scroll",
                      scrollbarWidth: "none",
                      paddingBottom: "50px",
                    }}
                  >
                    <PrivilegeDocumentRequest />
                  </div>
                ),
              },
              {
                label: `Requested signatures`,
                key: "signature_requested",
                children: (
                  <div>
                    <RequestedSignatures />
                  </div>
                ),
              },
              {
                label: `Completed documents`,
                key: "documents_completed",
                children: (
                  <div
                    style={{
                      height: "calc(100vh - 250px)",
                      overflowY: "scroll",
                      scrollbarWidth: "none",
                      paddingBottom: "50px",
                    }}
                  >
                    <SpaceBetween direction="vertical" size="l">
                      <TableStats
                        stats={[
                          {
                            label: "Total",
                            count: documentStats?.total?.toString() || "0",
                            color: "#2B6BE6",
                          },
                          {
                            label: "Verified",
                            count: documentStats?.verified?.toString() || "0",
                            color: "#3f8600",
                          },
                          {
                            label: "Expiring",
                            count: documentStats?.expiring?.toString() || "0",
                            color: "#FFBF00",
                          },
                          {
                            label: "Expired",
                            count: documentStats?.expired?.toString() || "0",
                            color: "#cf1322",
                          },
                        ]}
                      />
                      <PrivilegeDocumentList />
                    </SpaceBetween>
                  </div>
                ),
              },
              {
                label: `Workflow`,
                key: "workflow",
                children: (
                  <div
                    style={{
                      height: "calc(100vh - 250px)",
                      overflowY: "scroll",
                      scrollbarWidth: "none",
                      paddingBottom: "80px",
                    }}
                  >
                    <FormProvider {...workflowMethods}>
                      <Form layout="vertical">
                        <AddWorkflowV2 />
                      </Form>
                    </FormProvider>
                  </div>
                ),
              },
            ]}
          />
          {selectedTab !== "signature_requested" &&
            selectedTab !== "documents_requested" &&
            selectedTab !== "documents_completed" && (
              <StickyFooter
                hasPermission={true}
                onSubmit={async () => {
                  const handleSubmit = methods.handleSubmit(async (data) => {
                    spinnerContext?.showSpinner();
                    const response = await updatePrivilege({ id: privilegeId + "", ...data });

                    if (response?.error) {
                      toastContext?.openErrorNotification({
                        message: "Failed to edit Privileges",
                        placement: "topRight",
                      });
                    } else {
                      toastContext?.openSuccessNotification({
                        message: "Privileges edited successfully",
                        placement: "topRight",
                      });
                    }
                    spinnerContext?.hidespinner();
                  });
                  handleSubmit();
                  const workflowId = workflow?.id;
                  const onWorkflowSubmit = async (data: WorkflowRequest) => {
                    spinnerContext?.showSpinner();
                    if (privilegeId) {
                      const response = await addWorkflowToPrivilege({
                        ...data,
                        privilegeId: privilegeId,
                      });
                      if (response?.error) {
                        toastContext?.openErrorNotification({
                          message: "Failed to add Workflow",
                          placement: "topRight",
                        });
                      } else {
                        toastContext?.openSuccessNotification({
                          message: "Privileges workflow added successfully",
                          placement: "topRight",
                        });
                      }
                      spinnerContext?.hidespinner();
                    }
                  };
                  const onUpdateWorkflowSubmit = async (
                    data: WorkflowUpdateRequest
                  ) => {
                    if (!!privilegeId && !!workflow) {
                      spinnerContext?.showSpinner();
                      await updateWorkflowToPrivilege({
                        privilegeId: privilegeId,
                        workflowId: workflow?.id + "",
                        ...data,
                      });
                      spinnerContext?.hidespinner();
                      toastContext?.openSuccessNotification({
                        message: `workflow updated successfully`,
                        placement: "topRight",
                        onClose: () => {
                          navigate(-1);
                        },
                      });
                    }
                  };
                  workflowMethods.handleSubmit(
                    !!workflowId ? onUpdateWorkflowSubmit : onWorkflowSubmit
                  )();
                  navigate(-1);
                }
                }
                onCancel={() => {
                  navigate(-1);
                }}
              />
            )}
        </div>
      </IncredableContent>
    </>
  );
}
