import { Card, Col, Row, Typography } from "antd";
import { DocumentStats } from "../redux/api/document/types";

export default function SafeStats({
  documentStatsData,
}: {
  documentStatsData: DocumentStats | undefined;
}) {
  return (
    <Row gutter={16} style={{ paddingTop: "12px" }}>
      <Col span={8}>
        <Card bordered={true}>
          <Typography.Title
            level={5}
            style={{ paddingTop: "0px", marginTop: "0px" }}
          >
            Total Documents:{" "}
            <span
              style={{
                color: "#3f8600",
                fontWeight: "400",
                fontSize: "24px",
                textAlign: "center",
              }}
            >
              {documentStatsData?.totalDocument || 0}
            </span>{" "}
          </Typography.Title>
        </Card>
      </Col>
      <Col span={8}>
        <Card bordered={true}>
          <Typography.Title
            level={5}
            style={{ paddingTop: "0px", marginTop: "0px" }}
          >
            Expiring Documents:{" "}
            <span
              style={{
                color: "#FFBF00",
                fontWeight: "400",
                fontSize: "24px",
                textAlign: "center",
              }}
            >
              {documentStatsData?.expiringDocument || 0}
            </span>
          </Typography.Title>
        </Card>
      </Col>
      <Col span={8}>
        <Card bordered={true}>
          <Typography.Title
            level={5}
            style={{ paddingTop: "0px", marginTop: "0px" }}
          >
            Expired Documents:{" "}
            <span
              style={{
                color: "#cf1322",
                fontWeight: "400",
                fontSize: "24px",
                textAlign: "center",
              }}
            >
              {documentStatsData?.expiredDocument || 0}
            </span>
          </Typography.Title>
        </Card>
      </Col>
    </Row>
  );
}
