import { AttachmentFile } from "../../redux/api/contracts/types";
import { AllDocumentResponse, DocumentRequest } from "../../redux/api/document/types";
import { SectionRequest } from "../../redux/api/section/types";
type Attachment = {
  id?: number;
  name?: string;
  size?: number;
  contentType?: string;
  contentMD5Hash?: string;
  key?: string;
  orgId?: string;
};

// Define Document Type
export type DocumentProviderRecord = {
  id: string | undefined
  name: string | undefined;
  expirationDate: string;
  alertDays: number;
  notes: string;
  categoryId: string;
  attachment: Attachment;
  hgId?: string;
  createdDate: string;
  providerId: string;
  facilityId?: string;
};
export type ProviderProfileRecordTypes = {
  attributeValues: {
    [key: string]: string;
  },
  documents: DocumentProviderRecord[]
};
export type ProviderProfileRecordType = {
  [key: string]: string, // Any key (string), and all values must be strings
};

// get all records by provider id and section id
// @GetMapping("/providers/{providerId}/section/{sectionId}")
export const providerProfileRecordArray: ProviderProfileRecordType[] = [
  {
    "83": "Ravi teja",
    "90": "2024-11-11T00:00:00",
    "82": "2",
    "91": "travi3@fvsef.com",
    "92": "2032905323",
    "93": "true",
    "94": "inactive",
  },
  {
    "83": "teja",
    "90": "2025-11-11T00:00:00",
    "82": "4",
    "91": "24aseff@fvsef.com",
    "92": "46534232",
    "93": "false",
    "94": "enabled",
  },
];

// get All sections
export const sectionMetaData: SectionRequest = {
  id: "20",
  name: "Aakash Test",
  description: "test",
  type: "test",
  sortOrder: 0,
  isFacility: false,
  allowMultiple: false,
  attributes: [
    {
      id: "83",
      name: "First name",
      description: "First name",
      type: "Text",
      sortOrder: 0,
      defaultValue: "",
      sensitive: false,
      required: false,
    },
    {
      id: "90",
      name: "Appt date",
      description: "",
      type: "Date",
      sortOrder: 1,
      defaultValue: "",
      sensitive: false,
      required: false,
    },
    {
      id: "82",
      name: "years of exp",
      description: "",
      type: "Number",
      sortOrder: 2,
      defaultValue: "",
      sensitive: false,
      required: false,
    },
    {
      id: "91",
      name: "Supervisor email",
      description: "",
      type: "Email",
      sortOrder: 3,
      defaultValue: "",
      sensitive: false,
      required: false,
    },
    {
      id: "81",
      name: "Divider",
      description: "",
      type: "Divider",
      sortOrder: 4,
      defaultValue: "",
      sensitive: false,
      required: false,
    },
    {
      id: "92",
      name: "Supervisor Phone",
      description: "",
      type: "Phone",
      sortOrder: 5,
      defaultValue: "",

      sensitive: false,
      required: false,
    },
    {
      id: "93",
      name: "has Another name",
      description: "",
      type: "Checkbox",
      sortOrder: 6,
      defaultValue: "",
      sensitive: false,
      required: false,
    },
    {
      id: "94",
      name: "Status",
      description: "",
      type: "Dropdown.Single",
      sortOrder: 7,
      defaultValue: "",
      sensitive: false,
      required: false,
      optionDefinition: [
        { id: "100", value: "active", label: "Active" },
        { id: "101", value: "inactive", label: "In Active" },
        { id: "102", value: "disabled", label: "Disabled" },
        { id: "103", value: "enabled", label: "Enabled" },
      ],
    },
  ],
};
