import { createContext, ReactNode, useEffect, useState } from "react";
import useIsLoading from "../hooks/useIsLoading";
import axios from "./axios";
// utils
import { isValidToken, setSession, getTokenScope } from "./jwt";
import { ActionMap, AuthState, JWTContextType } from "./types";
import { UIActionTypes } from "../redux/UI/types";
import { connect, useDispatch } from "react-redux";
import { Reducer } from "redux";
import { ApplicationState } from "../redux/store";
import { AxiosError } from "axios";
import { captureException } from "@sentry/react";
import Cookies from "js-cookie";

// ----------------------------------------------------------------------

enum Types {
  Initial = "INITIALIZE",
  Login = "LOGIN",
  Logout = "LOGOUT",
  Register = "REGISTER",
  Success = "SUCCESS",
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
  };
  [Types.Login]: {};
  [Types.Logout]: undefined;
  [Types.Register]: {};
  [Types.Success]: { permissions: string[] };
  [UIActionTypes.SET_ROLE_PERMISSIONS]: {
    permissions: string[];
    is_owner: boolean;
  };
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
};

export const jwtReducer: Reducer<AuthState> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case Types.Initial:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
      };
    case Types.Login:
      return {
        ...state,
        isAuthenticated: true,
        isInitialized: true,
      };
    case Types.Logout:
      return {
        ...state,
        isAuthenticated: false,
      };
    case Types.Register:
      return {
        ...state,
        isAuthenticated: true,
      };
    /* add verify action type, and /verify router path
    in /verify router which just renders a spinner, in
    componentDidMount has a setTimeOut for 20secs, call verify method which checks for access token in cookie if presents validates
    */
    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};
type PropsFromState = { authState: AuthState };

function AuthProvider({
  children,
  authState,
}: AuthProviderProps & PropsFromState) {
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    axios.defaults.validateStatus = function (status) {
      return status === 200;
    };
    axios.defaults.withCredentials = true;
  }, []);
  useIsLoading(isLoading);

  const success = ({
    type,
    scope: { permissions, is_owner },
  }: {
    scope: { permissions: string[]; is_owner: boolean };
    type: Types;
  }) => {
    dispatch({
      type: type,
      payload: {},
    });
    dispatch({
      type: UIActionTypes.SET_ROLE_PERMISSIONS,
      payload: { permissions, is_owner },
    });
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken =
          Cookies.get("accessToken") ?? localStorage.getItem("accessToken");
        if (!!accessToken && isValidToken(accessToken)) {
          await setSession(accessToken);
          /* if (window.location.host !== "localhost:3000") {
            const response = await axios.get("/api/auth/verify");
            const { user } = response.data;
          }*/
          success({
            type: Types.Login,
            scope: { ...getTokenScope(accessToken) },
          });
        } else {
          Cookies.remove("accessToken");
          localStorage.removeItem("accessToken");
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
            },
          });
        }
      } catch (err) {
        Cookies.remove("accessToken");
        window.location.host !== "localhost:3000" &&
          localStorage.removeItem("accessToken");
        captureException(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    return new Promise<string | undefined>(async (resolve, reject) => {
      setIsLoading(true);
      try {
        axios
          .post(
            `/api/auth/login`,
            {
              username: email,
              password: password,
            },
            {
              validateStatus: (status) => status === 200,
            }
          )
          .then(async (response) => {
            const accessToken =
              Cookies.get("accessToken") ?? response?.data?.accessToken;

            if (!!accessToken && window.location.host === "localhost:3000") {
              await setSession(accessToken);
            }

            // Setting orgID in cookie is need for Support login
            if (!!urlParams.has("orgId") && !!urlParams.get("orgId")) {
              Cookies.set("orgId", urlParams.get("orgId") ?? "", {
                sameSite: "Strict",
              });
            }
            setIsLoading(false);
            success({
              type: Types.Login,
              scope: { ...getTokenScope(accessToken) },
            });
            resolve("");
          })
          .catch((error) => {
            setIsLoading(false);
            captureException(error);
            return reject(error?.response?.data?.message ?? "Error: ");
          });
      } catch (error) {
        setIsLoading(false);
        return reject("Error: ");
      }
    });
  };

  const register = async ({
    body,
    queryparams,
  }: {
    body: {
      email: string;
      password: string;
      frist_name: string;
      last_name: string;
      roleId: string;
      isOwner: boolean;
      invite_hash: string;
    };
    queryparams: string;
  }) => {
    return new Promise<string | undefined>(async (resolve, reject) => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `/api/auth/signup?${queryparams}`,
          body
        );
        const accessToken =
          Cookies.get("accessToken") ?? response?.data?.accessToken;

        await setSession(accessToken);
        setIsLoading(false);
        success({
          type: Types.Register,
          scope: { ...getTokenScope(accessToken) },
        });
        resolve("");
        dispatch({
          type: Types.Register,
          payload: {},
        });
      } catch (error) {
        setIsLoading(false);
        captureException(error);
        const err = error as AxiosError<{ message: string }>;
        if (err) {
          if (!!err.response?.data) {
            return reject(err.response?.data.message);
          }
        }
        return reject("Error:");
      }
    });
  };

  const logout = async () => {
    try {
      axios.post("/api/auth/logout");
    } catch (error) {
      console.log(error);
    }
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        method: "jwt",
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    authState: state.auth,
  };
};

export default connect(mapStateToProps, {})(AuthProvider);

export { AuthContext };

export const getIntivtationDetails = async (invite_hash: string) => {
  const response = await axios.get(`/api/auth/invitation/${invite_hash}`);
  const { role, email } = response.data;
  return { role, email };
};
