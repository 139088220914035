import { PlusOutlined } from "@ant-design/icons";
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Modal,
  Table,
  Pagination,
} from "@cloudscape-design/components";
import { Space, ConfigProvider, Button } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import useSafeFilter from "../table-filters/safe-filters";
import { AllDocumentResponse } from "../redux/api/document/types";
import { useCollection } from "@cloudscape-design/collection-hooks";

export default function ProviderDocumentModal({
  providerId,
  isDocumentModalOpen,
  setIsDocumentModalOpen,
  selectedDocument,
  setSelectedDocument,
  handleExistingDocumentSubmit,
}: {
  providerId: string | undefined;
  isDocumentModalOpen: boolean;
  setIsDocumentModalOpen: Dispatch<SetStateAction<boolean>>;
  selectedDocument: AllDocumentResponse[];
  setSelectedDocument: Dispatch<SetStateAction<AllDocumentResponse[]>>;
  handleExistingDocumentSubmit: (type?: string) => void;
}) {
  const {
    TableFilters,
    filteredDocuments = [],
    documentsTotalRecords,
    UseQueryHookResult: {
      setPageNumber,
      pageNumber,
      pageSize,
      setPageSize,
      isFetching: documentFetching,
      isLoading: documentLoding,
    },
  } = useSafeFilter({
    selectedProviderId: providerId ? providerId : "",
    isModal: true,
  });
  const [preferences, setPreferences] = useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: pageSize,
    wrapLines: true,
  });

  const { items, collectionProps, paginationProps } = useCollection(
    filteredDocuments,
    {
      pagination: { pageSize },
    }
  );

  return (
    <>
      <Modal
        visible={isDocumentModalOpen}
        onDismiss={() => setIsDocumentModalOpen(false)}
        closeAriaLabel="false"
        header={
          <Header counter={`(${filteredDocuments?.length})`}>Documents</Header>
        }
        footer={
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#2B6BE6",
                },
              }}
            >
              <Button
                type="primary"
                onClick={() => handleExistingDocumentSubmit()}
                icon={<PlusOutlined />}
              >
                Create New Document
              </Button>
            </ConfigProvider>
            <Space>
              <Button
                onClick={() => {
                  setIsDocumentModalOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => handleExistingDocumentSubmit("linkDocument")}
                disabled={selectedDocument?.length !== 1}
              >
                Link
              </Button>
            </Space>
          </Space>
        }
        size="large"
      >
        <div>
          <Table
            items={items}
            {...collectionProps}
            pagination={
              <Pagination
                {...paginationProps}
                currentPageIndex={pageNumber + 1}
                onChange={({ detail }) => {
                  setPageNumber(detail.currentPageIndex - 1);
                }}
                pagesCount={
                  pageSize && pageSize !== 0
                    ? Math.ceil(documentsTotalRecords / pageSize)
                    : 1
                }
              />
            }
            preferences={
              <CollectionPreferences
                onConfirm={({ detail }) => {
                  if (detail) {
                    setPreferences(detail);
                    if (detail.pageSize) {
                      setPageSize(detail.pageSize);
                    }
                  }
                }}
                preferences={preferences}
                pageSizePreference={{
                  options: [
                    { value: 10, label: "10 items" },
                    { value: 30, label: "30 items" },
                    { value: 50, label: "50 items" },
                  ],
                }}
              />
            }
            filter={TableFilters}
            variant="borderless"
            loading={documentLoding || documentFetching}
            loadingText="Loading..."
            selectionType="single"
            resizableColumns={false}
            stickyHeader={true}
            onRowClick={({ detail }) => {
              const selectedItem = detail?.item;
              if (selectedItem) {
                if (selectedDocument?.[0]?.id === selectedItem.id) {
                  setSelectedDocument([]);
                } else {
                  setSelectedDocument([selectedItem]);
                }
              }
            }}
            onSelectionChange={({ detail }) => {
              setSelectedDocument([detail?.selectedItems[0]]);
            }}
            selectedItems={selectedDocument}
            trackBy="id"
            columnDisplay={[
              { id: "id", visible: false },
              { id: "name", visible: true },
              { id: "category", visible: true },
              { id: "facility", visible: true },
            ]}
            columnDefinitions={[
              {
                id: "id",
                header: <div>ID</div>,
                cell: (item) => <span>{item?.id}</span>,
              },
              {
                id: "name",
                header: <div>Document Name</div>,
                cell: (item) => <span>{item?.name ?? "-"}</span>,
              },
              {
                id: "category",
                header: <div>Category</div>,
                cell: (item) => <span>{item?.category?.name ?? "-"}</span>,
              },
              {
                id: "facility",
                header: <div>Facility</div>,
                cell: (item) => <span>{item?.facility?.name ?? "-"}</span>,
              },
            ]}
          />
        </div>
      </Modal>
    </>
  );
}
