import { JwtPayload, jwtDecode } from "jwt-decode";
import * as Sentry from "@sentry/browser";
import { Modal } from "antd";

// routes
import { PATH_AUTH } from "../routes/path";

import axios from "./axios";
import Cookies from "js-cookie";

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded: JwtPayload = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return !!decoded?.exp && decoded?.exp > currentTime;
};

const getTokenScope = (
  accessToken: string
): { permissions: string[]; is_owner: boolean } => {
  if (!accessToken) {
    return { permissions: [], is_owner: false };
  }
  const decoded = jwtDecode<{ scope: string; role: string }>(accessToken);
  if (!decoded) {
    return { permissions: [], is_owner: false };
  } else {
    decoded.scope = decoded.scope;
  }

  return {
    permissions: decoded.scope.split(" ") ?? [],
    is_owner: decoded.role?.toLowerCase() === "OWNER".toLowerCase(),
  };
};

const handleTokenExpired = async (exp: number) => {
  let expiredTimer;

  const timeLeft = 115 * 60 * 1000; //exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(async () => {
    Modal.confirm({
      title: "Session Expiring",
      content: "Your session is about to expire. Do you want to continue?",
      okText: "Continue",
      cancelText: "Logout",
      onOk: async () => {
        try {
          const response = await axios.post(
            `/api/auth/refresh`,
            {},
            {
              withCredentials: true,
              validateStatus: (status) => status === 200,
            }
          );

          if (response.status === 200) {
            alert("Session refreshed successfully.");
            handleTokenExpired(timeLeft);
          } else {
            alert("Failed to refresh session. Please log in again.");
            Cookies.remove("accessToken");
            localStorage.removeItem("accessToken");
            Cookies.remove("refreshToken");
            localStorage.removeItem("refreshToken");
            window.location.href = PATH_AUTH.login;
          }
        } catch (error) {
          alert(
            "An error occurred while refreshing the session. Please log in again."
          );
          Cookies.remove("accessToken");
          localStorage.removeItem("accessToken");
          Cookies.remove("refreshToken");
          localStorage.removeItem("refreshToken");
          window.location.href = PATH_AUTH.login;
        }
      },
      onCancel: () => {
        Cookies.remove("accessToken");
        localStorage.removeItem("accessToken");
        Cookies.remove("refreshToken");
        localStorage.removeItem("refreshToken");
        window.location.href = PATH_AUTH.login;
        alert("Token expired. You have been logged out.");
      },
    });
  }, timeLeft);
};

interface DecodedToken {
  exp: number;
  sub: string;
  scope?: string;
  role?: string;
}
const setSession = async (accessToken: string | null) => {
  if (!!accessToken) {
    // The Authorization token is only set in the header when the application is running locally on localhost:3000.
    // Once deployed on the server, authorization is handled through cookie-based authentication.
    if (!!accessToken && window.location.host === "localhost:3000") {
      localStorage.setItem("accessToken", accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }
    // This function below will handle when token is expired
    const decoded = jwtDecode<DecodedToken>(accessToken);
    const { exp, sub } = decoded;
    Sentry.setUser({ username: sub });
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem("accessToken");
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    localStorage.removeItem("refreshToken");
    Cookies.remove("orgId");
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession, getTokenScope };
