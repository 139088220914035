import { useMemo } from "react";
import { SpaceBetween, Tiles } from "@cloudscape-design/components";
import IncredableContent from "../components/incredable-content";
import DocumentRequest from "./document-requests/document-request-list";
import EsignatureListV2 from "./signature-requests/e-signature-list-v2";
import { useSearchParams } from "react-router-dom";
import { useGetOrganizationDetailsQuery } from "../redux/api/organization/organization";

export default function AdminRequests() {
  const { data } = useGetOrganizationDetailsQuery();
  const [searchParams, setSearchParams] = useSearchParams({
    type: "signature_requests",
  });

  const selectedTab = useMemo(() => {
    return searchParams.get("type") ?? "signature_requests";
  }, [searchParams]);

  if (
    !!data?.organizationServiceLimits?.documentRequest &&
    !!data?.organizationServiceLimits?.eSignatures
  )
    return (
      <IncredableContent style={{ margin: "32px 32px 0 32px" }}>
        <SpaceBetween size="l" direction="vertical">
          <Tiles
            onChange={({ detail }) => {
              setSearchParams({ type: detail.value });
            }}
            value={selectedTab} // Use selectedTab as the value for Tiles
            items={[
              {
                label: "Signature Requests",
                description: "Signature Requests",
                value: "signature_requests",
              },
              {
                label: "Document Requests",
                description: "Document Requests",
                value: "document_requests",
              },
            ]}
          />
          {selectedTab === "signature_requests" && <EsignatureListV2 />}
          {selectedTab === "document_requests" && <DocumentRequest />}
        </SpaceBetween>
      </IncredableContent>
    );
  else if (!!data?.organizationServiceLimits?.documentRequest)
    return (
      <div style={{ padding: "32px 32px 0 32px" }}>
        <DocumentRequest />
      </div>
    );
  else
    return (
      <div style={{ padding: "32px 32px 0 32px" }}>
        <EsignatureListV2 />
      </div>
    );
}
