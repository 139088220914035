import { useContext, useState } from "react";
import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  CalendarOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../common/localStorage";
import {
  WORKFLOW_TABLE_CONTENT_DISPLAY_OPTIONS,
  WORKFLOW_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "./table-config";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import IncredableDateFormat from "../common/incredable-date-format";
import useWorkflowFilter from "../table-filters/workflow-filters";
import { WorkflowMetadata } from "../redux/api/workflow/types";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
import { useDeleteWorkflowMutation } from "../redux/api/workflow/workflow";

export default function WorkFlowList() {
  const [selectedRows, setSelectedRows] = useState<WorkflowMetadata[]>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const {
    TableFilters,
    filteredWorkflow = [],
    UseQueryHookResult: { refetch, isFetching, isLoading },
  } = useWorkflowFilter();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("workflow.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("workflow.delete") ?? false)
  );

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Workflow-Table-Preferences",
    WORKFLOW_TABLE_DEFAULT_PREFERENCES
  );
  const navigate = useNavigate();

  const [deleteWorkflow] = useDeleteWorkflowMutation();

  const { items, collectionProps, paginationProps } = useCollection(
    filteredWorkflow,
    {
      pagination: { pageSize: tablePreferences.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    }
  );

  return (
    <div style={{ padding: "32px 32px 0 32px" }}>
      <Table
        loading={isFetching || isLoading}
        onSelectionChange={({ detail }) => {
          const selectedItem = detail?.selectedItems[0];
          setSelectedRows(selectedItem ? [selectedItem] : []);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows?.at(0)?.id === selectedItem.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        columnDefinitions={[
          {
            id: "id",
            header: <div>ID</div>,
            cell: (item) => <span>{item.id}</span>,
          },
          {
            id: "name",
            header: <div>Name</div>,
            cell: (item) => (
              <NavLink to={hasPermission ? `/workflows/${item?.id}` : "#"}>
                {!!item?.name ? item?.name : "-"}
              </NavLink>
            ),
            sortingField: "name",
            sortingComparator: (a, b) =>
              (a?.name ?? "").localeCompare(b?.name ?? ""),
          },
          {
            id: "workflowStatusId",
            header: <div>Workflow Status</div>,
            cell: (item) => (
              <span>
                {!!item?.workFlowStatus?.name
                  ? item?.workFlowStatus?.name
                  : "-"}
              </span>
            ),
            sortingField: "workflowStatus.name",
            sortingComparator: (a, b) =>
              (a?.workFlowStatus?.name ?? "").localeCompare(b?.workFlowStatus?.name ?? ""),
          },
          {
            id: "workflowTemplate",
            header: <div>Workflow Template</div>,
            cell: (item) => (
              <span>
                {!!item?.workflowTemplate?.name
                  ? item?.workflowTemplate?.name
                  : "-"}
              </span>
            ),
            sortingField: "workflowTemplate.name",
            sortingComparator: (a, b) =>
              (a?.workflowTemplate?.name ?? "").localeCompare(b?.workflowTemplate?.name ?? ""),
          },
          {
            id: "admin",
            header: <div>Assigned To</div>,
            cell: (item) => (
              <span>
                {!!item?.admin
                  ? `${item?.admin?.lastName} ${item?.admin?.firstName}`
                  : "-"}
              </span>
            ),
            sortingField: "admin",
            sortingComparator: (a, b) =>
              (a?.admin?.lastName ?? "").localeCompare(
                b?.admin?.lastName ?? ""
              ),
          },
          {
            id: "provider",
            header: <div>Provider</div>,
            cell: (item) => (
              <span>
                {!!item?.provider
                  ? `${item?.provider?.lastName} ${item?.provider?.firstName}`
                  : "-"}
              </span>
            ),
            sortingField: "provider",
            sortingComparator: (a, b) =>
              (a?.provider?.lastName ?? "").localeCompare(
                b?.provider?.lastName ?? ""
              ),
          },
          {
            id: "facility",
            header: <div>Facility</div>,
            cell: (item) => (
              <span>
                {!!item?.facility?.name
                  ? item?.facility?.name
                  : "-"}
              </span>
            ),
            sortingField: "facility.name",
            sortingComparator: (a, b) =>
              (a?.facility?.name ?? "").localeCompare(b?.facility?.name ?? ""),
          },
          {
            id: "startDate",
            header: <div>Start Date</div>,
            cell: (item) => (item?.startDate ?
              <span><CalendarOutlined />{" "}{IncredableDateFormat(item?.startDate)}</span>
              : "-"
            ),
            sortingField: "startDate",
          },
          {
            id: "completedDate",
            header: <div>Expiration Date</div>,
            cell: (item) => (item?.completedDate ?
              <span><CalendarOutlined />{" "}{IncredableDateFormat(item?.completedDate)}</span>
              : "-"
            ),
            sortingField: "completedDate",
          },
          {
            id: "dueDate",
            header: <div>Due Date</div>,
            cell: (item) => (item?.dueDate ?
              <span><CalendarOutlined />{" "}{IncredableDateFormat(item?.dueDate)}</span>
              : "-"
            ),
            sortingField: "dueDate",
          },
          {
            id: "createdDate",
            header: <div>Created Date</div>,
            cell: (item) => (item?.createdDate ?
              <span><CalendarOutlined />{" "}{IncredableDateFormat(item?.createdDate)}</span>
              : "-"
            ),
            sortingField: "createdDate",
          },
          {
            id: "description",
            header: <div>Notes</div>,
            cell: (item) => (
              <span>{item?.notes}</span>
            ),
          },
        ]}
        items={items}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={WORKFLOW_TABLE_CONTENT_DISPLAY_OPTIONS}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        filter={TableFilters}
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <Space size="middle">
                  <Button
                    onClick={() => {
                      !!refetch && refetch();
                    }}
                  >
                    <ReloadOutlined />
                  </Button>
                  <Dropdown
                    menu={{
                      disabled: !selectedRows?.at(0)?.id,
                      items: [
                        {
                          label: hasPermission ? "Edit" : "View",
                          key: "edit",
                          disabled: selectedRows?.length !== 1,
                          icon: <EditOutlined />,
                          onClick: () => {
                            navigate(`${selectedRows?.at(0)?.id}`);
                          },
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          onClick: () => setIsDeleteModalOpen(true),
                          disabled: !hasDeletePermission,
                          icon: <DeleteOutlined />,
                          danger: true,
                        },
                      ],
                    }}
                  >
                    <Button>
                      Actions
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#2B6BE6",
                      },
                    }}
                  >
                    <Button
                      disabled={!hasPermission}
                      onClick={() => navigate(`add`)}
                      type="primary"
                      icon={<PlusOutlined />}
                    >
                      Add Workflow
                    </Button>
                  </ConfigProvider>
                </Space>
              }
            >
              <Typography.Title level={3} style={{ marginTop: "0" }}>
                Workflow{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${filteredWorkflow.length})`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedRows?.at(0)?.id) {
            setIsDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            try {
              await deleteWorkflow({
                id: String(selectedRows?.at(0)?.id),
              }).unwrap();
              toastContext?.openSuccessNotification({
                message: `workflow deleted successfully`,
                placement: "topRight",
              });
            } catch (error: any) {
              toastContext?.openErrorNotification({
                message: error?.data?.errorMessage || `Failed to delete workflow`,
                placement: "topRight"
              })
            }
            spinnerContext?.hidespinner();
            setSelectedRows([]);
          }
        }}
        closeModal={() => setIsDeleteModalOpen(false)}
        header="Delete Workflow"
        content={
          <Typography.Text>
            Workflow Name:{" "}
            <Typography.Text>{selectedRows?.at(0)?.name}</Typography.Text>
          </Typography.Text>
        }
        description="Are you sure you want to delete this workflow?"
      />
    </div>
  );
}
