import { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  ConfigProvider,
  Dropdown,
  Flex,
  Input,
  MenuProps,
  Row,
  Space,
  Typography,
} from "antd";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "../common/localStorage";
import {
  PAYERENROLLMENT_CONTENT_DISPLAY_OPTIONS,
  PAYERENROLLMENT_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "./table-config";
import { PayerEnrollment } from "../redux/api/payerenrollment/types";
import usePayerEnrollmentFilter from "../table-filters/payerenrollment-filters";
import { useDeletePayerEnrollmentMutation } from "../redux/api/payerenrollment/payerenrollment";
import IncredableDateFormat from "../common/incredable-date-format";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import { Provider } from "../redux/api/provider/types";
import { useGetBasicAllProvidersQuery } from "../redux/api/provider/provider";
import { useGetAllPayerQuery } from "../redux/api/payers/payers";
import { useGetAllPayerGroupQuery } from "../redux/api/payergroup/payergroup";
import { Payers } from "../redux/api/payers/types";
import { PayerGroup } from "../redux/api/payergroup/types";

const groupByItems: MenuProps["items"] = [
  {
    label: "Provider",
    key: "provider",
  },
  {
    label: "Network",
    key: "network",
  },
  {
    label: "Payer",
    key: "payer",
  },
  {
    type: "divider",
  },
  {
    label: "None",
    key: "none",
  },
];

function isProvider(item: any): item is Provider {
  return item && "firstName" in item && "lastName" in item;
}

function isPayerGroup(item: any): item is PayerGroup {
  return item && "name" in item && "description" in item;
}

function isPayers(item: any): item is Payers {
  return item && "name" in item && "payerId" in item;
}

export default function PayerEnrollmentListV2() {
  const { data: basicProviderData = [] } = useGetBasicAllProvidersQuery();
  const { data: payerData = [] } = useGetAllPayerQuery();
  const { data: networkData = [] } = useGetAllPayerGroupQuery();
  const [selectedRows, setSelectedRows] = useState<PayerEnrollment[]>();
  const [selectedLeftRows, setSelectedLeftRows] = useState<any[] | undefined>();
  const [searchParams, setSearchParams] = useSearchParams({ groupBy: "" });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [leftSearchText, setLeftSearchText] = useState("");
  const [groupByValue, setGroupByValue] = useState("none");
  const [groupByIds, setGroupByIds] = useState<any[]>([]);

  useEffect(() => {
    const groupBy = searchParams.get("groupBy") || "none";
    setGroupByValue(groupBy);

    const key =
      groupBy === "provider"
        ? "providerId"
        : groupBy === "network"
        ? "networkId"
        : groupBy === "payer"
        ? "payerId"
        : "";

    if (key) {
      const ids = searchParams.get(key)?.split(",") || [];
      setGroupByIds(ids.map((item) => Number(item)));
      setSelectedLeftRows(
        ids.map((item) => ({
          id: Number(item),
        }))
      );
    }
  }, [searchParams]);

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.delete") ?? false)
  );

  const leftTableData =
    groupByValue === "provider"
      ? basicProviderData
      : groupByValue === "network"
      ? networkData
      : payerData;

  const {
    TableFilters,
    filteredPayerEnrollments = [],
    enrollmentsTotalRecords,
    UseQueryHookResult: {
      reload,
      setPageNumber,
      setPageSize,
      pageNumber,
      pageSize,
      isFetching,
      sortingDetails,
      setSortingDetails,
    },
  } = usePayerEnrollmentFilter({
    key: groupByValue,
    id: groupByIds,
  });

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-PayerEnrollment-Table-Preferences",
    PAYERENROLLMENT_TABLE_DEFAULT_PREFERENCES
  );

  const navigate = useNavigate();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const [deletePayerEnrollment] = useDeletePayerEnrollmentMutation();

  useEffect(() => {
    if (tablePreferences?.pageSize) setPageSize(tablePreferences.pageSize);
  }, [tablePreferences, setPageSize]);

  const { items, collectionProps, paginationProps } = useCollection(
    filteredPayerEnrollments,
    {
      pagination: { pageSize },
    }
  );

  const filteredLeftTableData = (
    leftTableData as (Provider | PayerGroup | Payers)[]
  )?.filter((item) => {
    if (groupByValue === "provider" && isProvider(item)) {
      return `${item.firstName} ${item.lastName}`
        .toLowerCase()
        .includes(leftSearchText.toLowerCase());
    }
    if (
      (groupByValue === "network" || groupByValue === "payer") &&
      (isPayerGroup(item) || isPayers(item))
    ) {
      return item.name.toLowerCase().includes(leftSearchText.toLowerCase());
    }
    return false;
  });

  const {
    items: groupByTableData,
    collectionProps: leftCollectionProps,
    paginationProps: leftPaginationProps,
  } = useCollection(filteredLeftTableData, {
    pagination: { pageSize: pageSize },
  });

  const handelRouting = (id: string) => {
    const params = new URLSearchParams(searchParams);
    const key =
      groupByValue === "provider"
        ? "providerId"
        : groupByValue === "network"
        ? "networkId"
        : "payerId";

    let updatedIds = [...groupByIds];
    if (updatedIds.includes(id)) {
      updatedIds = updatedIds.filter((existingId) => existingId !== id);
    } else {
      updatedIds.push(Number(id));
    }
    if (updatedIds.length > 0) {
      params.set(key, updatedIds.join(","));
    } else {
      params.delete(key);
    }
    setGroupByIds(updatedIds);
    setSearchParams(params);
  };

  return (
    <div style={{ padding: "32px 32px 0 32px" }}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col
          span={6}
          style={groupByValue === "none" ? { display: "none" } : {}}
        >
          <Table
            selectionType="multi"
            columnDefinitions={[
              {
                id: `${groupByValue !== "none" && groupByValue}`,
                header: (
                  <span style={{ textTransform: "capitalize" }}>{`${
                    groupByValue !== "none" && groupByValue
                  } Name`}</span>
                ),
                cell: (item) => {
                  if (groupByValue === "provider" && isProvider(item)) {
                    return `${item.firstName} ${item.lastName}`;
                  }
                  return isPayerGroup(item) || isPayers(item) ? item.name : "-";
                },
                sortingField: "",
                isRowHeader: true,
              },
            ]}
            trackBy="id"
            header={
              <Typography.Title
                level={3}
                style={{ marginTop: "0", textTransform: "capitalize" }}
              >
                {groupByValue !== "none" && groupByValue}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{` (${groupByTableData?.length})`}</Typography.Text>
              </Typography.Title>
            }
            filter={
              <Flex>
                <Input
                  placeholder={`Search By ${
                    groupByValue !== "none" && groupByValue
                  }`}
                  value={leftSearchText}
                  onChange={(e) => setLeftSearchText(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Flex>
            }
            items={groupByTableData || []}
            pagination={<Pagination {...leftPaginationProps} />}
            onSelectionChange={({ detail }) => {
              let temp = selectedLeftRows;
              const oldRemovedId = temp?.find((item) =>
                groupByIds.includes(item.id)
              )?.id;
              const newSelectedId = detail?.selectedItems.find(
                (item) => !groupByIds.includes(item.id)
              )?.id;
              setSelectedLeftRows([...detail.selectedItems]);
              handelRouting(newSelectedId || oldRemovedId);
            }}
            onRowClick={({ detail }) => {
              const selectedItem = detail?.item;
              handelRouting(selectedItem.id);
              if (selectedItem) {
                setSelectedLeftRows((prevSelected) => {
                  const selectedArray = prevSelected || [];
                  const exists = selectedArray?.some(
                    (record) => record?.id === selectedItem?.id
                  );
                  return exists
                    ? selectedArray?.filter(
                        (record) => record?.id !== selectedItem?.id
                      )
                    : [...selectedArray, selectedItem];
                });
              }
            }}
            selectedItems={selectedLeftRows}
          />
        </Col>
        <Col span={groupByValue === "none" ? "" : 18}>
          <Table
            loading={isFetching}
            onSelectionChange={({ detail }) => {
              setSelectedRows([detail.selectedItems[0]]);
            }}
            onRowClick={(onRowClickDetails) => {
              const selectedItem = onRowClickDetails?.detail?.item;
              if (selectedItem) {
                if (selectedRows?.at(0)?.id === selectedItem?.id) {
                  setSelectedRows([]);
                } else {
                  setSelectedRows([selectedItem]);
                }
              }
            }}
            selectedItems={selectedRows || []}
            sortingColumn={{ sortingField: sortingDetails?.sortingField }}
            sortingDescending={sortingDetails?.isDescending}
            onSortingChange={({ detail }) => {
              setSortingDetails({
                sortingField: detail?.sortingColumn?.sortingField,
                isDescending: detail?.isDescending,
              });
            }}
            columnDefinitions={[
              {
                id: "id",
                header: "ID",
                cell: (item) => item?.id,
                sortingField: "id",
              },
              {
                id: "network",
                header: "Network",
                cell: (item) => item?.payerGroup?.name,
                sortingField: "payerGroup.name",
              },
              {
                id: "payer",
                header: "Payer Name",
                cell: (item) => (
                  <NavLink to={hasPermission ? `${item?.id}/edit` : "#"}>
                    {item?.payer?.name}
                  </NavLink>
                ),
                sortingField: "payer.name",
                isRowHeader: true,
              },
              {
                id: "status",
                header: "Status",
                cell: (item) => <span>{item?.status?.name ?? "-"}</span>,
                sortingField: "enrollmentStatus.name",
              },
              {
                id: "payerProviderId",
                header: "Payer Provider ID",
                cell: (item) => <div>{item?.payerProviderId}</div>,
                sortingField: "payerProviderId",
              },
              {
                id: "provider",
                header: "Provider",
                cell: (item) => (
                  <span>
                    {(item?.provider &&
                      item?.provider?.lastName +
                        " " +
                        item?.provider?.firstName) ??
                      "-"}
                  </span>
                ),
                sortingField: "provider.lastName",
              },
              {
                id: "facility",
                header: "Facility",
                cell: (item) => <span>{item?.facility?.name ?? "-"}</span>,
                sortingField: "facility.name",
              },
              {
                id: "facilityGroup",
                header: "Facility Group",
                cell: (item) => <span>{item?.facilityGroup?.name ?? "-"}</span>,
                sortingField: "facilityGroup.name",
              },
              {
                id: "submittedDate",
                header: "Submitted Date",
                cell: (item) => (
                  <span>
                    {IncredableDateFormat(item?.submittedDate) ?? "-"}
                  </span>
                ),
                sortingField: "submittedDate",
              },
              {
                id: "approvedDate",
                header: "Approved Date",
                cell: (item) => (
                  <span>{IncredableDateFormat(item?.approvedDate) ?? "-"}</span>
                ),
                sortingField: "approvedDate",
              },
              {
                id: "effectiveDate",
                header: "Effective Date",
                cell: (item) => (
                  <span>
                    {IncredableDateFormat(item?.effectiveDate) ?? "-"}
                  </span>
                ),
                sortingField: "effectiveDate",
              },
              {
                id: "recredentialingDate",
                header: "Recredentialing Date",
                cell: (item) => (
                  <span>
                    {IncredableDateFormat(item?.recredentialingDate) ?? "-"}
                  </span>
                ),
                sortingField: "recredentialingDate",
              },
              {
                id: "followupDate",
                header: "Follow-up Date",
                cell: (item) => (
                  <span>
                    {item?.alertDays > 0 ? `${item?.alertDays} days` : "-"}
                  </span>
                ),
              },
              {
                id: "description",
                header: "Description",
                cell: (item) => <span>{item?.description ?? "-"}</span>,
              },
              {
                id: "workflow",
                header: "Workflow",
                cell: (item) => <span>{item?.workflow?.name ?? "-"}</span>,
                sortingField: "workflow.name",
              },
            ]}
            items={items}
            pagination={
              <Pagination
                {...paginationProps}
                currentPageIndex={pageNumber + 1}
                onChange={({ detail }) => {
                  setPageNumber(detail.currentPageIndex - 1);
                }}
                pagesCount={
                  !!tablePreferences?.pageSize &&
                  tablePreferences?.pageSize !== 0
                    ? Math.ceil(
                        enrollmentsTotalRecords / tablePreferences?.pageSize
                      )
                    : 1
                }
              />
            }
            preferences={
              <TablePreferences
                preferences={tablePreferences}
                setPreferences={(preferences) => {
                  if (preferences.pageSize !== tablePreferences.pageSize) {
                    preferences.pageSize && setPageSize(preferences.pageSize);
                  }
                  setTablePreferences(preferences);
                }}
                contentDisplayOptions={PAYERENROLLMENT_CONTENT_DISPLAY_OPTIONS}
              />
            }
            loadingText="Loading resources"
            selectionType="single"
            trackBy="id"
            filter={TableFilters}
            header={
              <div style={{ marginTop: "8px", marginBottom: "12px" }}>
                <Header
                  actions={
                    <Space size="middle">
                      <Button
                        onClick={() => {
                          !!reload && reload();
                        }}
                      >
                        <ReloadOutlined />
                      </Button>
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: "#2B6BE6",
                          },
                        }}
                      >
                        <Dropdown
                          menu={{
                            items: groupByItems,
                            selectable: true,
                            onClick: ({ key }) => {
                              navigate(`?groupBy=${key}`, {
                                replace: true,
                              });
                              setSelectedLeftRows([]);
                              setLeftSearchText("");
                            },
                          }}
                        >
                          <Button>
                            Group by <DownOutlined />
                          </Button>
                        </Dropdown>
                      </ConfigProvider>
                      <Dropdown
                        menu={{
                          disabled: selectedRows?.length !== 1,
                          items: [
                            {
                              label: hasPermission ? "Edit" : "View",
                              key: "edit",
                              icon: <EditOutlined />,
                              onClick: () => {
                                navigate(`${selectedRows?.at(0)?.id}/edit  `);
                              },
                            },
                            {
                              label: "Delete",
                              key: "delete",
                              onClick: () => setIsDeleteModalOpen(true),
                              disabled: !hasDeletePermission,
                              icon: <DeleteOutlined />,
                              danger: true,
                            },
                          ],
                        }}
                      >
                        <Button>
                          Actions
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: "#2B6BE6",
                          },
                        }}
                      >
                        <Button
                          disabled={!hasPermission}
                          onClick={() => navigate(`add`)}
                          type="primary"
                          icon={<PlusOutlined />}
                        >
                          Add Payer Enrollment
                        </Button>
                      </ConfigProvider>
                    </Space>
                  }
                >
                  <Typography.Title level={3} style={{ marginTop: "0" }}>
                    Enrollments{" "}
                    <Typography.Text
                      type="secondary"
                      style={{ fontSize: "16px" }}
                    >{`(${enrollmentsTotalRecords})`}</Typography.Text>
                  </Typography.Title>
                </Header>
              </div>
            }
            {...collectionProps}
            columnDisplay={tablePreferences?.contentDisplay}
            wrapLines={tablePreferences?.wrapLines}
            stripedRows={tablePreferences?.stripedRows}
            contentDensity={tablePreferences?.contentDensity}
            stickyColumns={tablePreferences?.stickyColumns}
          />
        </Col>
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (selectedRows?.at(0)?.id) {
              setIsDeleteModalOpen(false);
              spinnerContext?.showSpinner();
              await deletePayerEnrollment({
                payerEnrollmentId: String(selectedRows?.at(0)?.id),
              });
              spinnerContext?.hidespinner();
              toastContext?.openSuccessNotification({
                message: `Payer Enrollment deleted successfully`,
                placement: "topRight",
              });
            }
          }}
          closeModal={() => setIsDeleteModalOpen(false)}
          header="Delete Enrollment"
          content={
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography.Text>Payer Name</Typography.Text>
                <Typography.Text strong style={{ marginTop: "11px" }}>
                  {selectedRows?.at(0)?.payer?.name}
                </Typography.Text>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography.Text>Provider Name</Typography.Text>
                <Typography.Text strong style={{ marginTop: "11px" }}>
                  {selectedRows?.at(0)?.provider?.firstName +
                    " " +
                    selectedRows?.at(0)?.provider?.lastName}
                </Typography.Text>
              </div>
            </>
          }
          description="Are you sure you want to delete?"
        />
      </Row>
    </div>
  );
}
