import { useContext, useState } from "react";
import {
  Button,
  ConfigProvider,
  Dropdown,
  Layout,
  Space,
  theme,
  Typography,
} from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { Payers } from "../../redux/api/payers/types";
import { useDeletePayerMutation } from "../../redux/api/payers/payers";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import usePayerFilter from "../../table-filters/payer-filters";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { useLocalStorage } from "../../common/localStorage";
import {
  PAYER_CONTENT_DISPLAY_OPTIONS,
  PAYER_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "./table-config";
import { AsYouType } from "libphonenumber-js";
const { useToken } = theme;

export default function PayersListV2() {
  const [selectedRows, setSelectedRows] = useState<Payers[]>([]);
  const {
    TableFilters,
    filteredPayers = [],
    UseQueryHookResult: {
      refetch,
      setPageSize,
      isFetching,
      sortingDetails,
      setSortingDetails,
    },
  } = usePayerFilter();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { token } = useToken();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const [deletePayer] = useDeletePayerMutation();

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.delete") ?? false)
  );

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Payer-Table-Preferences",
    PAYER_TABLE_DEFAULT_PREFERENCES
  );

  const { items, collectionProps, paginationProps } = useCollection(
    filteredPayers,
    {
      pagination: { pageSize: tablePreferences?.pageSize },
    }
  );

  return (
    <Layout style={{ margin: "20px 30px 0 30px" }}>
      <Space size="large" direction="vertical">
        <Table
          loading={isFetching}
          onSelectionChange={({ detail }) => {
            setSelectedRows([detail?.selectedItems[0]]);
          }}
          onRowClick={(onRowClickDetails) => {
            const selectedItem = onRowClickDetails?.detail?.item;
            if (selectedItem) {
              if (selectedRows?.at(0)?.id === selectedItem?.id) {
                setSelectedRows([]);
              } else {
                setSelectedRows([selectedItem]);
              }
            }
          }}
          selectedItems={selectedRows || []}
          sortingColumn={{ sortingField: sortingDetails?.sortingField }}
          sortingDescending={sortingDetails?.isDescending}
          onSortingChange={({ detail }) => {
            setSortingDetails({
              sortingField: detail?.sortingColumn?.sortingField,
              isDescending: detail?.isDescending,
            });
          }}
          columnDefinitions={[
            {
              id: "id",
              header: <div>ID</div>,
              cell: (item) => <span>{item.id}</span>,
            },
            {
              id: "name",
              header: <div>Name</div>,
              cell: (item) => (
                <NavLink to={hasPermission ? `/payers/${item.id}/edit` : `#`}>
                  {item?.name}
                </NavLink>
              ),
            },
            {
              id: "payerId",
              header: <div>Payer ID</div>,
              cell: (item) => <div>{item?.payerId ?? "-"}</div>,
            },
            {
              id: "website",
              header: "Website",
              cell: (item) => (
                <a href={item.website} target="_blank">
                  {item.website || "-"}
                </a>
              ),
              sortingField: "website",
              isRowHeader: true,
            },
            {
              id: "addressLane1",
              header: <div>Address Line 1</div>,
              cell: (item) => (
                <div> {item?.payerAddress?.addressLane1 ?? "-"} </div>
              ),
            },
            {
              id: "addressLane2",
              header: <div>Address Line 2</div>,
              cell: (item) => (
                <div> {item?.payerAddress?.addressLane2 ?? "-"} </div>
              ),
            },
            {
              id: "city",
              header: <div>City</div>,
              cell: (item) => <div> {item?.payerAddress?.city ?? "-"} </div>,
            },
            {
              id: "state",
              header: <div>State</div>,
              cell: (item) => <div> {item?.payerAddress?.state ?? "-"} </div>,
            },
            {
              id: "zipcode",
              header: <div>Zipcode</div>,
              cell: (item) => <div> {item?.payerAddress?.zipcode ?? "-"} </div>,
            },
            {
              id: "contactName",
              header: <div>Contact Name</div>,
              cell: (item) => (
                <div> {item?.payerAddress?.contactName ?? "-"} </div>
              ),
            },
            {
              id: "contactEmail",
              header: <div>Contact Email</div>,
              cell: (item) => (
                <div> {item?.payerAddress?.contactEmail ?? "-"} </div>
              ),
            },
            {
              id: "contactFax",
              header: <div>Contact Fax</div>,
              cell: (item) => (
                <div> {item?.payerAddress?.contactFax ?? "-"} </div>
              ),
            },
            {
              id: "contactPhone",
              header: <div>Contact Phone</div>,
              cell: (item) => (
                <div>
                  {" "}
                  {new AsYouType("US").input(
                    item?.payerAddress?.contactPhone ?? ""
                  ) ?? "-"}{" "}
                </div>
              ),
            },
          ]}
          items={items}
          pagination={<Pagination {...paginationProps} />}
          preferences={
            <TablePreferences
              preferences={tablePreferences}
              setPreferences={(preferences) => {
                if (preferences.pageSize !== tablePreferences.pageSize) {
                  preferences.pageSize && setPageSize(preferences.pageSize);
                }
                setTablePreferences(preferences);
              }}
              contentDisplayOptions={PAYER_CONTENT_DISPLAY_OPTIONS}
            />
          }
          loadingText="Loading resources"
          selectionType="single"
          trackBy="id"
          filter={TableFilters}
          header={
            <div style={{ marginTop: "8px", marginBottom: "12px" }}>
              <Header
                actions={
                  <Space size="middle">
                    <Button
                      onClick={() => {
                        !!refetch && refetch();
                      }}
                    >
                      <ReloadOutlined />
                    </Button>
                    <Dropdown
                      menu={{
                        disabled: selectedRows?.length !== 1,
                        items: [
                          {
                            label: hasPermission ? "Edit" : "View",
                            key: "edit",
                            icon: <EditOutlined />,
                            onClick: () => {
                              navigate(`${selectedRows?.at(0)?.id}/edit`);
                            },
                          },
                          {
                            label: "Delete",
                            key: "delete",
                            onClick: () => setDeleteModalOpen(true),
                            disabled: !hasDeletePermission,
                            icon: <DeleteOutlined />,
                            danger: true,
                          },
                        ],
                      }}
                    >
                      <Button>
                        Actions
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "#2B6BE6",
                        },
                      }}
                    >
                      <Button
                        disabled={!hasPermission}
                        onClick={() => navigate(`add`)}
                        type="primary"
                        icon={<PlusOutlined />}
                      >
                        Add Payer
                      </Button>
                    </ConfigProvider>
                  </Space>
                }
              >
                <Typography.Title level={3} style={{ marginTop: "0" }}>
                  Payers{" "}
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "16px" }}
                  >{`(${filteredPayers?.length})`}</Typography.Text>
                </Typography.Title>
              </Header>
            </div>
          }
          {...collectionProps}
          columnDisplay={tablePreferences?.contentDisplay}
          wrapLines={tablePreferences?.wrapLines}
          stripedRows={tablePreferences?.stripedRows}
          contentDensity={tablePreferences?.contentDensity}
          stickyColumns={tablePreferences?.stickyColumns}
        />
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (selectedRows?.at(0)?.id) {
              setDeleteModalOpen(false);
              spinnerContext?.showSpinner();
              try {
                await deletePayer({
                  payerId: String(selectedRows?.at(0)?.id),
                }).unwrap();
                toastContext?.openSuccessNotification({
                  message: `Payer deleted successfully`,
                  placement: "topRight",
                });
              } catch (error: any) {
                toastContext?.openErrorNotification({
                  message: error?.data?.errorMessage || `Failed to delete Payer`,
                  placement: "topRight"
                })
              }
              spinnerContext?.hidespinner();
              setSelectedRows([]);
            }
          }}
          closeModal={() => setDeleteModalOpen(false)}
          header="Delete Payer"
          content={
            <Typography.Text>
              Payer Name:{" "}
              <Typography.Text strong>
                {selectedRows?.at(0)?.name}
              </Typography.Text>
            </Typography.Text>
          }
          description="Are you sure you want to delete this payer?"
        />
      </Space>
    </Layout>
  );
}
