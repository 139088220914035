import { Col, Divider, Flex, Form, Row, Space, theme, Typography } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import { AffiliationStatusRequest, CMECredentialTypeRequest } from "../../redux/api/provider/types";
import {
    useGetAllAffiliationStatusQuery,
    useGetAllCMECredentialTypeQuery,
    useUpdateAffiliationStatusMutation,
    useUpdateCMECredentialTypeMutation,
} from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { ToastContext } from "../../context/toast/incredable-toast";
import { SpinnerContext } from "../../context/spinner/spinner";
import IncredableContent from "../../components/incredable-content";
import RHFTextArea from "../../components/RHF/RHFTextArea";

const { useToken } = theme;

type FormInputType = CMECredentialTypeRequest;
export default function EditCMECredentialType() {
    const { cmeCredentialTypeId } = useParams();
    const navigate = useNavigate();
    const methods = useForm<FormInputType>();
    const spinnerContext = useContext(SpinnerContext);
    const toastContext = useContext(ToastContext);
    const { data: cmeCredentialType } = useGetAllCMECredentialTypeQuery();
    const type = cmeCredentialType?.find(
        (name) => name?.id == cmeCredentialTypeId
    );
    const [updateCMECredentialType] = useUpdateCMECredentialTypeMutation();

    useEffect(() => {
        if (type) {
            methods.setValue("name", type?.name ?? "");
            methods.setValue("creditsRequired", type?.creditsRequired ?? "");
            methods.setValue("renewalValue", type?.renewalValue ?? "");
            methods.setValue("renewalUnit", type?.renewalUnit ?? "");
            methods.setValue("description", type?.description ?? "");
        }
    }, [type]);

    return (
        <IncredableContent
            breadcrumbGroupProps={{
                items: [
                    {
                        text: "CME Credential Type",
                        href: "/cmeCredentialType",
                    },
                    {
                        text: "Edit",
                        href: "#",
                    },
                ],
            }}
        >
            <FormProvider {...methods}>
                <Form
                    name="editCmeCredentialType"
                    layout="vertical"
                    onFinish={methods.handleSubmit(async (data: FormInputType) => {
                        spinnerContext?.showSpinner();
                        if (!!cmeCredentialTypeId)
                            await updateCMECredentialType({
                                ...data,
                                id: cmeCredentialTypeId,
                            });
                        toastContext?.openInfoNotification({
                            message: `CME Credential Type saved successfully`,
                            placement: "topRight",
                        });
                        spinnerContext?.hidespinner();
                        navigate(-1);
                    })}
                    autoComplete="off"
                >
                    <Flex gap="small" wrap justify="space-between">
                        <Typography.Text
                            strong
                            style={{
                                fontSize: "22px",
                                color: "#1D1A1F",
                                lineHeight: "25px",
                                fontWeight: "700",
                            }}
                        >
                            CME Credential Type
                        </Typography.Text>
                        <Space>
                            <IncredableButton
                                secondary
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                Cancel
                            </IncredableButton>
                            <IncredableButton type="primary" htmlType="submit">
                                Save
                            </IncredableButton>
                        </Space>
                    </Flex>
                    <Divider />
                    <Form.List name="cmeCredentialType" initialValue={[{}]}>
                        {(fields, { add }) => {
                            return (
                                <>
                                    {fields.map(({ key, name, ...restField }, index) => (
                                        <div key={key}>
                                            {index > 0 && <Divider />}
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col span={8}>
                                                    <RHFTextField
                                                        formItemProps={{
                                                            label: "Name",
                                                        }}
                                                        inputProps={{}}
                                                        controllerProps={{
                                                            name: `name`,
                                                        }}
                                                        rules={{ required: "This field is required" }}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <RHFTextField
                                                        formItemProps={{
                                                            label: "Credits Required",
                                                        }}
                                                        inputProps={{ type: "number" }}
                                                        controllerProps={{
                                                            name: `creditsRequired`,
                                                        }}
                                                        rules={{ required: "This field is required" }}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <RHFTextField
                                                        formItemProps={{
                                                            label: "Renewal Value",
                                                        }}
                                                        inputProps={{ type: "number" }}
                                                        controllerProps={{
                                                            name: `renewalValue`,
                                                        }}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <RHFTextField
                                                        formItemProps={{
                                                            label: "Renewal Unit",
                                                        }}
                                                        inputProps={{}}
                                                        controllerProps={{
                                                            name: `renewalUnit`,
                                                        }}
                                                    />
                                                </Col>
                                                <Divider/>
                                                <Col span={24}>
                                                    <RHFTextArea
                                                        formItemProps={{
                                                            label: "Description",
                                                        }}
                                                        textAreaProps={{}}
                                                        controllerProps={{
                                                            name: `description`,
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </>
                            );
                        }}
                    </Form.List>
                </Form>
            </FormProvider>
        </IncredableContent>
    );
}
