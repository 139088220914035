import { Select, Space, Tag } from "antd";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useGetFacilitiesQuery } from "../redux/api/facility/facility";
import { useGetBasicAllProvidersQuery } from "../redux/api/provider/provider";
import { debounce } from "lodash";
import {
  useGetAllPayerEnrollmentStatusQuery,
  useGetBasicAllPayerQuery,
  useLazyGetAllEnrollmentsQuery,
} from "../redux/api/payerenrollment/payerenrollment";
import { useGetAllPayerGroupQuery } from "../redux/api/payergroup/payergroup";
import { useGetAllFacilityGroupQuery } from "../redux/api/facilitygroup/facilityGroup";

export default function usePayerEnrollmentFilter({
  key,
  id = [],
}: {
  key?: string;
  id?: string[];
}) {
  const [selectedFilterkey, setSelectedFilterkey] = useState<string>("payer");
  const [providerNameFiltervalue, setProviderNameFiltervalue] = useState<
    string[] | undefined
  >(undefined);
  const [payerNameFilterValue, setPayerNameFilterValue] = useState<
    string[] | undefined
  >(undefined);
  const [netWorkNameFilterValue, setNetWorkNameFilterValue] = useState<
    string[] | undefined
  >(undefined);
  const [statusQueryFilterValue, setStatusQueryFilterValue] = useState<
    string | undefined
  >(undefined);
  const [facilityNameQueryFilterValue, setFacilityNameQueryFilterValue] =
    useState<string | undefined>(undefined);

  const [
    facilityGroupNameQueryFilterValue,
    setFacilityGroupNameQueryFilterValue,
  ] = useState<string | undefined>(undefined);
  const [sortingDetails, setSortingDetails] = useState<{
    sortingField?: string;
    isDescending?: boolean;
  }>({ sortingField: "id", isDescending: false });
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { data: facilities, isLoading: facilitiesLoading } =
    useGetFacilitiesQuery();
  const { data: providers } = useGetBasicAllProvidersQuery();
  const { data: status = [] } = useGetAllPayerEnrollmentStatusQuery();
  const { data: networks } = useGetAllPayerGroupQuery();
  const { data: facilityGroup } = useGetAllFacilityGroupQuery();
  const { data: payers } = useGetBasicAllPayerQuery();
  const [triggerEnrollment, { data: enrollmentsData, isFetching }] =
    useLazyGetAllEnrollmentsQuery({});

  useEffect(() => {
    if (key === "provider") {
      setProviderNameFiltervalue((prev) => {
        return prev !== id ? id : prev;
      });
      setPayerNameFilterValue(undefined);
      setNetWorkNameFilterValue(undefined);
      setSelectedFilterkey("payer");
    } else if (key === "network") {
      setNetWorkNameFilterValue((prev) => {
        return prev !== id ? id : prev;
      });
      setProviderNameFiltervalue(undefined);
      setPayerNameFilterValue(undefined);
      setSelectedFilterkey("payer");
    } else if (key === "payer") {
      setPayerNameFilterValue((prev) => {
        return prev !== id ? id : prev;
      });
      setProviderNameFiltervalue(undefined);
      setNetWorkNameFilterValue(undefined);
      setSelectedFilterkey("network");
    } else {
      setPayerNameFilterValue(undefined);
      setProviderNameFiltervalue(undefined);
      setNetWorkNameFilterValue(undefined);
    }
  }, [key, id.length]);

  useEffect(() => {
    setPageNumber(0);
  }, [
    providerNameFiltervalue,
    statusQueryFilterValue,
    facilityNameQueryFilterValue,
    facilityGroupNameQueryFilterValue,
    payerNameFilterValue,
    netWorkNameFilterValue,
  ]);

  const currentFilters = useMemo(
    () => ({
      pageNumber,
      pageSize,
      providerId: providerNameFiltervalue,
      statusId: statusQueryFilterValue,
      facilityId: facilityNameQueryFilterValue,
      facilityGroupId: facilityGroupNameQueryFilterValue,
      payerId: payerNameFilterValue,
      payerGroupId: netWorkNameFilterValue,
      sortBy: sortingDetails?.sortingField,
      direction: sortingDetails?.isDescending ? "DSC" : "ASC",
    }),
    [
      pageNumber,
      pageSize,
      providerNameFiltervalue,
      statusQueryFilterValue,
      facilityNameQueryFilterValue,
      facilityGroupNameQueryFilterValue,
      payerNameFilterValue,
      netWorkNameFilterValue,
      sortingDetails,
    ]
  );

  const debouncedTriggerEnrollment = useCallback(
    debounce((filters) => {
      triggerEnrollment(filters);
    }, 1200),
    [providerNameFiltervalue, payerNameFilterValue, netWorkNameFilterValue]
  );

  useEffect(() => {
    return () => {
      debouncedTriggerEnrollment.cancel();
    };
  }, [debouncedTriggerEnrollment]);

  useEffect(() => {
    debouncedTriggerEnrollment(currentFilters);
  }, [currentFilters, debouncedTriggerEnrollment]);

  const reload = useCallback(() => {
    debouncedTriggerEnrollment(currentFilters);
  }, [currentFilters, debouncedTriggerEnrollment]);

  const TableFilters = (
    <Space direction="vertical">
      <Space size="small">
        <Select
          value={selectedFilterkey}
          onChange={setSelectedFilterkey}
          style={{ width: "140px" }}
          options={[
            ...(key !== "payer" ? [{ value: "payer", label: "Payer" }] : []),
            ...(key !== "network"
              ? [{ value: "network", label: "Network" }]
              : []),
            ...(key !== "provider"
              ? [{ value: "provider", label: "Provider" }]
              : []),
            { value: "status", label: "Status" },
            { value: "facility", label: "Facility" },
            { value: "facilityGroup", label: "Facility Group" },
          ]}
        />
        {key !== "payer" && selectedFilterkey === "payer" && (
          <Select
            key="payer-select"
            placeholder="Select Payer"
            allowClear
            showSearch
            optionFilterProp="label"
            value={payerNameFilterValue}
            onChange={setPayerNameFilterValue}
            style={{ width: "320px" }}
            options={payers?.map((payer) => ({
              label: payer.name,
              searchValue: payer.name,
              value: payer.id,
            }))}
          />
        )}
        {key !== "network" && selectedFilterkey === "network" && (
          <Select
            key="network-select"
            placeholder="Select Network"
            allowClear
            showSearch
            optionFilterProp="label"
            value={netWorkNameFilterValue}
            onChange={setNetWorkNameFilterValue}
            style={{ width: "320px" }}
            options={networks?.map((network) => ({
              label: network.name,
              searchValue: network.name,
              value: network.id,
            }))}
          />
        )}
        {key !== "provider" && selectedFilterkey === "provider" && (
          <Select
            key="provider-select"
            placeholder="Select Provider"
            allowClear
            showSearch
            optionFilterProp="label"
            value={providerNameFiltervalue}
            onChange={setProviderNameFiltervalue}
            style={{ width: "320px" }}
            options={providers?.map((provider) => ({
              label: `${provider.firstName} ${provider.lastName}`,
              searchValue: provider.firstName,
              value: provider.id,
            }))}
          />
        )}
        {selectedFilterkey === "status" && (
          <Select
            key="status-select"
            placeholder="Select status"
            allowClear
            showSearch
            optionFilterProp="label"
            value={statusQueryFilterValue}
            onChange={setStatusQueryFilterValue}
            style={{ width: "320px" }}
            options={status.map((status) => ({
              label: status?.name,
              searchValue: status?.name,
              value: status?.id + "",
            }))}
          />
        )}
        {selectedFilterkey === "facility" && (
          <Select
            key="facility-select"
            allowClear
            showSearch
            optionFilterProp="label"
            placeholder="Select facility"
            value={facilityNameQueryFilterValue}
            onChange={setFacilityNameQueryFilterValue}
            style={{ width: "320px" }}
            options={facilities?.map((facility) => ({
              label: facility?.name,
              searchValue: facility?.name,
              value: facility?.id + "",
            }))}
          />
        )}
        {selectedFilterkey === "facilityGroup" && (
          <Select
            key="facility-group-select"
            allowClear
            showSearch
            optionFilterProp="label"
            placeholder="Select Facility Group"
            value={facilityGroupNameQueryFilterValue}
            onChange={setFacilityGroupNameQueryFilterValue}
            style={{ width: "320px" }}
            options={facilityGroup?.map((facility) => ({
              label: facility?.name,
              searchValue: facility?.name,
              value: facility?.id + "",
            }))}
          />
        )}
      </Space>
      <div>
        {key !== "payer" && !!payerNameFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setPayerNameFilterValue(undefined);
            }}
          >
            <span>
              Payer ={" "}
              {
                payers?.find(
                  (payer) => payer?.id + "" === payerNameFilterValue + ""
                )?.name
              }
            </span>
          </Tag>
        )}
        {key !== "network" && !!netWorkNameFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setNetWorkNameFilterValue(undefined);
            }}
          >
            <span>
              NetWork ={" "}
              {
                networks?.find(
                  (network) => network?.id + "" === netWorkNameFilterValue + ""
                )?.name
              }
            </span>
          </Tag>
        )}
        {key !== "provider" && !!providerNameFiltervalue && (
          <Tag
            closeIcon
            onClose={() => {
              setProviderNameFiltervalue(undefined);
            }}
          >
            <span>
              Provider ={" "}
              {
                providers?.find(
                  (provider) =>
                    provider?.id + "" === providerNameFiltervalue + ""
                )?.firstName
              }
            </span>
          </Tag>
        )}
        {!!statusQueryFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setStatusQueryFilterValue(undefined);
            }}
          >
            <span>
              Status ={" "}
              {
                status?.find(
                  (statusOption) =>
                    statusOption?.id + "" === statusQueryFilterValue + ""
                )?.name
              }
            </span>
          </Tag>
        )}
        {!!facilityNameQueryFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setFacilityNameQueryFilterValue(undefined);
            }}
          >
            <span>
              Facility ={" "}
              {
                facilities?.find(
                  (facility) =>
                    facility?.id + "" === facilityNameQueryFilterValue + ""
                )?.name
              }
            </span>
          </Tag>
        )}
        {!!facilityGroupNameQueryFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setFacilityGroupNameQueryFilterValue(undefined);
            }}
          >
            <span>
              Facility Group ={" "}
              {
                facilityGroup?.find(
                  (facility) =>
                    facility?.id + "" === facilityGroupNameQueryFilterValue + ""
                )?.name
              }
            </span>
          </Tag>
        )}
      </div>
    </Space>
  );

  return {
    TableFilters,
    filteredPayerEnrollments: enrollmentsData?.payerEnrollments || [],
    enrollmentsTotalRecords: enrollmentsData?.totalRecords ?? 0,
    UseQueryHookResult: {
      reload,
      isFetching,
      isLoading: facilitiesLoading,
      pageNumber,
      setPageNumber,
      pageSize,
      setPageSize,
      sortingDetails,
      setSortingDetails,
    },
  };
}
