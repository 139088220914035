import { Flex, Typography, Form, Space, Divider, Row, Col } from "antd";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ShareDocumentMutation } from "../../redux/api/documentrequest/types";
import { useContext, useEffect } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { useGetAllCategoryQuery } from "../../redux/api/category/category";
import { useGetBasicAllProvidersQuery } from "../../redux/api/provider/provider";
import {
  useAddPrivilegeDocumentShareRequestMutation,
  useGetPrivilegeQuery,
} from "../../redux/api/privileges/privileges";
import IncredableContent from "../../components/incredable-content";
import IncredableButton from "../../components/button";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFSelect from "../../components/RHF/RHFSelect";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { skipToken } from "@reduxjs/toolkit/query";

export default function AddPrivilegeDocumentRequest() {
  const navigate = useNavigate();
  const { privilegeId } = useParams();
  const methods = useForm<ShareDocumentMutation>();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const { data: categories } = useGetAllCategoryQuery();
  const { data: providers } = useGetBasicAllProvidersQuery();
  const { data: privilege } = useGetPrivilegeQuery(
    !!privilegeId ? { privilegeId: privilegeId } : skipToken
  );
  const [addPrivilegeDocumentShareRequest] =
    useAddPrivilegeDocumentShareRequestMutation();
  useEffect(() => {
    methods.setValue("providerId", privilege?.provider?.id ?? "");
  }, [privilege]);
  return (
    <IncredableContent
      breadcrumbGroupProps={{
        items: [
          {
            text: "Requests",
            href: `/privileges/editprivileges/${privilegeId}`,
          },
          {
            text: "Add",
            href: "#",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          layout="vertical"
          name="addDocumentRequest"
          onFinish={methods.handleSubmit(async (data) => {
            spinnerContext?.showSpinner();
            if (!!privilegeId) {
              try {
                await addPrivilegeDocumentShareRequest({
                  privilegeId: privilegeId,
                  ...data,
                }).unwrap();
                toastContext?.openInfoNotification({
                  message: `Request saved successfully`,
                  placement: "topRight",
                });
              } catch (error: any) {
                toastContext?.openErrorNotification({
                  message: error?.data?.errorMessage || `Failed to save Request`,
                  placement: "topRight",
                });
              }
              spinnerContext?.hidespinner();
              navigate(-1);
            }
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Add Document Share Request
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <RHFTextField
                formItemProps={{
                  label: "Title",
                }}
                inputProps={{}}
                controllerProps={{
                  name: `title`,
                }}
                rules={{ required: "This field is required" }}
              />
            </Col>

            <Col span={8}>
              <RHFSelect
                formItemProps={{ label: "Category" }}
                controllerProps={{
                  name: `categoryId`,
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Search to Select",
                  options: categories?.map((facility) => {
                    return {
                      label: facility?.name,
                      value: facility?.id,
                    };
                  }),
                }}
                rules={{ required: "This field is required" }}
              />
            </Col>
            <Col span={8}>
              <RHFSelect
                formItemProps={{ label: "Provider" }}
                controllerProps={{ name: "providerId" }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  disabled: true,
                  style: { width: "100%" },
                  placeholder: "Search to Select",
                  options: providers?.map((provider) => ({
                    label: `${provider.firstName} ${provider.lastName}`,
                    value: provider.id,
                  })),
                }}
                rules={{ required: "This field is required" }}
              />
            </Col>
            <Col span={24}>
              <RHFTextArea
                formItemProps={{
                  label: "Description",
                }}
                textAreaProps={{}}
                controllerProps={{
                  name: `description`,
                }}
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
