import { useParams } from "react-router-dom";
import { useGetAllCredentialTemplateCategoriesQuery, useUpdateCredentialTemplateCategoriesMutation } from "../../../redux/api/credentialpackages/credentialpackages";
import { useState } from "react";
import { Button, Col, ConfigProvider, Row } from "antd";
import { useGetAllCategoryQuery } from "../../../redux/api/category/category";
import { Category } from "../../../redux/api/category/types";
import { Table, Modal, Pagination } from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  CREDENTIALTEMPLATE_CATEGORY_CONTENT_DISPLAY_OPTIONS,
  CREDENTIALTEMPLATE_CATEGORY_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../table-config";
import { useLocalStorage } from "../../../common/localStorage";
import { skipToken } from "@reduxjs/toolkit/query";

export default function AddCategoryModal({
  visible,
  closeModal,
}: {
  visible: boolean;
  closeModal: VoidFunction;
}) {
  const {
    data: categories = [],
    isLoading,
    isFetching,
  } = useGetAllCategoryQuery();
  const { templateId } = useParams();
  const {
    data: templatecategories = [],
    refetch,
    isFetching: templateCategoryFetching,
    isLoading: templateCategoryLoading,
  } = useGetAllCredentialTemplateCategoriesQuery(
    templateId ? { credentialTemplateId: templateId } : skipToken
  );

  const [updateCredentialTemplateCategory] =
    useUpdateCredentialTemplateCategoriesMutation();

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Credential-Template-Category-Table-Preferences",
    CREDENTIALTEMPLATE_CATEGORY_TABLE_DEFAULT_PREFERENCES
  );

  const [selectedRows, setSelectedRows] = useState<Category[]>([]);

  const { items, collectionProps, paginationProps } = useCollection(
    categories,
    {
      pagination: { pageSize: tablePreferences.pageSize },
    }
  );

  return (
    <Modal
      header={"Add Categories"}
      onDismiss={closeModal}
      visible={visible}
      footer={
        <Row justify="end" gutter={16}>
          <Col>
            <Button onClick={closeModal}>Cancel</Button>
          </Col>
          <Col>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#2B6BE6",
                },
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  if (templateId) {
                    const categoryIds = selectedRows.map(
                      (category) => category.id
                    );
                    updateCredentialTemplateCategory({
                      id: templateId,
                      categories: categoryIds,
                    });
                  }
                  setSelectedRows([]);
                  closeModal();
                }}
              >
                Save
              </Button>
            </ConfigProvider>
          </Col>
        </Row>
      }
    >
      <Table
        loading={(isLoading || isFetching) && (templateCategoryFetching || templateCategoryLoading)}
        onSelectionChange={(selectionChangeDetail) => {
          setSelectedRows(selectionChangeDetail.detail.selectedItems);
        }}
        onRowClick={({ detail }) => {
          const temp = selectedRows;
          if (
            !detail.item ||
            !!templatecategories
              ?.map((category) => category?.id + "")
              ?.includes(detail.item.id + "")
          )
            return;
          if (temp?.includes(detail.item))
            setSelectedRows(temp.filter((item) => item !== detail.item));
          else setSelectedRows([detail.item, ...temp]);
        }}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={
              CREDENTIALTEMPLATE_CATEGORY_CONTENT_DISPLAY_OPTIONS
            }
          />
        }
        {...collectionProps}
        selectedItems={selectedRows || []}
        columnDefinitions={[
          {
            id: "name",
            header: <div>Name</div>,
            cell: (item) => <span>{item?.name}</span>,
          },
        ]}
        isItemDisabled={(item) => {
          return templatecategories
            ?.map((categories) => categories?.id + "")
            ?.includes(item?.id + "");
        }}
        items={items}
        loadingText="Loading resources"
        selectionType="multi"
        trackBy="id"
      />
    </Modal>
  );
}
