import { skipToken } from "@reduxjs/toolkit/query";
import {
  useDeleteCustomProfileRecordMutation,
  useGetAllRecordsByProviderAndSectionIdQuery,
  useGetSectionQuery,
} from "../../redux/api/section/section";
import { Box, Header, Table } from "@cloudscape-design/components";
import { ProviderProfileRecordType } from "./section-data";
import { renderAttribute } from "./provider-profile-section";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Space, Dropdown, Button, ConfigProvider } from "antd";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useContext, useState } from "react";
import AddEditProviderProfileSection from "./add-edit-provider-profile-section";
import { useParams } from "react-router-dom";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";

export default function SectionTableList({ sectionId }: { sectionId: string }) {
  const [selectedRow, setSelectedRow] =
    useState<ProviderProfileRecordType | null>(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [recordId, setRecordId] = useState<string | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const { providerId } = useParams<{ providerId?: string }>();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const { data: sectionData } = useGetSectionQuery(
    sectionId ? { id: sectionId } : skipToken
  );
  const [deleteProviderRecord] = useDeleteCustomProfileRecordMutation();

  const {
    data: providerProfileRecords,
    isLoading,
    isFetching,
  } = useGetAllRecordsByProviderAndSectionIdQuery(
    providerId ? { providerId, sectionId } : skipToken
  );

  const handleDeleteSection = async () => {
    if (selectedRow && selectedRow.recordId && providerId) {
      try {
        setDeleteModalOpen(false);
        spinnerContext?.showSpinner();
        await deleteProviderRecord({
          providerId,
          recordId: selectedRow.recordId,
        }).unwrap();
        toastContext?.openSuccessNotification({
          message: "Record deleted successfully",
          placement: "topRight",
        });
        setSelectedRow(null);
        setRecordId(undefined);
      } catch (error) {
        toastContext?.openErrorNotification({
          message: "Failed to delete record",
          placement: "topRight",
        });
      } finally {
        spinnerContext?.hidespinner();
        setDeleteModalOpen(false);
      }
    }
  };

  return (
    <>
      <Table
        loading={isLoading || isFetching}
        columnDefinitions={
          sectionData?.attributes
            ?.filter((attribute) => attribute?.type !== "Divider")
            ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
            ?.map((attribute) => ({
              id: attribute?.id ?? "",
              header: attribute?.name ?? "",
              cell: (item: ProviderProfileRecordType) =>
                attribute?.id
                  ? renderAttribute(attribute, item[attribute.id])
                  : "-",
            })) || []
        }
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <b>No resources</b>
          </Box>
        }
        items={providerProfileRecords || []}
        selectedItems={selectedRow ? [selectedRow] : []}
        selectionType="single"
        trackBy={(item: ProviderProfileRecordType) =>
          item.recordId || String(item.id)
        }
        onRowClick={({ detail }) => {
          const clickedItem = detail.item;
          if (selectedRow?.recordId === clickedItem.recordId) {
            setSelectedRow(null);
            setRecordId(undefined);
          } else {
            setSelectedRow(clickedItem);
            setRecordId(clickedItem.recordId);
          }
        }}
        header={
          <Header
            actions={
              <Space size="middle">
                <Dropdown
                  disabled={!selectedRow}
                  menu={{
                    items: [
                      {
                        label: "Edit",
                        key: "edit",
                        onClick: () => toggleDrawer(),
                        icon: <EditOutlined />,
                      },
                      {
                        label: "Delete",
                        key: "delete",
                        onClick: () => setDeleteModalOpen(true),
                        icon: <DeleteOutlined />,
                        danger: true,
                      },
                    ],
                  }}
                >
                  <Button>
                    Actions
                    <DownOutlined />
                  </Button>
                </Dropdown>
                <ConfigProvider
                  theme={{
                    token: { colorPrimary: "#2B6BE6" },
                  }}
                >
                  <Button
                    onClick={() => {
                      setRecordId(undefined);
                      setSelectedRow(null);
                      toggleDrawer();
                    }}
                    type="primary"
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                </ConfigProvider>
              </Space>
            }
          >
            {`${sectionData?.name || ""} (${
              providerProfileRecords?.length || 0
            })`}
          </Header>
        }
      />
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        size="90vw"
      >
        <AddEditProviderProfileSection
          recordId={recordId}
          sectionData={sectionData}
          toggleDrawer={toggleDrawer}
        />
      </Drawer>
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={handleDeleteSection}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete Record"
        content=""
        description="Are you sure you want to delete this record?"
      />
    </>
  );
}
