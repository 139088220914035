import {
  Button,
  ConfigProvider,
  Dropdown,
  Flex,
  Space,
  Tag,
  Typography,
} from "antd";
import { useContext, useMemo, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  CalendarOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { useDeleteProviderCMEMutation } from "../../redux/api/provider/provider";
import { ProviderCMEType } from "../../redux/api/provider/types";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import {
  CME_TRACKER_CONTENT_DISPLAY_OPTIONS,
  CME_TRACKER_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../table-config";
import { useLocalStorage } from "../../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  Header,
  Pagination,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import IncredableDateFormat from "../../common/incredable-date-format";
import useCMEFilter from "../../table-filters/cme-filters";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import EditCMETracker from "../modify/edit-providercmetracker";

const colors = ["green", "blue", "red", "orange", "purple"]; // Define your array of colors

export default function CMETrackerList() {
  const { providerId } = useParams();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<ProviderCMEType[]>([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
    setSelectedRows([]);
  };

  const [deleteProviderCME] = useDeleteProviderCMEMutation();
  const {
    TableFilters,
    filteredCME,
    UseQueryHookResult: { refetch, isFetching, isLoading },
  } = useCMEFilter({ providerId: providerId });

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.delete") ?? false)
  );
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-CME-Tracker-Table-Preferences",
    CME_TRACKER_TABLE_DEFAULT_PREFERENCES
  );

  const { items, collectionProps, paginationProps } = useCollection(
    filteredCME ?? [],
    {
      pagination: { pageSize: tablePreferences?.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    }
  );

  const sumByCreditType = useMemo(() => {
    return filteredCME?.reduce<Record<string, number>>(
      (accumulator, currentObject) => {
        const creditType = currentObject?.creditType;
        const value = currentObject.creditHours;

        // If the name already exists in the accumulator, add the value
        if (accumulator[creditType]) {
          accumulator[creditType] += value;
        } else {
          // Otherwise, initialize the sum with the current value
          accumulator[creditType] = value;
        }

        return accumulator;
      },
      {}
    );
  }, [filteredCME]);

  return (
    <>
      <Table
        loading={isFetching || isLoading}
        onSelectionChange={({ detail }) => {
          setSelectedRows([detail.selectedItems[0]]);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows.at(0)?.id === selectedItem?.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        columnDefinitions={[
          {
            id: "ceTitle",
            header: <div>CE Title</div>,
            cell: (item) => (
              <Button
                style={{ margin: "0", padding: "0", height: "0" }}
                onClick={() => {
                  setSelectedRows([item]);
                  toggleDrawer();
                }}
                type="link"
              >
                {item?.courseName}
              </Button>
            ),
          },
          {
            id: "creditHours",
            header: <div>Credit Hours</div>,
            cell: (item) => <div>{item?.creditHours ?? "-"}</div>,
          },
          {
            id: "credentialType",
            header: <div>Credential Type</div>,
            cell: (item) => <div>{item?.cmeCredentialType?.name ?? "-"}</div>,
          },
          {
            id: "dateAttended",
            header: <div>Date Attended</div>,
            cell: (item) => (
              <div>
                {" "}
                {item.dateAttended ? (
                  <span>
                    <CalendarOutlined />{" "}
                    {IncredableDateFormat(item.dateAttended)}
                  </span>
                ) : (
                  "-"
                )}{" "}
              </div>
            ),
          },
          {
            id: "expirationDate",
            header: <div>Expiration Date</div>,
            cell: (item) => (
              <div>
                {" "}
                {item.expirationDate ? (
                  <span>
                    <CalendarOutlined />{" "}
                    {IncredableDateFormat(item.expirationDate)}
                  </span>
                ) : (
                  "-"
                )}{" "}
              </div>
            ),
          },
          {
            id: "topicsFulfilled",
            header: <div>Topics Fulfilled</div>,
            cell: (item) => <div>{item?.topicsFulfilled ?? "-"}</div>,
          },
          {
            id: "alertDays",
            header: <div>Alert Days</div>,
            cell: (item) => (
              <span>{item.alertDays > 0 ? `${item.alertDays} days` : ""}</span>
            ),
          },
          {
            id: "creditType",
            header: <div>Credit Type</div>,
            cell: (item) => <div>{item?.creditType ?? "-"}</div>,
          },
          {
            id: "notes",
            header: <div>Notes</div>,
            cell: (item) => <div>{item?.notes ?? "-"}</div>,
          },
        ]}
        items={items}
        filter={TableFilters}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={CME_TRACKER_CONTENT_DISPLAY_OPTIONS}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        header={
          <div style={{ marginTop: "8px" }}>
            <SpaceBetween size={"s"}>
              <Header
                actions={
                  <Space size="middle">
                    <Button
                      onClick={() => {
                        !!refetch && refetch();
                      }}
                    >
                      <ReloadOutlined />
                    </Button>
                    <Dropdown
                      menu={{
                        disabled: selectedRows?.length !== 1,
                        items: [
                          {
                            label: hasPermission ? "Edit" : "View",
                            key: "edit",
                            icon: <EditOutlined />,
                            onClick: () => {
                              setIsOpen(true);
                            },
                          },
                          {
                            label: "Delete",
                            key: "delete",
                            onClick: () => setDeleteModalOpen(true),
                            disabled: !hasDeletePermission,
                            icon: <DeleteOutlined />,
                            danger: true,
                          },
                        ],
                      }}
                    >
                      <Button>
                        Actions
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "#2B6BE6",
                        },
                      }}
                    >
                      <Button
                        disabled={!hasPermission}
                        onClick={() => {
                          setSelectedRows([]);
                          toggleDrawer();
                        }}
                        type="primary"
                        icon={<PlusOutlined />}
                      >
                        Add
                      </Button>
                    </ConfigProvider>
                  </Space>
                }
              >
                <Space size="middle">
                  <Typography.Title
                    level={3}
                    style={{ marginTop: "0", fontSize: "22px" }}
                  >
                    CME Tracker{" "}
                    <Typography.Text
                      type="secondary"
                      style={{ fontSize: "16px" }}
                    >{`(${filteredCME?.length || 0})`}</Typography.Text>
                  </Typography.Title>
                </Space>
              </Header>
              <Flex gap="4px 0" wrap>
                {!!sumByCreditType &&
                  Object.entries(sumByCreditType)?.map(
                    ([key, value], index) => {
                      const color = colors[index % colors.length]; // Cycle through colors
                      if (key !== "null")
                        return (
                          <Tag color={color}>
                            {value ?? 0} {key} Credits completed
                          </Tag>
                        );
                      else return <></>;
                    }
                  )}
              </Flex>
            </SpaceBetween>
          </div>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        size="90vw"
      >
        <EditCMETracker
          key={selectedRows?.at(0)?.id ?? "add"}
          cmeId={"" + selectedRows?.at(0)?.id}
          toggleDrawer={toggleDrawer}
        />
      </Drawer>
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (!!providerId && !!selectedRows?.at(0)?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            try {
              await deleteProviderCME({
                providerId: providerId,
                providerCMEId: selectedRows?.at(0)?.id + " ",
              }).unwrap();
              toastContext?.openSuccessNotification({
                message: `CME deleted successfully`,
                placement: "topRight",
              });
            } catch (error: any) {
              toastContext?.openErrorNotification({
                message: error?.data?.errorMessage || `Failed to delete CME tracker list`,
                placement: "topRight"
              })
            }
            spinnerContext?.hidespinner();
            setSelectedRows([]);
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete CME"
        content={
          <Typography.Text>
            CME Tracker:{" "}
            <Typography.Text strong>
              {selectedRows?.at(0)?.courseName}
            </Typography.Text>
          </Typography.Text>
        }
        description="Are you sure you want to delete this cme?"
      />
    </>
  );
}
