import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space, Tag } from "antd";
import { useMemo, useState } from "react";
import {
  useGetAllContractTypesQuery,
} from "../redux/api/contracts/contract";
import { useGetFacilitiesQuery } from "../redux/api/facility/facility";
import { useGetAllPracticeEvaluationQuery } from "../redux/api/practiceevaluation/practiceevaluation";
import { useGetBasicAllProvidersQuery } from "../redux/api/provider/provider";
import { PrivilegeTypeOption, ResultOption } from "../privileges/constants";
import { useGetAllPrivilegesQuery, useGetAllPrivilegeTypesQuery } from "../redux/api/privileges/privileges";

export default function usePrivilegesFilter() {
  const [selectedFilterKey, setSelectedFilterKey] = useState<string>("privilegeType");

  const [privilegeTypeFilterValue, setPrivilegeTypeFilterValue] = useState<
    string | undefined
  >(undefined);

  const [providerFilterValue, setProviderFilterValue] = useState<
    string | undefined
  >(undefined);



  const { data: provider = [], isLoading: providerLoading } =
    useGetBasicAllProvidersQuery();
  const { data: privilegeType = [], isLoading: privilegeTypeLoading } =
    useGetAllPrivilegeTypesQuery();
  const {
    data: privileges = [],
    refetch,
    isLoading: PrivilegesLoading,
    isFetching,
  } = useGetAllPrivilegesQuery();

  const filteredPrivileges = useMemo(() => {
    if (providerLoading || PrivilegesLoading)
      return [];
    return privileges.filter((privilege) => {
      let matches = true;
      if (privilegeTypeFilterValue) {
        matches = matches && privilege?.privilegeType?.id === privilegeTypeFilterValue;
      }
      if (providerFilterValue) {
        matches =
          matches && privilege?.provider?.id === providerFilterValue;
      }
      return matches;
    });
  }, [
    privileges,
    providerFilterValue,
    privilegeTypeFilterValue,
    PrivilegesLoading,
    privilegeTypeLoading,
    providerLoading,
  ]);

  const TableFilters = (
    <Space direction="vertical">
      <Space size="small">
        <Select
          value={selectedFilterKey}
          onChange={(value) => setSelectedFilterKey(value)}
          style={{ width: "140px" }}
          options={[
            { value: "privilegeType", label: "Privilege Type" },
            { value: "provider", label: "Provider" },
          ]}
        />
        {selectedFilterKey === "provider" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={providerFilterValue}
            placeholder="Select Provider"
            onChange={(value) => setProviderFilterValue(value)}
            style={{ width: "320px" }}
            options={provider.map((provider) => ({
              label: `${provider?.lastName} ${provider?.firstName}`,
              value: provider.id,
            }))}
          />
        )}
        {selectedFilterKey === "privilegeType" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={privilegeTypeFilterValue}
            placeholder="Select Privilege Type"
            onChange={(value) => setPrivilegeTypeFilterValue(value)}
            style={{ width: "320px" }}
            options={privilegeType.map((type) => ({
              label: type.name,
              value: type.id,
            }))}
          />
        )}
      </Space>
      <div>
        {providerFilterValue && (
          <Tag closable onClose={() => setProviderFilterValue(undefined)}>
            Provider:{" "}
            {
              provider.find((provider) => provider.id === providerFilterValue)
                ?.lastName
            }
          </Tag>
        )}
        {privilegeTypeFilterValue && (
          <Tag closable onClose={() => setPrivilegeTypeFilterValue(undefined)}>
            Privilege Type:{" "}
            {
              privilegeType.find((type) => type.id === privilegeTypeFilterValue)
                ?.name
            }
          </Tag>
        )}
      </div>
    </Space>
  );

  return {
    TableFilters,
    filteredPrivileges,
    UseQueryHookResult: {
      refetch,
      isLoading: PrivilegesLoading,
      isFetching,
    },
  };
}
