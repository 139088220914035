import React from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Space,
  Card,
  Row as AntRow,
  Col,
  Typography,
  DatePicker,
  Checkbox,
  InputNumber,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import IncredableButton from "../../../components/button";
import { Attribute } from "../../../redux/api/section/types";
import { TypeOptions } from "../add-section";

const { Text } = Typography;

interface AddAttributeModalProps {
  isFieldsModalOpen: boolean;
  setIsFieldsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddAttribute: () => void;
  sectionAttribute: Attribute;
  setSectionAttribute: React.Dispatch<React.SetStateAction<Partial<Attribute>>>;
  attributeType: string;
}

const AddAttributeModal: React.FC<AddAttributeModalProps> = ({
  isFieldsModalOpen,
  setIsFieldsModalOpen,
  handleAddAttribute,
  sectionAttribute,
  setSectionAttribute,
  attributeType,
}) => {
  return (
    <Modal
      open={isFieldsModalOpen}
      onCancel={() => setIsFieldsModalOpen(false)}
      footer={
        <Space style={{ marginTop: 16 }}>
          <Button onClick={() => setIsFieldsModalOpen(false)}>Cancel</Button>
          <IncredableButton
            style={{ backgroundColor: "#598BEB" }}
            type="primary"
            onClick={handleAddAttribute}
          >
            Add Field
          </IncredableButton>
        </Space>
      }
      zIndex={1000}
      getContainer={false}
      centered
      width={"40vw"}
      height={"80vh"}
    >
      <Card title="Add Field">
        <Form layout="vertical">
          <AntRow gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={10}>
              <Form.Item label="Type">
                <Select
                  value={attributeType}
                  disabled={attributeType ? true : false}
                  options={TypeOptions}
                />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item
                label={
                  <span style={{ fontWeight: "400" }}>
                    Field Name <Text type="danger">*</Text>
                  </span>
                }
              >
                <Input
                  value={sectionAttribute.name}
                  onChange={(e) =>
                    setSectionAttribute({
                      ...sectionAttribute,
                      name: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              {attributeType !== "Divider" && (
                <Form.Item
                  label={<span style={{ fontWeight: "600" }}>Hint text</span>}
                >
                  <Input
                    value={sectionAttribute.description}
                    onChange={(e) =>
                      setSectionAttribute({
                        ...sectionAttribute,
                        description: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              )}
            </Col>
            <Col span={24} style={{ marginBottom: "12px" }}>
              {attributeType !== "Divider" && (
                <Form.Item
                  help={
                    "By default, this field is optional. Check this box to mark it required, ensuring users cannot leave it blank."
                  }
                  style={{ marginBottom: "32px" }}
                >
                  <Checkbox
                    checked={sectionAttribute.required}
                    onChange={(value) =>
                      setSectionAttribute({
                        ...sectionAttribute,
                        required: value?.target?.checked,
                      })
                    }
                  >
                    <span style={{ fontWeight: "600" }}>
                      Make This Field Required
                    </span>
                  </Checkbox>
                </Form.Item>
              )}
            </Col>
            <Col span={24} style={{ marginBottom: "12px" }}>
              {attributeType !== "Divider" && (
                <Form.Item
                  help={
                    "Check this box for confidential information (e.g., SSN, Medical ID). Access is restricted via Roles & Permissions."
                  }
                >
                  <Checkbox
                    checked={sectionAttribute.sensitive}
                    onChange={(value) =>
                      setSectionAttribute({
                        ...sectionAttribute,
                        sensitive: value?.target?.checked,
                      })
                    }
                  >
                    <span style={{ fontWeight: "600" }}>
                      Mark As Sensitive Info
                    </span>
                  </Checkbox>
                </Form.Item>
              )}
            </Col>
            <Col span={24}>
              {(attributeType === "Dropdown.Single" ||
                attributeType === "Dropdown.Multiple") && (
                  <Form.Item label="Options">
                    <Space direction="vertical" style={{ width: "100%" }}>
                      {sectionAttribute.optionDefinition?.map((option, index) => (
                        <Space key={index}>
                          <Input
                            placeholder={`Option  ${index + 1}`}
                            value={option.label}
                            onChange={(e) => {
                              const newOptions = [
                                ...(sectionAttribute.optionDefinition || []),
                              ];
                              newOptions[index] = {
                                ...newOptions[index],
                                label: e.target.value,
                                value: e.target.value,
                              };
                              setSectionAttribute({
                                ...sectionAttribute,
                                optionDefinition: newOptions,
                              });
                            }}
                          />
                          <DeleteOutlined
                            style={{
                              color: "#ff4d4f",
                              cursor: "pointer",
                              fontSize: "18px",
                            }}
                            onClick={() => {
                              const newOptions = [
                                ...(sectionAttribute.optionDefinition || []),
                              ];
                              newOptions.splice(index, 1);
                              setSectionAttribute({
                                ...sectionAttribute,
                                optionDefinition: newOptions,
                              });
                            }}
                          />
                        </Space>
                      ))}
                      <Button
                        type="dashed"
                        onClick={() => {
                          setSectionAttribute({
                            ...sectionAttribute,
                            optionDefinition: [
                              ...(sectionAttribute.optionDefinition || []),
                              { label: "", value: "" },
                            ],
                          });
                        }}
                      >
                        + Add Option
                      </Button>
                    </Space>
                  </Form.Item>
                )}
            </Col>
            <Col span={24}>
              <Card
                type="inner"
                title="Preview"
                style={{ marginBottom: "32px" }}
                hoverable
              >
                <Form.Item
                  label={
                    <span style={{ fontWeight: "600" }}>
                      {sectionAttribute.name}{" "}
                      {!!sectionAttribute.required ? (
                        <Text type="danger">*</Text>
                      ) : (
                        <></>
                      )}
                    </span>
                  }
                >
                  {typeFields(attributeType, sectionAttribute)}
                </Form.Item>
              </Card>
            </Col>
          </AntRow>
        </Form>
      </Card>
    </Modal>
  );
};

export default AddAttributeModal;

export const typeFields = (
  attributeType: string,
  sectionAttribute: Attribute
) => {
  switch (attributeType || sectionAttribute?.type) {
    case "Dropdown.Single":
      return (
        <Select
          style={{ width: "100%" }}
          placeholder={sectionAttribute.description}
          options={sectionAttribute?.optionDefinition?.map((option) => ({
            value: option?.value,
            label: option?.label,
          }))}
        />
      );

    case "Date":
      return (
        <DatePicker
          style={{ width: "100%" }}
          placeholder={sectionAttribute.description}
        />
      );

    case "Checkbox":
      return <Checkbox>{sectionAttribute.description}</Checkbox>;

    case "Number":
      return (
        <InputNumber
          style={{ width: "100%" }}
          placeholder={sectionAttribute.description}
        />
      );

    default:
      return (
        <Input
          style={{ width: "100%" }}
          placeholder={sectionAttribute.description}
        />
      );
  }
};
