import { Modal, Space, Col, Row, Card } from "antd";
import React from "react";
import { SpaceBetween } from "@cloudscape-design/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fa1,
  faCalendarDays,
  faEnvelopeOpenText,
  faFont,
  faMinus,
  faPhone,
  faSquareCaretDown,
  faToggleOff,
} from "@fortawesome/free-solid-svg-icons";

const ListIcon = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    }}
  >
    <div
      style={{
        width: "14px",
        height: "3px",
        backgroundColor: "white",
        borderRadius: "4px",
        marginBottom: "3px",
      }}
    ></div>
    <div
      style={{
        width: "14px",
        height: "3px",
        backgroundColor: "white",
        borderRadius: "4px",
        marginBottom: "3px",
      }}
    ></div>
    <div
      style={{
        width: "14px",
        height: "3px",
        backgroundColor: "white",
        borderRadius: "4px",
      }}
    ></div>
  </div>
);

const CalendarIcon = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      width: "32px",
      alignItems: "center",
    }}
  >
    <div
      style={{
        height: "6px",
        width: "24px",
        backgroundColor: "#ef4444",
        borderRadius: "2px",
        marginBottom: "4px",
      }}
    ></div>
    <div
      style={{
        height: "6px",
        width: "16px",
        backgroundColor: "#facc15",
        borderRadius: "2px",
      }}
    ></div>
  </div>
);

const DocIcon = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      width: "32px",
      alignItems: "center",
      position: "relative",
    }}
  >
    <div
      style={{
        height: "32px",
        width: "24px",
        backgroundColor: "white",
        borderRadius: "2px",
        border: "1px solid #d1d5db",
      }}
    ></div>
    <div
      style={{
        height: "16px",
        width: "24px",
        backgroundColor: "#3b82f6",
        position: "absolute",
        right: "0",
        top: "4px",
        borderRadius: "2px",
      }}
    ></div>
  </div>
);

const BoardIcon = () => (
  <div style={{ display: "flex", flexDirection: "column", width: "32px" }}>
    <div
      style={{
        height: "12px",
        width: "20px",
        backgroundColor: "#1d4ed8",
        borderRadius: "2px",
        marginBottom: "4px",
      }}
    ></div>
    <div
      style={{
        height: "12px",
        width: "20px",
        backgroundColor: "#1d4ed8",
        borderRadius: "2px",
      }}
    ></div>
  </div>
);

const FormIcon = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      width: "32px",
      alignItems: "center",
    }}
  >
    <div
      style={{
        height: "16px",
        width: "24px",
        backgroundColor: "#4338ca",
        borderRadius: "2px",
        marginBottom: "4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          height: "8px",
          width: "8px",
          backgroundColor: "white",
          borderRadius: "50%",
        }}
      ></div>
    </div>
    <div
      style={{
        height: "8px",
        width: "24px",
        backgroundColor: "white",
        borderRadius: "2px",
      }}
    ></div>
  </div>
);

const TypeOptions = [
  {
    label: "Text",
    value: "Text",
    description:
      "Enter plain text, such as names, addresses, or general information.",
  },
  {
    label: "Date",
    value: "Date",
    description:
      "Select a date using a calendar picker (e.g., birthdate, appointment date).",
  },
  {
    label: "Number",
    value: "Number",
    description:
      "Input numeric values only (e.g., age, quantity, salary). No letters or symbols allowed.",
  },
  {
    label: "Email",
    value: "Email",
    description: "Enter a valid email address (e.g., user@example.com).",
  },
  {
    label: "Phone",
    value: "Phone",
    description:
      "Input a phone number in the correct format (e.g., (123) 456-7890 or +1 234-567-8900).",
  },
  {
    label: "Checkbox",
    value: "Checkbox",
    description:
      "Choose between a Yes/No option or enable/disable a setting by checking the box.",
  },
  {
    label: "Divider",
    value: "Divider",
    description:
      "A visual separator to organize sections of the form. It does not collect input.",
  },
  {
    label: "Dropdown",
    value: "Dropdown.Single",
    description:
      "Select one option from a predefined list (e.g., country selection, status).",
  },
];

interface TypeModalProps {
  isTypeModalOpen: boolean;
  setIsTypeModalOpen: (value: boolean) => void;
  setIsFieldsModalOpen: (value: boolean) => void;
  setAttributeType: (value: string) => void;
}

const TypeModal: React.FC<TypeModalProps> = ({
  isTypeModalOpen,
  setIsTypeModalOpen,
  setIsFieldsModalOpen,
  setAttributeType,
}) => {
  const handelNext = (type: string) => {
    setAttributeType(type);
    setIsTypeModalOpen(false);
    setIsFieldsModalOpen(true);
  };

  const getIcon = (type: string) => {
    switch (type) {
      case "Text":
        return (
          <FontAwesomeIcon
            icon={faFont}
            style={{ color: "#FFD166" }}
            size="2x"
          />
        );
      case "Date":
        return (
          <FontAwesomeIcon
            icon={faCalendarDays}
            style={{ color: "#B39DDB" }}
            size="2x"
          />
        );
      case "Number":
        return (
          <FontAwesomeIcon icon={fa1} style={{ color: "#86C6F4" }} size="2x" />
        );
      case "Email":
        return (
          <FontAwesomeIcon
            icon={faEnvelopeOpenText}
            style={{ color: "#FF9AA2" }}
            size="2x"
          />
        );
      case "Phone":
        return (
          <FontAwesomeIcon
            icon={faPhone}
            style={{ color: "#A0CED9" }}
            size="2x"
          />
        );
      case "Checkbox":
        return (
          <FontAwesomeIcon
            icon={faToggleOff}
            style={{ color: "#B5EAD7" }}
            size="2x"
          />
        ); // Pastel Green
      case "Divider":
        return (
          <FontAwesomeIcon
            icon={faMinus}
            style={{ color: "#D4A5A5" }}
            size="2x"
          />
        );
      default:
        return (
          <FontAwesomeIcon
            icon={faSquareCaretDown}
            style={{ color: "#F7C8E0" }}
            size="2x"
          />
        ); // Baby Pink
    }
  };

  return (
    <Modal
      title="Select Field Type"
      open={isTypeModalOpen}
      onCancel={() => setIsTypeModalOpen(false)}
      footer={false}
      zIndex={1000}
      centered
      width={"60vw"}
      height={"80vh"}
    >
      <Row gutter={[16, 16]}>
        {TypeOptions.map((option) => (
          <Col span={12}>
            <Card
              type="inner"
              onClick={() => handelNext(option.value)}
              hoverable
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <Space size="middle">
                <div>{getIcon(option.value)}</div>
                <SpaceBetween size="xxs">
                  <span style={{ fontSize: "16px", fontWeight: "600" }}>
                    {option.label}
                  </span>
                  <span>{option.description}</span>
                </SpaceBetween>
              </Space>
            </Card>
          </Col>
        ))}
      </Row>
    </Modal>
  );
};

export default TypeModal;
