import React, { useContext, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Space,
  Card,
  Row as AntRow,
  Col,
  Typography,
  Checkbox,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import IncredableButton from "../../../components/button";
import { Attribute } from "../../../redux/api/section/types";
import { TypeOptions } from "../add-section";
import { typeFields } from "./add-attribute-modal";
import { useDeleteAttributeOptionMutation } from "../../../redux/api/section/section";
import { SpinnerContext } from "../../../context/spinner/spinner";

const { Text } = Typography;

interface EditAttributeModalProps {
  isEditFieldModalOpen: boolean;
  setIsEditFieldModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleEditAttribute: () => void;
  editAttribute: Attribute;
  setEditAttribute: React.Dispatch<React.SetStateAction<Attribute | null>>;
  attributeType: string;
  sectionId?: string;
}

const EditAttributeModal: React.FC<EditAttributeModalProps> = ({
  isEditFieldModalOpen,
  setIsEditFieldModalOpen,
  handleEditAttribute,
  editAttribute,
  setEditAttribute,
  attributeType,
  sectionId,
}) => {
  const [deletingOptions, setDeletingOptions] = useState<
    Record<string, boolean>
  >({});
  const spinnerContext = useContext(SpinnerContext);

  const [deleteAttributeOption] = useDeleteAttributeOptionMutation();

  const handleDeleteOption = async (optionId: string, index: number) => {
    try {
      if (!sectionId || !editAttribute?.id) return;
      setDeletingOptions((prev) => ({ ...prev, [optionId]: true }));
      await deleteAttributeOption({
        sectionId: sectionId,
        attributeId: editAttribute?.id,
        optionId,
      });
      const newOptions = [...(editAttribute.optionDefinition || [])];
      newOptions.splice(index, 1);
      setEditAttribute({
        ...editAttribute,
        optionDefinition: newOptions,
      });
      spinnerContext?.hidespinner();
    } catch (error) {
      console.error("Failed to delete option:", error);
    }
  };

  return (
    <Modal
      open={isEditFieldModalOpen}
      onCancel={() => setIsEditFieldModalOpen(false)}
      footer={
        <Space style={{ marginTop: 16 }}>
          <Button onClick={() => setIsEditFieldModalOpen(false)}>Cancel</Button>
          <IncredableButton
            style={{ backgroundColor: "#598BEB" }}
            type="primary"
            onClick={handleEditAttribute}
          >
            Save
          </IncredableButton>
        </Space>
      }
      zIndex={1000}
      getContainer={false}
      centered
      width={"40vw"}
      height={"80vh"}
    >
      {editAttribute && (
        <Card title="Edit Field">
          <Form layout="vertical">
            <AntRow gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={10}>
                <Form.Item
                  label={<span style={{ fontWeight: "600" }}>Type</span>}
                >
                  <Select
                    value={editAttribute.type}
                    disabled={true}
                    onChange={(value) =>
                      setEditAttribute({ ...editAttribute, type: value })
                    }
                    options={TypeOptions}
                  />
                </Form.Item>
              </Col>
              <Col span={14}>
                <Form.Item
                  label={
                    <span style={{ fontWeight: "600" }}>
                      Field Name <Text type="danger">*</Text>
                    </span>
                  }
                >
                  <Input
                    value={editAttribute.name}
                    onChange={(e) =>
                      setEditAttribute({
                        ...editAttribute,
                        name: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={<span style={{ fontWeight: "600" }}>Hint text</span>}
                >
                  <Input
                    value={editAttribute.description}
                    placeholder="Hint text"
                    onChange={(e) =>
                      setEditAttribute({
                        ...editAttribute,
                        description: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  help={
                    "By default, this field is optional. Check this box to mark it required, ensuring users cannot leave it blank."
                  }
                  style={{ marginBottom: "32px" }}
                >
                  <Checkbox
                    checked={!!editAttribute.required ? true : false}
                    onChange={(value) =>
                      setEditAttribute({
                        ...editAttribute,
                        required: value?.target?.checked,
                      })
                    }
                  >
                    <span style={{ fontWeight: "600" }}>
                      Make This Field Required
                    </span>
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={24} style={{ marginBottom: "12px" }}>
                <Form.Item
                  help={
                    "Check this box for confidential information (e.g., SSN, Medical ID). Access is restricted via Roles & Permissions."
                  }
                >
                  <Checkbox
                    checked={!!editAttribute.sensitive ? true : false}
                    onChange={(value) =>
                      setEditAttribute({
                        ...editAttribute,
                        sensitive: value?.target?.checked,
                      })
                    }
                  >
                    <span style={{ fontWeight: "600" }}>
                      Mark As Sensitive Info
                    </span>
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col span={24}>
                {(editAttribute.type === "Dropdown.Single" ||
                  editAttribute.type === "Dropdown.Multiple") && (
                  <Form.Item label="Options">
                    <Space direction="vertical" style={{ width: "100%" }}>
                      {editAttribute.optionDefinition?.map((option, index) => (
                        <Space key={index}>
                          <Input
                            placeholder={`Option ${index + 1}`}
                            value={option.label}
                            onChange={(e) => {
                              const newOptions = [
                                ...(editAttribute.optionDefinition || []),
                              ];
                              newOptions[index] = {
                                ...newOptions[index],
                                label: e.target.value,
                                value: e.target.value,
                              };
                              setEditAttribute({
                                ...editAttribute,
                                optionDefinition: newOptions,
                              });
                            }}
                          />
                          <DeleteOutlined
                            style={{
                              color: "#ff4d4f",
                              cursor: "pointer",
                              fontSize: "18px",
                            }}
                            spin={deletingOptions[option.id || index] || false}
                            onClick={() => {
                              if (sectionId && option?.id) {
                                handleDeleteOption(option?.id, index);
                              } else {
                                const newOptions = [
                                  ...(editAttribute.optionDefinition || []),
                                ];
                                newOptions.splice(index, 1);
                                setEditAttribute({
                                  ...editAttribute,
                                  optionDefinition: newOptions,
                                });
                              }
                            }}
                          />
                        </Space>
                      ))}
                      <Button
                        type="dashed"
                        onClick={() => {
                          setEditAttribute({
                            ...editAttribute,
                            optionDefinition: [
                              ...(editAttribute.optionDefinition || []),
                              { label: "", value: "" },
                            ],
                          });
                        }}
                      >
                        + Add Option
                      </Button>
                    </Space>
                  </Form.Item>
                )}
              </Col>

              <Col span={24} style={{ marginTop: "12px" }}>
                <Card
                  type="inner"
                  title="Preview"
                  style={{ marginBottom: "32px" }}
                  hoverable
                >
                  <Form.Item
                    label={
                      <span style={{ fontWeight: "600" }}>
                        {editAttribute.name}{" "}
                        {!!editAttribute.required ? (
                          <Text type="danger">*</Text>
                        ) : (
                          <></>
                        )}
                      </span>
                    }
                  >
                    {typeFields(attributeType, editAttribute)}
                  </Form.Item>
                </Card>
              </Col>
            </AntRow>
          </Form>
        </Card>
      )}
    </Modal>
  );
};

export default EditAttributeModal;
