import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    PlusOutlined,
    ReloadOutlined,
} from "@ant-design/icons";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import { PrivilegeType } from "../redux/api/privileges/types";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { useDeletePrivilegeTypeMutation, useGetAllPrivilegeTypesQuery } from "../redux/api/privileges/privileges";
import { useLocalStorage } from "../common/localStorage";
import { PRIVILEGETYPE_CONTENT_DISPLAY_OPTIONS, PRIVILEGETYPE_TABLE_DEFAULT_PREFERENCES, TablePreferences } from "./table-config";
import IncredableButton from "../components/button";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";

export default function PrivilegeTypeList() {
    const navigate = useNavigate();
    const [selectedRows, setSelectedRows] = useState<PrivilegeType[]>([]);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const spinnerContext = useContext(SpinnerContext);
    const toastContext = useContext(ToastContext);
    const [deletePrivilegeType] = useDeletePrivilegeTypeMutation();
    const {
        data: privilegeType,
        isLoading,
        isFetching,
        error,
        refetch,
    } = useGetAllPrivilegeTypesQuery();
    const [tablePreferences, setTablePreferences] = useLocalStorage(
        "RD-Privilege Type-Table-Preferences",
        PRIVILEGETYPE_TABLE_DEFAULT_PREFERENCES
    );

    const { items, collectionProps, paginationProps } = useCollection(
        privilegeType ?? [],
        {
            pagination: { pageSize: tablePreferences?.pageSize },
        }
    );

    if (error) {
        return <div>Error loading privilege type</div>;
    }

    return (
        <>
            <IncredableButton
                style={{
                    margin: "12px 0 0 32px",
                    padding: "12px",
                }}
                secondary
                onClick={() => navigate(-1)}
            >
                Go back
            </IncredableButton>
            <div style={{ padding: "32px 32px 0 32px" }}    >
                <Table
                    loading={isFetching || isLoading}
                    onSelectionChange={({ detail }) => {
                        setSelectedRows([...detail.selectedItems]);
                    }}
                    onRowClick={(onRowClickDetails) => {
                        const selectedItem = onRowClickDetails?.detail?.item;
                        if (selectedItem) {
                            if (selectedRows?.at(0)?.id === selectedItem?.id) {
                                setSelectedRows([]);
                            } else {
                                setSelectedRows([selectedItem]);
                            }
                        }
                    }}
                    selectedItems={selectedRows || []}
                    columnDefinitions={[
                        {
                            id: "name",
                            header: <div>Name</div>,
                            width: "250px",
                            cell: (item) => (
                                <NavLink to={`${item.id}/edit`}>{item?.name}</NavLink>
                            ),
                        },
                        {
                            id: "description",
                            header: <div>Description</div>,
                            cell: (item) => <span>{item?.description}</span>,
                        },
                    ]}
                    items={items}
                    pagination={<Pagination {...paginationProps} />}
                    preferences={
                        <TablePreferences
                            preferences={tablePreferences}
                            setPreferences={(preferences) => {
                                setTablePreferences(preferences);
                            }}
                            contentDisplayOptions={PRIVILEGETYPE_CONTENT_DISPLAY_OPTIONS}
                        />
                    }
                    loadingText="Loading resources"
                    selectionType="single"
                    trackBy="id"
                    header={
                        <div style={{ marginTop: "8px", marginBottom: "12px" }}>
                            <Header
                                actions={
                                    <Space size="middle">
                                        <Button
                                            onClick={() => {
                                                !!refetch && refetch();
                                            }}
                                        >
                                            <ReloadOutlined />
                                        </Button>
                                        <Dropdown
                                            menu={{
                                                disabled: selectedRows?.length !== 1,
                                                items: [
                                                    {
                                                        label: "Edit",
                                                        key: "edit",
                                                        icon: <EditOutlined />,
                                                        onClick: () => {
                                                            navigate(`${selectedRows?.at(0)?.id}/edit`);
                                                        },
                                                    },
                                                    {
                                                        label: "Delete",
                                                        key: "delete",
                                                        onClick: () => setDeleteModalOpen(true),
                                                        icon: <DeleteOutlined />,
                                                        danger: true,
                                                    },
                                                ],
                                            }}
                                        >
                                            <Button>
                                                Actions
                                                <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                        <ConfigProvider
                                            theme={{
                                                token: {
                                                    colorPrimary: "#2B6BE6",
                                                },
                                            }}
                                        >
                                            <Button
                                                onClick={() => navigate(`add`)}
                                                type="primary"
                                                icon={<PlusOutlined />}
                                            >
                                                Add Privilege Type
                                            </Button>
                                        </ConfigProvider>
                                    </Space>
                                }
                            >
                                <Typography.Title level={3} style={{ marginTop: "0" }}>
                                    Privilege Type{" "}
                                    <Typography.Text
                                        type="secondary"
                                        style={{ fontSize: "16px" }}
                                    >{`(${privilegeType?.length || 0})`}</Typography.Text>
                                </Typography.Title>
                            </Header>
                        </div>
                    }
                    {...collectionProps}
                    columnDisplay={tablePreferences?.contentDisplay}
                    wrapLines={tablePreferences?.wrapLines}
                    stripedRows={tablePreferences?.stripedRows}
                    contentDensity={tablePreferences?.contentDensity}
                    stickyColumns={tablePreferences?.stickyColumns}
                />
                <DeleteAlertModal
                    visible={isDeleteModalOpen}
                    action={async () => {
                        if (selectedRows?.at(0)?.id) {
                            setDeleteModalOpen(false);
                            spinnerContext?.showSpinner();
                            try {
                                await deletePrivilegeType({
                                    privilegeTypeId: String(selectedRows?.at(0)?.id),
                                }).unwrap();
                                toastContext?.openSuccessNotification({
                                    message: `Privilege Type deleted successfully`,
                                    placement: "topRight",
                                });
                            }
                            catch (error: any) {
                                toastContext?.openErrorNotification({
                                    message: `Failed to delete privilege type`,
                                    placement: "topRight"
                                })
                            }
                            spinnerContext?.hidespinner();
                            setSelectedRows([]);
                        }
                    }}
                    closeModal={() => setDeleteModalOpen(false)}
                    header="Delete Privilege Type"
                    content={
                        <Typography.Text>
                            Privilege Type:{" "}
                            <Typography.Text>{selectedRows?.at(0)?.name}</Typography.Text>
                        </Typography.Text>
                    }
                    description="Are you sure you want to delete this privilege type?"
                />
            </div>
        </>
    );
}