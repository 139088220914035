import {
  CollectionPreferences,
  CollectionPreferencesProps,
} from "@cloudscape-design/components";
import { last } from "lodash";

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: "10 items" },
  { value: 30, label: "30 items" },
  { value: 50, label: "50 items" },
];
export const PRIVILEGETYPE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Privilege Type ID" },
  { id: "name", label: "Name" },
  { id: "description", label: "Description" },
];
export const PRIVILEGETYPE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
{
  pageSize: 10,
  contentDisplay: [
    { id: "id", visible: true },
    { id: "name", visible: true },
    { id: "description", visible: true },
  ],
  wrapLines: false,
  stripedRows: true,
  contentDensity: "comfortable",
  stickyColumns: { first: 0, last: 0 },
};

export const PRIVILEGES_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "ID", alwaysVisible: false },
  { id: "name", label: "Name" },
  { id: "privilegeType", label: "Privilege Type" },
  { id: "licenseType", label: "licenseType" },
  { id: "provider", label: "Provider" },
  { id: "providerAffiliation", label: "Provider Affiliation" },
  { id: "category", label: "Category" },
  { id: "createdDate", label: "Created Date" },
  { id: "expirationDate", label: "Expiration Date" },
  { id: "alertDays", label: "Alert Days" },
  { id: "description", label: "Description", alwaysVisible: false }
];
export const PRIVILEGES_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
{
  pageSize: 10,
  contentDisplay: [
    { id: "id", visible: true },
    { id: "name", visible: true },
    { id: "privilegeType", visible: true },
    { id: "licenseType", visible: true },
    { id: "provider", visible: true },
    { id: "providerAffiliation", visible: true },
    { id: "category", visible: true },
    { id: "createdDate", visible: true },
    { id: "expirationDate", visible: true },
    { id: "alertDays", visible: true },
    { id: "description", visible: true }
  ],
  wrapLines: false,
  stripedRows: true,
  contentDensity: "comfortable",
  stickyColumns: { first: 0, last: 0 },
};

export const PRIVILEGEDOCUMENT_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Document ID", alwaysVisible: false },
  { id: "documentName", label: "Document Name", alwaysVisible: true },
  { id: "categoryName", label: "Category Name" },
  { id: "provider", label: "Provider" },
  { id: "facility", label: "Facility" },
  { id: "fileName", label: "File Name" },
  { id: "status", label: "Status" },
  { id: "expiration", label: "Expiration Date" },
  { id: "alertDays", label: "Expiration Reminder" },
  { id: "uploaded", label: "Uploaded Date" },
  { id: "notes", label: "Notes" },
];
export const PRIVILEGEDOCUMENT_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
{
  pageSize: 10,
  contentDisplay: [
    { id: "id", visible: false },
    { id: "documentName", visible: true },
    { id: "categoryName", visible: true },
    { id: "provider", visible: true },
    { id: "facility", visible: true },
    { id: "fileName", visible: true },
    { id: "status", visible: true },
    { id: "expiration", visible: true },
    { id: "alertDays", visible: true },
    { id: "uploaded", visible: true },
    { id: "notes", visible: true },
  ],
  wrapLines: false,
  stripedRows: true,
  contentDensity: "comfortable",
  stickyColumns: { first: 0, last: 0 },
};

export const TablePreferences = ({
  title,
  disabled,
  preferences,
  setPreferences,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  contentDisplayOptions,
}: {
  title?: string;
  disabled?: boolean;
  preferences: CollectionPreferencesProps.Preferences<any> | undefined;
  setPreferences: (
    preferences: CollectionPreferencesProps.Preferences<any>
  ) => void;
  pageSizeOptions?: CollectionPreferencesProps.PageSizeOption[];
  contentDisplayOptions: CollectionPreferencesProps.ContentDisplayOption[];
}) => (
  <CollectionPreferences
    disabled={disabled || !contentDisplayOptions.length}
    preferences={preferences}
    onConfirm={({ detail }) => {
      setPreferences(detail);
    }}
    title={title}
    pageSizePreference={{ options: pageSizeOptions }}
    wrapLinesPreference={{}}
    stripedRowsPreference={{}}
    contentDensityPreference={{}}
    contentDisplayPreference={{ options: contentDisplayOptions }}
    stickyColumnsPreference={{
      firstColumns: {
        title: "Stick first column(s)",
        description:
          "Keep the first column(s) visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "First column", value: 1 },
          { label: "First two columns", value: 2 },
        ],
      },
      lastColumns: {
        title: "Stick last column",
        description:
          "Keep the last column visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "Last column", value: 1 },
        ],
      },
    }}
  />
);
