import { Col, Divider, Flex, Form, Row, Space, theme, Typography } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SpinnerContext } from "../../../context/spinner/spinner";
import { ToastContext } from "../../../context/toast/incredable-toast";
import IncredableButton from "../../../components/button";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { PayerGroupRequest } from "../../../redux/api/payergroup/types";
import { useCreatePayerGroupMutation } from "../../../redux/api/payergroup/payergroup";
import IncredableContent from "../../../components/incredable-content";
import RHFTextArea from "../../../components/RHF/RHFTextArea";

const { useToken } = theme;
type FormInputType = PayerGroupRequest;

export default function AddPayerGroup() {
  const navigate = useNavigate();
  const methods = useForm<FormInputType>();
  const [addPayerGroup] = useCreatePayerGroupMutation();
  const { token } = useToken();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  return (
    <IncredableContent
      breadcrumbGroupProps={{
        items: [
          {
            text: "Network",
            href: "/payergroup",
          },
          {
            text: "Add",
            href: "#",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="addpayergroup"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            spinnerContext?.showSpinner();
            try {
              await addPayerGroup({
                name: data?.name,
                groupCode: data?.groupCode,
                description: data?.description,
              }).unwrap();
              toastContext?.openSuccessNotification({
                message: `Network saved successfully`,
                placement: "topRight",
              });
            } catch (error: any) {
              toastContext?.openErrorNotification({
                message: error?.data?.errorMessage || `Failed to add Network`,
                placement: "topRight"
              })
            }
            spinnerContext?.hidespinner();
            navigate(-1);
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Network
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="payergroup" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{
                              label: "Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `name`,
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{
                              label: "Group Code",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `groupCode`,
                            }}
                          />
                        </Col>
                        <Col span={24}>
                          <RHFTextArea
                            formItemProps={{
                              label: "Notes",
                            }}
                            textAreaProps={{}}
                            controllerProps={{
                              name: `description`,
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent >
  );
}
