import { api } from "..";
import { SMSMessageType } from "./types";

const smsMessagingAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllSMSMessageByProviderid: build.query<
      SMSMessageType[],
      { providerId: string }
    >({
      query: ({ providerId }) => `sms/provider/${providerId}/all`,
      providesTags: ["SMSMessaging"],
    }),
    sendSMSMessage: build.mutation<
      void,
      { providerId: string; messageBody: string }
    >({
      query: ({ providerId, messageBody }) => {
        return {
          url: `sms/provider/${providerId}`,
          method: "POST",
          body: messageBody,
        };
      },
      invalidatesTags: ["SMSMessaging"],
    }),
  }),
});

export const {
  useSendSMSMessageMutation,
  useGetAllSMSMessageByProvideridQuery,
} = smsMessagingAPI;
