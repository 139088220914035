import { Card, Flex, Space, Switch } from "antd";
import React, { useContext } from "react";
import IncredableButton from "../button";
import { EditOutlined } from "@ant-design/icons";
import MaskValue from "../MaskValue";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { ToastContext } from "../../context/toast/incredable-toast";
import { KeyValuePairs } from "@cloudscape-design/components";

interface ProviderCardProps {
  title: React.ReactNode;
  extraButton?: {
    text: string;
    icon?: string;
    onClick?: () => void;
  };
  data: {
    label: string;
    value: string | boolean | Date | number | { id: string; name: string };
  }[];
}

const ProviderCard: React.FC<ProviderCardProps> = ({
  title,
  extraButton,
  data,
}) => {
  const [showSSN, setShowSSN] = React.useState(false);
  const toastContext = useContext(ToastContext);

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner || (state.ui.permissions?.includes("ssn.view") ?? false)
  );

  const showSecrets = () => {
    if (hasPermission) {
      setShowSSN(!showSSN);
    } else {
      toastContext?.openErrorNotification({
        message: `You Don't Have Permission to view SSN.`,
        placement: "top",
      });
    }
  };

  return (
    <Card
      title={title}
      styles={{
        body: { maxHeight: "300px", overflow: "auto" },
      }}
      extra={
        !!extraButton && (
          <IncredableButton
            type="primary"
            icon={<EditOutlined />}
            iconPosition="start"
            onClick={extraButton.onClick}
          >
            Edit
          </IncredableButton>
        )
      }
    >
      <KeyValuePairs
        id="keyvaluepair"
        columns={3}
        items={data?.map((item, index) => ({
          key: item.label,
          label: item.label === "SSN" ? "" : item.label,
          value:
            item.label === "SSN" ? (
              <>
                <Flex justify="space-between">
                  <Space
                    style={{
                      color: "rgba(0, 0, 0, 0.88)",
                      fontWeight: "600",
                      letterSpacing: "0.30px",
                    }}
                  >
                    {item?.label}
                  </Space>
                  <Space direction="horizontal">
                    <Switch
                      checked={showSSN}
                      onChange={showSecrets}
                      size="small"
                    />
                    Show SSN
                  </Space>
                </Flex>
                <MaskValue
                  maskContent={!showSSN}
                  value={item?.value ? String(item.value) : "-"}
                />
              </>
            ) : (
              <Space>{String(item.value)}</Space>
            ),
        }))}
      />
    </Card>
  );
};

export default ProviderCard;
