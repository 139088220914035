const ROOTS_DASHBOARD = "/";
const ROOTS_AUTH = "/auth";

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/signup"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/resetpassword"),
  requestReset: path(ROOTS_AUTH, "/requestreset"),
  newPassword: path(ROOTS_AUTH, "/newpassword"),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  gme: {
    list: path(ROOTS_DASHBOARD, "gme"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `gme/${id}`),
  },
  reports: {
    list: path(ROOTS_DASHBOARD, "reports"),
    expiringdocuments: {
      list: path(ROOTS_DASHBOARD, "expiringdocuments"),
    },
    expireddocuments: {
      list: path(ROOTS_DASHBOARD, "expireddocuments"),
    },
    alldocuments: {
      list: path(ROOTS_DASHBOARD, "alldocuments"),
    },
    payerenrollment: {
      list: path(ROOTS_DASHBOARD, "enrollmentreport"),
    },
    provideraffiliation: {
      list: path(ROOTS_DASHBOARD, "provideraffiliation"),
    },
    providerroatser: {
      list: path(ROOTS_DASHBOARD, "providerroatser"),
    },
  },
  dashboard: {
    list: path(ROOTS_DASHBOARD, "dashboard"),
  },

  digitalforms: {
    list: path(ROOTS_DASHBOARD, "readyforms"),
    templates: {
      designer: (id: string) =>
        path(ROOTS_DASHBOARD, `readyforms/${id}/templatedesigner`),
    },
    signaturerequest: (id: string) =>
      path(ROOTS_DASHBOARD, `readyforms/${id}/requestsignature?preview=true`),
  },
  roles: {
    list: path(ROOTS_DASHBOARD, "settings/roles"),
  },
  signaturerequests: {
    list: path(ROOTS_DASHBOARD, "signaturerequests"),
  },
  provider: {
    list: path(ROOTS_DASHBOARD, "providers"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `provider/${id}`),
    manageprovider: {
      list: path(ROOTS_DASHBOARD, "manageprovider"),
      edit: (id: string) => path(ROOTS_DASHBOARD, `manageprovider/${id}`),
    },
  },
  safe: {
    list: path(ROOTS_DASHBOARD, "safe"),
    edit: (providerId: string) => path(ROOTS_DASHBOARD, `safe/${providerId}`),
    add: (providerId: string) =>
      path(ROOTS_DASHBOARD, `safe/${providerId}/documents/addnew`),
  },
  newsafe: {
    list: path(ROOTS_DASHBOARD, "newsafe"),
    edit: (providerId: string) =>
      path(ROOTS_DASHBOARD, `newsafe/${providerId}`),
    add: (providerId: string) =>
      path(ROOTS_DASHBOARD, `newsafe/${providerId}/documents/addnew`),
  },
  manageadmin: {
    list: path(ROOTS_DASHBOARD, "manageadmin"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `manageadmin/${id}`),
    add: (id: string) => path(ROOTS_DASHBOARD, `manageadmin/add/${id}`),
    editmember: (id: string) =>
      path(ROOTS_DASHBOARD, `manageadmin/editmember/${id}`),
  },
  details: {
    list: path(ROOTS_DASHBOARD, "details/list"),
    detailsContent: (id: string) => path(ROOTS_DASHBOARD, `details/list/${id}`),
  },
  managecategory: {
    list: path(ROOTS_DASHBOARD, "managecategory"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `managecategory/${id}`),
  },
  managesection: {
    list: path(ROOTS_DASHBOARD, "managesection"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `managesection/${id}`),
  },
  managefacilities: {
    list: path(ROOTS_DASHBOARD, "managefacilities"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `managefacilities/${id}`),
  },
  profile: {
    list: path(ROOTS_DASHBOARD, "profile"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `profile/${id}`),
  },
  organization: {
    list: path(ROOTS_DASHBOARD, "organization"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `organization/${id}`),
  },
  contracts: {
    list: path(ROOTS_DASHBOARD, "contract"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `contract/${id}`),
  },
  credential: {
    packages: {
      list: path(ROOTS_DASHBOARD, "credentialpackages"),
      edit: (id: string) => path(ROOTS_DASHBOARD, `credentialpackages/${id}`),
      requestSignature: (id: string) =>
        path(ROOTS_DASHBOARD, `credentialpackages/${id}/requestsignature`),
    },
    templates: {
      list: path(ROOTS_DASHBOARD, "credentialtemplates"),
      edit: (id: string) => path(ROOTS_DASHBOARD, `credentialtemplates/${id}`),
    },
  },
  payerenrollment: {
    list: path(ROOTS_DASHBOARD, "payerenrollment"),
    add: path(ROOTS_DASHBOARD, "payerenrollment/addnew"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `payerenrollment/${id}`),
  },
  payers: {
    list: path(ROOTS_DASHBOARD, "payers"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `payers/${id}`),
    templates: {
      designer: (id: string) =>
        path(ROOTS_DASHBOARD, `payers/${id}/templatedesigner`),
    },
  },
  payergroup: {
    list: path(ROOTS_DASHBOARD, "payergroup"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `payergroup/${id}`),
    templates: {
      designer: (id: string) =>
        path(ROOTS_DASHBOARD, `payergroup/${id}/templatedesigner`),
    },
  },
  facilitygroup: {
    list: path(ROOTS_DASHBOARD, "facilitygroup"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `facilitygroup/${id}`),
  },
  tasks: {
    list: path(ROOTS_DASHBOARD, "tasks"),
    add: path(ROOTS_DASHBOARD, "tasks/addnew"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `tasks/${id}`),
  },
  workflowtasks: {
    add: (workflowId: string) =>
      path(ROOTS_DASHBOARD, `workflows/${workflowId}/addtask`),
    edit: (workflowId: string, taskId: string) =>
      path(ROOTS_DASHBOARD, `workflows/${workflowId}/task/${taskId}`),
  },
  manage: {
    licensetype: {
      list: path(ROOTS_DASHBOARD, "licensetypes"),
      add: path(ROOTS_DASHBOARD, "licensetypes/add"),
      edit: (id: string) => path(ROOTS_DASHBOARD, `licensetypes/${id}`),
    },
    categories: {
      list: path(ROOTS_DASHBOARD, "categories"),
      add: path(ROOTS_DASHBOARD, "categories/addnew"),
      edit: (id: string) => path(ROOTS_DASHBOARD, `categories/${id}`),
    },
    workflows: {
      list: path(ROOTS_DASHBOARD, "workflows"),
      add: path(ROOTS_DASHBOARD, "workflows/add"),
      edit: (id: string) => path(ROOTS_DASHBOARD, `workflows/${id}`),
      tasks: {
        list: path(ROOTS_DASHBOARD, "workflows/tasks"),
        add: path(ROOTS_DASHBOARD, "workflows/task/addnew"),
        edit: (id: string) => path(ROOTS_DASHBOARD, `workflows / task / ${id}`),
      },
    },
    workflowTemplates: {
      list: path(ROOTS_DASHBOARD, "workflowtemplate"),
      add: path(ROOTS_DASHBOARD, "workflowtemplate/add"),
      edit: (id: string) => path(ROOTS_DASHBOARD, `workflowtemplate/${id}`),
    },
    locations: {
      list: path(ROOTS_DASHBOARD, "location"),
      add: path(ROOTS_DASHBOARD, "location/add"),
      edit: (id: string) => path(ROOTS_DASHBOARD, `location/${id}`),
    },
    profilebuilder: {
      list: path(ROOTS_DASHBOARD, "profilebuilder"),
      add: path(ROOTS_DASHBOARD, "profilebuilder/add"),
      edit: (id: string) => path(ROOTS_DASHBOARD, `profilebuilder/${id}/edit`),
    },
  },
  privileges: {
    list: path(ROOTS_DASHBOARD, "privileges"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `privileges / ${id}`),
    editAffiliationType: path(ROOTS_DASHBOARD, "affiliationtype"),
  },
  affiliations: {
    list: path(ROOTS_DASHBOARD, "affiliations"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `affiliations / ${id}`),
    editAffiliationType: path(ROOTS_DASHBOARD, "affiliationtype"),
  },
  eSignatures: {
    list: path(ROOTS_DASHBOARD, "requests"),
  },
  contracttypes: {
    list: path(ROOTS_DASHBOARD, "contracttypes"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `contractstypes / ${id}`),
  },
};
