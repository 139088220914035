import React, { useEffect } from "react";
import { Modal, Form, Col, Space } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import IncredableButton from "../../components/button";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { RoleRequest } from "../../redux/api/role/types";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import {
  useGetRoleQuery,
  useUpdateRoleMutation,
} from "../../redux/api/role/role";
import { useParams } from "react-router-dom";

type FormInputType = {
  name: string;
  description: string;
};

interface AddRoleModalProps {
  visible: boolean;
  onClose: () => void;
}

const EditRoleModal: React.FC<AddRoleModalProps> = ({ visible, onClose }) => {
  const methods = useForm<RoleRequest>();
  const spinnerContext = React.useContext(SpinnerContext);
  const toastContext = React.useContext(ToastContext);
  const { id: roleId } = useParams();
  const [updateRole] = useUpdateRoleMutation();
  const { data: role } = useGetRoleQuery({
    id: roleId ?? "",
  });

  useEffect(() => {
    if (role) {
      methods.setValue("name", role?.name ?? "");
      methods.setValue("description", role?.description ?? "");
    }
  }, [role]);

  const handleSubmit = methods.handleSubmit(async (data: FormInputType) => {
    if (roleId) {
      spinnerContext?.showSpinner();
      try {
        await updateRole({
          id: roleId,
          name: data?.name,
          description: data?.description,
        }).unwrap();
        toastContext?.openSuccessNotification({
          message: "Role Updated successfully",
          placement: "topRight",
        });
      } catch (error: any) {
        toastContext?.openErrorNotification({
          message: `Failed to update Role`,
          placement: "topRight"
        })
      }
      spinnerContext?.hidespinner();
      onClose();
    }
  });
  return (
    <Modal visible={visible} onCancel={onClose} title="Edit Role" footer={null}>
      <FormProvider {...methods}>
        <Form layout="vertical" onFinish={handleSubmit}>
          <Col span={24}>
            <RHFTextField
              formItemProps={{
                label: "Name",
              }}
              controllerProps={{
                name: "name",
              }}
              inputProps={{}}
            />
          </Col>
          <Col span={24}>
            <RHFTextArea
              formItemProps={{ label: "Description" }}
              controllerProps={{ name: "description" }}
            />
          </Col>
          <Space style={{ width: "100%", justifyContent: "end" }}>
            <IncredableButton secondary onClick={onClose}>
              Cancel
            </IncredableButton>
            <IncredableButton type="primary" htmlType="submit">
              Save
            </IncredableButton>
          </Space>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default EditRoleModal;
