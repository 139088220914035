import { Col, Divider, Flex, Form, Row, Space, Typography } from "antd";
import RHFSelect from "../../components/RHF/RHFSelect";
import RHFTextField from "../../components/RHF/RHFTextField";
import IncredableContent from "../../components/incredable-content";
import { FormProvider, useForm } from "react-hook-form";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import {
  useGetBasicAllProvidersQuery,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { useCreatePrivilegeMutation, useGetAllPrivilegeTypesQuery } from "../../redux/api/privileges/privileges";
import { PrivilegeCreateRequest } from "../../redux/api/privileges/types";
import { useGetAllCategoryQuery } from "../../redux/api/category/category";
import { useGetAllLicenseTypeQuery } from "../../redux/api/licensetype/licensetype";
import { skipToken } from "@reduxjs/toolkit/query";
import { alertReminderOptions } from "../../common/constants";

export default function AddPrivileges() {
  const methods = useForm<PrivilegeCreateRequest>();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false)
  );
  const { data: providers = [] } = useGetBasicAllProvidersQuery();
  const { data: privilegeType = [] } = useGetAllPrivilegeTypesQuery();
  const { data: categories = [] } = useGetAllCategoryQuery();
  const { data: licenseTypes = [] } = useGetAllLicenseTypeQuery();


  const [addPrivilege] = useCreatePrivilegeMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const selectedProviderId = methods.watch("providerId");
  useEffect(() => {
    methods.resetField("providerAffiliationId");
  }, [selectedProviderId]);
  const {
    data: provider,
  } = useGetProviderQuery(!!selectedProviderId ? { providerId: selectedProviderId } : skipToken);

  const handleSubmit = methods.handleSubmit(async (data) => {
    spinnerContext?.showSpinner();
    try {
      await addPrivilege(data).unwrap();
      toastContext?.openSuccessNotification({
        message: "Privileges added successfully",
        placement: "topRight",
      });
    } catch (error: any) {
      toastContext?.openErrorNotification({
        message: error?.data?.errorMessage || "Failed to add Privilege",
        placement: "topRight",
      });
    }
    spinnerContext?.hidespinner();
    navigate(-1);
  });

  return (
    <>
      <IncredableContent
        breadcrumbGroupProps={{
          items: [
            {
              text: "Privileges",
              href: "/privileges",
            },
            {
              text: "Add",
              href: "#",
            },
          ],
        }}
      >
        <FormProvider {...methods}>
          <Form layout="vertical" onFinish={handleSubmit}>
            <Flex gap="small" wrap justify="space-between">
              <Typography.Text
                strong
                style={{
                  fontSize: "22px",
                  color: "#1D1A1F",
                  lineHeight: "25px",
                  fontWeight: "700",
                }}
              >
                Privilege
              </Typography.Text>
              <Space>
                <IncredableButton
                  secondary
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </IncredableButton>
                <IncredableButton type="primary" htmlType="submit">
                  Save
                </IncredableButton>
              </Space>
            </Flex>
            <Divider />
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={8}>
                <RHFTextField
                  formItemProps={{
                    label: "Name",
                  }}
                  inputProps={{}}
                  controllerProps={{
                    name: `name`,
                  }}
                />
              </Col>
              <Col span={8}>
                <RHFSelect
                  formItemProps={{ label: "Privilege Type" }}
                  controllerProps={{ name: "privilegeTypeId" }}
                  selectProps={{
                    style: { width: "100%" },
                    placeholder: "Select Privilege Type",
                    options: privilegeType.map((item) => ({
                      label: item?.name,
                      value: item?.id
                    })),
                    onModifyClick: () => navigate("/privilegetype"),
                  }}
                  rules={{ required: "This field is required" }}
                />
              </Col>
              <Col span={8}>
                <RHFSelect
                  formItemProps={{ label: "Category" }}
                  controllerProps={{ name: "categoryId" }}
                  selectProps={{
                    style: { width: "100%" },
                    placeholder: "Select Category",
                    options: categories?.map((category) => ({
                      label: category.name,
                      value: category.id,
                    })),
                  }}
                />
              </Col>
              <Col span={8}>
                <RHFSelect
                  formItemProps={{ label: "Provider" }}
                  controllerProps={{ name: "providerId" }}
                  selectProps={{
                    allowClear: true,
                    showSearch: true,
                    style: { width: "100%" },
                    options: providers?.map((provider) => ({
                      label: `${provider.firstName} ${provider.lastName}`,
                      value: provider.id,
                    })),
                  }}
                  rules={{ required: "This field is required" }}
                />
              </Col>
              <Col span={8}>
                <RHFSelect
                  formItemProps={{ label: "Provider Affiliation" }}
                  controllerProps={{ name: "providerAffiliationId" }}
                  selectProps={{
                    allowClear: true,
                    showSearch: true,
                    style: { width: "100%" },
                    options: provider?.providerAffiliations?.map(a => ({
                      label: a.name,
                      value: a.id,
                    }))
                  }}
                />
              </Col>

              <Col span={8}>
                <RHFSelect
                  formItemProps={{ label: "License Type" }}
                  controllerProps={{ name: "licenseTypeId" }}
                  selectProps={{
                    allowClear: true,
                    showSearch: true,
                    style: { width: "100%" },
                    placeholder: "Search to Select",
                    options: licenseTypes?.map((type) => ({
                      label: type?.name,
                      value: type?.id,
                    })),
                  }}
                />
              </Col>
              <Col span={8}>
                <RHFDatePicker
                  formItemProps={{ label: "Expiration Date" }}
                  controllerProps={{ name: "expirationDate" }}
                  datePickerProps={{
                    style: { width: "100%" },
                    placeholder: "Select Expiration Date",
                  }}
                />
              </Col>

              <Col span={8}>
                <RHFSelect
                  formItemProps={{ label: "Follow Up Date" }}
                  controllerProps={{
                    name: `alertDays`,
                  }}
                  selectProps={{
                    allowClear: true,
                    showSearch: true,
                    style: { width: "100%" },
                    placeholder: "Select Days",
                    options: alertReminderOptions?.map((alert) => {
                      return {
                        label: alert?.label,
                        value: alert?.value,
                      };
                    }),
                  }}
                />
              </Col>

            </Row>
            <Divider />
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24}>
                <RHFTextArea
                  formItemProps={{ label: "Notes" }}
                  controllerProps={{ name: "description" }}
                />
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </IncredableContent>
    </>
  );
}