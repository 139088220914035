import { Col, Divider, Flex, Form, Row, Space, theme, Typography } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { DigitalFormTypeRequest } from "../redux/api/digitalform/types";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import {
  useGetAllDigitalFormTypesQuery,
  useUpdateDigitalFormTypeMutation,
} from "../redux/api/digitalform/digitalforms";
import IncredableContent from "../components/incredable-content";
import IncredableButton from "../components/button";
import RHFTextField from "../components/RHF/RHFTextField";

const { useToken } = theme;

type FormInputType = DigitalFormTypeRequest;
export default function EditReadyFormType() {
  const { readyformtypeId } = useParams();
  const navigate = useNavigate();
  const methods = useForm<FormInputType>();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const { data: readyFormTypes } = useGetAllDigitalFormTypesQuery();
  const readyformtype = readyFormTypes?.find(
    (type) => type?.id == readyformtypeId
  );
  const [updateDigitalFormType] = useUpdateDigitalFormTypeMutation();

  useEffect(() => {
    if (readyformtype) {
      methods.setValue("type", readyformtype?.type ?? "");
    }
  }, [readyformtype]);

  return (
    <IncredableContent
      breadcrumbGroupProps={{
        items: [
          {
            text: "Ready Form Type",
            href: "/readyformtype",
          },
          {
            text: "Edit",
            href: "#",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="editreadyformtype"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            spinnerContext?.showSpinner();
            if (readyformtypeId) {
              try {
                await updateDigitalFormType({
                  ...data,
                  id: readyformtypeId,
                }).unwrap();
                toastContext?.openInfoNotification({
                  message: `Ready Form Type saved successfully`,
                  placement: "topRight",
                });
              } catch (error: any) {
                toastContext?.openErrorNotification({
                  message: error?.data?.errorMessage || "Failed to update ready form type",
                  placement: "topRight",
                });
              }
            }
            spinnerContext?.hidespinner();
            navigate(-1);
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Ready Form Type
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="readyformtype" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{
                              label: "Type",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `type`,
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent >
  );
}
