import {
  Box,
  Header,
  KeyValuePairs,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import IncredableContent from "../../components/incredable-content";
import { Card } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import IncredableButton from "../../components/button";
import { Attribute } from "../../redux/api/section/types";
import IncredableDateFormat from "../../common/incredable-date-format";
import {
  ProviderProfileRecordType,
  providerProfileRecordArray,
  sectionMetaData,
} from "./section-data";
import AddEditProviderProfileSection from "./add-edit-provider-profile-section";

export default function ProviderProfileSection() {
  return (
    <IncredableContent>
      <SpaceBetween size={"xxl"}>
        {/* displaying an section which can only have single record i.e personal info, professionalInfo */}
        <Card
          title={<Header> {sectionMetaData?.name} </Header>}
          extra={
            <IncredableButton
              type="primary"
              icon={<EditOutlined />}
              iconPosition="start"
              onClick={() => {}}
            >
              Edit
            </IncredableButton>
          }
        >
          <KeyValuePairs
            columns={3}
            items={
              sectionMetaData?.attributes
                ?.filter((attribute) => attribute?.type !== "Divider")
                ?.map((attribute) => {
                  return {
                    label: attribute?.name,
                    value: !!attribute?.id
                      ? renderAttribute(
                          attribute,
                          providerProfileRecordArray?.at(0)?.[attribute.id]
                        )
                      : "-",
                  };
                }) ?? []
            }
          />
        </Card>
        <Table
          columnDefinitions={sectionMetaData?.attributes
            ?.filter((attribute) => attribute?.type !== "Divider")
            ?.map((attribute) => {
              return {
                id: attribute?.id ?? "",
                header: attribute?.name ?? "",
                cell: (item: ProviderProfileRecordType) =>
                  !!attribute?.id
                    ? renderAttribute(attribute, item[attribute?.id])
                    : "-",
              };
            })}
          empty={
            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
              <b>No resources</b>
            </Box>
          }
          items={providerProfileRecordArray}
          header={
            <Header
              actions={
                <IncredableButton
                  type="primary"
                  icon={<PlusOutlined />}
                  iconPosition="start"
                  onClick={() => {}}
                >
                  Add
                </IncredableButton>
              }
            >
              {sectionMetaData?.name}
            </Header>
          }
        />
        <AddEditProviderProfileSection />
      </SpaceBetween>
    </IncredableContent>
  );
}

export const renderAttribute = (attribute: Attribute, value?: string) => {
  switch (attribute.type) {
    case "Date":
      return IncredableDateFormat(value) ?? "-";
    case "Checkbox":
      return value === "true" ? "Yes" : "No";
    case "Dropdown.Single":
      return (
        attribute?.optionDefinition?.find(
          (option) => "" + option?.value === "" + value
        )?.label ?? "-"
      );
    default:
      return value ?? "-"; //Text, Number, Email, Phone Address type
  }
};
