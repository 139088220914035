import { Layout, Space } from "antd";
import SafeStats from "./safe-stats";
import SafeDocumentsList from "./safe-documents-list";
import { useState } from "react";
import { DocumentStats } from "../redux/api/document/types";

export default function SafeListV3() {
  const [documentStatsData, setDocumentStatsData] = useState<
    DocumentStats | undefined
  >();

  return (
    <Layout style={{ margin: "12px 30px 12px 30px" }}>
      <Space size="middle" direction="vertical">
        <SafeStats documentStatsData={documentStatsData} />
        <SafeDocumentsList setDocumentStatsData={setDocumentStatsData} />
      </Space>
    </Layout>
  );
}
