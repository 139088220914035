import { Col, Divider, Form, Row, Tabs as AntTabs, ConfigProvider } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import {
  useGetBasicAllProvidersQuery,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import IncredableContent from "../../components/incredable-content";
import { alertReminderOptions } from "../../common/constants";
import RHFSelect from "../../components/RHF/RHFSelect";
import { PayerEnrollmentRequest } from "../../redux/api/payerenrollment/types";
import {
  useCreatePayerEnrollmentMutation,
  useGetAllPayerEnrollmentStatusQuery,
} from "../../redux/api/payerenrollment/payerenrollment";
import { useGetAllPayerQuery } from "../../redux/api/payers/payers";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useGetAllPayerGroupQuery } from "../../redux/api/payergroup/payergroup";
import { useGetAllWorkflowQuery } from "../../redux/api/workflow/workflow";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { useGetAllFacilityGroupQuery } from "../../redux/api/facilitygroup/facilityGroup";
import { skipToken } from "@reduxjs/toolkit/query";
import ProviderDocumentDetails from "../provider-document-details";
import StickyFooter from "../../components/StickyFooter";
import { ApplicationState } from "../../redux/store";
import { useSelector } from "react-redux";
import {
  AllDocumentResponse,
  DocumentRequest,
} from "../../redux/api/document/types";
import {
  useSaveDocumentMutation,
  useUpdateDocumentMutation,
} from "../../redux/api/document/document";
import { getFileUrl } from "../../config";
import { captureException } from "@sentry/browser";
import { fileAxios } from "../../context/axios";
import ProviderDocumentModal from "../provider-document-modal";

export default function AddProviderEnrolledPayer({
  toggleDrawer,
}: {
  toggleDrawer?: () => void;
}) {
  const { providerId } = useParams();
  const methods = useForm<PayerEnrollmentRequest>();
  const documentMethods = useForm<DocumentRequest & { documentId: string }>();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [showDocumentTab, setShowDocumentTab] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>("enrollment");
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<
    AllDocumentResponse[]
  >([]);

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const { data: status = [] } = useGetAllPayerEnrollmentStatusQuery();
  const { data: payer = [] } = useGetAllPayerQuery();
  const { data: networks = [] } = useGetAllPayerGroupQuery();
  const { data: workflows = [], refetch } = useGetAllWorkflowQuery();
  const {
    data: providers = [],
    isLoading,
    isFetching,
  } = useGetBasicAllProvidersQuery();
  const { data: facilities = [] } = useGetFacilitiesQuery();
  const { data: facilityGroup = [] } = useGetAllFacilityGroupQuery();

  useEffect(() => {
    if (providerId) {
      methods.setValue("providerId", providerId);
    }
  }, [providerId]);

  const { data: provider } = useGetProviderQuery(
    !!providerId ? { providerId } : skipToken
  );

  const [addPayerEnrollment] = useCreatePayerEnrollmentMutation();
  const [updateDocument] = useUpdateDocumentMutation();
  const [saveDocument] = useSaveDocumentMutation();

  const handleExistingDocumentSubmit = (type?: string) => {
    setIsDocumentModalOpen(false);
    setShowDocumentTab(true);
    setActiveKey("document");
    if (type == "linkDocument") {
      documentMethods.setValue("documentId", selectedDocument?.at(0)?.id || "");
      documentMethods.setValue("name", selectedDocument?.at(0)?.name + "");
      documentMethods.setValue(
        "categoryId",
        selectedDocument?.at(0)?.category?.id || ""
      );
      documentMethods.setValue("notes", selectedDocument?.at(0)?.notes);
    } else {
      setSelectedDocument([]);
      documentMethods.setValue("documentId", "");
      setIsDocumentModalOpen(false);
      setShowDocumentTab(true);
      setActiveKey("document");
    }
  };

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false)
  );

  return (
    <IncredableContent
      style={{ position: "relative", paddingTop: "10px" }}
      spinning={isLoading || isFetching}
      breadcrumbGroupProps={{
        items: [
          {
            text: "Provider",
            href: "/manageprovider",
          },
          {
            text: (
              <span onClick={toggleDrawer}>{`${
                provider?.firstName || ""
              } ${provider?.lastName || ""}`}</span>
            ),
          },
          {
            text: "Add Enrollment",
            href: "#",
          },
        ],
      }}
    >
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "#2B6BE6",
              cardBg: "#F5F5F5",
              colorBgContainer: "#2B6BE6",
              inkBarColor: "#2B6BE6",
              colorBorderSecondary: "#c6c6cd",
              fontSize: 16,
            },
          },
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <AntTabs
            defaultActiveKey="enrollment"
            size="large"
            activeKey={activeKey}
            onChange={(activeKey) => setActiveKey(activeKey)}
            tabBarExtraContent={
              <IncredableButton
                icon={<PlusOutlined />}
                onClick={() => setIsDocumentModalOpen(true)}
              >
                Add Document
              </IncredableButton>
            }
            items={[
              {
                label: (
                  <span style={{ fontWeight: "700" }}>Add Enrollment</span>
                ),
                key: "enrollment",
                children: (
                  <FormProvider {...methods}>
                    <Form
                      style={{
                        height: "calc(100vh - 250px)",
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        paddingBottom: "50px",
                      }}
                      name="addproviderenrolledpayer"
                      layout="vertical"
                      onFinish={methods.handleSubmit(
                        async (data: PayerEnrollmentRequest) => {
                          if (!!providerId) {
                            if (!data.payerId && !data.payerGroupId) {
                              toastContext?.openErrorNotification({
                                message: `Network or Payer is required.`,
                                placement: "top",
                              });
                              return;
                            }
                            if (!data.providerId && !data.facilityId) {
                              toastContext?.openErrorNotification({
                                message: `Provider or Facility is required.`,
                                placement: "top",
                              });
                              return;
                            }
                            spinnerContext?.showSpinner();
                            await addPayerEnrollment({ ...data });
                            spinnerContext?.hidespinner();
                            toastContext?.openSuccessNotification({
                              message: `Enrolled Payer saved successfully`,
                              placement: "topRight",
                            });
                            !!toggleDrawer && toggleDrawer();
                          }
                        }
                      )}
                      autoComplete="off"
                    >
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Payer" }}
                            controllerProps={{
                              name: `payerId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select payer",
                              options: payer?.map((name) => {
                                return {
                                  label: name?.name,
                                  value: name?.id,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Network" }}
                            controllerProps={{
                              name: `payerGroupId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select network",
                              options: networks?.map((name) => {
                                return {
                                  label: name?.name,
                                  value: name?.id,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Provider" }}
                            controllerProps={{
                              name: `providerId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              disabled: true,
                              style: { width: "100%" },
                              placeholder: "Select provider",
                              options: providers?.map((name) => {
                                return {
                                  label: name?.firstName + " " + name?.lastName,
                                  value: name?.id + "",
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Facility" }}
                            controllerProps={{
                              name: `facilityId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select facility",
                              options: facilities?.map((name) => {
                                return {
                                  label: name?.name + "",
                                  value: name?.id + "",
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{
                              label: "Facility Group",
                            }}
                            controllerProps={{
                              name: `facilityGroupId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select Facility Group",
                              options: facilityGroup?.map((name) => {
                                return {
                                  label: name?.name + "",
                                  value: name?.id + "",
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Status" }}
                            controllerProps={{
                              name: `enrollmentStatusId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select status",
                              options: status?.map((name) => {
                                return {
                                  label: name?.name,
                                  value: name?.id,
                                };
                              }),
                              onModifyClick: () => {
                                navigate("/payerenrollmentstatus");
                              },
                            }}
                            rules={{
                              required: "This field is required",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Submitted Date",
                            }}
                            controllerProps={{
                              name: `submittedDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Approved Date",
                            }}
                            controllerProps={{
                              name: `approvedDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Effective Date",
                            }}
                            controllerProps={{
                              name: `effectiveDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Re-Enrollment Date",
                            }}
                            controllerProps={{
                              name: `recredentialingDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Payer Provider Id",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `payerProviderId`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{
                              label: "Follow Up Date",
                            }}
                            controllerProps={{
                              name: `alertDays`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select Days",
                              options: alertReminderOptions?.map((alert) => {
                                return {
                                  label: alert?.label,
                                  value: alert?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={12}>
                          <RHFSelect
                            formItemProps={{ label: "Workflows" }}
                            controllerProps={{
                              name: `workflowId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select workflow",
                              options: workflows?.map((name) => {
                                return {
                                  label: name?.name,
                                  value: name?.id,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col
                          span={12}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <ReloadOutlined
                            size={20}
                            style={{ marginLeft: "10px", marginTop: "42px" }}
                            onClick={() => refetch()}
                          />
                          <IncredableButton
                            style={{
                              borderColor: "#598BEB",
                              color: "#598BEB",
                              marginLeft: "10px",
                            }}
                            secondary
                            onClick={() => navigate("/workflows/add")}
                          >
                            Create New Workflow
                          </IncredableButton>
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={24}>
                          <RHFTextArea
                            formItemProps={{ label: "Notes" }}
                            controllerProps={{
                              name: "description",
                            }}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </FormProvider>
                ),
              },
              {
                label: <span style={{ fontWeight: "700" }}>Document</span>,
                key: "document",
                children: (
                  <FormProvider {...documentMethods}>
                    <ConfigProvider
                      theme={{
                        components: {
                          Form: {
                            verticalLabelMargin: -4,
                            itemMarginBottom: 16,
                          },
                        },
                      }}
                    >
                      <Form
                        style={{
                          height: "calc(100vh - 250px)",
                          overflowY: "scroll",
                          scrollbarWidth: "none",
                          paddingBottom: "30px",
                        }}
                        onFinish={documentMethods.handleSubmit(
                          async (data: DocumentRequest) => {
                            console.log("document methods");
                          }
                        )}
                        layout="vertical"
                        size="middle"
                        autoComplete="off"
                      >
                        <ProviderDocumentDetails
                          documentId={documentMethods?.getValues("documentId")}
                          uploadedFile={uploadedFile}
                          setUploadedFile={setUploadedFile}
                        />
                      </Form>
                    </ConfigProvider>
                  </FormProvider>
                ),
              },
            ].filter((item) =>
              showDocumentTab ? item : item.key !== "document"
            )}
          />
          <ProviderDocumentModal
            providerId={providerId}
            isDocumentModalOpen={isDocumentModalOpen}
            setIsDocumentModalOpen={setIsDocumentModalOpen}
            selectedDocument={selectedDocument}
            setSelectedDocument={setSelectedDocument}
            handleExistingDocumentSubmit={handleExistingDocumentSubmit}
          />
          <StickyFooter
            hasPermission={true}
            onSubmit={async () => {
              const hasDocumentValues =
                documentMethods.getValues("alertDays") ||
                documentMethods.getValues("expirationDate") ||
                documentMethods.getValues("name") ||
                documentMethods.getValues("file");

              const handleProviderEnrolledPayersSubmit = async (
                documentId?: string
              ): Promise<void> => {
                methods.handleSubmit(async (data: PayerEnrollmentRequest) => {
                  if (!!providerId) {
                    if (!data.payerId && !data.payerGroupId) {
                      toastContext?.openErrorNotification({
                        message: `Network or Payer is required.`,
                        placement: "top",
                      });
                      return;
                    }
                    if (!data.providerId && !data.facilityId) {
                      toastContext?.openErrorNotification({
                        message: `Provider or Facility is required.`,
                        placement: "top",
                      });
                      return;
                    }
                    spinnerContext?.showSpinner();
                    await addPayerEnrollment({
                      ...data,
                      ...(documentId && { documentId }),
                    });
                    spinnerContext?.hidespinner();
                    toastContext?.openSuccessNotification({
                      message: "Enrolled Payer saved successfully",
                      placement: "topRight",
                    });
                    !!toggleDrawer && toggleDrawer();
                  }
                })();
              };

              const handleFileUpload = async (
                key: string,
                file?: File
              ): Promise<void> => {
                if (!!file && key) {
                  try {
                    await fileAxios.put(getFileUrl(key), file);
                    toastContext?.openSuccessNotification({
                      message: `Document saved successfully`,
                      placement: "topRight",
                    });
                  } catch (error) {
                    captureException(error);
                    toastContext?.openErrorNotification({
                      message: `Failed to save Document`,
                      placement: "topRight",
                    });
                  }
                }
              };

              if (!hasDocumentValues) {
                handleProviderEnrolledPayersSubmit();
              } else {
                const documentId = documentMethods.getValues("documentId");

                const handleDocumentUpdate = async (
                  data: DocumentRequest & { documentId: string }
                ): Promise<void> => {
                  try {
                    const result = await updateDocument({
                      documentId: data.documentId,
                      isVersioned: false,
                      document: {
                        ...data,
                        file: uploadedFile,
                        providerId,
                      },
                    }).unwrap();

                    await handleProviderEnrolledPayersSubmit(result?.id);
                    await handleFileUpload(
                      result?.attachment?.key,
                      uploadedFile
                    );
                    spinnerContext?.hidespinner();
                    toastContext?.openSuccessNotification({
                      message: `Document updated successfully`,
                      placement: "topRight",
                    });
                  } catch (error) {
                    spinnerContext?.hidespinner();
                    toastContext?.openErrorNotification({
                      message: `Document update failed`,
                      placement: "topRight",
                    });
                  }
                };

                const handleDocumentSave = async (
                  data: DocumentRequest
                ): Promise<void> => {
                  try {
                    const documentResponse = await saveDocument({
                      document: {
                        ...data,
                        file: uploadedFile,
                        providerId,
                      },
                    }).unwrap();

                    await handleProviderEnrolledPayersSubmit(
                      documentResponse?.id
                    );
                    await handleFileUpload(
                      documentResponse?.attachment?.key,
                      uploadedFile
                    );
                  } catch (error) {
                    captureException(error);
                    toastContext?.openErrorNotification({
                      message: `Failed to save Document`,
                      placement: "topRight",
                    });
                  }
                };

                documentMethods.handleSubmit(
                  documentId ? handleDocumentUpdate : handleDocumentSave
                )();
              }
            }}
            onCancel={() => {
              !!toggleDrawer && toggleDrawer();
            }}
          />
        </div>
      </ConfigProvider>
    </IncredableContent>
  );
}
